export const medicalTourism = {
    title: "Medicinski turizam",
    description: "Medicinski turizam je praksa putovanja u inozemstvo radi liječenja. U prošlosti se to obično odnosilo na one koji su putovali iz manje razvijenih zemalja u glavne medicinske centre u visoko razvijenim zemljama radi liječenja koje nije bilo dostupno kod kuće.",
    servicesTitle: "Naše usluge",
    servicesDescription: "Pružamo vrhunske usluge našim klijentima s fokusom na kvalitetu i zadovoljstvo kupaca. Naš tim stručnjaka ovdje je da vam pomogne s bilo kojom potrebom koju možete imati. Slobodno nam se obratite putem našeg kontakt obrasca.",
    cards: [
      {
        title: "Plastična hirurgija",
        price: ["$", "4999", ""],
        options: [
          "Konzultacije",
          "Prethodni pregled",
          "Nakon operativna njega",
          "Kontrolni pregledi",
        ],
        path: "/services/plastic-surgery",
      },
      {
        title: "Stomatologija",
        price: ["$", "999", ""],
        options: [
          "Konzultacije",
          "Izbjeljivanje zuba",
          "Liječenje karijesa",
          "Kontrolni pregledi",
        ],
        path: "/services/dentistry",
      },
      {
        title: "Hirurgija mršavljenja",
        price: ["$", "7999", ""],
        options: [
          "Konzultacije",
          "Prethodni pregled",
          "Nakon operativna njega",
          "Kontrolni pregledi",
        ],
        path: "/services/weight-loss-surgery",
      },
    ],
  };
  