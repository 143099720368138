const translationsEn = {
    title: "Contact Us",
    emailLabel: "Your Email",
    messageLabel: "Your Message",
    sendMessage: "Send message",
    emailError: "Please enter a valid email address.",
    successMessage: "Your message has been sent successfully!",
    cta: "Send us an email!",
    
  };
  
  export default translationsEn;
  