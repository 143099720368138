import React, { useContext } from "react";
import { Typography } from "@material-tailwind/react";
import { LanguageContext } from "../context/LanguageProvider";
import TrustedByLogos from "../components/TrustedByLogos";

export function HeroSection15() {
  const { language } = useContext(LanguageContext);

  const translations = {
    en: {
      title: "Connecting Borders and Enhancing Lives",
      description:
        "From reliable logistics to premium products and unique solutions for medical tourism, Bomonti is your trusted global partner.",
    },
    bs: {
      title: "pomažemo vam da spojite svijet",
      description:
        " Vrhunska logistika, kvalitetni proizvodi i unikatna rješenja po pitanju medicinskog turizma, uz pouzdanost i izvrsnost. Bomonti je vaš najpouzdaniji partner.",
    },
  };

  return (
    <div className="relative min-h-[80vh] sm:min-h-screen w-full overflow-hidden">
    {/* Embedded Video Background */}
    <div className="absolute inset-0 z-0">  
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute w-full h-full object-cover"
      >
        <source src="/compressedbg.mp4" type="video/mp4"/>
      </video>
  
      {/* Fallback Background Image */}
      <div
        className="absolute inset-0 w-full h-full object-cover"
        style={{
          backgroundImage: 'url("/fallback-image.jpg")',
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </div>
  
    {/* Overlay for darkening the background video */}
    <div className="absolute inset-0 z-10 bg-black/50" />
  
    {/* Main Content */}
    <div className="relative z-20 flex min-h-[80vh] sm:min-h-screen flex-col items-center justify-center px-8">
      {/* Text Section */}
      <div className="text-center mb-12">
        <Typography
          variant="h1"
          color="white"
          className="my-4 text-4xl sm:text-5xl !leading-snug lg:text-7xl font-gilory uppercase"
        >
          Connecting Borders and Enhancing Lives
        </Typography>
        <Typography
          variant="lead"
          color="white"
          className="mb-0 max-w-3xl mx-auto text-gray-300 font-gilory text-xl md:text-2xl"
        >
          From reliable logistics to premium products and unique solutions for
          medical tourism, Bomonti is your trusted global partner.
        </Typography>
      </div>
  
      {/* Logos Section */}
      <div className="absolute bottom-0 sm:bottom-4 md:bottom-8 mt-20">
        <TrustedByLogos />
      </div>
    </div>
  </div>  
  );
}

export default HeroSection15;
