"use client";

import { cn } from "../lib/utils";
import { AnimatedList } from "./ui/AnimatedList";
import { FaTooth } from "react-icons/fa"; // Dentistry
import { FaUserMd } from "react-icons/fa"; // Plastic Surgery
import { BiBody } from "react-icons/bi"; // Weight Loss Surgery

const getIcon = (title) => {
  switch (title) {
    case "Dentistry":
    case "Stomatologija":
      return <FaTooth className="text-6xl text-white" />;
    case "Plastic Surgery":
    case "Plastična hirurgija":
      return <FaUserMd className="text-6xl text-white" />;
    case "Weight Loss Surgery":
    case "Hirurgija mršavljenja":
      return <BiBody className="text-6xl text-white" />;
    default:
      return null;
  }
};

let notifications = [
  {
    name: "Plastic Surgery",
    description: "Magic UI",
    icon: getIcon("Plastic Surgery"),
    color: "#00C9A7",
    url: "/services/plastic-surgery",
  },
  {
    name: "Dentistry",
    description: "Magic UI",
    icon: getIcon("Dentistry"),
    color: "#FFB800",
    url: "/services/dentistry",
  },
  {
    name: "Weight Loss Surgery",
    description: "Magic UI",
    icon: getIcon("Weight Loss Surgery"),
    color: "#FF3D71",
    url: "/services/weight-loss-surgery",
  },
];

const Notification = ({ name, url, icon, color }) => {
  return (
    <figure
      className={cn(
        "relative mx-auto flex-grow w-full cursor-pointer overflow-hidden rounded-2xl p-4",
        // animation styles
        "transition-all duration-200 ease-in-out hover:scale-[103%]",
        // light styles
        "bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]",
        // dark styles
        "transform-gpu dark:bg-transparent dark:backdrop-blur-md dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-20px_80px_-20px_#ffffff1f_inset]"
      )}
    >
      <a href={url} className="flex flex-row items-center gap-3">
        <div
          className="flex size-20 items-center justify-center rounded-2xl"
          style={{
            backgroundColor: color,
          }}
        >
          <span className="text-lg">{icon}</span>
        </div>
        <div className="flex flex-col overflow-hidden">
          <figcaption className="flex flex-row items-center whitespace-pre text-lg font-medium dark:text-white">
            <span className="text-sm sm:text-lg text-black font-semibold">{name}</span>
            <span className="mx-1">·</span>
          </figcaption>
          {/* <p className="text-sm font-normal dark:text-white/60">
            {description}
          </p> */}
        </div>
      </a>
    </figure>
  );
};

export function OurOffer({ className }) {
  return (
    <div
      className={cn(
        "relative flex h-full w-full flex-col p-6 overflow-hidden rounded-lg border bg-background md:shadow-xl",
        className
      )}
    >
      <AnimatedList className="flex-grow flex justify-center">
        {notifications.map((item, idx) => {
          return <Notification {...item} key={idx} />;
        })}
      </AnimatedList>
    </div>
  );
}
