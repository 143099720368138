import { cn } from "../../lib/utils";

const BentoGrid = ({ children, className }) => {
  return (
    <div
      className={cn(
        "relative grid w-full auto-rows-[30rem] grid-cols-3 gap-4",
        className
      )}
    >
      {children}
    </div>
  );
};

const BentoCard = ({
  name,
  className,
  background,
  description,
  children,
  additionalStyling,
  idx,
}) => (
  <div
    key={name}
    className={
      (additionalStyling && cn(additionalStyling, className)) ||
      cn(
        "group h-full relative col-span-3 flex flex-col rounded-xl overflow-hidden",
        // light styles
        `${
          idx === 3
            ? ""
            : "bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]"
        }`,
        // dark styles
        "dark:bg-black dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-20px_80px_-20px_#ffffff1f_inset]",
        className
      )
    }
  >
    <div className="absolute inset-0">{children}</div>{" "}
    {/* Make children cover the whole component */}
    <div>{background}</div> {/* Optional background div */}
    <div className="flex flex-col gap-1 p-6 relative z-10 items-center">
      {" "}
      {/* Ensure this content is above children */}
      <h3 className="text-3xl font-semibold text-neutral-900 dark:text-neutral-300">
        {name}
      </h3>
      <p className="max-w-lg text-neutral-400">{description}</p>
    </div>
  </div>
);

export { BentoCard, BentoGrid };
