import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from "./shared/Navigation";
import HeroSection15 from "./pages/HeroSection15";
import AboutUs from "./pages/AboutUs";
import ProductList from "./pages/ProductList";
import { Services } from "./pages/Services";
import ContactSection14 from "./pages/ContactSection14";
import ProductDetails from "./components/ProductDetails";
import ServiceDetail from "./pages/ServiceDetail";
import ScrollToTop from "./components/ScrollToTop";
import TestimonialSection from "./components/TestimonialSection";
import { LanguageProvider } from "./context/LanguageProvider";
import { ProductsProvider } from "./context/ProductsProvider";
import sendMail from "./services/sendEmail";
import PreLoader from "./components/PreLoader";
import MedicalTourism from "./components/MedicalTourism";
import { ImportExport } from "./components/ImportExport";
import Transportation from "./components/Transportation";
import MedicalTourismServiceCards from "./components/MedicalTourismServiceCards";
import PreLoaderMedicalTourism from "./components/ui/PreLoaderMedicalTourism";
import AutoPlayVideo from "./components/AutoPlayVideo";

const App = () => {
  const [loading, setLoading] = useState(true);
  const [hidden, setHidden] = useState(true);
  const [rightAfterHidden, setRightAfterHidden] = useState(true);
  const [initialDelay, setInitialDelay] = useState(4100);

  const aboutUsRef = useRef(null);
  const productsRef = useRef(null);
  const serviceRef = useRef(null);
  const contactUsRef = useRef(null);
  const importExportRef = useRef(null);
  const transportationRef = useRef(null);
  const medicalTourismRef = useRef(null);

  const scrollToSection = (sectionRef) => {
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setHidden(false);
    }, 4000);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    setTimeout(() => {
      setInitialDelay(2000);
    }, 5000);
   
  }, []);
  
  useEffect(() => {
    setTimeout(() => {
      setRightAfterHidden(false);
    },4100);
    setTimeout(() => {
      setInitialDelay(2000);
    }, 5000);
  }, [hidden])

  
  

  return (
    <LanguageProvider>
      <ProductsProvider>
        <div className="relative">
          <Router>
            <Navigation
              scrollToSection={scrollToSection}
              aboutUsRef={aboutUsRef}
              productsRef={productsRef}
              serviceRef={serviceRef}
              contactUsRef={contactUsRef}
              importExportRef={importExportRef}
              transportationRef={transportationRef}
              medicalTourismRef={medicalTourismRef}
            />
            <Routes>
              <Route path="/pages">
                <Route
                  path="medicaltourism"
                  element={
                    <>
                      <PreLoaderMedicalTourism loading={loading} />
                      <div className="lg:w-[80%] md:w-[85%] sm:w-[90%] w-[95%] mx-auto mb-10">
                        <div className="pt-20">
                          <AutoPlayVideo
                            videoSrc="/betterminela.mp4"
                            thumbnailSrc="/img/thumbnail.jpg"
                            thumbnailAlt="Video thumbnail"
                            delay={initialDelay}
                          />
                        </div>
                      </div>
                      <div
                        className={`transition-opacity duration-1000 ${
                          hidden ? "hidden" : ""
                        } ${
                          !rightAfterHidden ? "opacity-100" : "opacity-0"
                        }`}
                      >
                        <MedicalTourism isSeperatePage={true} />
                        <div className="bg-primary mx-auto pt-8">
                          <MedicalTourismServiceCards />
                        </div>
                        <TestimonialSection />
                        <div ref={contactUsRef}>
                          <ContactSection14 />
                        </div>
                      </div>
                    </>
                  }
                />
                <Route
                  path="import"
                  element={
                    <div>
                      <ImportExport mapBool={true} />
                      <div ref={contactUsRef} className={"pt-[100px]"}>
                        <ContactSection14 />
                      </div>
                    </div>
                  }
                />
                <Route
                  path="transportation"
                  element={
                    <div>
                      <Transportation mapBool={true} />
                      <div ref={contactUsRef}>
                        <ContactSection14 />
                      </div>
                    </div>
                  }
                />
                <Route
                  path="products"
                  element={
                    <div>
                      <ProductList className={"pt-[120px] mb-[200px]"} />
                      <div ref={contactUsRef}>
                        <ContactSection14 className={"pt-[30px]"} />
                      </div>
                    </div>
                  }
                />
                <Route
                  path="aboutus"
                  element={
                    <div>
                      <AboutUs className={"pt-[120px]"} />
                      <div ref={contactUsRef}>
                        <ContactSection14 />
                      </div>
                    </div>
                  }
                />
              </Route>
              <Route
                path="/"
                element={
                  <div>
                    <PreLoader loading={loading} />
                    <MainContent
                      className={hidden ? "hidden" : ""}
                      aboutUsRef={aboutUsRef}
                      productsRef={productsRef}
                      serviceRef={serviceRef}
                      contactUsRef={contactUsRef}
                      importExportRef={importExportRef}
                      transportationRef={transportationRef}
                      medicalTourismRef={medicalTourismRef}
                    />
                  </div>
                }
              />
              <Route path="/product/:id" element={<ProductDetails />} />

              <Route path="/services/:id" element={<ServiceDetail />} />
            </Routes>
          </Router>
        </div>
      </ProductsProvider>
    </LanguageProvider>
  );
};

const MainContent = ({
  aboutUsRef,
  productsRef,
  serviceRef,
  contactUsRef,
  importExportRef,
  transportationRef,
  medicalTourismRef,
  className,
}) => {
  return (
    <>
      <div>
        <HeroSection15 />
      </div>
      <div ref={aboutUsRef} className={className}>
        <AboutUs />
      </div>
      <div ref={productsRef} className={className}>
        <ProductList />
      </div>
      <div ref={serviceRef} className={className}>
        <Services
          importExportRef={importExportRef}
          transportationRef={transportationRef}
          medicalTourismRef={medicalTourismRef}
        />
      </div>
      <div className={className}>
        <TestimonialSection />
      </div>
      <div ref={contactUsRef} className={className}>
        <ContactSection14 className={"pt-[100px]"} />
      </div>
      <ScrollToTop />
    </>
  );
};

export default App;
