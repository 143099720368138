import React, { useEffect } from "react";
import { Typography } from "@material-tailwind/react";

export function Popup({ message, onClose }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000); // Hide after 3 seconds

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="fixed top-4 right-4 bg-green-500 text-white p-4 rounded shadow-lg z-50">
      <Typography variant="small" className="font-medium">
        {message}
      </Typography>
    </div>
  );
}

export default Popup;
