import React, { useContext, useEffect, useState } from "react";
import { Typography } from "@material-tailwind/react";
import { LanguageContext } from "../context/LanguageProvider";
import BoxReveal from "../components/ui/BoxReveal";
import { IoIosGlobe } from "react-icons/io";
import { PiTruckTrailerLight } from "react-icons/pi";
import { IoSparklesOutline } from "react-icons/io5";
import { BsAirplaneEngines } from "react-icons/bs";

export function AboutUs({className}) {
  const { language } = useContext(LanguageContext);
  const imagesURL = ["/img/truck1.jpg", "/img/truck2.jpg", "/img/truck3.jpg"];
  const [currentImg, setCurrentImg] = useState(0);
  const [isWhiteScreen, setIsWhiteScreen] = useState(false);

  useEffect(() => {
    const imageSwitchTimeout = setTimeout(() => {
      setIsWhiteScreen(true);
      setTimeout(() => {
        setCurrentImg((prevImg) => (prevImg === 2 ? 0 : prevImg + 1));
        setIsWhiteScreen(false);
      }, 1000);
    }, 7000);

    return () => clearTimeout(imageSwitchTimeout);
  }, [currentImg]);

  const translations = {
    en: {
      title: "What makes us different?",
      descriptionP1:
        "Bomonti Group began its journey in Bosnia and Herzegovina as Carbone, a dedicated transportation company. For over 20 years, Carbone has supported businesses across Bosnia with reliable and essential transportation solutions.",
      descriptionP2:
        "Building on its solid foundation in goods transportation, Bomonti expanded its services to include import operations, now offering a diverse range of products.",
      descriptionP3:
        "Bomonti now offers goods transportation across a wide network. This expanded reach allows us to connect clients with key markets throughout the region, delivering efficient transportation solutions.",
      descriptionP4:
        "We decidced to enter the field of medical tourism, offering specialized support in plastic surgery, dentistry, and weight loss surgery. We connect clients with top medical centers and expert care, ensuring a seamless experience from start to finish.",
    },
    bs: {
      title: "Šta nas čini drugačijima?",
      descriptionP1:
        "Bomonti je počeo svoje putovanje u Bosni i Hercegovini pod firmom Carbone, kompanijom koja se bavi transportom dobara. Već više od 20 godina, Carbone pruža pouzdana rješenja za transport dobara širom Bosne.",
      descriptionP2:
        "Uz čvrst temelj u području transporta dobara, proširili smo svoje usluge kako bismo uključili i uvoz robe, nudeći raznovrsnu ponudu proizvoda. Naš cilj je omogućiti klijentima lakši pristup visokokvalitetnim proizvodima uz efikasan proces uvoza.",
      descriptionP3:
        "Sada transportujemo robu na širem nivou, te nam ova proširena mreža omogućava povezivanje klijenata s ključnim tržištima u regiji. Naša posvećenost preciznosti i efikasnosti osigurava da svaki transport bude realizovan na najvišem nivou.",
      descriptionP4:
        "Također smo se odlučili za ulazak u oblast medicinskog turizma, sa specijalizacijom u plastičnoj hirurgiji, stomatologiji i operacijama mršavljenja. Povezujemo klijente s vrhunskim bolnicama, osiguravajući besprijekorno iskustvo od početka do kraja tretmana.",
    }    
  };

  return (
    <section className={`relative ${className}`}>
      <div className="flex flex-col md:flex-row w-full h-full ">
        <div className="flex flex-col justify-center w-full md:w-1/2 h-full p-8">
          <div className="text-center sm:text-left">
            <BoxReveal boxColor={"#183f99"} duration={0.5}>
              <Typography
                variant="h2"
                className="text-3xl mb-4 sm:mb-6 md:mb-8 lg:mb-10 lg:text-5xl font-bold tracking-wide text-gray-900 sm:text-2xl"
              >
                {translations[language].title}
              </Typography>
            </BoxReveal>
            <BoxReveal boxColor={"#183f99"} duration={1}>
              <Typography
                variant="lead"
                className="flex items-center justify-center font-normal text-gray-800 leading-relaxed text-base sm:text-lg mb-6"
              >
                <IoSparklesOutline
                  size={100}
                  className="mr-4 md:mr-10 text-primary"
                />
                {translations[language].descriptionP1}
              </Typography>
            </BoxReveal>
            <BoxReveal boxColor={"#183f99"} duration={1.3}>
              <Typography
                variant="lead"
                className="flex items-center justify-center font-normal text-gray-800 leading-relaxed text-base sm:text-lg mb-6"
              >
                <IoIosGlobe size={100} className="mr-4 md:mr-10 text-primary" />
                {translations[language].descriptionP2}
              </Typography>
            </BoxReveal>
            <BoxReveal boxColor={"#183f99"} duration={1.1}>
              <Typography
                variant="lead"
                className="flex items-center justify-center font-normal text-gray-800 leading-relaxed text-base sm:text-lg mb-6"
              >
                <PiTruckTrailerLight
                  size={100}
                  className="mr-4 md:mr-10 text-primary"
                />
                {translations[language].descriptionP3}
              </Typography>
            </BoxReveal>
            <BoxReveal boxColor={"#183f99"} duration={1.1}>
              <Typography
                variant="lead"
                className="flex items-center justify-center font-normal text-gray-800 leading-relaxed text-base sm:text-lg mb-6"
              >
                <BsAirplaneEngines
                  size={100}
                  className="mr-4 md:mr-10 text-primary"
                />
                {translations[language].descriptionP4}
              </Typography>
            </BoxReveal>
          </div>
        </div>
        <div className="relative w-full md:w-1/2 h-full mt-6 md:mt-0">
          <div
            style={{
              backgroundImage: `url(${imagesURL[currentImg]})`,
              backgroundColor: "white",
              transition: "opacity 1s ease-in-out",
              opacity: isWhiteScreen ? 0 : 1,
            }}
            className="h-[400px] md:h-[600px] w-full bg-cover bg-center bg-no-repeat"
          ></div>
          <div
            style={{
              transition: "opacity 1s ease-in-out",
              backgroundColor: "white",
              opacity: isWhiteScreen ? 1 : 0,
            }}
            className="absolute top-0 left-0 right-0 bottom-0"
          ></div>
          <div className="md:absolute md:right-0 md:inset-0 md:bg-gradient-to-r md:from-white md:to-transparent md:z-10"></div>
          <div className="md:absolute md:bottom-0 md:left-0 md:right-0 h-1/2 md:bg-gradient-to-t md:from-white md:to-transparent"></div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
