import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../context/LanguageProvider";
import translationsEn from "../data/language/products.en";
import translationsBs from "../data/language/products.bs";
import { ProductsContext } from "../context/ProductsProvider";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { AiOutlineShopping } from "react-icons/ai";
import { IoArrowDown } from "react-icons/io5";
import { BlurFade } from "../components/ui/BlurFade";


export function ProductList({ initialListProducts, className}) {
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  const { products, updateProducts, popProducts, productsHistory } =
    useContext(ProductsContext);
  const [listProducts, setListProducts] = useState(initialListProducts || []);
  const scrollTop = useRef(null)

  const translations = language === "en" ? translationsEn : translationsBs;

  useEffect(() => {
    setListProducts(
      translations.products.filter((p) => products.includes(p.id))
    );
  }, [products, translations]);

  const handleReadMore = (productId) => {
    navigate(`/product/${productId}`);
    scrollTop.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleRenderChildren = (product) => {
    updateProducts(product.children);
    const childProducts = translations.products.filter((p) =>
      product.children.includes(p.id)
    );
    setListProducts(childProducts);
    if (scrollTop.current) {
      scrollTop.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  const handleGoBack = () => {
    if (productsHistory.length >= 1) {
      popProducts();
    } else {
      navigate(`/`);
    }
    if (scrollTop.current) {
      scrollTop.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  console.log(productsHistory);
  return (
    <div className={`relative pb-10 px-6 sm:mt-10 w-full ${className}`} >
      <div ref={scrollTop}></div>
      {productsHistory.length > 0 ? (
        <button
          onClick={() => handleGoBack()}
          className="p-4 rounded-full bg-primary text-white hover:text-primary hover:bg-white transition mb-20"
        >
          <ArrowLeftIcon className="h-6 w-6" />
        </button>
      ) : (
        <div className="h-24 w-full mb-10 flex items-center justify-center text-primary font-black text-5xl tracking-widest">
          <IoArrowDown size={40} className="text-primary" />
          <AiOutlineShopping size={80} className="text-primary pb-6" />
          <IoArrowDown size={40} className="text-primary" />
        </div>
      )}
      <section className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {listProducts.map((product, idx) => (
          <BlurFade key={product.id} delay={0.25 + idx * 0.05} inView>
            <div
              onClick={() => {
                if (product.children && product.children.length > 0) {
                  handleRenderChildren(product);
                } else {
                  handleReadMore(product.id);
                }
              }}
              className="relative w-full lg:w-full h-full max-w-sm mx-auto bg-white border border-gray-200 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out dark:bg-gray-800 dark:border-gray-700 cursor-pointer"
            >
              {product.images && product.images.length > 0 ? (
                <img
                  className="rounded-t-lg w-full h-[250px] object-cover"
                  src={product.images[0]}
                  alt={product.name}
                />
              ) : (
                <div className="rounded-t-lg w-full h-[250px] flex items-center justify-center bg-gray-200">
                  <p className="text-gray-500">No image available</p>
                </div>
              )}
              <div className="p-5 pb-14">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {product.name}
                </h5>
                {product.details && (
                  <p className="font-normal mb-2 text-gray-700 dark:text-gray-400">
                    {product.details.join(", ")}
                  </p>
                )}
              </div>
              <div className="mt-4">
                {product.children && product.children.length > 0 ? (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRenderChildren(product);
                    }}
                    className="absolute bottom-5 left-5 font-semibold text-white bg-primary border border-white hover:bg-white hover:text-black hover:border-black transition-color drop-shadow-2xl hover:drop-shadow-none py-2 px-4 rounded-md transition-colors duration-200"
                  >
                    {translations.readMore}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleReadMore(product.id);
                    }}
                    className="absolute bottom-5 left-5 font-semibold text-white bg-primary border border-white hover:bg-white hover:text-black hover:border-black transition-color drop-shadow-2xl hover:drop-shadow-none py-2 px-4 rounded-md transition-colors duration-200"
                  >
                    {translations.readMore}
                  </button>
                )}
              </div>
            </div>
          </BlurFade>
        ))}
      </section>
    </div>
  );
}

export default ProductList;

