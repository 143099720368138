import React, { useContext, useState, useEffect } from "react";
import { BentoCard, BentoGrid } from "./ui/Bento";
import { importAndExportBS } from "../data/language/services.bs";
import { importAndExportENG } from "../data/language/services.en";
import { LanguageContext } from "../context/LanguageProvider";
import ContactForm from "./ContactForm";
import { OrbitingCirclesDemo } from "./OrbitingCirclesDemo";
import { DotPattern } from "./ui/DotPattern";
import { cn } from "../lib/utils";
import MapChart from "./MapChart";
import { Typography } from "@material-tailwind/react";
import { ServicesDescription } from "./ServicesDescription";


export const ImportExport = ({mapBool}) => {
  const { language } = useContext(LanguageContext);
  const content = language == "en" ? importAndExportENG : importAndExportBS;

  const [isMapActive, setIsMapActive] = useState(mapBool)
  const [geoData, setGeoData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTimeout((() => {}, 6000))
        const response = await fetch(
          "https://api.maptiler.com/data/3bcde533-6282-4741-9c15-a7c69c9ae58d/features.json?key=tkc8TThBxt6WcapX6R2g"
        );
        const data = await response.json();
        setGeoData(data);
        setTimeout(() => setIsMapActive(true), 4000)
      } catch (error) {
        console.error("Error fetching the GeoJSON data:", error);
      }
    };

    fetchData();
  }, []);

  const features = [
    {
      name: content.title,
      description: <ServicesDescription content={content} />,
      href: content.imageUrl,
      cta: content.cta,
      className:
        "lg:col-start-1 lg:col-end-2 col-start-1 col-end-4 row-start-1",
      background: <div></div>,
      children: <div></div>,
    },
    {
      name: "",
      description: "",
      href: "",
      cta: "",
      className:
        "lg:col-start-2 lg:col-end-4 col-start-1 col-end-4 row-start-4 lg:row-start-1",
      background: <div></div>,
      children: <ContactForm subject={content.title} message={content.subTitle} className={"h-full"} />,
    },
    {
      name: "",
      description: "",
      href: "",
      className:
        "lg:col-start-1 lg:col-end-3 col-start-1 col-end-4 row-start-2 row-end-2 lg:row-start-2",
      background: <div></div>,
      children: (
        <div className="relative">
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              zIndex: 20,
              background: "black",
            }}
          >
           {isMapActive && <MapChart geoData={geoData} center={[36.455175, 40.300258]} />}
          </div>
        </div>
      ),
    },
    {
      name: "",
      description: "",
      href: "",
      cta: "",
      className:
        "lg:col-start-3 lg:col-end-4 col-start-1 col-end-4 row-start-3 row-end-3 lg:row-start-2",
      children: <OrbitingCirclesDemo />,
    },
  ];

  return (
    <div className="relative flex justify-center items-center w-full px-4 sm:px-10 md:px-14 lg:px-6 xl:px-20 py-40 border-solid border-primary border-b-2">
      <div
        className="absolute inset-0 bg-gradient-to-b from-transparent to-gray-200"
        style={{ zIndex: -1 }}
      />
      <BentoGrid className="relative">
        {features.map((feature, idx) => (
          <BentoCard className="relative" key={idx} idx={idx} {...feature} />
        ))}
      </BentoGrid>
    </div>

  );
};
