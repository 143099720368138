import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";

export default function App({ geoData, center }) {
  return (
    <MapContainer
      center={center}
      zoom={4}
      style={{ width: "100%", height: "500px" }}
    >
      <TileLayer
        url="https://api.maptiler.com/maps/streets-v2/256/{z}/{x}/{y}.png?key=tkc8TThBxt6WcapX6R2g"
        attribution='<a  href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
      />
      {/* Render GeoJSON data only when it's loaded */}
      {geoData && <GeoJSON data={geoData.features} />}
    </MapContainer>
  );
}
