import React, { useContext } from "react"; 
import { medicalTourismBS } from "../data/language/services.bs";
import { medicalTourismENG } from "../data/language/services.en";
import { LanguageContext } from "../context/LanguageProvider";
import ContactForm from "./ContactForm";
import { BentoCard, BentoGrid } from "./ui/Bento";
import { OurOffer } from "./OurOffer";
import { HeroVideoDialog } from "./ui/HeroVideoDialog";
import { ServicesDescription } from "./ServicesDescription";
import AutoPlayVideo from "./AutoPlayVideo";

function MedicalTourism({isSeperatePage = false}) {
  const { language } = useContext(LanguageContext);
  const content = language === "en" ? medicalTourismENG : medicalTourismBS;

  const features = [
    {
      name: "",
      description: "",
      href: "",
      className:
        "lg:col-start-1 lg:col-end-2 col-start-1 col-end-4 row-start-1 lg:row-end-2 lg:block hidden ",
      background: <div></div>,
      children: <OurOffer />,
    },
    {
      name: content.title,
      description: <ServicesDescription content={content} className={""} />,
      href: content.imageUrl,
      className:
        "lg:col-start-2 lg:col-end-3 row-start-1 row-end-2 lg:row-start-1 lg:row-end-2",
      background: <div></div>,
      children: null,
    },
    {
      name: "",
      description: "",
      href: "",
      className: "lg:col-start-3 lg:col-end-4 lg:row-start-1 row-start-2 row-end-2",
      background: <div></div>,
      children: <ContactForm subject={content.title} message={content.subTitle} className={"h-full"} />,
    }
  ];

  return (
    <>
    {isSeperatePage ? ( <div className="relative w-full px-4 sm:px-10 md:px-14 lg:px-20 py-10 transition-all duration-700">
      {/* Background SVG */}
      <div className="absolute bottom-0 left-0 right-0 z-0">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#183f99"
            fillOpacity="1"
            d="M0,96L30,106.7C60,117,120,139,180,138.7C240,139,300,117,360,117.3C420,117,480,139,540,128C600,117,660,75,720,58.7C780,43,840,53,900,69.3C960,85,1020,107,1080,133.3C1140,160,1200,192,1260,202.7C1320,213,1380,203,1410,197.3L1440,192L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
          ></path>
        </svg>
      </div>

      

      {/* BentoGrid (Cards) */}
      <div className="relative z-10">
        <BentoGrid>
          {features.map((feature, idx) => (
            <BentoCard className="relative" key={idx} {...feature} />
          ))}
        </BentoGrid>
      </div>

      {/* Render HeroVideoDialog BELOW the BentoGrid, spanning full width */}
      
      <div className="absolute bg-primary w-full h-[5px] bottom-0 right-0 left-0"></div>
    </div>) : ( <div className="relative w-full px-4 sm:px-10 md:px-14 lg:px-20 py-40">
      {/* Background SVG */}
      <div className="absolute bottom-0 left-0 right-0 z-0">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#183f99"
            fillOpacity="1"
            d="M0,96L30,106.7C60,117,120,139,180,138.7C240,139,300,117,360,117.3C420,117,480,139,540,128C600,117,660,75,720,58.7C780,43,840,53,900,69.3C960,85,1020,107,1080,133.3C1140,160,1200,192,1260,202.7C1320,213,1380,203,1410,197.3L1440,192L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
          ></path>
        </svg>
      </div>

      {/* BentoGrid (Cards) */}
      <div className="relative z-10">
        <BentoGrid>
          {features.map((feature, idx) => (
            <BentoCard className="relative" key={idx} {...feature} />
          ))}
        </BentoGrid>
      </div>

      {/* Render HeroVideoDialog BELOW the BentoGrid, spanning full width */}
      <div className="lg:w-[80%] w-full md:mx-auto mt-10">
        <AutoPlayVideo 
          videoSrc="/betterminela.mp4"
          thumbnailSrc="/img/thumbnail.jpg"
          thumbnailAlt="Video thumbnail"
        />
      </div>
      <div className="absolute bg-primary w-full h-[5px] bottom-0 right-0 left-0"></div>
    </div>)}
   
    </>
    
    
  ) 
}

export default MedicalTourism;
