import React, { useRef, useState, useContext } from "react";
import { Button, Input, Textarea, Typography } from "@material-tailwind/react";
import emailjs from "@emailjs/browser";
import Popup from "./Popup"; // Import the Popup component
import { LanguageContext } from "../context/LanguageProvider";
import translationsEn from "../data/language/contactForm.en";
import translationsBs from "../data/language/contactForm.bs";
import { Typewriter } from "react-simple-typewriter";
import sendMail from "../services/sendEmail";


export function ContactForm({ message, subject, className }) {
  const form = useRef();
  const [emailError, setEmailError] = useState("");
  const [formValues, setFormValues] = useState({
    from_email: "",
    message: "",
  });
  const [showPopup, setShowPopup] = useState(false);
  const { language } = useContext(LanguageContext);

  const translations = language === "en" ? translationsEn : translationsBs;


  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    console.log("Email sent");

    if (!validateEmail(formValues.from_email)) {
      setEmailError(translations.emailError);
      return;
    }
    try{ 
      await sendMail(subject, formValues.message, formValues.from_email)
          setShowPopup(true); 
          setFormValues({ from_email: "", message: "" });
          setEmailError("");
        } catch (e) {
          console.error("Error sending email:", e);
        }
      
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  return (
    <div className={`p-4 lg:p-6 bg-white rounded-xl shadow-md ${className}`}>
      <form
        action="#"
        ref={form}
        onSubmit={sendEmail}
        className="flex flex-col gap-4"
      >
        <div className="mb-4">
          <h2 className="text-md font-bold mb-4">
            {message}
            <span className={message ? "text-primary pl-2" : "text-black"}>
            <Typewriter
              words={[translations.cta]}
              loop
              cursor
              cursorStyle="|"
              typeSpeed={70}
              deleteSpeed={50}
              delaySpeed={1000}
              
            />
            </span>
          </h2>
          <Typography
            variant="small"
            className="mb-2 text-left font-bold !text-primary"
          >
            {translations.emailLabel}
          </Typography>
          <Input
            style={{ border: "1.5px solid #183f99" }} 
            size="lg"
            placeholder="name@email.com"
            name="from_email"
            value={formValues.from_email}
            onChange={handleChange}
            required
            className="focus:!border-black focus:!shadow-lg h-14" 
            containerProps={{
              className: "!min-w-full",
            }}
            labelProps={{
              className: "hidden",
            }}
          />

          {emailError && (
            <Typography variant="small" className="text-red-600">
              {emailError}
            </Typography>
          )}
        </div>
        <div className="mb-4">
          <Typography
            variant="small"
            className="mb-2 text-left font-bold !text-primary"
          >
            {translations.messageLabel}
          </Typography>
          <Textarea
            rows={6}
            style={{ border: "1.5px solid #183f99" }}
            name="message"
            value={formValues.message}
            onChange={handleChange}
            required
            className="focus:!border-black focus:!shadow-lg h-14"
            containerProps={{
              className: "!min-w-full",
            }}
            labelProps={{
              className: "hidden",
            }}
          />
        </div>
        <Button
          type="submit"
          className="w-full rounded-none bg-white text-black border-solid border-[1px] border-black hover:shadow-xl hover:bg-primary hover:text-white hover:rounded-sm hover:border-none"
        >
          {translations.sendMessage}
        </Button>
      </form>

      {showPopup && (
        <Popup
          message={translations.successMessage}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
}

export default ContactForm;