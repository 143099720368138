import { createContext, useState } from "react";
import translationsEn from "../data/language/products.en";

export const ProductsContext = createContext();

export const ProductsProvider = ({ children }) => {
  const [products, setProducts] = useState(
    translationsEn.products
      .filter((product) => product.initial === true)
      .map((product) => product.id)
  );
  const [productsHistory, setProductsHistory] = useState([]);

  const updateProducts = (newProducts) => {
    setProductsHistory((prevHistory) => [...prevHistory, products]);
    setProducts(newProducts);
  };

  const popProducts = () => {
    const newProducts = productsHistory[productsHistory.length - 1]
    setProducts(newProducts);
    setProductsHistory((prevHistory) => prevHistory.slice(0, -1));
  };

  return (
    <ProductsContext.Provider
      value={{ products, updateProducts, popProducts, productsHistory }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
