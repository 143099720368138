const servicesDataEn = [
  {
    id: "plastic-surgery",
    title: "Plastic Surgery",
    description:
      "Bomonti can connect you with top-notch plastic surgery clinics. Our years of experience have allowed us to build strong relationships with leading clinics worldwide.",
    imageUrl:
      "../../public/img/medical-services/cosmetic-surgery-optimized.webp",
  },
  {
    id: "dentistry",
    title: "Dentistry",
    description:
      "Bomonti connects you with the best dental clinics. We help you get quality care and modern treatments for your dental needs.",
    imageUrl: "../../public/img/medical-services/MyIC_Article_114454.jpeg",
  },
  {
    id: "weight-loss-surgery",
    title: "Weight Loss Surgery",
    description:
      "It's important to get the right and professional services for weight loss surgery. Bomonti will connect you with clinics around the world and help you find the one that's right for you.",
    imageUrl: "../../public/img/medical-services/Blogs-20.png",
  },
];

export default servicesDataEn;
