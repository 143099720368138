import React, { useState, useEffect } from "react";

import "../index.css";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

const Testimonial = ({
  beforeImage,
  afterImage,
  name,
  descriptionBefore,
  descriptionAfter,
}) => {
  const [showAfter, setShowAfter] = useState(false);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFade(false); // Start fade out
      setTimeout(() => {
        setShowAfter((prev) => !prev);
        setFade(true); // Start fade in
      }, 500); // Match this timeout with the fade-out transition time
    }, 6000); // Switch every 6 seconds

    return () => clearTimeout(timer); // Cleanup the timer
  }, [showAfter]);

  return (
    <div className="relative flex md:flex-row flex-col items-center justify-between mx-auto  sm:mb-12 font-sans max-w-6xl h-screen p-2 ">
      {/* Text Section */}
      <div
        className={`w-[70%] md:w-[42%] flex flex-col md:justify-center justify-end md:h-full h-[40%] md:mb-0 mb-2 transition-opacity duration-500 ${
          fade ? "opacity-100" : "opacity-0"
        }`}
      >
        <h2 className="w-full mt-4 mb-4 md:mb-10 sm:text-4xl font-semibold text-primary text-center lg:text-left">
          {name}
        </h2>

        <p className="text-gray-700 text-sm sm:text-base md:text-xl text-center lg:text-left ">
          {showAfter ? descriptionAfter : descriptionBefore}
        </p>
      </div>
      <MdOutlineKeyboardDoubleArrowRight className="animate-move md:block hidden text-primary w-[10%]" size={70} />{" "}
      {/* Image Section */}
      <div
        className={`w-[60%] md:w-[42%]  flex items-center justify-center h-[95%] transition-opacity duration-500 ${
          fade ? "opacity-100" : "opacity-0"
        }`}
      >
        <img
          src={showAfter ? afterImage : beforeImage}
          alt={showAfter ? `After: ${name}` : `Before: ${name}`}
          className="w-full h-full object-contain "
        />
      </div>
    </div>
  );
};

export default Testimonial;
