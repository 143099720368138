import React, { useContext } from "react";
import { Typography, Button, Card, CardBody } from "@material-tailwind/react";
import { CheckIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../context/LanguageProvider";
import { medicalTourism as medicalTourismEn } from "../data/language/medicalTourism.en";
import { medicalTourism as medicalTourismBs } from "../data/language/medicalTourism.bs";
import { FaTooth } from "react-icons/fa"; 
import { FaUserMd } from "react-icons/fa"; 
import { BiBody } from "react-icons/bi"; 

const serviceUrlMapping = {
  Dentistry: "dentistry",
  "Plastic Surgery": "plastic-surgery",
  "Weight Loss Surgery": "weight-loss-surgery",
  Stomatologija: "dentistry",
  "Plastična hirurgija": "plastic-surgery",
  "Hirurgija mršavljenja": "weight-loss-surgery",
};

function PricingCard({ title, options }) {
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);

  const handleCardClick = () => {
    const urlPath = serviceUrlMapping[title];
    navigate(`/services/${urlPath}`);
  };

  const getIcon = () => {
    switch (title) {
      case "Dentistry":
      case "Stomatologija":
        return <FaTooth className="text-6xl text-black font-semibold" />;
      case "Plastic Surgery":
      case "Plastična hirurgija":
        return <FaUserMd className="text-6xl text-black font-semibold" />;
      case "Weight Loss Surgery":
      case "Hirurgija mršavljenja":
        return <BiBody className="text-6xl text-black font-semibold" />;
      default:
        return null;
    }
  };

  return (
    <Card
      variant="gradient"
      className={`cursor-pointer lg:h-max transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-2xl hover:shadow-white ${
        title === "Plastic Surgery" || title === "Weight Loss Surgery"
          ? "lg:translate-y-4"
          : "translate-y-0"
      }`}
      color="white"
      onClick={handleCardClick} 
    >
      <CardBody className="text-center p-2.5 sm:p-6">
        <div className="flex justify-center mb-4">{getIcon()}</div>
        <Typography variant="h5" color="blue-gray" className="mb-2 font-bold">
          {title}
        </Typography>

        <ul className="mb-8 flex flex-col items-center gap-3">
          {options.map((option, key) => (
            <li key={key} className="flex items-center gap-2 text-gray-700">
              <CheckIcon className="h-5 w-5" strokeWidth={2} />
              <Typography className="font-normal">{option}</Typography>
            </li>
          ))}
        </ul>

        <Button
          fullWidth
          color="gray"
          className="flex items-center justify-center gap-4 transition-colors duration-300 hover:bg-primary hover:text-white"
          onClick={(e) => {
            e.stopPropagation();
            handleCardClick();
          }}
        >
          {language === "en" ? "Learn More" : "Saznaj više"}
          <ArrowRightIcon className="h-5 w-5" strokeWidth={2} />
        </Button>
      </CardBody>
    </Card>
  );
}


export function PricingSection6() {
  const { language } = useContext(LanguageContext);
  const pricingData = language === "en" ? medicalTourismEn : medicalTourismBs;

  return (
    <div className="mt-8 sm:mt-10 px-2 sm:px-10 pt-8 pb-16 sm:pb-24 ">
      <div className="container mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 bg-primary">
        {pricingData.cards.map((props, key) => (
          <PricingCard key={key} {...props} language={language} />
        ))}
      </div>
    </div>
  );
}

export default PricingSection6;
