import beforeImage1 from '../../data/images/minelabefore1.jpg';
import afterImage1 from '../../data/images/minelaafter1.jpg';
import beforeImage2 from '../../data/images/minelabefore2.jpg';
import afterImage2 from '../../data/images/minelaafter2.jpg';
import beforeImage3 from '../../data/images/minelabefore3.jpg';
import afterImage3 from '../../data/images/minelaafter3.jpg';
import beforeImage7 from '../../data/images/minelabefore7.jpg';
import afterImage7 from '../../data/images/minelaafter7.jpg';

export const testimonialsEN = [
  {
    id: 1,
    beforeImage: beforeImage1,
    afterImage: afterImage1,
    name: "Nedžada Osmanović",
    descriptionBefore: "Često sam uzimala brze užine i preskakala obroke, što je dovelo do niske energije i frustracije. Odjeća mi je bila tijesna i izbjegavala sam ogledala. Znala sam da se moram promijeniti, borila sam se da se držim zdrave rutine. Svaki dan mi je bio kao bitka između mojih želja i ciljeva.",
    descriptionAfter: "Sada dajem prioritet uravnoteženim obrocima i uživam u vježbanju. Osjećam se energično i samouvjereno u svojoj koži. Odjeća mi pristaje udobno, a ja grlim svoj odraz. Kuvanje je postalo strast i volim isprobavati nove recepte. Svaki dan se osjećam osnaženo i spremno za bilo šta.",
  },
  {
    id: 2,
    beforeImage: beforeImage2,
    afterImage: afterImage2,
    name: "Miroslav Pehilj",
    descriptionBefore: "I often felt sluggish and unhappy with my body. Fast food was convenient, and I skipped the gym regularly. My clothes felt tight, and I avoided mirrors. Although I wanted to change, the effort seemed daunting. I found myself stuck in a routine that didn’t serve me anymore.",
    descriptionAfter: "Now, I’ve embraced a fitness routine and healthier meals. I feel stronger and more energized. My clothes fit better, and I’m proud of my progress. I wake up excited to tackle each day, grateful for the commitment I made to transform my health and happiness.",
  },
  {
    id: 3,
    beforeImage: beforeImage3,
    afterImage: afterImage3,
    name: "Mirjana Jokić",
    descriptionBefore: "I spent years feeling insecure about my body. I often chose takeout over healthy meals and felt tired all the time. Social events became daunting, and I wished for a change. Despite my desire for transformation, I felt stuck in habits that didn’t serve my health or happiness.",
    descriptionAfter: "After committing to healthier choices, I feel fantastic! I enjoy cooking and experimenting with nutritious recipes. My energy levels are up, and I love participating in social activities. I’ve learned to appreciate my body and celebrate every little victory. Life feels brighter, and I’m excited for what’s next!",
  },
  {
    id: 4,
    beforeImage: beforeImage7,
    afterImage: afterImage7,
    name: "Bogdan Vrbica",
    descriptionBefore: "I struggled to maintain a healthy lifestyle. I often chose convenience over nutrition, which left me feeling tired and unmotivated. I avoided group activities because I felt self-conscious about my appearance. Change felt impossible, and I often wished for a healthier, happier version of myself.",
    descriptionAfter: "Now, I’ve found a fitness routine I love and enjoy cooking healthy meals. I feel fantastic! My confidence has improved, and I love being active with friends. I feel empowered by my transformation, excited about the future, and ready to embrace all the opportunities that come my way.",
  },
];
