const servicesDataBs = [
  {
    id: "plastic-surgery",
    title: "Plastična Hirurgija",
    description:
      "Bomonti vam može pomoći da pronađete najbolje klinike za plastičnu hirurgiju. Imamo čvrste veze s vrhunskim klinikama širom svijeta.",
    imageUrl:
      "../../public/img/medical-services/cosmetic-surgery-optimized.webp",
  },
  {
    id: "dentistry",
    title: "Stomatologija",
    description:
      "Bomonti vas povezuje s najboljim stomatološkim klinikama. Pomažemo vam da dobijete kvalitetnu njegu i savremene tretmane za vaše stomatološke potrebe.",
    imageUrl: "../../public/img/medical-services/MyIC_Article_114454.jpeg",
  },
  {
    id: "weight-loss-surgery",
    title: "Hirurgija Mršavljenja",
    description:
      "Važno je da dobijete prave i stručne usluge za hirurgiju mršavljenja. Bomonti će vas povezati s klinikama širom svijeta i pomoći vam da pronađete onu pravu za vas.",
    imageUrl: "../../public/img/medical-services/Blogs-20.png",
  },
];

export default servicesDataBs;
