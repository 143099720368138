import axios from 'axios';

const sendMail = async (subject, body, sender) => {
  const apiKey = import.meta.env.VITE_BREVO_API_KEY; 
  const url = 'https://api.brevo.com/v3/smtp/email'; 
  let data = {
    sender: { name: sender, email: sender },
    to: [{ email: "bomontigrupdoo@hotmail.com" }],
    subject: subject,
    htmlContent: body,
    headers: { "Some-Custom-Name": "unique-id-1234" },
    params: { parameter: "Custom param value" },
  };

  try {
    const response = await axios.post(url, data, {
      headers: {
        'api-key': apiKey,
        'Content-Type': 'application/json',
      }
    });
    console.log('Email has been sent!', response.data);
  } catch (error) {
    console.error('Error sending email:', error);
  }
  try {
    const response = await axios.post(url, data, {
      headers: {
        'api-key': apiKey,
        'Content-Type': 'application/json',
      }
    });
    console.log('Email has been sent!', response.data);
  } catch (error) {
    console.error('Error sending email:', error);
  }

};

export default sendMail;
