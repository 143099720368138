import React, { useContext, useState, useEffect } from "react";
import {
  Navbar,
  Collapse,
  Button,
  IconButton,
  Typography,
} from "@material-tailwind/react";
import {
  Squares2X2Icon,
  UserIcon,
  PhoneIcon,
  HeartIcon,
  HomeIcon,
} from "@heroicons/react/24/solid";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { LanguageContext } from "../context/LanguageProvider";
import translationsEn from "../data/language/navbar.en";
import translationsBs from "../data/language/navbar.bs";
import { IoIosGlobe } from "react-icons/io";
import { PiTruckTrailerLight } from "react-icons/pi";
import { BsAirplaneEngines } from "react-icons/bs";
import { BiSolidTruck } from "react-icons/bi";
import { BsFillAirplaneFill } from "react-icons/bs";
import { IoGlobeSharp } from "react-icons/io5";
import { FaHandshakeSimple } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";

function NavItem({ children, onClick }) {
  return (
    <li>
      <Typography
        as="a"
        href="#"
        variant="paragraph"
        className="flex items-center gap-2 font-medium text-gray-800 hover:text-primary transition-colors duration-300"
        onClick={onClick}
      >
        {children}
      </Typography>
    </li>
  );
}

export function Navigation({
  scrollToSection,
  aboutUsRef,
  productsRef,
  serviceRef,
  contactUsRef,
  importExportRef,
  transportationRef,
  medicalTourismRef,
}) {
  const { language, toggleLanguage } = useContext(LanguageContext);
  const [open, setOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSinglePage, setIsSinglePage] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsSinglePage(location.pathname.startsWith("/pages"));
  }, [location]);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpen(false)
    );
  }, []);

  // Timer for auto-closing the dropdown
  useEffect(() => {
    let timer;
    if (isDropdownOpen) {
      timer = setTimeout(() => setIsDropdownOpen(false), 4000); // Close after 5 seconds
    }
    return () => {
      if (timer) clearTimeout(timer); // Cleanup timer on unmount or when dropdown closes
    };
  }, [isDropdownOpen]);

  const translations = language === "en" ? translationsEn : translationsBs;

  return (
    <Navbar
      fullWidth
      shadow={false}
      color="transparent"
      className="absolute z-50 backdrop-blur-sm bg-white"
    >
      <div className="container mx-auto flex items-center justify-between">
        <img src="/bomonti.png" alt="Bomonti" className="h-10 w-auto" />
        <ul className="ml-10 hidden items-center gap-8 lg:flex">
          {isSinglePage ? (
            <NavItem
              onClick={() => {
                navigate("/");
              }}
            >
              <HomeIcon className="h-5 w-5" />
              {translations.home}
            </NavItem>
          ) : null}
          <NavItem
            onClick={() => {
              if (isSinglePage) {
                setOpen(false);
                navigate("/pages/aboutus");
              } else {
                scrollToSection(aboutUsRef);
              }
            }}
          >
            <UserIcon className="h-5 w-5" />
            {translations.aboutUs}
          </NavItem>
          <NavItem
            onClick={() => {
              if (isSinglePage) {
                setOpen(false);
                navigate("/pages/products");
              } else {
                scrollToSection(productsRef);
              }
            }}
          >
            <Squares2X2Icon className="h-5 w-5" />
            {translations.products}
          </NavItem>
          {/* Services Dropdown - Hover */}
          <div
            className="relative"
            onMouseEnter={() => setIsDropdownOpen(true)}
            onClick={() => setIsDropdownOpen(true)}
          >
            <NavItem>
              <FaHandshakeSimple className="h-5 w-5" />
              {translations.services}
            </NavItem>
            {isDropdownOpen && (
              <ul
                onMouseLeave={() => setIsDropdownOpen(false)}
                className="absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-md"
              >
                <li
                  className="flex items-center justify-left px-4 text-black py-2 hover:bg-gray-100 hover:text-primary cursor-pointer"
                  onClick={() => {
                    if (isSinglePage) {
                      setOpen(false)
                      navigate("/pages/import");
                    } else {
                      scrollToSection(importExportRef);
                    }
                  }}
                >
                  <IoIosGlobe size={30} className=" pr-2" />{" "}
                  {translations.importExport}
                </li>
                <li
                  className="flex items-center justify-left px-4 text-black py-2 hover:bg-gray-100 hover:text-primary cursor-pointer"
                  onClick={() => {
                    if (isSinglePage) {
                      setOpen(false)
                      navigate("/pages/transportation");
                    } else {
                      scrollToSection(transportationRef);
                    }
                  }}
                >
                  <PiTruckTrailerLight size={30} className=" pr-2" />{" "}
                  {translations.transportation}
                </li>
                <li
                  className="flex items-center justify-left px-4 text-black py-2 hover:bg-gray-100 hover:text-primary cursor-pointer"
                  onClick={() => {
                    if (isSinglePage) {
                      setOpen(false)
                      navigate("/pages/medicaltourism");
                    } else {
                      scrollToSection(medicalTourismRef);
                    }
                  }}
                >
                  <BsAirplaneEngines size={30} className="pr-2" />
                  {translations.medicalTourism}
                </li>
              </ul>
            )}
          </div>
          <NavItem
             onClick={() => {
              scrollToSection(contactUsRef);
          }}
          >
            <PhoneIcon className="h-5 w-5" />
            {translations.contactUs}
          </NavItem>
        </ul>
        <div className="hidden items-center gap-4 lg:flex">
          <Button
            className="text-white bg-primary border border-white  hover:bg-white hover:text-black hover:border-black transition-color drop-shadow-2xl hover:drop-shadow-none"
            onClick={toggleLanguage}
          >
            {translations.button}
          </Button>
        </div>
        <IconButton
          variant="text"
          onClick={() => setOpen((cur) => !cur)}
          className="ml-auto inline-block lg:hidden"
        >
          {open ? (
            <XMarkIcon strokeWidth={2} className="h-6 w-6" />
          ) : (
            <Bars3Icon strokeWidth={2} className="h-6 w-6" />
          )}
        </IconButton>
      </div>
      <Collapse open={open}>
        <div className="container mx-auto mt-4 rounded-lg bg-white px-6 py-5">
          <ul className="flex flex-col gap-4 text-gray-800 ">
            {isSinglePage ? (
              <NavItem
                onClick={() => {
                  setOpen(false);
                  navigate("/");
                }}
              >
                <HomeIcon className="h-5 w-5" />
                {translations.home}
              </NavItem>
            ) : null}
            <NavItem
              onClick={() => {
                if (isSinglePage) {
                  setOpen(false);
                  navigate("/pages/aboutus");
                } else {
                  scrollToSection(aboutUsRef);
                }
              }}
            >
              <UserIcon className="h-5 w-5" />
              {translations.aboutUs}
            </NavItem>
            <NavItem
              onClick={() => {
                if (isSinglePage) {
                  setOpen(false);
                  navigate("/pages/products");
                } else {
                  scrollToSection(productsRef);
                }
              }}
            >
              <Squares2X2Icon className="h-5 w-5" />
              {translations.products}
            </NavItem>
            <NavItem
              onClick={() => {
                if (isSinglePage) {
                  setOpen(false)
                  navigate("/pages/import");
                } else {
                  scrollToSection(importExportRef);
                }
              }}
            >
              <IoGlobeSharp className="h-5 w-5" />
              {translations.importExport}
            </NavItem>
            <NavItem
              onClick={() => {
                if (isSinglePage) {
                  setOpen(false)
                  navigate("/pages/transportation");
                } else {
                  scrollToSection(transportationRef);
                }
              }}
            >
              <BsFillAirplaneFill className="h-5 w-5" />
              {translations.transportation}
            </NavItem>
            <NavItem
              onClick={() => {
                if (isSinglePage) {
                  setOpen(false)
                  navigate("/pages/medicaltourism");
                } else {
                  scrollToSection(medicalTourismRef);
                }
              }}
            >
              <BiSolidTruck className="h-5 w-5" />
              {translations.medicalTourism}
            </NavItem>
            <NavItem
              onClick={() => {
                  scrollToSection(contactUsRef);
              }}
            >
              <PhoneIcon className="h-5 w-5" />
              {translations.contactUs}
            </NavItem>
          </ul>
          <div className="mt-6 flex items-center gap-4">
            <Button
              className="text-white bg-primary border border-white  hover:bg-white hover:text-black hover:border-black transition-color"
              onClick={toggleLanguage}
            >
              {translations.button}
            </Button>
          </div>
        </div>
      </Collapse>
    </Navbar>
  );
}

export default Navigation;
