import React, { useEffect, useState } from 'react';
import { FaTruckFast } from "react-icons/fa6";

const PreLoader = ({ loading }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      loading();
    }, 5000); 
    return () => clearTimeout(timer);
  }, [loading]);

  return (
    <div className="preloader-container absolute w-[100vw] h-[100vh] bg-primary flex items-center justify-center z-[100]">
      <FaTruckFast size={200} className="truck-icon text-white inline-block" />
    </div>
  );
};

export default PreLoader;