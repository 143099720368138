import beforeImage1 from "../../data/images/minelabefore1.jpg";
import afterImage1 from "../../data/images/minelaafter1.jpg";
import beforeImage2 from "../../data/images/minelabefore2.jpg";
import afterImage2 from "../../data/images/minelaafter2.jpg";
import beforeImage3 from "../../data/images/minelabefore3.jpg";
import afterImage3 from "../../data/images/minelaafter3.jpg";
import beforeImage7 from "../../data/images/minelabefore7.jpg";
import afterImage7 from "../../data/images/minelaafter7.jpg";

export const testimonialsBS = [
  {
    id: 1,
    beforeImage: beforeImage1,
    afterImage: afterImage1,
    name: "Nedžada Osmanović",
    descriptionBefore:
      "Često sam uzimala brze užine i preskakala obroke, što je dovelo do niske energije i frustracije. Odjeća mi je bila tijesna i izbjegavala sam ogledala. Znala sam da se moram promijeniti, ali sam se borila da se držim zdrave rutine. Svaki dan mi je bio kao bitka između mojih želja i ciljeva.",
    descriptionAfter:
      "Sada dajem prioritet uravnoteženim obrocima i uživam u vježbanju. Osjećam se energično i samouvjereno u svojoj koži. Odjeća mi pristaje udobno, a ja grlim svoj odraz. Kuhanje je postalo strast i volim isprobavati nove recepte. Svaki dan se osjećam osnaženo i spremno za bilo šta.",
  },
  {
    id: 2,
    beforeImage: beforeImage2,
    afterImage: afterImage2,
    name: "Miroslav Pehilj",
    descriptionBefore:
      "Često sam se osjećao usporeno i nezadovoljno svojim tijelom. Brza hrana bila je pogodna, a teretanu sam redovno preskakao. Odjeća mi je bila tijesna, a ogledala sam izbjegavao. Iako sam želio promjenu, trud se činio zastrašujućim. Osjećao sam se zaglavljen u rutini koja mi više nije odgovarala.",
    descriptionAfter:
      "Sada sam prihvatio fitness rutinu i zdravije obroke. Osjećam se jače i pun energije. Odjeća mi bolje pristaje, a ponosan sam na svoj napredak. Budim se uzbuđen za svaki dan, zahvalan na odluci da transformišem svoje zdravlje i sreću.",
  },
  {
    id: 3,
    beforeImage: beforeImage3,
    afterImage: afterImage3,
    name: "Mirjana Jokić",
    descriptionBefore:
      "Godinama sam se osjećala nesigurno zbog svog tijela. Često sam birala brzu hranu umjesto zdravih obroka i uvijek sam bila umorna. Društveni događaji su mi postali zastrašujući, a priželjkivala sam promjenu. Uprkos želji za transformacijom, osjećala sam se zarobljeno u navikama koje nisu služile mom zdravlju niti sreći.",
    descriptionAfter:
      "Nakon što sam se posvetila zdravijim izborima, osjećam se fantastično! Uživan u kuhanju i eksperimentisanju s nutritivnim receptima. Moja energija je na visokom nivou, a volim učestvovati u društvenim aktivnostima. Naučila sam cijeniti svoje tijelo i slaviti svaku malu pobjedu. Život je svjetliji, a ja sam uzbuđena za sve što dolazi!",
  },
  {
    id: 4,
    beforeImage: beforeImage7,
    afterImage: afterImage7,
    name: "Bogdan Vrbica",
    descriptionBefore:
      "Borio sam se da održim zdrav način života. Često sam birao pogodnost umjesto nutritivne vrijednosti, što me ostavljalo umornim i nemotivisanim. Izbjegavao sam grupne aktivnosti jer sam se osjećao nesigurno zbog svog izgleda. Promjena se činila nemogućom, a često sam priželjkivao zdraviju, sretniju verziju sebe.",
    descriptionAfter:
      "Sada sam pronašao fitness rutinu koju volim i uživam u kuhanju zdravih obroka. Osjećam se fantastično! Moje samopouzdanje se poboljšalo, a volim biti aktivan s prijateljima. Osjećam se osnaženo svojom transformacijom, uzbuđen za budućnost i spreman prihvatiti sve prilike koje dolaze.",
  },
];
