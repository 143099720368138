const translationsBs = {
    title: "Kontaktirajte Nas",
    emailLabel: "Vaša Email Adresa",
    messageLabel: "Vaša Poruka",
    sendMessage: "Pošaljite poruku",
    emailError: "Molimo unesite validnu email adresu.",
    successMessage: "Vaša poruka je uspješno poslana!",
    cta: "Pošaljite nam email!"
  };
  
  export default translationsBs;
  