export const importAndExportENG = {
  id: 1,
  title: "IMPORT OF PRODUCTS",
  subTitle: `Need help with import of products...? `,
  cta: `Send us an email!`,
  descriptionP1:
    "Import of products is the process of bringing goods across borders for trade, involving sourcing, handling, and ensuring safe delivery while meeting legal and regulatory requirements.",
  descriptionP2:
    "Bomonti imports high-quality products in Bosnia, Turkey, and Iran, offering an innovative range of products along with 24/7 technical support for product use and application.",
  descriptionP3:
    "We value strong client relationships and seek new partnerships to expand our business while keeping our customers a first priority.",

  imageUrl: "https://i.ibb.co/FV4y050/all-over-the-world.png",

  highLightedWords: {
    P1: ["Import", "products", "trade", "sourcing", "handling", "safe", "across", "delivery", "borders", "legal", "requirements", "regulatory" ],
    P2: ["high-quality", "Bosnia,", "Turkey,", "Iran,", "products", "support", "application", "24/7", "technical"],
    P3:["strong", "client", "relationships", "customers", "first", "priority."],
  },
};

export const transportationENG = {
  id: 2,
  title: "INTERNATIONAL TRANSPORTATION",
  subTitle: `Need help with transportation...? `,
  cta: `Send us an email!`,
  descriptionP1:
    "International transportation of goods involves moving full (FTL) or partial (LTL) loads across countries via road, supported by comprehensive logistics and freight services.",
  descriptionP2:
    "We ensure fast and cost-effective delivery by using our extensive network of transporters covering the regions of Germany, Italy, Slovenia, Croatia and Bosnia.",
  descriptionP3:
    "Bomonti is commited to efficiency, speed and reliability, providing seamless logistics solutions tailored to client needs.",
  mapUrl: "https://google.com/maps",
  highLightedWords: {
    P1: ["International", "transportation", "of", "goods", "loads", "across", "countries"],
    P2: ["fast", "cost-effective", "safely", "network", "transporters", "Germany,", "Italy,", "Slovenia,", "Croatia", "Bosnia."],
    P3: ["commited", "efficiency,", "speed", "reliability,", "client", "needs."],
  },
};

export const medicalTourismENG = { 
  id: 3,
  title: "MEDICAL TOURISM",
  subTitle: `Need help with medical tourism...? `,
  cta: `Send us an email!`,
  descriptionP1: "",
  descriptionP2:
    "Our goal is to provide top-quality medical treatments in Istanbul with all-inclusive packages that include: accommodation in a hotel and hospital, 24/7 medical care, VIP transportation, experienced doctors, preoperative analyses, postoperative support, medications, nutritional advice, and support in your language.",
  descriptionP3:
    "Bomonti focuses on patient satisfaction, providing end-to-end assistance along with certified healthcare providers, and a comprehensive travel experience tailored to individual needs.",
  videoUrl: "/betterminela.mp4",
  highLightedWords: {
    P1: [],
    P2: [
      "goal",
      "provide",
      "top-quality",
      "medical",
      "treatments",
      "Istanbul",
      "Turkey",
      "affordable",
      "prices",
      "all-inclusive",
      "packages",
      "accommodation",
      "hotel",
      "hospital",
      "medical",
      "care",
      "24/7",
      "VIP",
      "transportation",
      "experienced",
      "doctors",
      "preoperative",
      "analyses",
      "postoperative",
      "support",
      "medications",
      "nutritional",
      "advice",
      "support",
      "your",
      "language",
      "clients"
    ],
    P3: [
      "patient",
      "support",
      "certified",
      "healthcare",
      "providers",
      "travel",
      "experience",
      "individual",
      "needs"
    ],
  },
};



