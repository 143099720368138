import React, { useRef, useEffect, useState } from "react";

const AutoPlayVideo = ({
  videoSrc,
  thumbnailSrc,
  thumbnailAlt,
  className,
  delay = 2000,
}) => {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [isPausedByUser, setIsPausedByUser] = useState(false);
  const [isDelayed, setIsDelayed] = useState(false);
  const [muted, setMuted] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let timeoutId;

    const playVideo = () => {
      console.log(isPausedByUser);
      if (videoRef.current && !isPausedByUser) {
        videoRef.current
          .play()
          .then(() => setIsPlaying(true))
          .catch((err) => {
            console.log("Autoplay with sound failed:", err);
          });
      }
    };

    if (isVisible) {
      setMuted(false);
      if (firstTime) {
        timeoutId = setTimeout(() => {
          setIsDelayed(true);
          setTimeout(() => {
            setFirstTime(false);
            playVideo();
          }, 1000);
        }, delay);
      } else {
        setIsDelayed(true);
        playVideo();
      }
    } else {
      clearTimeout(timeoutId);
      if (videoRef.current) {
        videoRef.current.pause();
      }
      setIsPlaying(false);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isVisible, delay, firstTime, isPausedByUser]);

  const handleOverlayClick = () => {
    if (videoRef.current) {
      if (!videoRef.current.paused) {
        videoRef.current.pause();
        setIsPausedByUser(true); // User manually paused
      } else {
        videoRef.current.play();
        setIsPausedByUser(false); // User resumed playback
      }
    }
  };

  return (
    <div className={`relative p-0 sm:p-4 transition-all duration-1000 ${className}`}>
      {firstTime && (
        <div
          className={`absolute inset-0 z-10 transition-opacity duration-1000 ${
            isDelayed ? "opacity-0 pointer-events-none" : "opacity-100"
          }`}
          style={{
            backgroundColor: "white",
          }}
        >
          <img
            src={thumbnailSrc}
            alt={thumbnailAlt}
            className="w-full h-full object-cover rounded-lg border-2 border-white shadow-lg shadow-primary"
          />
        </div>
      )}
      <div className="relative">
        <video
          ref={videoRef}
          src={videoSrc}
          controls
          loop
          muted={muted}
          className={`w-full h-auto rounded-lg border-2 border-white shadow-lg shadow-primary transition-opacity duration-1000 ${
            isDelayed ? "opacity-100" : "opacity-0"
          }`}
          style={{ pointerEvents: "auto" }} // Allow interaction with the video
        />
        {/* Transparent overlay for detecting user clicks */}
        <div
          className="absolute inset-0 z-10 cursor-pointer"
          onClick={handleOverlayClick}
          style={{
            backgroundColor: "transparent",
            pointerEvents: "auto", // Ensure the overlay detects clicks
          }}
        />
      </div>
    </div>
  );
};

export default AutoPlayVideo;
