import React from "react";

const Table = ({ measurements, lang }) => {
  if (!measurements || measurements.length === 0) {
    return <div></div>;
  }

  const rows = [];
  const labels = [];

  measurements.forEach(item => {
    item.values.forEach(value => {
      const [label, valueText] = value.split(":").map(part => part.trim());
      labels.push(label);
      rows.push(valueText);
    });
  });

  const numberOfColumns = measurements.length;
  const numberOfRows = Math.max(...measurements.map(item => item.values.length));

  return (
    <div className="overflow-x-auto mb-10 mt-16">
      <table className="min-w-full table-auto text-left border-collapse border border-primary">
        <thead>
          <tr>
            <th className="px-4 py-2 border-r border-b border-primary text-sm font-semibold">
              {lang === 'en' ? "Options: " : "Opcije: "}
            </th>
            {measurements.map((item, index) => (
              <th key={index} className="px-4 py-2 border-r border-b border-primary text-sm font-semibold ">
                {item.key}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: numberOfRows }).map((_, rowIndex) => (
            <tr key={rowIndex}>
              <td className="px-4 py-2 border-r border-b border-primary text-sm font-medium">
                {labels[rowIndex]}
              </td>
              {measurements.map((item, index) => {
                const value = item.values[rowIndex];
                const [_, valueText] = value ? value.split(":").map(part => part.trim()) : ['', ''];
                return (
                  <td key={index} className="px-4 py-2 border-r border-b border-primary text-sm">
                    {valueText}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
