import { Typography } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
const currentYear = new Date().getFullYear();

export function FooterWithSitemap() {
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText("bomontigrupdoo@hotmail.com")
      .then(() => {
        alert("Email address copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy email address:", err);
      });
  };

  return (
    <footer className="relative w-full mt-20">
      <div className="w-full px-8">
        <div className="flex items-center space-x-6 mb-4">
          {/* Email Icon */}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Prevent navigation
              handleCopyToClipboard();
            }}
            className="text-gray-700 hover:text-primary transition-all"
            title="Copy email to clipboard"
          >
            <FontAwesomeIcon icon={faEnvelope} size="2x" />
          </a>

          {/* Facebook 1 Icon */}
          <a
            href="https://www.facebook.com/Medarmedicaltourism?mibextid=LQQJ4d"
            className="text-gray-700 hover:text-primary transition-all"
            target="_blank"
            rel="noopener noreferrer"
            title="Visit our Facebook page"
          >
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
          <a
            href="https://www.facebook.com/carbonedoo?mibextid=LQQJ4d"
            className="text-gray-700 hover:text-primary transition-all"
            target="_blank"
            rel="noopener noreferrer"
            title="Visit our Facebook page"
          >
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>

          {/* Instagram Icon */}
          <a
            href="https://www.instagram.com/merdar_medicaltourism?igsh=MW5jMWF0YXBra2s4Yg%3D%3D&utm_source=qr"
            className="text-gray-700 hover:text-primary transition-all"
            target="_blank"
            rel="noopener noreferrer"
            title="Visit us on Instagram"
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
        </div>

        <div className="flex w-full flex-col items-center justify-center border-t border-blue-gray-50 py-4 md:flex-row md:justify-between">
          <Typography
            variant="small"
            className="mb-4 text-center font-normal text-blue-gray-900 md:mb-0"
          >
            &copy; {currentYear}{" "}
            <span className="text-primary">
              <a href="/">Bomonti</a>
            </span>
            . All Rights Reserved.
          </Typography>
          <div>
            <Typography
              variant="small"
              className="mb-4 text-center font-normal text-blue-gray-900 md:mb-0"
            >
              Made by <span className="text-primary">SAAI</span>Solutions.
            </Typography>
          </div>
        </div>
      </div>
    </footer>
  );
}
