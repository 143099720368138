import React, { useContext, useEffect, useRef, useState } from "react";
import { Typography } from "@material-tailwind/react";
import { FooterWithSitemap } from "../components/Footer";
import ContactForm from "../components/ContactForm";
import { LanguageContext } from "../context/LanguageProvider";
import { Ripple } from "../components/ui/Ripple";

export function ContactSection14({ className }) {
  const { language } = useContext(LanguageContext);

  const [isVisible, setIsVisible] = useState(false);
  const contactUsRef = useRef(null);

  const handleScroll = () => {
    if (contactUsRef.current) {
      const { top, bottom } = contactUsRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (top < windowHeight / 2 && bottom > windowHeight / 2) {
        setTimeout(() => {
          setIsVisible(true);
        }, 1000);
      } else {
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflowX = "hidden";
    } else {
      document.body.style.overflowX = "";
    }
    return () => {
      document.body.style.overflowX = ""; // Clean up on unmount
    };
  }, [isVisible]);

  const content = {
    en: {
      title: "Contact Us",
      description: "Any questions or remarks? Just write us a message!",
      mapTitle: "Google Maps Location",
    },
    bs: {
      title: "Kontaktirajte Nas",
      description: "Imate li pitanja ili primjedbi? Samo nam pošaljite poruku!",
      mapTitle: "Google Maps Lokacija",
    },
  };

  return (
    <section
      className={`relative w-full px-8 ${className}`}
      ref={contactUsRef}
    >
      <div
        className={`container mx-auto text-center mb-16 contact-us ${
          isVisible ? "slide-out" : ""
        }`}
      >
        <Typography
          variant="h1"
          color="blue-gray"
          className={`mb-4 !text-3xl lg:!text-4xl`}
        >
          {content[language].title}
        </Typography>
        <Typography variant="lead" className="mx-auto !text-gray-500">
          {content[language].description}
        </Typography>
      </div>
      <div
        className={`container mx-auto grid grid-cols-1 gap-x-12 lg:grid-cols-2 items-start two-box ${
          isVisible ? "slide-in" : ""
        }`}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3511.8640652866916!2d18.014922412364083!3d44.01586307096675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475f23f52eb51583%3A0xda0807295d39c610!2sCarbone!5e1!3m2!1sen!2sba!4v1729679803965!5m2!1sen!2sba"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title={content[language].mapTitle}
        />
        <div className={`relative`}>
          <ContactForm subject={content[language].title} className={"h-full"} />
        </div>
      </div>

      <div className="absolute top-0 left-0 z-[-1] flex h-full w-full overflow-hidden">
        <Ripple />
      </div>
      <FooterWithSitemap />
    </section>
  );
}

export default ContactSection14;
