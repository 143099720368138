const translationsBs = {
  productTitle: "Proizvodi",
  readMore: "Saznaj više",
  products: [
    {
      "id": 10023,
      "name": "Proizvodi od šablonskih ploča",
      "images": [
        "/uploads/hekimboard-desenli-levha-1080x1080.jpg"
      ],
      "details": [],
      "children": [
        10047,
        10048,
        10049,
        10050,
        10051,
        10052,
        10053,
        10054,
        10055,
        10056
      ],
      "measurements": []
    },
    {
      "id": 10048,
      "name": "Tabla sa šahovskim uzorkom",
      "images": [
        "/uploads/sasirtmali-tugla-desenli-fibercement-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10036,
      "name": "Kutna obloga od 45 stepeni",
      "images": [
        "/uploads/45-acili-fibercement-sove_3.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 80 x 2500",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,2"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 100 x 2500",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,25"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dimenzije (mm x mm): 150 x 2500",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,375"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dimenzije (mm x mm): 200 x 2500",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,5"
          ]
        },
        {
          "key": "5",
          "values": [
            "Dimenzije (mm x mm): 80 x 3000",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,2"
          ]
        },
        {
          "key": "6",
          "values": [
            "Dimenzije (mm x mm): 100 x 3000",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,25"
          ]
        },
        {
          "key": "7",
          "values": [
            "Dimenzije (mm x mm): 150 x 3000",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,375"
          ]
        },
        {
          "key": "8",
          "values": [
            "Dimenzije (mm x mm): 200 x 3000",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,5"
          ]
        }
      ]
    },
    {
      "id": 6,
      "name": "GRAĐEVINSKI MATERIJALI",
      "images": [
        "https://media.istockphoto.com/id/836219748/photo/set-of-construction-materials.jpg?s=612x612&w=0&k=20&c=cwsoqQt0poMxiH17EV_rHjxLYXyn9x-F_Q-iLE14hf4="
      ],
      "details": [
        "Građevinski materijali kao što su gipsane ploče, građevinski tekstili, staklena vuna i kamena vuna se često koriste u suvoj gradnji za izolaciju i strukturnu podršku.",
        "Ovi materijali nude efikasna rešenja za stvaranje energetski efikasnih i izdržljivih zgrada, svaki služi specifičnim potrebama u termalnoj i zvučnoj izolaciji."
      ],
      "initial": true,
      "children": [
        10017, 10018, 10019, 10020, 10021, 10022, 10023, 10057, 10058, 10059, 10107, 10108, 10109, 10110, 10111, 10112, 10113, 10114, 10131, 10132, 10133, 10143, 10153, 10157, 10158, 10009,
        10182, 10183, 10211, 10212, 10213, 10225, 10226, 10227, 10244, 10245, 10246, 10265, 10266, 10267, 10293, 10294, 10295, 10296, 10332, 10333, 10334,
      ],
      "measurements": []
    },
    {
      "id": 1,
      "name": "ALUMINIJUMSKI PROFILI (STANDARDNI I SPECIJALNI)",
      "images": [
        "https://ba.chinealuminium.com/uploads/202311890/square-tube-standard-aluminium-sections382bdf5d-88b0-419b-bd99-77daa06950b4.jpg",
        "https://feal.ba/wp-content/uploads/2020/06/Francuski-balkoni.jpg",
        "https://www.murex.ba/wp-content/uploads/2023/11/101k.jpeg",
        "https://feal.ba/wp-content/uploads/2023/01/01-FNE-2000.jpg"
      ],
      "details": [
        "Aluminijumski profili i solarni sistemi pružaju lagana, izdržljiva i otpornija rešenja na koroziju za gradnju i energetsku efikasnost.",
        "Sistemi vrata i prozora osiguravaju izolaciju i izdržljivost, dok ograde nude sigurne, stilizovane granice."
      ],
      "initial": true,
      "children": [
        14,
        15,
        16,
        19,
        10363
      ],
      "measurements": []
    },
    {
      "id": 5,
      "name": "POCINČANI GRAĐEVINSKI PROFILI",
      "images": [
        "https://imgusr.tradekey.com/p-2280688-20081119151716/steel-galvanized-profiles-for-dry-construction-of-houses.jpg"
      ],
      "details": [
        "Galvanizovani profili, uključujući UD, CD, CW i UW, su dizajnirani za gradnju, nudeći otpornu podršku na koroziju za malterisanje i druge strukturne upotrebe.",
        "Cene variraju u zavisnosti od količine narudžbine i tipa profila, pružajući fleksibilna rešenja za male i velike projekte gradnje."
      ],
      "initial": true,
      "children": [],
      "measurements": []
    },
    {
      "id": 12,
      "name": "STAKLO SVIH DIMENZIJA I SPECIFIKACIJA",
      "images": [
        "https://www.wonderdays.co.uk/uploads/products/_1200x630_crop_center-center_82_none/glassmaking.png?mtime=1653475397"
      ],
      "details": [
        "Tipovi stakla, uključujući kaljeno, laminirano i obojeno staklo, dostupni su u raznim dimenzijama i specifikacijama, nudeći čvrstoću, sigurnost i estetsku svestranost.",
        "Ova stakla dolaze u različitim debljinama i završnim obradama, a cene se zasnivaju na tipu i količini narudžbine, služeći širokom spektru građevinskih, automobilski i dizajnerskih aplikacija."
      ],
      "initial": true,
      "children": [],
      "measurements": []
    },
    {
      "id": 8,
      "name": "INDUSTRIJSKO ŽELJEZO I ČELIK",
      "images": [
        "https://www.market-prospects.com/storage/images/251-1200x675.jpg"
      ],
      "details": [
        "Industrijski proizvodi od gvožđa i čelika, kao što su armature (6-18 mm), armature mreže i profili (HEA, NPU, NPI, kutija i L), su neophodni za gradnju i strukturno ojačanje.",
        "Ovi materijali pružaju čvrstoću i izdržljivost, a cene se zasnivaju na tipu proizvoda i količini narudžbine."
      ],
      "initial": true,
      "children": [],
      "measurements": []
    },
    {
      "id": 11,
      "name": "KERAMIČKE PLOČICE (ZIDOVE I PODOVE)",
      "images": [
        "https://dropinblog.net/34246798/files/featured/Top_8_Flooring_Tiles_You_Must_Know_For_Your_Home.jpg"
      ],
      "details": [
        "Keramičke pločice, dizajnirane za primenu na zidovima i podovima, nude izdržljivu i svestranu opciju za poboljšanje estetike i funkcionalnosti bilo kojeg prostora.",
        "Dostupne su u različitim veličinama, dizajnima i završnim obradama, a cene zavise od tipa pločica i količine narudžbine, čineći ih pogodnim za stambene i komercijalne projekte."
      ],
      "initial": true,
      "children": [],
      "measurements": []
    },
    {
      "id": 10,
      "name": "KAFA, BIBER, KARDAMOM ZAČIN, KAKAO",
      "images": [
        "https://www.thespruceeats.com/thmb/NnN4vOmp_BqCEjs4CuD1lgBlQog=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/moroccan-spiced-coffee-2394960-step-01-b898ab4c49bf42dbaba949f2c2bbbbd4.jpg"
      ],
      "details": [
        "100% organski, proizveden u Hondurasu",
        "SHG Supreme kafa, HG kafa, kafa Santa Teresa",
        "Mješavina kafe za bosansku kafu (60% Arabica, 40% Robusta)",
        "Začin kardamom i zrna bibera, 100% organski",
        "Kakao prah i kakao maslac, 100% organski"
      ],
      "initial": true,
      "children": [
        10350,
        10351,
        10352,
        10353,
        10354,
        10355,
        10356,
        10357,
        10358,
        10359
      ],
      "measurements": []
    },
    {
      "id": 3,
      "name": "PARAFIN",
      "images": [
        "https://d2r3z0h7oyiawr.cloudfront.net/2024/05/21/16/01/13/c7d3fa80-559b-4970-aa96-2b0c48b3ca4b/paraffin-header-with-out-text.jpg"
      ],
      "details": [
        "Parafin je svestran materijal koji se obično koristi u pravljenju svijeća, vodootpornim i industrijskim primjenama zbog svojih svojstava čiste sagorijevanja i otpornosti na vodu.",
        "Dostupan u velikim količinama, cijena zavisi od obima narudžbe i upita, što ga čini idealnim za proizvodnju u velikim razmjerama ili specijalizovanu upotrebu."
      ],
      "initial": true,
      "children": [],
      "measurements": []
    },
    {
      "id": 14,
      "name": "PROFILI",
      "images": [
        "/img/profiles.jpg"
      ],
      "details": [
        "Aluminijski profili su lagani, izdržljivi strukturni elementi napravljeni od ekstruziranog aluminijuma.",
        "Obično se koriste u građevinarstvu i proizvodnji zbog svoje otpornosti na koroziju i svestranosti u raznim primjenama."
      ],
      "children": [
        1000,
        1001,
        1002,
        1003,
        1004,
        1005,
        1006,
        1007,
        1008,
        1009,
        1010,
        1011,
        1012,
        1013,
        1015,
        1016,
        1017,
        1018,
        1019,
        1020,
        1021,
        1022,
        1023,
        1024,
        1025,
        1026,
        1027,
        1028
      ],
      "measurements": []
    },
    {
      "id": 15,
      "name": "SOLARNI SISTEMI",
      "images": [
        "https://feal.ba/wp-content/uploads/2023/01/01-FNE-2000.jpg"
      ],
      "details": [
        "Aluminijski solarni sistemi su lagani, izdržljivi i otporni na koroziju, pružajući dugotrajnu podršku za solarne panele.",
        "Lako se instaliraju i održavaju, maksimiziraju energetsku efikasnost i osiguravaju pouzdanu izvedbu u bilo kojem okruženju."
      ],
      "children": [
        1029,
        1030,
        1031,
        1032,
        1033,
        1034,
        1035
      ],
      "measurements": []
    },
    {
      "id": 16,
      "name": "SISTEMI VRATA I PROZORA",
      "images": [
        "/img/doorandwindowsystems.jpg"
      ],
      "details": [
        "Sistemi vrata i prozora, dostupni sa ili bez termalne prekidne tačke, nude superiornu izolaciju, energetsku efikasnost i izdržljivost.",
        "Sistemi sa termalnom prekidnom tačkom pružaju poboljšanu kontrolu temperature, dok opcije bez termalne prekidne tačke nude ekonomična rješenja."
      ],
      "children": [
        17,
        18
      ],
      "measurements": []
    },
    {
      "id": 17,
      "name": "BEZ TERMALNE PREKIDNE TAČKE",
      "images": [
        "/img/nothermalbreak.png"
      ],
      "details": [
        "Sistemi vrata i prozora bez termalne prekidne tačke nude ekonomično, izdržljivo rješenje za područja gdje izolacija nije ključna.",
        "Idealni za toplije klime, pružaju pouzdanu izvedbu i dugotrajnu vrijednost po pristupačnoj cijeni."
      ],
      "children": [
        1041,
        1042,
        1043,
        1044,
        1045,
        1046,
        1047,
        1048,
        1049,
        1050,
        1051,
        1052,
        1053,
        1054,
        1055,
        1056,
        1057,
        1058,
        1059,
        1060,
        1061,
        1062,
        1063,
        1064,
        1065,
        1066,
        1067,
        1068,
        1069,
        1070,
        1071,
        1072,
        1073,
        1074,
        1075,
        1076,
        1077,
        1078,
        1079,
        1080,
        1081,
        1082,
        1083,
        1084,
        1085,
        1086,
        1087,
        1088,
        1089
      ],
      "measurements": []
    },
    {
      "id": 18,
      "name": "SA TERMALNOM PREKIDNOM TAČKOM",
      "images": [
        "/img/thermalbreak.jpg"
      ],
      "details": [
        "Sistemi vrata i prozora sa termalnom prekidnom tačkom nude superiornu izolaciju, smanjujući troškove energije sprečavanjem prenosa toplote.",
        "Savršeni su za ekstremne klime, pružajući udobnost, izdržljivost i dugoročne uštede uz visoku efikasnost."
      ],
      "children": [
        1090,
        1091,
        1092,
        1093,
        1094,
        1095,
        1096,
        1097,
        1098,
        1099,
        1100,
        1101,
        1102,
        1103,
        1104,
        1105,
        1106,
        1108,
        1109,
        1110,
        1111,
        1112,
        1113,
        1114,
        1115,
        1116,
        1117,
        1118,
        1119,
        1120,
        1121
      ],
      "measurements": []
    },
    {
      "id": 19,
      "name": "DODACI ZA PROFILI",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Dodaci za aluminijske profile poboljšavaju vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Lagani, ali čvrsti, pojednostavljuju sklapanje i prilagodbu, čineći ih idealnim za razne primjene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 20,
      "name": "KAKAO MASLAC",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Dodaci za aluminijske profile poboljšavaju vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Lagani, ali čvrsti, pojednostavljuju sklapanje i prilagodbu, čineći ih idealnim za razne primjene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 21,
      "name": "KAKAO PRAH",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Dodaci za aluminijske profile poboljšavaju vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Lagani, ali čvrsti, pojednostavljuju sklapanje i prilagodbu, čineći ih idealnim za razne primjene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 22,
      "name": "CACAO PROFILI",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Pribor za aluminijske profile poboljšava vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Lagan, ali čvrst, pojednostavljuje sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 23,
      "name": "Omoa",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Pribor za aluminijske profile poboljšava vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Lagan, ali čvrst, pojednostavljuje sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 24,
      "name": "Jezero Yojoa",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Pribor za aluminijske profile poboljšava vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Lagan, ali čvrst, pojednostavljuje sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 25,
      "name": "Copán",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Pribor za aluminijske profile poboljšava vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Lagan, ali čvrst, pojednostavljuje sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 26,
      "name": "KAFEA",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Pribor za aluminijske profile poboljšava vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Lagan, ali čvrst, pojednostavljuje sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 27,
      "name": "Zelena kafa",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Pribor za aluminijske profile poboljšava vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Lagan, ali čvrst, pojednostavljuje sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 28,
      "name": "Pečena kafa",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Pribor za aluminijske profile poboljšava vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Lagan, ali čvrst, pojednostavljuje sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 29,
      "name": "Mlevena kafa",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Pribor za aluminijske profile poboljšava vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Lagan, ali čvrst, pojednostavljuje sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 30,
      "name": "Arabika",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Pribor za aluminijske profile poboljšava vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Lagan, ali čvrst, pojednostavljuje sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 31,
      "name": "HG EP",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Pribor za aluminijske profile poboljšava vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Lagan, ali čvrst, pojednostavljuje sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 32,
      "name": "HG EP",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Dodaci za aluminijske profile poboljšavaju vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Laki, ali čvrsti, pojednostavljuju sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 33,
      "name": "SHG EP",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Dodaci za aluminijske profile poboljšavaju vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Laki, ali čvrsti, pojednostavljuju sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 34,
      "name": "SHG EP Plus",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Dodaci za aluminijske profile poboljšavaju vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Laki, ali čvrsti, pojednostavljuju sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 35,
      "name": "Stock A, 150-180 defekata",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Dodaci za aluminijske profile poboljšavaju vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Laki, ali čvrsti, pojednostavljuju sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 36,
      "name": "Stock B, 250-180 defekata",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Dodaci za aluminijske profile poboljšavaju vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Laki, ali čvrsti, pojednostavljuju sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 37,
      "name": "Stock C, 350-380 defekata",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Dodaci za aluminijske profile poboljšavaju vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Laki, ali čvrsti, pojednostavljuju sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 38,
      "name": "CUP PROFILES",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Dodaci za aluminijske profile poboljšavaju vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Laki, ali čvrsti, pojednostavljuju sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 39,
      "name": "Allspice",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Dodaci za aluminijske profile poboljšavaju vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Laki, ali čvrsti, pojednostavljuju sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 40,
      "name": "Cardamom",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Dodaci za aluminijske profile poboljšavaju vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Laki, ali čvrsti, pojednostavljuju sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 41,
      "name": "HG-15 Defekti",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Dodaci za aluminijske profile poboljšavaju vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Laki, ali čvrsti, pojednostavljuju sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 42,
      "name": "SHG-15 Defekti",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Dodaci od aluminijumskih profila poboljšavaju vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Laki, ali čvrsti, pojednostavljuju sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 43,
      "name": "Pacamara Mikrolot",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Dodaci od aluminijumskih profila poboljšavaju vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Laki, ali čvrsti, pojednostavljuju sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 44,
      "name": "Stok Lot 110 Miramar Profil",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Dodaci od aluminijumskih profila poboljšavaju vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Laki, ali čvrsti, pojednostavljuju sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 45,
      "name": "Stok Lotu 400",
      "images": [
        "/img/accessories.jpeg"
      ],
      "details": [
        "Dodaci od aluminijumskih profila poboljšavaju vaše projekte sa izdržljivošću, preciznošću i svestranošću.",
        "Laki, ali čvrsti, pojednostavljuju sklapanje i prilagođavanje, čineći ih idealnim za razne primene."
      ],
      "children": [
        1036,
        1037,
        1038,
        1039,
        1040
      ],
      "measurements": []
    },
    {
      "id": 1000,
      "name": "stakleni rub",
      "images": [
        "/img/glazingbead.png"
      ],
      "details": [
        "težina: 0.280 kg/m",
        "perimetar: 96.5834 mm"
      ],
      "measurements": []
    },
    {
      "id": 1001,
      "name": "polu stub",
      "images": [
        "/img/halvedmullion.png"
      ],
      "details": [
        "težina: 1.866 kg/m",
        "perimetar: 400.302 mm"
      ],
      "measurements": []
    },
    {
      "id": 1002,
      "name": "prečka",
      "images": [
        "/img/transom.png"
      ],
      "details": [
        "težina: 2.723 kg/m",
        "perimetar: 449.153 mm"
      ],
      "measurements": []
    },
    {
      "id": 1003,
      "name": "donja prečka",
      "images": [
        "/img/lowertransom.png"
      ],
      "details": [
        "težina: 2.280 kg/m",
        "perimetar: 535 mm"
      ],
      "measurements": []
    },
    {
      "id": 1004,
      "name": "gornja prečka",
      "images": [
        "/img/uppertransom.png"
      ],
      "details": [
        "težina: 2.541 kg/m",
        "perimetar: 448.34 mm"
      ],
      "measurements": []
    },
    {
      "id": 1005,
      "name": "profil za fiksno krilo",
      "images": [
        "/img/staffbeadforfixedwing.png"
      ],
      "details": [
        "težina: 0.431 kg/m",
        "perimetar: 128.39 mm"
      ],
      "measurements": []
    },
    {
      "id": 1006,
      "name": "perla za otvaranje krila",
      "images": [
        "/img/staffbeadforopeningwing.png"
      ],
      "details": [
        "težina: 0.257 kg/m",
        "perimetar: 104.631 mm"
      ],
      "measurements": []
    },
    {
      "id": 1007,
      "name": "profil za panel",
      "images": [
        "/img/profileforpannel.png"
      ],
      "details": [
        "težina: 0.52 kg/m",
        "perimetar: - mm"
      ],
      "measurements": []
    },
    {
      "id": 1008,
      "name": "lajsna",
      "images": [
        "/img/skirting.png"
      ],
      "details": [
        "težina: 0.557 kg/m",
        "perimetar: 285.51 mm"
      ],
      "measurements": []
    },
    {
      "id": 1009,
      "name": "manji prečka",
      "images": [
        "/img/smallertransom.png"
      ],
      "details": [
        "težina: 1.753 kg/m",
        "perimetar: 342.12 mm"
      ],
      "measurements": []
    },
    {
      "id": 1010,
      "name": "horizontalni profil krila",
      "images": [
        "/img/horizontalwingprofile.png"
      ],
      "details": [
        "težina: 1.059 kg/m",
        "perimetar: 302.27 mm"
      ],
      "measurements": []
    },
    {
      "id": 1011,
      "name": "vertikalni profil krila",
      "images": [
        "/img/verticalwingprofile.png"
      ],
      "details": [
        "težina: 0.877 kg/m",
        "perimetar: 231.18 mm"
      ],
      "measurements": []
    },
    {
      "id": 1012,
      "name": "pojačanje za stub",
      "images": [
        "/img/reinforcementformullion.png"
      ],
      "details": [
        "težina: 2.236 kg/m",
        "perimetar: 507.24 mm"
      ],
      "measurements": []
    },
    {
      "id": 1013,
      "name": "veće pojačanje za stub",
      "images": [
        "/img/largerreinforcementformullion.png"
      ],
      "details": [
        "težina: 2.562 kg/m",
        "perimetar: 949.16 mm"
      ],
      "measurements": []
    },
    {
      "id": 1015,
      "name": "kut",
      "images": [
        "/img/corner.png"
      ],
      "details": [
        "težina: 1.46 kg/m",
        "perimetar: 285.72 mm"
      ],
      "measurements": []
    },
    {
      "id": 1016,
      "name": "vertikalni profil krila 261.31mm",
      "images": [
        "/img/verticalwingprofile261.31mm.png"
      ],
      "details": [
        "težina: 0.943 kg/m",
        "perimetar: 261.31 mm"
      ],
      "measurements": []
    },
    {
      "id": 1017,
      "name": "horizontalni profil krila 288.5mm",
      "images": [
        "/img/horizontalwingprofile288.5mm.png"
      ],
      "details": [
        "težina: 1.083 kg/m",
        "perimetar: 288.5 mm"
      ],
      "measurements": []
    },
    {
      "id": 1018,
      "name": "veća staklena letvica",
      "images": [
        "/img/largerglazingbead.png"
      ],
      "details": [
        "težina: 0.299 kg/m",
        "perimetar: 103.69 mm"
      ],
      "measurements": []
    },
    {
      "id": 1019,
      "name": "stub",
      "images": [
        "/img/mullion.png"
      ],
      "details": [
        "težina: 3.568 kg/m",
        "perimetar: 537.73 mm"
      ],
      "measurements": []
    },
    {
      "id": 1020,
      "name": "zatvoren stub",
      "images": [
        "/img/closedmullion.png"
      ],
      "details": [
        "težina: 1.384 kg/m",
        "perimetar: 296.10 mm"
      ],
      "measurements": []
    },
    {
      "id": 1021,
      "name": "gornji prečka 391mm",
      "images": [
        "/img/uppertransom391mm.png"
      ],
      "details": [
        "težina: 0 kg/m",
        "perimetar: 0 mm"
      ],
      "measurements": []
    },
    {
      "id": 1022,
      "name": "donji prečka 424.51mm",
      "images": [
        "/img/lowertransom424.51mm.png"
      ],
      "details": [
        "težina: 2.06 kg/m",
        "perimetar: 424.51 mm"
      ],
      "measurements": []
    },
    {
      "id": 1023,
      "name": "krilo 337.07mm",
      "images": [
        "/img/wing337.07mm.png"
      ],
      "details": [
        "težina: 1.482 kg/m",
        "perimetar: 337.07 mm"
      ],
      "measurements": []
    },
    {
      "id": 1024,
      "name": "krilo 301.61mm",
      "images": [
        "/img/wing301.61mm.png"
      ],
      "details": [
        "težina: 1.332 kg/m",
        "perimetar: 301.61 mm"
      ],
      "measurements": []
    },
    {
      "id": 1025,
      "name": "vertikalno krilo 365.18mm",
      "images": [
        "/img/verticalwing365.18mm.png"
      ],
      "details": [
        "težina: 1.582 kg/m",
        "perimetar: 365.18 mm"
      ],
      "measurements": []
    },
    {
      "id": 1026,
      "name": "termalni prekid krila",
      "images": [
        "/img/thermalbreakwing.png"
      ],
      "details": [
        "težina: 1.658 kg/m",
        "perimetar: 373.66 mm"
      ],
      "measurements": []
    },
    {
      "id": 1027,
      "name": "vertikalno krilo 355,18mm",
      "images": [
        "/img/verticalwing355.18mm.png"
      ],
      "details": [
        "težina: 1,557 kg/m",
        "perimetar: 355,18 mm"
      ],
      "measurements": []
    },
    {
      "id": 1028,
      "name": "termalno prekidno krilo 363,66mm",
      "images": [
        "/img/thermalbreakwing363.66.png"
      ],
      "details": [
        "težina: 1,633 kg/m",
        "perimetar: 363,66 mm"
      ],
      "measurements": []
    },
    {
      "id": 1029,
      "name": "trapezoidni i sendvič krovni sistemi",
      "images": [
        "/img/ALUM-TR-1065.png",
        "/img/ALUM-TR-1075.png",
        "/img/ALUM-TR-1055.png",
        "/img/ALUM-TR-2023.png"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1030,
      "name": "krovni sistemi sa stojećim spojem",
      "images": [
        "/img/ALUM-TR-1075.png",
        "/img/ALUM-KT-2707.png",
        "/img/ALUM-TR-2023.png"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1031,
      "name": "ondulin krovni sistemi",
      "images": [
        "/img/ALUM-TR-1075.png",
        "/img/ALUM-KT-2207.png",
        "/img/ALUM-TR-2023.png"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1032,
      "name": "ravni krovni sistemi",
      "images": [
        "/img/ALUM-FL-1605.png",
        "/img/ALUM-FL-1610.png",
        "/img/ALUM-FL-1615.png",
        "/img/ALUM-FL-1620.png"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1033,
      "name": "keramičke pločice krovni sistemi",
      "images": [
        "/img/ALUM-TR-1075.png",
        "/img/ALUM-TR-2023.png"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1034,
      "name": "membranski krovni sistemi",
      "images": [
        "/img/ALUM-TR-1075.png",
        "/img/ALUM-MB-2007.png",
        "/img/ALUM-TR-2023.png"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1035,
      "name": "šindrovni krovni sistemi",
      "images": [
        "/img/ALUM-TR-1075.png",
        "/img/ALUM-MB-2007.png",
        "/img/ALUM-TR-2023.png"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1036,
      "name": "ležajevi",
      "images": [
        "/img/ballbearings1.png",
        "/img/ballbearings2.png",
        "/img/ballbearings3.png",
        "/img/ballbearings4.png"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1037,
      "name": "UGAO",
      "images": [
        "/img/corner1.png",
        "/img/corner2.png",
        "/img/corner3.png"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1038,
      "name": "UGAO SPOJ",
      "images": [
        "/img/jointcorner1.PNG",
        "/img/jointcorner2.PNG",
        "/img/jointcorner3.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1039,
      "name": "ŠARKA",
      "images": [
        "/img/hinge1.PNG",
        "/img/hinge2.PNG",
        "/img/hinge3.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1040,
      "name": "KIT DODACI",
      "images": [
        "/img/kitaccessories1.PNG",
        "/img/kitaccessories2.PNG",
        "/img/kitaccessories3.PNG",
        "/img/kitaccessories4.PNG",
        "/img/kitaccessories5.PNG",
        "/img/kitaccessories6.PNG",
        "/img/kitaccessories7.PNG",
        "/img/kitaccessories8.PNG",
        "/img/kitaccessories9.PNG",
        "/img/kitaccessories10.PNG",
        "/img/kitaccessories11.PNG",
        "/img/kitaccessories12.PNG",
        "/img/kitaccessories13.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 10000,
      "name": "Hekimboard",
      "images": [
        "/uploads/hekimboard.jpg"
      ],
      "details": [],
      "children": [
        10017,
        10018,
        10019,
        10020,
        10021,
        10022,
        10023
      ],
      "measurements": []
    },
    {
      "id": 10017,
      "name": "Nova generacija spojne ploče",
      "images": [
        "/uploads/hekimboard-yeni-nesil-fugali-levha-1080x1080.jpg"
      ],
      "details": [],
      "children": [
        10024,
        10025,
        10026,
        10027,
        10028
      ],
      "measurements": []
    },
    {
      "id": 1041,
      "name": "M.SH-K075",
      "images": [
        "/img/M.SH-K075.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1042,
      "name": "M.SH-K076",
      "images": [
        "/img/M.SH-K076.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1043,
      "name": "M.SH-K072",
      "images": [
        "/img/M.SH-K072.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1044,
      "name": "M.SH-K0121",
      "images": [
        "/img/M.SH-K0121.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1045,
      "name": "M.SH-K071",
      "images": [
        "/img/M.SH-K071.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1046,
      "name": "M.SH-K073",
      "images": [
        "/img/M.SH-K073.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1047,
      "name": "M.SH-K070",
      "images": [
        "/img/M.SH-K070.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1048,
      "name": "M.SH-K074",
      "images": [
        "/img/M.SH-K074.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1049,
      "name": "M.SH-K079",
      "images": [
        "/img/M.SH-K079.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1050,
      "name": "M.SH-K078",
      "images": [
        "/img/M.SH-K078.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1051,
      "name": "M.SH-K090",
      "images": [
        "/img/M.SH-K090.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1052,
      "name": "M.SH-K089",
      "images": [
        "/img/M.SH-K089.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1053,
      "name": "M.SH-K0114",
      "images": [
        "/img/M.SH-K0114.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1054,
      "name": "M.SH-K083",
      "images": [
        "/img/M.SH-K083.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1055,
      "name": "M.SH-K084",
      "images": [
        "/img/M.SH-K084.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1056,
      "name": "M.SH-K082",
      "images": [
        "/img/M.SH-K082.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1057,
      "name": "M.SH-K086E",
      "images": [
        "/img/M.SH-K086E.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1058,
      "name": "M.SH-K081",
      "images": [
        "/img/M.SH-K081.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1059,
      "name": "M.SH-K0113",
      "images": [
        "/img/M.SH-K0113.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1060,
      "name": "M.SH-K088",
      "images": [
        "/img/M.SH-K088.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1061,
      "name": "M.SH-K0112",
      "images": [
        "/img/M.SH-K0112.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1062,
      "name": "M.SH-K0106",
      "images": [
        "/img/M.SH-K0106.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1063,
      "name": "M.SH-K0102",
      "images": [
        "/img/M.SH-K0102.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1064,
      "name": "M.SH_K0106",
      "images": [
        "/img/M.SH-K0106.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1065,
      "name": "M.SH-K0104",
      "images": [
        "/img/M.SH-K0104.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1066,
      "name": "M.SH-K0116",
      "images": [
        "/img/M.SH-K0116.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1067,
      "name": "M.SH-K0115",
      "images": [
        "/img/M.SH-K0115.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1068,
      "name": "M.SH-K099",
      "images": [
        "/img/M.SH-K099.png"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1069,
      "name": "M.SH-K098",
      "images": [
        "/img/M.SH-K098.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1070,
      "name": "M.SH-K0107",
      "images": [
        "/img/M.SH-K0107.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1071,
      "name": "M.SH-K0109",
      "images": [
        "/img/M.SH-K0109.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1072,
      "name": "M.SH-K0108",
      "images": [
        "/img/M.SH-K0108.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1073,
      "name": "M.SH-K0105",
      "images": [
        "/img/M.SH-K0105.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1074,
      "name": "M.SH-K0100",
      "images": [
        "/img/M.SH-K0100.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1075,
      "name": "M.SH-K0101",
      "images": [
        "/img/M.SH-K0101.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1076,
      "name": "M.SH-K0103",
      "images": [
        "/img/M.SH-K0103.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1077,
      "name": "M.SH-K095",
      "images": [
        "/img/M.SH-K095.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1078,
      "name": "M.SH-K097",
      "images": [
        "/img/M.SH-K097.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1079,
      "name": "M.SH-K087",
      "images": [
        "/img/M.SH-K087.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1080,
      "name": "M.SH-K096",
      "images": [
        "/img/M.SH-K096.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1081,
      "name": "M.SH-K077",
      "images": [
        "/img/M.SH-K077.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1082,
      "name": "M.SH-K080",
      "images": [
        "/img/M.SH-K080.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1083,
      "name": "M.SH-K085",
      "images": [
        "/img/M.SH-K085.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1084,
      "name": "M.SH-K0118",
      "images": [
        "/img/M.SH-K0118.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1085,
      "name": "M.SH-K0120",
      "images": [
        "/img/M.SH-K0120.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1086,
      "name": "M.SH-K091",
      "images": [
        "/img/M.SH-K091.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1087,
      "name": "M.SH-K0119",
      "images": [
        "/img/M.SH-K0119.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1088,
      "name": "M.SH-K0110",
      "images": [
        "/img/M.SH-K0110.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1089,
      "name": "M.SH-K0117",
      "images": [
        "/img/M.SH-K0117.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1090,
      "name": "M.SH 005",
      "images": [
        "/img/M.SH 005.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1091,
      "name": "M.SH 004",
      "images": [
        "/img/M.SH 004.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1092,
      "name": "M.SH 28",
      "images": [
        "/img/M.SH 28.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1093,
      "name": "M.SH 006",
      "images": [
        "/img/M.SH 006.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1094,
      "name": "M.SH 024",
      "images": [
        "/img/M.SH 024.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1095,
      "name": "M.SH 025",
      "images": [
        "/img/M.SH 025.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1096,
      "name": "M.SH 021",
      "images": [
        "/img/M.SH 021.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1097,
      "name": "M.SH 018",
      "images": [
        "/img/M.SH 018.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1098,
      "name": "M.SH 017",
      "images": [
        "/img/M.SH 017.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1099,
      "name": "M.SH 007",
      "images": [
        "/img/M.SH 007.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1100,
      "name": "M.SH 011",
      "images": [
        "/img/M.SH 011.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1101,
      "name": "M.SH 014",
      "images": [
        "/img/M.SH 014.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1102,
      "name": "M.SH 020",
      "images": [
        "/img/M.SH 020.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1103,
      "name": "M.SH 012",
      "images": [
        "/img/M.SH 012.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1104,
      "name": "M.SH 019",
      "images": [
        "/img/M.SH 019.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1105,
      "name": "M.SH 013",
      "images": [
        "/img/M.SH 013.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1106,
      "name": "M.SH 008",
      "images": [
        "/img/M.SH 008.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1108,
      "name": "M.SH 022",
      "images": [
        "/img/M.SH 022.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1109,
      "name": "M.SH 023",
      "images": [
        "/img/M.SH 023.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1110,
      "name": "M.SH 026",
      "images": [
        "/img/M.SH 026.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1111,
      "name": "T.900",
      "images": [
        "/img/T.900.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1112,
      "name": "T.901",
      "images": [
        "/img/T.901.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1113,
      "name": "T.902",
      "images": [
        "/img/T.902.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1114,
      "name": "T.903",
      "images": [
        "/img/T.903.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1115,
      "name": "T.904",
      "images": [
        "/img/T.904.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1116,
      "name": "M.SH 015",
      "images": [
        "/img/M.SH 015.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1117,
      "name": "M.SH 016",
      "images": [
        "/img/M.SH 016.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1118,
      "name": "M.SH 010",
      "images": [
        "/img/M.SH 010.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1119,
      "name": "M.SH 009",
      "images": [
        "/img/M.SH 009.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1120,
      "name": "T.951",
      "images": [
        "/img/T.951.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 1121,
      "name": "T.950",
      "images": [
        "/img/T.950.PNG"
      ],
      "details": [],
      "measurements": []
    },
    {
      "id": 10024,
      "name": "TIP-05 Nova generacija rebraste ploče",
      "images": [
        "/uploads/tip-05-fibercement-fugali-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1",
        "Uzorci: Glatka, Cedrovina, Orah"
      ],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 100 - 415 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,04"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 150 - 615 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,54"
          ]
        }
      ],
      "children": []
    },
    {
      "id": 10025,
      "name": "TIP-04 Nova generacija rebraste ploče",
      "images": [
        "/uploads/tip-04-fibercement-fugali-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1",
        "Uzorci: Glatka"
      ],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 275 - 605 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,51"
          ]
        }
      ],
      "children": []
    },
    {
      "id": 10026,
      "name": "TIP-03 Nova generacija rebraste ploče",
      "images": [
        "/uploads/tip-03-fibercement-fugali-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1",
        "Uzorci: Glatka"
      ],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 175 - 405 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,01"
          ]
        }
      ],
      "children": []
    },
    {
      "id": 10027,
      "name": "TIP-02 Nova generacija rebraste ploče",
      "images": [
        "/uploads/tip-02-fibercement-fugali-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1",
        "Uzorci: Glatka, Cedrovina, Orah, Prirodna, Kamen, Dekorativna, HekimStone"
      ],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 375 - 410 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,03"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 580 - 615 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,54"
          ]
        }
      ],
      "children": []
    },
    {
      "id": 10028,
      "name": "TIP-01 Nova Generacija Rebraste Ploče",
      "images": [
        "/uploads/tip-01-fibercement-fugali-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1",
        "Uzorci: Glatka, Cedrovina, Orah, Prirodna, Kamen, Dekorativna, HekimStone"
      ],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 175 - 405 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,01"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 275 - 605 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,51"
          ]
        }
      ],
      "children": []
    },
    {
      "id": 10018,
      "name": "TurkSiding",
      "images": [
        "/uploads/turksiding-1080x1080.jpg"
      ],
      "details": [],
      "children": [
        10029,
        10030
      ],
      "measurements": []
    },
    {
      "id": 10029,
      "name": "Cedrovina Uzorak Siding",
      "images": [
        "/uploads/sedir-agaci-desenli-fibercement-siding.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 170 x 2500",
            "Debljina (mm): 8",
            "Površina ploče (m2): 0,425"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 170 x 2500",
            "Debljina (mm): 10",
            "Površina ploče (m2): 0,425"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dimenzije (mm x mm): 200 x 2500",
            "Debljina (mm): 8",
            "Površina ploče (m2): 0,500"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dimenzije (mm x mm): 200 x 2500",
            "Debljina (mm): 10",
            "Površina ploče (m2): 0,500"
          ]
        }
      ]
    },
    {
      "id": 10030,
      "name": "Orah Uzorak Siding",
      "images": [
        "/uploads/ceviz-agaci-desenli-fibercement-siding.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 170 x 2500",
            "Debljina (mm): 8",
            "Površina ploče (m2): 0,425"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 170 x 2500",
            "Debljina (mm): 10",
            "Površina ploče (m2): 0,425"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dimenzije (mm x mm): 200 x 2500",
            "Debljina (mm): 8",
            "Površina ploče (m2): 0,500"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dimenzije (mm x mm): 200 x 2500",
            "Debljina (mm): 10",
            "Površina ploče (m2): 0,500"
          ]
        },
        {
          "key": "5",
          "values": [
            "Dimenzije (mm x mm): 170 x 3000",
            "Debljina (mm): 8",
            "Površina ploče (m2): 0,510"
          ]
        },
        {
          "key": "6",
          "values": [
            "Dimenzije (mm x mm): 170 x 3000",
            "Debljina (mm): 10",
            "Površina ploče (m2): 0,510"
          ]
        },
        {
          "key": "7",
          "values": [
            "Dimenzije (mm x mm): 200 x 3000",
            "Debljina (mm): 8",
            "Površina ploče (m2): 0,600"
          ]
        },
        {
          "key": "8",
          "values": [
            "Dimenzije (mm x mm): 200 x 3000",
            "Debljina (mm): 10",
            "Površina ploče (m2): 0,600"
          ]
        }
      ]
    },
    {
      "id": 10019,
      "name": "Jamb",
      "images": [
        "/uploads/sove-1080x1080.jpg"
      ],
      "details": [],
      "children": [
        10031,
        10032,
        10033,
        10034,
        10035,
        10036
      ],
      "measurements": []
    },
    {
      "id": 10031,
      "name": "PS-04 Okvir prozora",
      "images": [
        "/uploads/ps-04-fibercement-pencere-sovesi.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 150 x 2500",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,375"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 150 x 3000",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,45"
          ]
        }
      ]
    },
    {
      "id": 10032,
      "name": "PS-03 Okvir prozora",
      "images": [
        "/uploads/ps-03-fibercement-pencere-sovesi.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 150 x 2500",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,375"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 150 x 3000",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,45"
          ]
        }
      ]
    },
    {
      "id": 10033,
      "name": "PS-02 Okvir prozora",
      "images": [
        "/uploads/ps-02-fibercement-pencere-sovesi.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 120 x 2500",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,3"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 120 x 3000",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,36"
          ]
        }
      ]
    },
    {
      "id": 10034,
      "name": "PS-01 Okvir prozora",
      "images": [
        "/uploads/ps-01-fibercement-pencere-sovesi.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 100 x 2500",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,25"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 100 x 3000",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,30"
          ]
        }
      ]
    },
    {
      "id": 10035,
      "name": "Obični okvir",
      "images": [
        "/uploads/duz-fibercement-sove.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 80 x 2500",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,2"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 100 x 2500",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,25"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dimenzije (mm x mm): 150 x 2500",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,375"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dimenzije (mm x mm): 200 x 2500",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,5"
          ]
        },
        {
          "key": "5",
          "values": [
            "Dimenzije (mm x mm): 80 x 3000",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,2"
          ]
        },
        {
          "key": "6",
          "values": [
            "Dimenzije (mm x mm): 100 x 3000",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,25"
          ]
        },
        {
          "key": "7",
          "values": [
            "Dimenzije (mm x mm): 150 x 3000",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,375"
          ]
        },
        {
          "key": "8",
          "values": [
            "Dimenzije (mm x mm): 200 x 3000",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,5"
          ]
        }
      ]
    },
    {
      "id": 10020,
      "name": "Brisanje poda",
      "images": [
        "/uploads/kat-silmesi-1080x1080.jpg"
      ],
      "details": [],
      "children": [
        10037,
        10038,
        10039
      ],
      "measurements": []
    },
    {
      "id": 10037,
      "name": "KS-01 podna lajsna",
      "images": [
        "/uploads/ks-01-fibercement-kat-silmesi_1.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1",
        "Mjerenja:"
      ],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 200 x 2500",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,5"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 200 x 3000",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,6"
          ]
        }
      ],
      "children": []
    },
    {
      "id": 10038,
      "name": "KS-02 podna lajsna",
      "images": [
        "/uploads/ks-02-fibercement-kat-silmesi.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1",
        "Mjerenja:"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 205 x 2500",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,51"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 205 x 3000",
            "Debljina (mm): 20",
            "Površina ploče (m2): 0,61"
          ]
        }
      ]
    },
    {
      "id": 10021,
      "name": "Spojena ploča",
      "images": [
        "/uploads/hekimboard-fuagli-levha-1080x1080.jpg"
      ],
      "details": [],
      "children": [
        10040,
        10041,
        10042,
        10043,
        10044
      ],
      "measurements": []
    },
    {
      "id": 10040,
      "name": "TIP-E rebrasta ploča",
      "images": [
        "/uploads/tip-e-fibercement-fugali-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1",
        "Uzorci: Glatka, Cedrovina, Orah, Prirodni kamen, Dekorativni kamen, HekimStone"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 607 - 625 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,56"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 607 - 625 x 3000",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,87"
          ]
        }
      ]
    },
    {
      "id": 10041,
      "name": "TIP-D rebrasta ploča",
      "images": [
        "/uploads/tip-d-fibercement-fugali-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1",
        "Uzorci: Glatka, Cedrovina, Orah, Prirodni kamen, Dekorativni kamen, HekimStone"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 385 - 403 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,01"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 385 - 403 x 3000",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,20"
          ]
        }
      ]
    },
    {
      "id": 10042,
      "name": "TIP-C rebrasta ploča",
      "images": [
        "/uploads/tip-c-fibercement-fugali-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1",
        "Uzorci: Glatka, Cedrovina, Orah, Prirodni kamen, Dekorativni kamen, HekimStone"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 190 - 608 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,52"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 190 - 608 x 3000",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,82"
          ]
        }
      ]
    },
    {
      "id": 10043,
      "name": "TIP-B rebrasta ploča",
      "images": [
        "/uploads/tip-b-fibercement-fugali-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1",
        "Uzorci: Glatka, Cedrovina, Orah, Prirodni kamen, Dekorativni kamen, HekimStone"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 290 - 608 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,52"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 290 - 608 x 3000",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,82"
          ]
        }
      ]
    },
    {
      "id": 10044,
      "name": "TIP-A rebrasta ploča",
      "images": [
        "/uploads/tip-a-fibercement-fugali-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1",
        "Uzorci: Glatka, Cedrovina, Orah, Prirodni kamen, Dekorativni kamen, HekimStone"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 125 - 413 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,03"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 125 - 413 x 3000",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,24"
          ]
        }
      ]
    },
    {
      "id": 10022,
      "name": "Ravna ploča",
      "images": [
        "/uploads/hekim-board-duz-levha-1080x1080.jpg"
      ],
      "details": [],
      "children": [
        10045,
        10046
      ],
      "measurements": []
    },
    {
      "id": 10045,
      "name": "Obična unutrašnja ploča",
      "images": [
        "/uploads/duz-ic-fibercement-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 6",
            "Površina ploče (m2): 3.125"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 8",
            "Površina ploče (m2): 3.125"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3.125"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 3.125"
          ]
        },
        {
          "key": "5",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 14",
            "Površina ploče (m2): 3.125"
          ]
        },
        {
          "key": "6",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 16",
            "Površina ploče (m2): 3.125"
          ]
        },
        {
          "key": "7",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 18",
            "Površina ploče (m2): 3.125"
          ]
        },
        {
          "key": "8",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 20",
            "Površina ploče (m2): 3.125"
          ]
        },
        {
          "key": "9",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2800",
            "Debljina (mm): 8",
            "Površina ploče (m2): 3.50"
          ]
        },
        {
          "key": "10",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2800",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3.50"
          ]
        },
        {
          "key": "11",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2800",
            "Debljina (mm): 12",
            "Površina ploče (m2): 3.50"
          ]
        },
        {
          "key": "12",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2800",
            "Debljina (mm): 14",
            "Površina ploče (m2): 3.50"
          ]
        },
        {
          "key": "13",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2800",
            "Debljina (mm): 16",
            "Površina ploče (m2): 3.50"
          ]
        },
        {
          "key": "14",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2800",
            "Debljina (mm): 18",
            "Površina ploče (m2): 3.50"
          ]
        },
        {
          "key": "15",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 6",
            "Površina ploče (m2): 3.75"
          ]
        },
        {
          "key": "16",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 8",
            "Površina ploče (m2): 3.75"
          ]
        },
        {
          "key": "17",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3.75"
          ]
        },
        {
          "key": "18",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 12",
            "Površina ploče (m2): 3.75"
          ]
        },
        {
          "key": "19",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 14",
            "Površina ploče (m2): 3.75"
          ]
        },
        {
          "key": "20",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 16",
            "Površina ploče (m2): 3.75"
          ]
        },
        {
          "key": "21",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 18",
            "Površina ploče (m2): 3.75"
          ]
        },
        {
          "key": "22",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 20",
            "Površina ploče (m2): 3.75"
          ]
        }
      ],
      "children": []
    },
    {
      "id": 10046,
      "name": "Obična spoljašnja ploča",
      "images": [
        "/uploads/duz-dis-fibercement-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 6",
            "Površina ploče (m2): 3.125"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 8",
            "Površina ploče (m2): 3.125"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3.125"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 3.125"
          ]
        },
        {
          "key": "5",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 14",
            "Površina ploče (m2): 3.125"
          ]
        },
        {
          "key": "6",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 16",
            "Površina ploče (m2): 3.125"
          ]
        },
        {
          "key": "7",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 18",
            "Površina ploče (m2): 3.125"
          ]
        },
        {
          "key": "8",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 20",
            "Površina ploče (m2): 3.125"
          ]
        },
        {
          "key": "9",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 6",
            "Površina ploče (m2): 3.75"
          ]
        },
        {
          "key": "10",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 8",
            "Površina ploče (m2): 3.75"
          ]
        },
        {
          "key": "11",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3.75"
          ]
        },
        {
          "key": "12",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 12",
            "Površina ploče (m2): 3.75"
          ]
        },
        {
          "key": "13",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 14",
            "Površina ploče (m2): 3.75"
          ]
        },
        {
          "key": "14",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 16",
            "Površina ploče (m2): 3.75"
          ]
        },
        {
          "key": "15",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 18",
            "Površina ploče (m2): 3.75"
          ]
        },
        {
          "key": "16",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 20",
            "Površina ploče (m2): 3.75"
          ]
        }
      ],
      "children": []
    },
    {
      "id": 10047,
      "name": "Ploča sa uzorkom cedra",
      "images": [
        "/uploads/sedir-agaci-desenli-fibercement-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 8",
            "Površina ploče (m2): 3,125"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3,125"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 3,125"
          ]
        }
      ],
      "children": []
    },
    {
      "id": 10049,
      "name": "Ploča sa uzorkom kamenog zidanja",
      "images": [
        "/uploads/orme-tas-desenli-fibercement-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2620",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3,275"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2620",
            "Debljina (mm): 12",
            "Površina ploče (m2): 3,275"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3,75"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 12",
            "Površina ploče (m2): 3,75"
          ]
        }
      ],
      "children": []
    },
    {
      "id": 10050,
      "name": "Ploča sa uzorkom malih kamenčića",
      "images": [
        "/uploads/kucuk-blok-tas-desenli-fibercement-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 92,5x195 - 615 x 2460",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,513"
          ]
        }
      ],
      "children": []
    },
    {
      "id": 10051,
      "name": "Ploča sa mešanim uzorkom kamena",
      "images": [
        "/uploads/karisik-tas-desenli-fibercement-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 1225 x 2500",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3,21"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 1225 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 3,21"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dimenzije (mm x mm): 1225 x 3000",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3,675"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dimenzije (mm x mm): 1225 x 3000",
            "Debljina (mm): 12",
            "Površina ploče (m2): 3,675"
          ]
        }
      ],
      "children": []
    },
    {
      "id": 10052,
      "name": "HekimStone ploča sa uzorkom kamena",
      "images": [
        "/uploads/hekimstone-tas-desenli-fibercement-levha.png"
      ],
      "details": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3,125"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 3,125"
          ]
        }
      ],
      "children": []
    },
    {
      "id": 10053,
      "name": "Ploča sa uzorkom prirodnog kamena",
      "images": [
        "/uploads/dogal-tas-desenli-fibercement-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3,125"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 3,125"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3,75"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 12",
            "Površina ploče (m2): 3,75"
          ]
        }
      ],
      "children": []
    },
    {
      "id": 10054,
      "name": "Dekorativna ploča sa uzorkom kamena",
      "images": [
        "/uploads/dekoratif-tas-desenli-fibercement-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3,125"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 3,125"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3,75"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 12",
            "Površina ploče (m2): 3,75"
          ]
        }
      ],
      "children": []
    },
    {
      "id": 10055,
      "name": "Ploča sa uzorkom oraha",
      "images": [
        "/uploads/ceviz-agaci-desenli-fibercement-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 8",
            "Površina ploče (m2): 3,75"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3,125"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 12",
            "Površina ploče (m2): 3,125"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 8",
            "Površina ploče (m2): 3,75"
          ]
        },
        {
          "key": "5",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3,75"
          ]
        },
        {
          "key": "6",
          "values": [
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 12",
            "Površina ploče (m2): 3,75"
          ]
        }
      ],
      "children": []
    },
    {
      "id": 10056,
      "name": "Velika blok kamen uzorak ploča",
      "images": [
        "/uploads/buyuk-blok-tas-desenli-fibercement-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 195x400 - 615 x 2460",
            "Debljina (mm): 12",
            "Površina ploče (m2): 1,513"
          ]
        }
      ],
      "children": []
    },
    {
      "id": 10001,
      "name": "HekimPanel",
      "images": [
        "/uploads/hekimpanel_1.jpg"
      ],
      "details": [],
      "children": [
        10057,
        10058,
        10059
      ],
      "measurements": []
    },
    {
      "id": 10057,
      "name": "Panel kontejner",
      "images": [
        "/uploads/soguk-oda-tipi-sac-eps-pet-cephe-paneli.png"
      ],
      "details": [],
      "children": [
        10060,
        10061,
        10062,
        10063,
        10064,
        10065,
        10066
      ],
      "measurements": []
    },
    {
      "id": 10060,
      "name": "Metalna ploča rock vuna panel za hladnu sobu",
      "images": [
        "/uploads/soguk-oda-tipi-sac-tasyunu-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: A2.S1.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 600 - 1250",
            "Širina (mm): 2150 - 15000",
            "Debljina izolacije (mm): 40 - 150",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,4 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10061,
      "name": "Metalna ploča EPS panel za hladnu sobu",
      "images": [
        "/uploads/SoIuk-Oda-Tipi-Sac-EPS-Sac-Panel.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 600 - 1250",
            "Širina (mm): 2150 - 15000",
            "Debljina izolacije (mm): 40 - 180",
            "Debljina gornjeg metalnog sloja (mm): 0,3 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,3 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10062,
      "name": "Metalna ploča poliuretan panel za hladnu sobu",
      "images": [
        "/uploads/soguk-oda-tipi-sac-poliuretan-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: C.S3.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 800 - 1250",
            "Širina (mm): 2150 - 15000",
            "Debljina izolacije (mm): 40 - 150",
            "Debljina gornjeg metalnog sloja (mm): 0,35 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,3 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10063,
      "name": "Metalna ploča PIR panel za hladnu sobu",
      "images": [
        "/uploads/soguk-oda-tipi-sac-pir-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: B.S2.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 800 - 1250",
            "Širina (mm): 2150 - 15000",
            "Debljina izolacije (mm): 40 - 150",
            "Debljina gornjeg metalnog sloja (mm): 0,35 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,3 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10064,
      "name": "Metalna ploča EPS-Pet panel za hladnu sobu",
      "images": [
        "/uploads/soguk-oda-tipi-sac-eps-pet-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 600 - 1250",
            "Širina (mm): 2500 - 15000",
            "Debljina izolacije (mm): 40 - 180",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,6 - 0,8"
          ]
        }
      ]
    },
    {
      "id": 10065,
      "name": "Metalna ploča rock vuna-fibercement panel za hladnu sobu",
      "images": [
        "/uploads/soguk-oda-tipi-sac-tasyunu-fibercement-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: A2.S1.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 600 - 1250",
            "Širina (mm): 2500 - 3000",
            "Debljina izolacije (mm): 40 - 150",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 8 - 10"
          ]
        }
      ]
    },
    {
      "id": 10066,
      "name": "Metalna ploča EPS-fibercement panel za hladnu sobu",
      "images": [
        "/uploads/soguk-oda-tipi-sac-eps-fibercement-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 600 - 1250",
            "Širina (mm): 2500 - 3000",
            "Debljina izolacije (mm): 40 - 180",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 8 - 10"
          ]
        }
      ]
    },
    {
      "id": 10058,
      "name": "Fasadne Panele",
      "images": [
        "/uploads/cephe-panelleri-1080x1080.jpg"
      ],
      "details": [],
      "children": [
        10067,
        10068,
        10069,
        10070,
        10071,
        10072,
        10073,
        10074,
        10075,
        10076,
        10077,
        10078,
        10079,
        10080,
        10081,
        10082,
        10083,
        10084,
        10085,
        10086,
        10087,
        10088,
        10089,
        10090,
        10091,
        10092,
        10093,
        10094
      ],
      "measurements": []
    },
    {
      "id": 10067,
      "name": "Čisti Sinus Metalni List PIR Panel",
      "images": [
        "/uploads/tam-sinus-sac-pir-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: B.S2.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 50",
            "Debljina gornjeg metalnog sloja (mm): 0,5 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,35 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10068,
      "name": "Polu Sinus Metalni List PIR Panel",
      "images": [
        "/uploads/yarim-sinus-sac-pir-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: B.S2.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,5 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,35 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10069,
      "name": "Čisti Sinus Metalni List Poliuretanski Panel",
      "images": [
        "/uploads/tam-sinus-sac-poliuretan-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: A2.S1.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 600 - 1250",
            "Širina (mm): 2150 - 15000",
            "Debljina izolacije (mm): 40 - 150",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,4 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10070,
      "name": "Polu Sinus Metalni List Poliuretanski Panel",
      "images": [
        "/uploads/yarim-sinus-sac-poliuretan-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: C.S3.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,5 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,35 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10071,
      "name": "H Tip Metalni List - Panel od Stjenovite Vune",
      "images": [
        "/uploads/h-tipi-sac-tasyunu-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: A2.S1.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 600 - 1250",
            "Širina (mm): 2150 - 15000",
            "Debljina izolacije (mm): 40 - 150",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,4 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10072,
      "name": "Obloga Metalnog Lista - Poliuretanski Panel",
      "images": [
        "/uploads/siding-sac-poliuretan-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: C.S3.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,5 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,35 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10073,
      "name": "Obloga Metalnog Lista - PIR Panel",
      "images": [
        "/uploads/siding-sac-pir-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: B.S2.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,5 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,35 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10074,
      "name": "H Tip Metalni List - Panel od Stjenovite Vune - Fibercement",
      "images": [
        "/uploads/H-Tipi-Sac-TaIyunu-Fibercement-Panel.png"
      ],
      "details": [
        "Klasa nepaljivosti: A2.S1.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 60 - 1250",
            "Širina (mm): 2500 - 3000",
            "Debljina izolacije (mm): 40 - 150",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 8 - 10"
          ]
        }
      ]
    },
    {
      "id": 10075,
      "name": "H Tip Metalni List PIR Panel",
      "images": [
        "/uploads/H-Tipi-Sac-PIR-Sac-Panel.png"
      ],
      "details": [
        "Klasa nepaljivosti: B.S2.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000 - 1250",
            "Širina (mm): 2500 - 3000",
            "Debljina izolacije (mm): 50 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 8"
          ]
        }
      ]
    },
    {
      "id": 10076,
      "name": "H Tipi Sac-PIR-Fibercement Panel",
      "images": [
        "/uploads/h-tipi-sac-pir-fibercement-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: B.S2.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000 - 1250",
            "Širina (mm): 2500 - 3000",
            "Debljina izolacije (mm): 50 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 8"
          ]
        }
      ]
    },
    {
      "id": 10077,
      "name": "H Tip Metal Sheet Polyurethane Panel",
      "images": [
        "/uploads/h-tipi-sac-poliuretan-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: C.S3.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 800 - 1250",
            "Širina (mm): 2500 - 15000",
            "Debljina izolacije (mm): 50 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,4 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10078,
      "name": "H Tip Metal Sheet-EPS Panel",
      "images": [
        "/uploads/H-Tipi-Sac-EPS-Sac-Panel.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 600 - 1250",
            "Širina (mm): 2150 - 15000",
            "Debljina izolacije (mm): 40 - 150",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,4 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10079,
      "name": "H Tip Metal Sheet Polyurethane-Fibercement Panel",
      "images": [
        "/uploads/H-Tipi-Sac-Poliuretan-Fibercement-Panel.png"
      ],
      "details": [
        "Klasa nepaljivosti: C.S3.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000 - 1250",
            "Širina (mm): 2500 - 3000",
            "Debljina izolacije (mm): 50 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 8"
          ]
        }
      ]
    },
    {
      "id": 10080,
      "name": "Blind Screw Metal Sheet Polyurethane Panel",
      "images": [
        "/uploads/gizli-vida-sac-poliuretan-sac-cephe-paneli-1.png"
      ],
      "details": [
        "Klasa nepaljivosti: C.S3.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 800 - 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 80",
            "Debljina gornjeg metalnog sloja (mm): 0,35 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,3 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10081,
      "name": "H Tip Metal Sheet-EPS-Pet Panel",
      "images": [
        "/uploads/h-tipi-sac-eps-pet-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 600 - 1250",
            "Širina (mm): 2150 - 15000",
            "Debljina izolacije (mm): 50 - 180",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,6 - 0,8"
          ]
        }
      ]
    },
    {
      "id": 10082,
      "name": "H Tip Metal Sheet EPS-Fibercement Panel",
      "images": [
        "/uploads/h-tipi-sac-eps-fibercement-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 600 - 1250",
            "Širina (mm): 2150 - 15000",
            "Debljina izolacije (mm): 50 - 180",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 8 - 10"
          ]
        }
      ]
    },
    {
      "id": 10083,
      "name": "Blind Screw Metal Sheet PIR Panel",
      "images": [
        "/uploads/gizli-vida-sac-pir-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: B.S2.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 800 - 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 80",
            "Debljina gornjeg metalnog sloja (mm): 0,35 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,3 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10084,
      "name": "Blind Screw Metal Sheet Rockwool Panel",
      "images": [
        "/uploads/Gizli-Vida-Sac-TaIyunu-Sac-Panel.png"
      ],
      "details": [
        "Klasa nepaljivosti: A2.S1.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 150",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,4 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10085,
      "name": "Blind Screw Metal Sheet EPS Panel",
      "images": [
        "/uploads/gizli-vida-sac-eps-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 50 - 180",
            "Debljina gornjeg metalnog sloja (mm): 0,35 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,3 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10086,
      "name": "Metalni panel s blind vijakom EPS-Pet",
      "images": [
        "/uploads/gizli-vida-sac-eps-pet-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 50 - 180",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,6 - 0,8"
          ]
        }
      ]
    },
    {
      "id": 10087,
      "name": "Metalni panel G Form poliuretan",
      "images": [
        "/uploads/g-form-sac-poliuretan-sac-cephe-paneli-1.png"
      ],
      "details": [
        "Klasa nepaljivosti: C.S3.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,5 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,35 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10088,
      "name": "Metalni panel G Form-PIR",
      "images": [
        "/uploads/g-form-sac-pir-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: B.S2.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,5 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,35 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10089,
      "name": "Metalni panel s 5 rebra poliuretan",
      "images": [
        "/uploads/5-hadve-fugali-sac-poliuretan-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: C.S3.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,5 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,35 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10090,
      "name": "Metalni panel s 5 rebra PIR",
      "images": [
        "/uploads/5-hadve-fugali-sac-pir-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: B.S2.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,5 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,35 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10091,
      "name": "Metalni panel s 3 rebra poliuretan",
      "images": [
        "/uploads/3-hadve-fugali-sac-poliuretan-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: C.S3.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,5 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,35 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10092,
      "name": "Metalni panel s 3 rebra PIR",
      "images": [
        "/uploads/3-hadve-fugali-sac-pir-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: B.S2.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,5 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,35 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10093,
      "name": "Metalni panel s 1 rebro poliuretan",
      "images": [
        "/uploads/1-hadve-fugali-sac-poliuretan-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: C.S3.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,5 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,35 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10094,
      "name": "Metalni panel s 1 rebro PIR",
      "images": [
        "/uploads/1-hadve-fugali-sac-pir-sac-cephe-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: B.S2.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,5 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,35 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10059,
      "name": "Krovni paneli",
      "images": [
        "/uploads/cati-panelleri-1080x1080.jpg"
      ],
      "details": [],
      "children": [
        10095,
        10096,
        10097,
        10098,
        10099,
        10100,
        10101,
        10102,
        10103,
        10104,
        10105,
        10106
      ],
      "measurements": []
    },
    {
      "id": 10095,
      "name": "Metalni panel sa 5 rebara - Rockwool",
      "images": [
        "/uploads/5-hadve-sac-tasyunu-sac-cati-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 150",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,4 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10096,
      "name": "Metalni panel sa 5 rebara - Poliuretan",
      "images": [
        "/uploads/5-hadve-sac-poliuretan-sac-cati-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: C.S3.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2500 - 15000",
            "Debljina izolacije (mm): 35 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,3 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,3 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10097,
      "name": "Metalni panel sa 5 rebara - PIR",
      "images": [
        "/uploads/5-hadve-sac-pir-sac-cati-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: B.S2.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2500 - 15000",
            "Debljina izolacije (mm): 35 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,3 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,3 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10098,
      "name": "Metalni panel sa 5 rebara - PIR-Membrana",
      "images": [
        "/uploads/5-hadve-sac-pir-membran-cati-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: B.S2.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2500 - 15000",
            "Debljina izolacije (mm): 40 - 80",
            "Debljina gornjeg metalnog sloja (mm): 0,45 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 1,2"
          ]
        }
      ]
    },
    {
      "id": 10099,
      "name": "Metalni panel sa 5 rebara - EPS",
      "images": [
        "/uploads/5-hadve-sac-eps-sac-cati-paneli-1.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 180",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,3 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10100,
      "name": "Metalni panel sa 5 rebara - Poliuretan-Membrana",
      "images": [
        "/uploads/5-hadve-sac-poliuretan-membran-cati-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: C.S3.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2500 - 15000",
            "Debljina izolacije (mm): 40 - 80",
            "Debljina gornjeg metalnog sloja (mm): 0,45 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 1,2"
          ]
        }
      ]
    },
    {
      "id": 10101,
      "name": "5 rebara - EPS - Pet panel",
      "images": [
        "/uploads/5-hadve-sac-eps-pet-cati-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 180",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,6 - 0,8"
          ]
        }
      ]
    },
    {
      "id": 10102,
      "name": "Metalni panel sa 3 rebara - Poliuretan",
      "images": [
        "/uploads/3-hadve-sac-poliuretan-sac-cati-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: C.S3.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2500 - 15000",
            "Debljina izolacije (mm): 35 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,3 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,3 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10103,
      "name": "Metalni panel sa 3 rebara - Sac-PIR",
      "images": [
        "/uploads/3-hadve-sac-pir-sac-cati-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: B.S2.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2500 - 15000",
            "Debljina izolacije (mm): 35 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,3 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,3 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10104,
      "name": "Metalni panel sa 3 rebara - EPS",
      "images": [
        "/uploads/3-hadve-sac-eps-sac-cati-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 180",
            "Debljina gornjeg metalnog sloja (mm): 0,35 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,3 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10105,
      "name": "Profil kapa 3 rebra metalna ploča - poliuretanska panela",
      "images": [
        "/uploads/3-hadve-kepli-sac-poliuretan-sac-cati-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: C.S3.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,3 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10106,
      "name": "Profil kapa 3 rebra metalna ploča - PIR panel",
      "images": [
        "/uploads/3-hadve-kepli-sac-pir-sac-cati-paneli.png"
      ],
      "details": [
        "Klasa nepaljivosti: B.S2.d0"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Visina (mm): 1000",
            "Širina (mm): 2300 - 15000",
            "Debljina izolacije (mm): 40 - 100",
            "Debljina gornjeg metalnog sloja (mm): 0,4 - 0,7",
            "Debljina donjeg metalnog sloja (mm): 0,3 - 0,7"
          ]
        }
      ]
    },
    {
      "id": 10002,
      "name": "HekimColorboard",
      "images": [
        "/uploads/hekimcolorboard.jpg"
      ],
      "details": [],
      "children": [
        10107,
        10108,
        10109
      ],
      "measurements": []
    },
    {
      "id": 10107,
      "name": "Fibercement u boji drveta",
      "images": [
        "/uploads/wood-renkli-fibercement-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1",
        "Boje proizvoda: Ecru, Jasen, Siva, Dim, Siva, Antracit, Bež, Bijela, Kafa, Narandžasta, Pločica, Crvena",
        "Boje gornjeg sloja: Ecru, Jasen, Siva, Dim, Siva, Antracit, Bež, Bijela, Kafa, Narandžasta, Pločica, Crvena"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3,125"
          ]
        }
      ]
    },
    {
      "id": 10108,
      "name": "Fibercement u prugama",
      "images": [
        "/uploads/striped-renkli-fibercement-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1",
        "Boje proizvoda: Ecru, Jasen, Siva, Dim, Siva, Antracit, Bež, Bijela, Kafa, Narandžasta, Pločica, Crvena",
        "Boje gornjeg sloja: Ecru, Jasen, Siva, Dim, Siva, Antracit, Bež, Bijela, Kafa, Narandžasta, Pločica, Crvena"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3,125"
          ]
        }
      ]
    },
    {
      "id": 10109,
      "name": "Fibercement u boji kamena",
      "images": [
        "/uploads/stone-renkli-fibercement-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: A1",
        "Boje proizvoda: Ecru, Jasen, Siva, Dim, Siva, Antracit, Bež, Bijela, Kafa, Narandžasta, Pločica, Crvena",
        "Boje gornjeg sloja: Ecru, Jasen, Siva, Dim, Siva, Antracit, Bež, Bijela, Kafa, Narandžasta, Pločica, Crvena"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 10",
            "Površina ploče (m2): 3,125"
          ]
        }
      ]
    },
    {
      "id": 10003,
      "name": "Hekimpor",
      "images": [
        "/uploads/hekimpor_1.jpg"
      ],
      "details": [],
      "children": [
        10110,
        10111,
        10112,
        10113,
        10114
      ],
      "measurements": []
    },
    {
      "id": 10110,
      "name": "EPS izolacione ploče",
      "images": [
        "/uploads/eps-yalitim-levhalari-1080x1080.jpg"
      ],
      "details": [],
      "children": [
        10115,
        10116,
        10117
      ],
      "measurements": []
    },
    {
      "id": 10115,
      "name": "MaxiPor EPS ploča",
      "images": [
        "/uploads/maxipor-eps-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: E",
        "Zahvaljujući reflektivnoj osobini ugljika u svom sastavu, pruža 20% bolju izolaciju od normalnih ploča.",
        "Njegova λ vrednost je 0,035.",
        "Pošto postoje stabilizovane ploče, dimenzionalna stabilnost je stabilna.",
        "Ne sadrži CFC (hlorofluorougljenik) gas i njegove derivate.",
        "Njegova propusnost za vodenu paru je visoka.",
        "Toplotna otpornost je visoka.",
        "Ima visoku otpornost na pritisak.",
        "Zgrada ne gubi svoju izolacionu osobinu tokom života zgrade.",
        "To je E klasa protivpožarna zaštita.",
        "Kao izolacioni materijal unutar Hekimboard Hekimpan montažne zidne ploče.",
        "Kao materijal za toplotnu izolaciju između cigle, gas betona, pumice betonskih blokova i gipsanih montažnih zidnih panela.",
        "Kao toplotna izolacija između zidova u proizvodnji pregradnih zidova."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 10",
            "Broj proizvoda u paketu: 50",
            "Ukupna površina (m2): 25",
            "Ukupni volumen (m3): 0.25"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 20",
            "Broj proizvoda u paketu: 25",
            "Ukupna površina (m2): 12.5",
            "Ukupni volumen (m3): 0.25"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 30",
            "Broj proizvoda u paketu: 16",
            "Ukupna površina (m2): 8",
            "Ukupni volumen (m3): 0.24"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 40",
            "Broj proizvoda u paketu: 12",
            "Ukupna površina (m2): 6",
            "Ukupni volumen (m3): 0.24"
          ]
        },
        {
          "key": "5",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 50",
            "Broj proizvoda u paketu: 10",
            "Ukupna površina (m2): 5",
            "Ukupni volumen (m3): 0.25"
          ]
        },
        {
          "key": "6",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 60",
            "Broj proizvoda u paketu: 8",
            "Ukupna površina (m2): 4",
            "Ukupni volumen (m3): 0.24"
          ]
        },
        {
          "key": "7",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 70",
            "Broj proizvoda u paketu: 7",
            "Ukupna površina (m2): 3.5",
            "Ukupni volumen (m3): 0.245"
          ]
        },
        {
          "key": "8",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 80",
            "Broj proizvoda u paketu: 6",
            "Ukupna površina (m2): 3",
            "Ukupni volumen (m3): 0.24"
          ]
        },
        {
          "key": "9",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 90",
            "Broj proizvoda u paketu: 5",
            "Ukupna površina (m2): 2.5",
            "Ukupni volumen (m3): 0.225"
          ]
        },
        {
          "key": "10",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 100",
            "Broj proizvoda u paketu: 5",
            "Ukupna površina (m2): 2.5",
            "Ukupni volumen (m3): 0.25"
          ]
        }
      ]
    },
    {
      "id": 10116,
      "name": "EPS ploča ojačana ugljenikom",
      "images": [
        "/uploads/karbon-takviyeli-eps-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 10",
            "Broj proizvoda u paketu: 50",
            "Ukupna površina (m2): 25",
            "Ukupni volumen (m3): 0.25"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 20",
            "Broj proizvoda u paketu: 25",
            "Ukupna površina (m2): 12.5",
            "Ukupni volumen (m3): 0.25"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 30",
            "Broj proizvoda u paketu: 16",
            "Ukupna površina (m2): 8",
            "Ukupni volumen (m3): 0.24"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 40",
            "Broj proizvoda u paketu: 12",
            "Ukupna površina (m2): 6",
            "Ukupni volumen (m3): 0.24"
          ]
        },
        {
          "key": "5",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 50",
            "Broj proizvoda u paketu: 10",
            "Ukupna površina (m2): 5",
            "Ukupni volumen (m3): 0.25"
          ]
        },
        {
          "key": "6",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 60",
            "Broj proizvoda u paketu: 8",
            "Ukupna površina (m2): 4",
            "Ukupni volumen (m3): 0.24"
          ]
        },
        {
          "key": "7",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 70",
            "Broj proizvoda u paketu: 7",
            "Ukupna površina (m2): 3.5",
            "Ukupni volumen (m3): 0.245"
          ]
        },
        {
          "key": "8",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 80",
            "Broj proizvoda u paketu: 6",
            "Ukupna površina (m2): 3",
            "Ukupni volumen (m3): 0.24"
          ]
        },
        {
          "key": "9",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 90",
            "Broj proizvoda u paketu: 5",
            "Ukupna površina (m2): 2.5",
            "Ukupni volumen (m3): 0.225"
          ]
        },
        {
          "key": "10",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 100",
            "Broj proizvoda u paketu: 5",
            "Ukupna površina (m2): 2.5",
            "Ukupni volumen (m3): 0.25"
          ]
        }
      ]
    },
    {
      "id": 10117,
      "name": "Bijela EPS ploča",
      "images": [
        "/uploads/beyaz-eps-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 10",
            "Broj proizvoda u paketu: 50",
            "Ukupna površina (m2): 25",
            "Ukupni volumen (m3): 0.25"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 20",
            "Broj proizvoda u paketu: 25",
            "Ukupna površina (m2): 12.5",
            "Ukupni volumen (m3): 0.25"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 30",
            "Broj proizvoda u paketu: 16",
            "Ukupna površina (m2): 8",
            "Ukupni volumen (m3): 0.24"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 40",
            "Broj proizvoda u paketu: 12",
            "Ukupna površina (m2): 6",
            "Ukupni volumen (m3): 0.24"
          ]
        },
        {
          "key": "5",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 50",
            "Broj proizvoda u paketu: 10",
            "Ukupna površina (m2): 5",
            "Ukupni volumen (m3): 0.25"
          ]
        },
        {
          "key": "6",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 60",
            "Broj proizvoda u paketu: 8",
            "Ukupna površina (m2): 4",
            "Ukupni volumen (m3): 0.24"
          ]
        },
        {
          "key": "7",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 70",
            "Broj proizvoda u paketu: 7",
            "Ukupna površina (m2): 3.5",
            "Ukupni volumen (m3): 0.245"
          ]
        },
        {
          "key": "8",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 80",
            "Broj proizvoda u paketu: 6",
            "Ukupna površina (m2): 3",
            "Ukupni volumen (m3): 0.24"
          ]
        },
        {
          "key": "9",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 90",
            "Broj proizvoda u paketu: 5",
            "Ukupna površina (m2): 2.5",
            "Ukupni volumen (m3): 0.225"
          ]
        },
        {
          "key": "10",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 100",
            "Broj proizvoda u paketu: 5",
            "Ukupna površina (m2): 2.5",
            "Ukupni volumen (m3): 0.25"
          ]
        }
      ]
    },
    {
      "id": 10111,
      "name": "EPS blokovi",
      "images": [
        "/uploads/eps-bloklar-1080x1080.jpg"
      ],
      "details": [],
      "children": [
        10118,
        10119,
        10120
      ],
      "measurements": []
    },
    {
      "id": 10118,
      "name": "EPS blok ojačan ugljenikom",
      "images": [
        "/uploads/karbon-takviyeli-eps-blok.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Gustina (kg/m3): 16-22",
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 1000",
            "Broj proizvoda u paketu: 1",
            "Ukupna površina (m2): 3.125",
            "Ukupni volumen (m3): 3.125"
          ]
        },
        {
          "key": "2",
          "values": [
            "Gustina (kg/m3): 16-22",
            "Dimenzije (mm x mm): 1250 x 2800",
            "Debljina (mm): 1000",
            "Broj proizvoda u paketu: 1",
            "Ukupna površina (m2): 3.5",
            "Ukupni volumen (m3): 3.5"
          ]
        },
        {
          "key": "3",
          "values": [
            "Gustina (kg/m3): 16-22",
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 1000",
            "Broj proizvoda u paketu: 1",
            "Ukupna površina (m2): 3.75",
            "Ukupni volumen (m3): 3.75"
          ]
        }
      ]
    },
    {
      "id": 10119,
      "name": "Hekim Geofoam EPS blok",
      "images": [
        "/uploads/hekimgeofam-eps-blok.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Gustina (kg/m3): 10-20",
            "Dimenzije (mm x mm): 1250 x 2000",
            "Debljina (mm): 1000",
            "Broj proizvoda u paketu: 1",
            "Ukupna površina (m2): 2.5",
            "Ukupni volumen (m3): 2.5"
          ]
        },
        {
          "key": "2",
          "values": [
            "Gustina (kg/m3): 10-40",
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 1000",
            "Broj proizvoda u paketu: 1",
            "Ukupna površina (m2): 3.125",
            "Ukupni volumen (m3): 3.125"
          ]
        },
        {
          "key": "3",
          "values": [
            "Gustina (kg/m3): 10-40",
            "Dimenzije (mm x mm): 1250 x 2800",
            "Debljina (mm): 1000",
            "Broj proizvoda u paketu: 1",
            "Ukupna površina (m2): 3.5",
            "Ukupni volumen (m3): 3.5"
          ]
        },
        {
          "key": "4",
          "values": [
            "Gustina (kg/m3): 10-40",
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 1000",
            "Broj proizvoda u paketu: 1",
            "Ukupna površina (m2): 3.75",
            "Ukupni volumen (m3): 3.75"
          ]
        }
      ]
    },
    {
      "id": 10120,
      "name": "Bijeli EPS blok",
      "images": [
        "/uploads/beyaz-eps-blok.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Gustina (kg/m3): 10-20",
            "Dimenzije (mm x mm): 1250 x 2000",
            "Debljina (mm): 1000",
            "Broj proizvoda u paketu: 1",
            "Ukupna površina (m2): 2.5",
            "Ukupni volumen (m3): 2.5"
          ]
        },
        {
          "key": "2",
          "values": [
            "Gustina (kg/m3): 10-40",
            "Dimenzije (mm x mm): 1250 x 2500",
            "Debljina (mm): 1000",
            "Broj proizvoda u paketu: 1",
            "Ukupna površina (m2): 3.125",
            "Ukupni volumen (m3): 3.125"
          ]
        },
        {
          "key": "3",
          "values": [
            "Gustina (kg/m3): 10-40",
            "Dimenzije (mm x mm): 1250 x 2800",
            "Debljina (mm): 1000",
            "Broj proizvoda u paketu: 1",
            "Ukupna površina (m2): 3.5",
            "Ukupni volumen (m3): 3.5"
          ]
        },
        {
          "key": "4",
          "values": [
            "Gustina (kg/m3): 10-40",
            "Dimenzije (mm x mm): 1250 x 3000",
            "Debljina (mm): 1000",
            "Broj proizvoda u paketu: 1",
            "Ukupna površina (m2): 3.75",
            "Ukupni volumen (m3): 3.75"
          ]
        }
      ]
    },
    {
      "id": 10112,
      "name": "Dekorativni proizvodi",
      "images": [
        "/uploads/dekoratif-urunler-1080x1080.jpg"
      ],
      "details": [],
      "children": [
        10121,
        10122,
        10123,
        10124,
        10125
      ],
      "measurements": []
    },
    {
      "id": 10121,
      "name": "T5 dekorativna ploča",
      "images": [
        "/uploads/t5-dekoratif-eps-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Gustina (kg/m3): 24",
            "Dimenzije (mm x mm): 625 x 625",
            "Debljina (mm): 15",
            "Broj proizvoda u paketu: 41",
            "Ukupna površina (m2): 16.031",
            "Ukupni volumen (m3): 0.246"
          ]
        },
        {
          "key": "2",
          "values": [
            "Gustina (kg/m3): 24",
            "Dimenzije (mm x mm): 500 x 500",
            "Debljina (mm): 10",
            "Broj proizvoda u paketu: 31",
            "Ukupna površina (m2): 7.75",
            "Ukupni volumen (m3): 0.093"
          ]
        }
      ]
    },
    {
      "id": 10122,
      "name": "T4 dekorativna ploča",
      "images": [
        "/uploads/t4-dekoratif-eps-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Gustina (kg/m3): 24",
            "Dimenzije (mm x mm): 625 x 625",
            "Debljina (mm): 15",
            "Broj proizvoda u paketu: 36",
            "Ukupna površina (m2): 14.076",
            "Ukupni volumen (m3): 0.216"
          ]
        },
        {
          "key": "2",
          "values": [
            "Gustina (kg/m3): 24",
            "Dimenzije (mm x mm): 500 x 500",
            "Debljina (mm): 10",
            "Broj proizvoda u paketu: 29",
            "Ukupna površina (m2): 7.75",
            "Ukupni volumen (m3): 0.087"
          ]
        }
      ]
    },
    {
      "id": 10123,
      "name": "T3 dekorativna ploča",
      "images": [
        "/uploads/t3-dekoratif-eps-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Gustina (kg/m3): 24",
            "Dimenzije (mm x mm): 625 x 625",
            "Debljina (mm): 15",
            "Broj proizvoda u paketu: 48",
            "Ukupna površina (m2): 18.768",
            "Ukupni volumen (m3): 0.288"
          ]
        },
        {
          "key": "2",
          "values": [
            "Gustina (kg/m3): 24",
            "Dimenzije (mm x mm): 500 x 500",
            "Debljina (mm): 10",
            "Broj proizvoda u paketu: 45",
            "Ukupna površina (m2): 11.25",
            "Ukupni volumen (m3): 0.135"
          ]
        }
      ]
    },
    {
      "id": 10124,
      "name": "T2 dekorativna ploča",
      "images": [
        "/uploads/t2-dekoratif-eps-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Gustina (kg/m3): 24",
            "Dimenzije (mm x mm): 625 x 625",
            "Debljina (mm): 15",
            "Broj proizvoda u paketu: 41",
            "Ukupna površina (m2): 16.031",
            "Ukupni volumen (m3): 0.246"
          ]
        },
        {
          "key": "2",
          "values": [
            "Gustina (kg/m3): 24",
            "Dimenzije (mm x mm): 500 x 500",
            "Debljina (mm): 10",
            "Broj proizvoda u paketu: 35",
            "Ukupna površina (m2): 8.75",
            "Ukupni volumen (m3): 0.105"
          ]
        }
      ]
    },
    {
      "id": 10125,
      "name": "T1 Dekorativna Ploča",
      "images": [
        "/uploads/t1-dekoratif-eps-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Gustina (kg/m3): 24",
            "Dimenzije (mm x mm): 625 x 625",
            "Debljina (mm): 15",
            "Broj proizvoda u paketu: 41",
            "Ukupna površina (m2): 16.031",
            "Ukupni volumen (m3): 0.246"
          ]
        },
        {
          "key": "2",
          "values": [
            "Gustina (kg/m3): 24",
            "Dimenzije (mm x mm): 500 x 500",
            "Debljina (mm): 10",
            "Broj proizvoda u paketu: 31",
            "Ukupna površina (m2): 7.75",
            "Ukupni volumen (m3): 0.093"
          ]
        }
      ]
    },
    {
      "id": 10113,
      "name": "Proizvodi za Krovnu Izolaciju",
      "images": [
        "/uploads/cati-izolasyon-urunleri-1080x1080.jpg"
      ],
      "details": [],
      "children": [
        10126,
        10127
      ],
      "measurements": []
    },
    {
      "id": 10126,
      "name": "Styrofoam Ispod Trapezoidne Ploče",
      "images": [
        "/uploads/trapez-levha-alti-strafor.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Gustina (kg/m3): 10-16",
            "Dimenzije (mm x mm): 860 x 1900",
            "Debljina (mm): 20",
            "Groove Depth (mm): 27",
            "Number of Groove: 4",
            "Broj proizvoda u paketu: 16",
            "Ukupna površina (m2): 26.144",
            "Ukupni volumen (m3): 0.528"
          ]
        },
        {
          "key": "2",
          "values": [
            "Gustina (kg/m3): 10-16",
            "Dimenzije (mm x mm): 860 x 2000",
            "Debljina (mm): 20",
            "Groove Depth (mm): 27",
            "Number of Groove: 4",
            "Broj proizvoda u paketu: 16",
            "Ukupna površina (m2): 27.52",
            "Ukupni volumen (m3): 0.544"
          ]
        }
      ]
    },
    {
      "id": 10127,
      "name": "Styrofoam Ispod Valovitih Ploča",
      "images": [
        "/uploads/onduleli-levha-alti-strafor.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Gustina (kg/m3): 10-16",
            "Dimenzije (mm x mm): 877 x 1900",
            "Debljina (mm): 10",
            "Groove Depth (mm): 35",
            "Number of Groove: 9",
            "Broj proizvoda u paketu: 20",
            "Ukupna površina (m2): 33.32",
            "Ukupni volumen (m3): 0.34"
          ]
        }
      ]
    },
    {
      "id": 10114,
      "name": "Asmolen",
      "images": [
        "/uploads/asmolen-1080x1080.jpg"
      ],
      "details": [],
      "children": [
        10128,
        10129,
        10130
      ],
      "measurements": []
    },
    {
      "id": 10128,
      "name": "Karaelmas EPS Ploča",
      "images": [
        "/uploads/karaelmas-eps-levha.png"
      ],
      "details": [
        "Klasa nepaljivosti: E",
        "Zahvaljujući reflektivnoj osobini ugljika u svom sastavu, pruža 20% bolju izolaciju od normalnih ploča.",
        "Njegova λ vrijednost je 0,033.",
        "Budući da postoje stabilizovane ploče, dimenzionalna stabilnost je stabilna.",
        "Ne sadrži CFC (hlorofluorougljikovodike) i njegove derivate.",
        "Njegova propusnost za vodenu paru je visoka. (μ 20-40)",
        "Toplinska otpornost je visoka.",
        "Ima visoku otpornost na pritisak.",
        "Zgrada ne gubi svoju izolacionu osobinu tokom života zgrade.",
        "To je E klasa protivpožarna."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 10",
            "Broj proizvoda u paketu: 50",
            "Ukupna površina (m2): 25",
            "Ukupni volumen (m3): 0.25"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 20",
            "Broj proizvoda u paketu: 25",
            "Ukupna površina (m2): 12.5",
            "Ukupni volumen (m3): 0.25"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 30",
            "Broj proizvoda u paketu: 16",
            "Ukupna površina (m2): 8",
            "Ukupni volumen (m3): 0.24"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 40",
            "Broj proizvoda u paketu: 12",
            "Ukupna površina (m2): 6",
            "Ukupni volumen (m3): 0.24"
          ]
        },
        {
          "key": "5",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 50",
            "Broj proizvoda u paketu: 10",
            "Ukupna površina (m2): 5",
            "Ukupni volumen (m3): 0.25"
          ]
        },
        {
          "key": "6",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 60",
            "Broj proizvoda u paketu: 8",
            "Ukupna površina (m2): 4",
            "Ukupni volumen (m3): 0.24"
          ]
        },
        {
          "key": "7",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 70",
            "Broj proizvoda u paketu: 7",
            "Ukupna površina (m2): 3.5",
            "Ukupni volumen (m3): 0.245"
          ]
        },
        {
          "key": "8",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 80",
            "Broj proizvoda u paketu: 6",
            "Ukupna površina (m2): 3",
            "Ukupni volumen (m3): 0.24"
          ]
        },
        {
          "key": "9",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 90",
            "Broj proizvoda u paketu: 6",
            "Ukupna površina (m2): 3",
            "Ukupni volumen (m3): 0.27"
          ]
        },
        {
          "key": "10",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 100",
            "Broj proizvoda u paketu: 5",
            "Ukupna površina (m2): 2.5",
            "Ukupni volumen (m3): 0.25"
          ]
        }
      ]
    },
    {
      "id": 10129,
      "name": "Pamukkale Šuplja Blok",
      "images": [
        "/uploads/pamukkale-asmolen.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 200",
            "Broj proizvoda u paketu: 2",
            "Ukupna površina (m2): 0.8",
            "Ukupni volumen (m3): 0.16"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 220",
            "Broj proizvoda u paketu: 2",
            "Ukupna površina (m2): 0.8",
            "Ukupni volumen (m3): 0.176"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 230",
            "Broj proizvoda u paketu: 2",
            "Ukupna površina (m2): 0.8",
            "Ukupni volumen (m3): 0.184"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 250",
            "Broj proizvoda u paketu: 2",
            "Ukupna površina (m2): 0.8",
            "Ukupni volumen (m3): 0.2"
          ]
        },
        {
          "key": "5",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 270",
            "Broj proizvoda u paketu: 2",
            "Ukupna površina (m2): 0.8",
            "Ukupni volumen (m3): 0.216"
          ]
        },
        {
          "key": "6",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 280",
            "Broj proizvoda u paketu: 2",
            "Ukupna površina (m2): 0.8",
            "Ukupni volumen (m3): 0.224"
          ]
        },
        {
          "key": "7",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 300",
            "Broj proizvoda u paketu: 2",
            "Ukupna površina (m2): 0.8",
            "Ukupni volumen (m3): 0.24"
          ]
        },
        {
          "key": "8",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 330",
            "Broj proizvoda u paketu: 2",
            "Ukupna površina (m2): 0.8",
            "Ukupni volumen (m3): 0.264"
          ]
        },
        {
          "key": "9",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 350",
            "Broj proizvoda u paketu: 2",
            "Ukupna površina (m2): 0.8",
            "Ukupni volumen (m3): 0.28"
          ]
        }
      ]
    },
    {
      "id": 10130,
      "name": "HekimPor Šuplja Blok",
      "images": [
        "/uploads/hekimpor-asmolen.png"
      ],
      "details": [
        "Klasa nepaljivosti: E"
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 200",
            "Broj proizvoda u paketu: 2",
            "Ukupna površina (m2): 0.8",
            "Ukupni volumen (m3): 0.16"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 220",
            "Broj proizvoda u paketu: 2",
            "Ukupna površina (m2): 0.8",
            "Ukupni volumen (m3): 0.176"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 230",
            "Broj proizvoda u paketu: 2",
            "Ukupna površina (m2): 0.8",
            "Ukupni volumen (m3): 0.184"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 250",
            "Broj proizvoda u paketu: 2",
            "Ukupna površina (m2): 0.8",
            "Ukupni volumen (m3): 0.2"
          ]
        },
        {
          "key": "5",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 270",
            "Broj proizvoda u paketu: 2",
            "Ukupna površina (m2): 0.8",
            "Ukupni volumen (m3): 0.216"
          ]
        },
        {
          "key": "6",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 280",
            "Broj proizvoda u paketu: 2",
            "Ukupna površina (m2): 0.8",
            "Ukupni volumen (m3): 0.224"
          ]
        },
        {
          "key": "7",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 300",
            "Broj proizvoda u paketu: 2",
            "Ukupna površina (m2): 0.8",
            "Ukupni volumen (m3): 0.24"
          ]
        },
        {
          "key": "8",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 330",
            "Broj proizvoda u paketu: 2",
            "Ukupna površina (m2): 0.8",
            "Ukupni volumen (m3): 0.264"
          ]
        },
        {
          "key": "9",
          "values": [
            "Dimenzije (mm x mm): 500 x 1000",
            "Debljina (mm): 350",
            "Broj proizvoda u paketu: 2",
            "Ukupna površina (m2): 0.8",
            "Ukupni volumen (m3): 0.28"
          ]
        }
      ]
    },
    {
      "id": 10004,
      "name": "Technonicol",
      "images": [
        "/uploads/technonicol.jpg"
      ],
      "details": [],
      "children": [
        10131,
        10132,
        10133
      ],
      "measurements": []
    },
    {
      "id": 10131,
      "name": "Mastici",
      "images": [
        "/uploads/6f06cdd5c223acaafd52706e249ffdcc.jpg"
      ],
      "details": [],
      "children": [
        10134,
        10135
      ],
      "measurements": []
    },
    {
      "id": 10134,
      "name": "Bitumen-polimer SBS-modificirani vodonepropusni materijal",
      "images": [
        "/uploads/TechnoelastMostB_29_10_2021.png"
      ],
      "details": [
        "Bitumen-polimer SBS-modificirani naneseni vodonepropusni materijal povećane pouzdanosti i debljine.",
        "Proizvodi se samo na ojačanoj podlozi, što sprečava skupljanje materijala tokom formiranja i rada vodonepropusnog sloja.",
        "Može se koristiti za jednoslojne i višeslojne vodonepropusne sisteme.",
        "Dizajniran za vodonepropusnost betonskih konstrukcija, temelja i podzemnih delova zgrada.",
        "Materijal omogućava formiranje vodonepropusnog sloja u horizontalnim delovima bez lepljenja na podlogu (slobodno postavljanje).",
        "Ovo je posebno važno za duboke temelje i kada se radi u jesen-zimskom periodu."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10135,
      "name": "Arhitektonska mastika",
      "images": [
        "/uploads/6f06cdd5c223acaafd52706e249ffdcc.jpg"
      ],
      "details": [
        "Primjenjuje se za lepljenje mekih pločica na bazi bitumena i drugih materijala na ciglu, beton, metal, drvo, keramiku i druge površine.",
        "Ima pastoznu konzistenciju, osigurava da se slojevi zapečate jedni za druge.",
        "Materijal je spreman za upotrebu, lako se nanosi.",
        "Njegova potrošnja je prilično ekonomična.",
        "Polimerni bitumenski hladni lepak TECHNONICOL 23 (FIXER)",
        "Odličan je proizvod za kvalitetnu montažu.",
        "Pogodan je za sve vrste pločica."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10132,
      "name": "Samolepljiva traka",
      "images": [
        "/uploads/1b8e3eda6cfb157553b1903fffa38d03.jpg"
      ],
      "details": [],
      "children": [
        10136,
        10137,
        10138
      ],
      "measurements": []
    },
    {
      "id": 10136,
      "name": "NICOBAND UNUTRA",
      "images": [
        "/uploads/a_1.jpg"
      ],
      "details": [
        "NICOBAND traka za zaptivanje je vrlo praktičan način za zaptivanje raznih pukotina i spojeva.",
        "Može se koristiti i za izolaciju spojeva, za popravke krovova i za sisteme popravke krovova.",
        "Poseban UV zaštitni sloj povećava njegovu trajnost na otvorenom, a asortiman boja čini ga pogodnim za svaki krov.",
        "Zaštićen od UV zračenja.",
        "Bitumenski sloj NICOBAND trake za zaptivanje zaštićen je od UV zračenja aluminijumskom oblogom.",
        "Ovo omogućava korišćenje trake na otvorenom.",
        "Lako se koristi i izdržljiv je.",
        "NICOBAND traka za zaptivanje je vrlo jednostavna za korišćenje i njena primena ne zahteva posebne veštine.",
        "Funkcija zaptivanja trake traje tokom celog njenog veka trajanja (10 godina) zahvaljujući posebno formulisanom polimerno-bitumenskom vezivu.",
        "Različite boje.",
        "Traka je dizajnirana za najpopularnije boje krovova (uključujući metalik).",
        "Omogućava izvođenje popravki ili održavanja u tonu glavne površine bez narušavanja njenog oblika.",
        "Besprekorno prianjanje na mnoge različite površine.",
        "Samolepljivi sloj obezbeđuje savršeno kvalitetno prianjanje na mnoge različite površine: metal, škriljac, drvo, plastika, beton, staklo, itd.",
        "Površina mora biti ravna, suva i čista.",
        "Isecite traku na potrebnu dužinu, uklonite zaštitnu foliju, zalepite traku na željeno mesto i čvrsto pritisnite.",
        "Ako je temperatura ispod +5°C, potrebno je prvo držati traku na sobnoj temperaturi najmanje 12 sati pre primene.",
        "Korišćenje materijala na temperaturama ispod nule zahteva dodatno zagrevanje površine.",
        "Korišćenje trake za zaptivanje se ne preporučuje na vrućim površinama sa nagibom ≥ 55⁰ ili na površinama sa temperaturom iznad +80°C."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Širina (cm): 5",
            "Dužina 3m: +",
            "Dužina 10m: -"
          ]
        },
        {
          "key": "2",
          "values": [
            "Širina (cm): 7.5",
            "Dužina 3m: +",
            "Dužina 10m: +"
          ]
        },
        {
          "key": "3",
          "values": [
            "Širina (cm): 10",
            "Dužina 3m: +",
            "Dužina 10m: +"
          ]
        },
        {
          "key": "4",
          "values": [
            "Širina (cm): 15",
            "Dužina 3m: +",
            "Dužina 10m: +"
          ]
        },
        {
          "key": "5",
          "values": [
            "Širina (cm): 20",
            "Dužina 3m: -",
            "Dužina 10m: +"
          ]
        },
        {
          "key": "6",
          "values": [
            "Širina (cm): 30",
            "Dužina 3m: -",
            "Dužina 10m: +"
          ]
        }
      ]
    },
    {
      "id": 10137,
      "name": "NICOBAND DUO",
      "images": [
        "/uploads/c2c7139d4f0268563b0f62b7df8511ab.jpg"
      ],
      "details": [
        "NICOBAND traka za zaptivanje je vrlo praktičan način za zaptivanje raznih pukotina i spojeva.",
        "Može se koristiti i za izolaciju spojeva, za popravke krovova i za sisteme popravke krovova.",
        "Poseban UV zaštitni sloj povećava njegovu trajnost na otvorenom, a asortiman boja čini ga pogodnim za svaki krov.",
        "Zaštićen od UV zračenja.",
        "Bitumenski sloj NICOBAND trake za zaptivanje zaštićen je od UV zračenja aluminijumskom oblogom.",
        "Ovo omogućava korišćenje trake na otvorenom.",
        "Lako se koristi i izdržljiv je.",
        "NICOBAND traka za zaptivanje je vrlo jednostavna za korišćenje i njena primena ne zahteva posebne veštine.",
        "Funkcija zaptivanja trake traje tokom celog njenog veka trajanja (10 godina) zahvaljujući posebno formulisanom polimerno-bitumenskom vezivu.",
        "Različite boje.",
        "Traka je dizajnirana za najpopularnije boje krovova (uključujući metalik).",
        "Omogućava izvođenje popravki ili održavanja u tonu glavne površine bez narušavanja njenog oblika.",
        "Besprekorno prianjanje na mnoge različite površine.",
        "Samolepljivi sloj obezbeđuje savršeno kvalitetno prianjanje na mnoge različite površine: metal, škriljac, drvo, plastika, beton, staklo, itd.",
        "Površina mora biti ravna, suva i čista.",
        "Isecite traku na potrebnu dužinu, uklonite zaštitnu foliju sa prve strane, zalepite traku na željeno mesto i čvrsto pritisnite.",
        "Uklonite zaštitnu foliju sa druge strane trake, pritisnite površinu pričvršćenog elementa na traku.",
        "Ako je temperatura ispod +5°C, potrebno je prvo držati traku na sobnoj temperaturi najmanje 12 sati pre primene.",
        "Korišćenje materijala na temperaturama ispod nule zahteva dodatno zagrevanje površine.",
        "Korišćenje trake za zaptivanje se ne preporučuje na vrućim površinama sa nagibom ≥ 55⁰ ili na površinama sa temperaturom iznad +80°C."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Širina (cm): 5",
            "Dužina 3m: +",
            "Dužina 10m: -"
          ]
        },
        {
          "key": "2",
          "values": [
            "Širina (cm): 7.5",
            "Dužina 3m: +",
            "Dužina 10m: +"
          ]
        },
        {
          "key": "3",
          "values": [
            "Širina (cm): 10",
            "Dužina 3m: +",
            "Dužina 10m: +"
          ]
        },
        {
          "key": "4",
          "values": [
            "Širina (cm): 15",
            "Dužina 3m: +",
            "Dužina 10m: +"
          ]
        },
        {
          "key": "5",
          "values": [
            "Širina (cm): 20",
            "Dužina 3m: -",
            "Dužina 10m: +"
          ]
        },
        {
          "key": "6",
          "values": [
            "Širina (cm): 30",
            "Dužina 3m: -",
            "Dužina 10m: +"
          ]
        }
      ]
    },
    {
      "id": 10138,
      "name": "NICOBAND",
      "images": [
        "/uploads/1b8e3eda6cfb157553b1903fffa38d03.jpg"
      ],
      "details": [
        "NICOBAND traka za zaptivanje je vrlo praktičan način za zaptivanje raznih pukotina i spojeva.",
        "Može se koristiti i za izolaciju spojeva, za popravke krovova i za sisteme popravke krovova.",
        "Poseban UV zaštitni sloj povećava njegovu trajnost na otvorenom, a asortiman boja čini ga pogodnim za svaki krov.",
        "Zaštićen od UV zračenja.",
        "Bitumenski sloj NICOBAND trake za zaptivanje zaštićen je od UV zračenja aluminijumskom oblogom.",
        "Ovo omogućava korišćenje trake na otvorenom.",
        "Lako se koristi i izdržljiv je.",
        "NICOBAND traka za zaptivanje je vrlo jednostavna za korišćenje i njena primena ne zahteva posebne veštine.",
        "Funkcija zaptivanja trake traje tokom celog njenog veka trajanja (10 godina) zahvaljujući posebno formulisanom polimerno-bitumenskom vezivu.",
        "Različite boje.",
        "Traka je dizajnirana za najpopularnije boje krovova (uključujući metalik).",
        "Omogućava izvođenje popravki ili održavanja u tonu glavne površine bez narušavanja njenog oblika.",
        "Površina mora biti ravna, suva i čista.",
        "Isecite traku na potrebnu dužinu, uklonite zaštitnu foliju, zalepite traku na željeno mesto i čvrsto pritisnite.",
        "Ako je temperatura ispod +5°C, potrebno je prvo držati traku na sobnoj temperaturi najmanje 12 sati pre primene.",
        "Korišćenje materijala na temperaturama ispod nule zahteva dodatno zagrevanje površine.",
        "Korišćenje trake za zaptivanje se ne preporučuje na vrućim površinama sa nagibom ≥ 55⁰ ili na površinama sa temperaturom iznad +80°C."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Širina (cm): 5",
            "Dužina 3m: +",
            "Dužina 10m: -"
          ]
        },
        {
          "key": "2",
          "values": [
            "Širina (cm): 7.5",
            "Dužina 3m: +",
            "Dužina 10m: +"
          ]
        },
        {
          "key": "3",
          "values": [
            "Širina (cm): 10",
            "Dužina 3m: +",
            "Dužina 10m: +"
          ]
        },
        {
          "key": "4",
          "values": [
            "Širina (cm): 15",
            "Dužina 3m: +",
            "Dužina 10m: +"
          ]
        },
        {
          "key": "5",
          "values": [
            "Širina (cm): 20",
            "Dužina 3m: -",
            "Dužina 10m: +"
          ]
        },
        {
          "key": "6",
          "values": [
            "Širina (cm): 30",
            "Dužina 3m: -",
            "Dužina 10m: +"
          ]
        }
      ]
    },
    {
      "id": 10133,
      "name": "Bitumenska vodonepropusna obloga",
      "images": [
        "/uploads/406acfcfc6732073a27ed0c305b09650.jpg"
      ],
      "details": [],
      "children": [
        10139,
        10140,
        10141,
        10142
      ],
      "measurements": []
    },
    {
      "id": 10139,
      "name": "MIDA TROPİK P 4000",
      "images": [
        "/uploads/e9fc26b5ca9160190fbedbe32a298baa_1.jpg"
      ],
      "details": [
        "MIDA Tropik P 4000 je materijal na bazi bitumena koji se koristi kao membrana prvog sloja, dobijen korišćenjem polietilenske folije kao gornjih i donjih zaštitnih slojeva na bitumenskom materijalu sa nosacem od poliesterske filca.",
        "Materijal se koristi kao prvi sloj u višeslojnim novim krovnim sistemima tokom radova na vodonepropusnosti ili u renoviranju starih krovnih sistema.",
        "Materijal se topi i nanosi plamenom.",
        "OSOBINE MIDA TROPIC P 4000",
        "Otpornost na temperaturu, °C +130",
        "Hladno savijanje, °C -5",
        "Nosilac poliesterska filca",
        "Otpornost na istezanje, N/50 mm uzdužno / poprečno 600 / 400 ±100",
        "Produžetak, 30% / 30 ±10%",
        "Debljina, mm 4.0 ±0.1",
        "Dužina / Širina, m 10 / 1"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10140,
      "name": "MIDA TROPİK P 3000",
      "images": [
        "/uploads/e9fc26b5ca9160190fbedbe32a298baa.jpg"
      ],
      "details": [
        "MIDA Tropik P 3000 je materijal na bazi bitumena koji se koristi kao membrana prvog sloja, dobijen korišćenjem polietilenske folije kao gornjih i donjih zaštitnih slojeva na bitumenskom materijalu sa nosacem od poliesterske filca.",
        "Materijal se koristi kao prvi sloj u višeslojnim novim krovnim sistemima tokom radova na vodonepropusnosti ili u renoviranju starih krovnih sistema.",
        "Materijal se topi i nanosi plamenom.",
        "OSOBINE MIDA TROPIC P 3000",
        "Otpornost na temperaturu, °C +130",
        "Hladno savijanje, °C -5",
        "Nosilac poliesterska filca",
        "Otpornost na istezanje, N/50 mm uzdužno / poprečno 600 / 400 ±100",
        "Produžetak, 30% / 30 ±10%",
        "Debljina, mm 3.0 ±0.1",
        "Dužina / Širina, m 10 / 1"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10141,
      "name": "MIDA TROPİK P40M",
      "images": [
        "/uploads/82df288bee54dc93a18d355c05b99dd0.jpg"
      ],
      "details": [
        "MIDA Tropik P40M je materijal na bazi bitumena koji se koristi kao membrana gornjeg sloja, dobijen korišćenjem mineralnog kamena kao gornjeg zaštitnog sloja i polietilenske folije kao donjeg zaštitnog sloja, na bitumenskom materijalu sa nosacem od poliesterske filca.",
        "Materijal se koristi kao gornji sloj u višeslojnim novim krovnim sistemima ili u renoviranju starih krovnih sistema.",
        "Materijal se topi i nanosi plamenom.",
        "OSOBINE MIDA TROPIC P40M",
        "Otpornost na temperaturu, °C +130",
        "Hladno savijanje, °C -5",
        "Nosilac poliesterska filca",
        "Otpornost na istezanje, N/50 mm uzdužno / poprečno 600 / 400 ±100",
        "Produžetak, 30% / 30 ±10%",
        "Debljina, mm 3.3 ±0.1",
        "Dužina / Širina, m 10 / 1"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10142,
      "name": "MIDA TROPIK C 2000",
      "images": [
        "/uploads/406acfcfc6732073a27ed0c305b09650.jpg"
      ],
      "details": [
        "MIDA Tropik C 2000 je materijal koji se koristi kao prva slojna membrana, koja se dobija korišćenjem polietilenske folije kao gornjih i donjih zaštitnih slojeva na bitumenskom materijalu sa staklenim vlaknima.",
        "Materijal se koristi kao prva sloj u višeslojnim novim krovnim sistemima tokom radova na hidroizolaciji ili u renoviranju starih krovnih sistema.",
        "Materijal se topi plamenom ili se primenjuje mehanički.",
        "OSOBINE MIDA TROPIK C 2000",
        "Otpornost na temperaturu, °C +130",
        "Hladno savijanje, °C -5",
        "nosilac stakleni veo",
        "Otpornost na istezanje, N/50 mm uzdužno / poprečno 400 / 300 ±100",
        "Produženje, % 4 / 4 ±2",
        "Debljina, mm 2.0 ±0.1",
        "Dužina / Širina, m 15 / 1"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10005,
      "name": "Shinglas",
      "images": [
        "/uploads/shinglassss.jpg"
      ],
      "details": [],
      "children": [
        10143
      ],
      "measurements": []
    },
    {
      "id": 10143,
      "name": "Shingle",
      "images": [
        "/uploads/Shinglass.jpg"
      ],
      "details": [],
      "children": [
        10144,
        10145,
        10146,
        10147,
        10148,
        10149,
        10150,
        10151,
        10152
      ],
      "measurements": []
    },
    {
      "id": 10144,
      "name": "STANDARD ROCK SERIES",
      "images": [
        "/uploads/standart-rock.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10145,
      "name": "CLASSIC SERIES",
      "images": [
        "/uploads/classic.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10146,
      "name": "ULTRA SERIES",
      "images": [
        "/uploads/ultra.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10147,
      "name": "ATLANTICA COLLECTION",
      "images": [
        "/uploads/atlantico.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10148,
      "name": "RANCHO COLLECTION",
      "images": [
        "/uploads/rancho.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10149,
      "name": "COUNTRY COLLECTION",
      "images": [
        "/uploads/country.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10150,
      "name": "JAZZ COLLECTION",
      "images": [
        "/uploads/jazz.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10151,
      "name": "ZAPADNA KOLEKCIJA",
      "images": [
        "/uploads/a827b9d72325cb6d393d780d846ab17e.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10152,
      "name": "KOLEKCIJA KONTINENTA",
      "images": [
        "/uploads/Shinglass.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10006,
      "name": "Kronospan",
      "images": [
        "/uploads/kronospan.jpg"
      ],
      "details": [],
      "children": [
        10153
      ],
      "measurements": []
    },
    {
      "id": 10153,
      "name": "OSB",
      "images": [
        "/uploads/kronospan-osb3_800x800_crop_478b24840a-1.jpg"
      ],
      "details": [],
      "children": [
        10154,
        10155,
        10156
      ],
      "measurements": []
    },
    {
      "id": 10154,
      "name": "OSB 4",
      "images": [
        "/uploads/kronospan-osb3_800x800_crop_478b24840a-1.jpg"
      ],
      "details": [
        "OSB 4 ploče imaju veću nosivost i krutost od OSB 3 ploča.",
        "OSB 4 je razvijen i proizveden u potpunosti u skladu sa trenutnim zahtjevima ekološkog stanovanja.",
        "Odabirom odgovarajuće drvne građe i veziva, OSB 4 ispunjava visoke zahtjeve ne samo ekoloških zgrada.",
        "Standard EN 300 - tip OSB 4; EN 13501-1: klasa D-s1, d0; EN 13986:2004+A1:2015",
        "Klasa emisije E1 (EN ISO 12460-5)",
        "Karakteristike - Povećana otpornost na vlažne uvjete",
        "Visoka nosivost i krutost za građevinske svrhe",
        "Visoka dimenzionalna tačnost i stabilnost oblika",
        "Visoka fleksibilnost i otpornost na udarce",
        "Jednostavna obrada sa uobičajenim alatima za obradu drveta",
        "Ekološki prihvatljiv, reciklabilan proizvod",
        "Primjene - Strukturne primjene: zidovi, podovi, krovovi, stepenice, ograde",
        "Unutrašnjost: elementi namještaja, dekorativni elementi u unutrašnjim prostorima, okvir u tapeciranom namještaju, vrata, obloge i pregrade",
        "Pakiranje: kutije i kontejneri za otpremu, palete",
        "Maloprodaja i izložbe",
        "Upravljanje skladištem (police, regali, itd.)",
        "Ekspresni program - Kronobuild",
        "Format 2500x1250",
        "Debljina 12 15 18 22 25",
        "OSB 4"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10155,
      "name": "OSB 3",
      "images": [
        "/uploads/kronospan-osb3_800x800_crop_478b24840a.jpg"
      ],
      "details": [
        "OSB 3 je najnapredniji tip OSB-a koji je trenutno dostupan.",
        "OSB 3 je razvijen i proizveden u potpunosti u skladu sa trenutnim zahtjevima ekološkog stanovanja fokusiranog na organske materijale.",
        "Odabirom odgovarajuće drvne građe i veziva, OSB 3 ispunjava visoke standarde ne samo ekoloških zgrada.",
        "Standard EN 300 - tip OSB 3; EN 13501-1: klasa D-s1, d0; EN 13986:2004+A1:2015",
        "Klasa emisije E1 (EN ISO 12460-5)",
        "Karakteristike - Povećana otpornost na vlažne uvjete",
        "Visoka nosivost i krutost za građevinske svrhe",
        "Visoka dimenzionalna tačnost i stabilnost oblika",
        "Visoka fleksibilnost i otpornost na udarce",
        "Jednostavna obrada sa uobičajenim alatima za obradu drveta",
        "Ekološki prihvatljiv, reciklabilan proizvod",
        "Primjene - Strukturne primjene: zidovi, podovi, krovovi, stepenice, ograde",
        "Unutrašnjost: elementi namještaja, dekorativni elementi u unutrašnjim prostorima, okvir u tapeciranom namještaju, vrata, obloge i pregrade",
        "Pakiranje: kutije i kontejneri za otpremu, palete",
        "Maloprodaja i izložbe",
        "Upravljanje skladištem (police, regali, itd.)",
        "Ekspresni program - Kronobuild",
        "Format 2500x1250 2440x1220",
        "Debljina 9 10 12 15 18 22 25 9 10 11 12 15 18 22 25",
        "OSB 3"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10156,
      "name": "OSB 2",
      "images": [
        "/uploads/kronospan-osb2_800x800_crop_478b24840a.jpg"
      ],
      "details": [
        "OSB 2 je razvijen i proizveden u potpunosti u skladu sa trenutnim zahtjevima ekološkog stanovanja fokusiranog na organske materijale.",
        "Odabirom odgovarajuće drvne građe i veziva, OSB 2 ispunjava visoke standarde ne samo ekoloških zgrada.",
        "Standard EN 300 - tip OSB 2; EN 13501-1: klasa D-s1, d0; EN 13986:2004+A1:2015",
        "Klasa emisije E1 (EN ISO 12460-5)",
        "Karakteristike - Visoka nosivost i krutost za građevinske svrhe",
        "Visoka dimenzionalna tačnost i stabilnost oblika",
        "Visoka fleksibilnost i otpornost na udarce",
        "Jednostavna obrada sa uobičajenim alatima za obradu drveta",
        "Ekološki prihvatljiv, reciklabilan proizvod",
        "Primjene - Strukturne primjene: zidovi, podovi, krovovi, stepenice, ograde",
        "Unutrašnjost: elementi namještaja, dekorativni elementi u unutrašnjim prostorima, okvir u tapeciranom namještaju, vrata, obloge i pregrade",
        "Pakiranje: kutije i kontejneri za otpremu, palete",
        "Maloprodaja i izložbe",
        "Upravljanje skladištem (police, regali, itd.)",
        "Ekspresni program - Kronobuild",
        "Format 2500x1250 2440x1220",
        "Debljina 9 10 11 12 15 18 22 25 9 10 11 12 15 18 22 25",
        "OSB 2"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10007,
      "name": "Terrawool",
      "images": [
        "/uploads/terrawoll.jpg"
      ],
      "details": [],
      "children": [],
      "links": [
        "/links/terrawool.pdf"
      ],
      "measurements": []
    },
    {
      "id": 10008,
      "name": "Jotun boja",
      "images": [
        "/uploads/jotun.jpg"
      ],
      "details": [],
      "children": [
        10157,
        10158
      ],
      "measurements": []
    },
    {
      "id": 10157,
      "name": "Eksterijerni proizvodi",
      "images": [
        "/uploads/1.jpg"
      ],
      "details": [],
      "children": [
        10159,
        10160,
        10161,
        10162,
        10163,
        10164,
        10165,
        10166,
        10167,
        10168,
        10169,
        10170
      ],
      "measurements": []
    },
    {
      "id": 10159,
      "name": "Jotashield Tex Ultra",
      "images": [
        "/uploads/13.jpg"
      ],
      "details": [
        "Jotashield Tex Ultra je srednje teksturirana, fleksibilna, polusjajna, vodena boja za eksterijer sa 100% čistim akrilnim smolama.",
        "Jotashield Tex Ultra ima svojstvo premošćavanja pukotina protiv kapilarnih pukotina koje nastaju usled sleganja strukture tokom vremena.",
        "Štiti estetski izgled vaše zgrade godinama zahvaljujući svom niskom svojstvu zadržavanja prljavštine.",
        "Ima difuziju ugljen-dioksida i hloridnih jona, propusnost za vodenu paru, propusnost za vodu i nepropusnost za vodu.",
        "Zahvaljujući svojoj otpornosti na difuziju hloridnih jona, sprečava probleme sa bojom uzrokovane soli ili vlagom, posebno kada se koristi blizu mora ili u zgradama izloženim intenzivnoj vlažnosti.",
        "Eksterijer boja sa uzorkom narandžine kore",
        "100% čisti akril",
        "Svojstvo premošćavanja pukotina",
        "Difuzija ugljen-dioksida",
        "Vodootporno svojstvo",
        "Izvrsna zaštita betona",
        "Propusnost za vodenu paru"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10160,
      "name": "Jotashield Surface Strengthening Primer (Water Based)",
      "images": [
        "/uploads/12.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10161,
      "name": "Jotashield Surface Strengthening Primer (Solvent Based)",
      "images": [
        "/uploads/11aa.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10162,
      "name": "Jotashield Heritage Glaze",
      "images": [
        "/uploads/10.jpg"
      ],
      "details": [
        "Jotashield Heritage Glaze je 100% čista akrilna vodena boja za eksterijer koja nudi dekorativni izgled.",
        "Jotashield Heritage Glaze je boja efekta premium kvaliteta na bazi vode.",
        "Ima 100% čisti akrilni veziv.",
        "Antikni i stari efekti mogu se napraviti sa Jotashield Heritage Glaze, nudeći različite uzorke za površine gde je željena dekorativna završna obrada.",
        "Stvara drveni izgled",
        "Nudi antikni izgled",
        "100% čisti akril",
        "Visoka UV otpornost i nisko zadržavanje prljavštine",
        "Izvrsna zaštita betona",
        "Izvrsna zaštita od teških klimatskih uslova"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10163,
      "name": "Jotashield Real Matt",
      "images": [
        "/uploads/9.jpg"
      ],
      "details": [
        "Jotashield Real Matt je boja za eksterijer sa glatkom mat završnom obradom i jedinstvenim svojstvima niskog zadržavanja prljavštine koja nudi 15 godina zaštite od teških klimatskih uslova.",
        "Mat i glatka završna obrada boje za eksterijer",
        "Izuzetno nisko zadržavanje prljavštine",
        "Izvrsno iskustvo boje",
        "Izvrsna zaštita do 15 godina",
        "Prednost u troškovima održavanja",
        "Visoka pokrivenost",
        "Visoka UV otpornost",
        "Prednost u potrošnji"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10164,
      "name": "Jotashield SuperDurable",
      "images": [
        "/uploads/8.jpg"
      ],
      "details": [
        "Jotashield SuperDurable je 100% čista silikonski modifikovana akrilna, vodena boja za eksterijer koja nudi do 20 godina zaštite od teških klimatskih uslova.",
        "Jotashield SuperDurable je posebno razvijen da izdrži najteže klimatske uslove.",
        "Stvara dugotrajnu i izdržljivu svilen mat površinu sa niskim svojstvom zadržavanja prljavštine svog posebnog akrilnog veziva.",
        "Pružajući ekološki prihvatljiva rešenja, pruža dugotrajnu zaštitu u poređenju sa bojama na bazi rastvarača i mnogim dekorativnim sistemima boja.",
        "Pruža estetski izgled dok štiti zgradu od spoljašnjih uslova tokom 20 godina.",
        "Sa ovom karakteristikom, uzimajući u obzir troškovnu krivu života, pruža dugorošna rešenja nudeći prednosti u troškovima održavanja."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10165,
      "name": "Jotashield Matt",
      "images": [
        "/uploads/6.jpg"
      ],
      "details": [
        "Jotashield Matt je boja za eksterijer visokih performansi na bazi 100% čiste akrilne smole, koja nudi glatku i mat završnu obradu.",
        "Mat i glatka završna obrada boje za eksterijer",
        "100% čisti akril",
        "Visoka UV otpornost i nisko zadržavanje prljavštine",
        "Izvrsna zaštita betona",
        "Izvrsna zaštita od teških klimatskih uslova"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10166,
      "name": "Jotashield Silk",
      "images": [
        "/uploads/5.jpg"
      ],
      "details": [
        "Jotashield Silk je boja za eksterijer visokih performansi na bazi 100% čiste akrilne smole, koja nudi glatku i svilen mat završnu obradu.",
        "Boja za eksterijer sa glatkom završnom obradom",
        "100% čisti akril",
        "Visoka UV otpornost i nisko zadržavanje prljavštine",
        "Izvrsna zaštita betona",
        "Izvrsna zaštita od teških klimatskih uslova",
        "576 alternativnih boja"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10167,
      "name": "Jotashield Tex Fine",
      "images": [
        "/uploads/4.jpg"
      ],
      "details": [
        "Jotashield Tex Fine je svilen mat, vodena, fleksibilna, 100% čista akrilna smola boja za eksterijer koja daje laganu teksturiranu i dekorativnu pojavu sa agregatnim česticama koje sadrži.",
        "Jotashield Tex Fine nudi svilen mat izgled sa svojom finom teksturom.",
        "Ima svojstvo premošćavanja pukotina protiv kapilarnih pukotina koje nastaju usled sleganja strukture tokom vremena.",
        "Ima visoku otpornost na vodu i teške vremenske uslove.",
        "Kada se koral primeni valjkom, skriva manje nedostatke i stvara glatku teksturu.",
        "Fina tekstura boje za eksterijer",
        "100% čisti akril",
        "Svojstvo premošćavanja pukotina",
        "Difuzija ugljen-dioksida",
        "Vodootporno svojstvo",
        "Izvrsna zaštita betona",
        "Propusnost za vodenu paru"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10168,
      "name": "Jotashield Tex Ultra",
      "images": [
        "/uploads/3.jpg"
      ],
      "details": [
        "Jotashield Tex Ultra je srednje teksturirana, fleksibilna, polusjajna, vodena boja za eksterijer sa 100% čistim akrilnim smolama.",
        "Jotashield Tex Ultra ima svojstvo premošćavanja pukotina protiv kapilarnih pukotina koje nastaju usled sleganja strukture tokom vremena.",
        "Štiti estetski izgled vaše zgrade godinama zahvaljujući svom niskom svojstvu zadržavanja prljavštine.",
        "Ima difuziju ugljen-dioksida i hloridnih jona, propusnost za vodenu paru, propusnost za vodu i nepropusnost za vodu.",
        "Zahvaljujući svojoj otpornosti na difuziju hloridnih jona, sprečava probleme sa bojom uzrokovane soli ili vlagom, posebno kada se koristi blizu mora ili u zgradama izloženim intenzivnoj vlažnosti.",
        "Eksterijer boja sa uzorkom narandžine kore",
        "100% čisti akril",
        "Svojstvo premošćavanja pukotina",
        "Difuzija ugljen-dioksida",
        "Vodootporno svojstvo",
        "Izvrsna zaštita betona",
        "Propusnost za vodenu paru"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10169,
      "name": "Jotashield temeljni premaz za jačanje površine (na bazi vode)",
      "images": [
        "/uploads/2.jpg"
      ],
      "details": [
        "Jotashield temeljni premaz za jačanje površine je temeljni premaz na bazi vode, akrilni kopolimer.",
        "Ima odlična svojstva penetracije.",
        "Jotashield temeljni premaz za jačanje površine savršeno prodire u glatke površine kao što su predgotovljeni beton, izloženi beton.",
        "Zahvaljujući svojoj bazi vode, nudi ekološki prihvatljiva rešenja.",
        "Temeljni premaz na bazi vode",
        "Akrilni kopolimer",
        "Odlična penetracija površine"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10170,
      "name": "Jotashield temeljni premaz za jačanje površine (na bazi rastvarača)",
      "images": [
        "/uploads/a1.jpg"
      ],
      "details": [
        "Jotashield temeljni premaz za jačanje površine je temeljni premaz na bazi rastvarača, akrilni kopolimer.",
        "Ima odlična svojstva penetracije.",
        "Jotashield temeljni premaz na bazi rastvarača se nanosi za jačanje labavih i prašnjavih površina.",
        "Jača površinu pre nanošenja završnog sloja boje.",
        "Savršeno prodire u glatke površine kao što su predgotovljeni beton i izloženi beton.",
        "Nije preporučljivo koristiti na obloženim površinama zbog rastvarača koji sadrži.",
        "Temeljni premaz na bazi rastvarača",
        "Odlična penetracija površine"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10158,
      "name": "Proizvodi za unutrašnje zidove",
      "images": [
        "/uploads/fenomastic-guzel-evim.jpg"
      ],
      "details": [],
      "children": [
        10171,
        10172,
        10173,
        10174,
        10175,
        10176,
        10177,
        10178,
        10179
      ],
      "measurements": []
    },
    {
      "id": 10171,
      "name": "Fenomastic Mat",
      "images": [
        "/uploads/jotun-fenomastic-mat-kirik-beyaz-15-lt-4ba1-9.jpg"
      ],
      "details": [
        "Fenomastic Mat je lako primenljiva, bez prskanja unutrašnja boja za zidove sa odličnom teksturom.",
        "Fenomastic serija je posebno dizajnirana za opštu primenu boje.",
        "Tokom razvoja naše serije, uključili smo četiri glavne karakteristike, uzimajući u obzir očekivanja i potrebe naših majstora.",
        "Fenomastic Mat je lako primenljiva, bez prskanja unutrašnja boja za zidove sa odličnom teksturom.",
        "Pruža glatku mat završnicu.",
        "Lako se nanosi i dobro se širi; Ima visoka svojstva pokrivanja i dotjerivanja.",
        "Ne prska; Ne ostavlja tragove četke ili valjka.",
        "Otpornost na prskanje: To je među našim prioritetima da naši majstori boje u boljim uslovima. U tu svrhu, dizajnirali smo naše proizvode kao 'bez prskanja'.",
        "Laka primena: Dok su naši proizvodi laki za primenu, takođe omogućavaju našem majstoru da završi posao na vreme bez problema, zahvaljujući visokom pokrivanju. Ne ostavlja tragove četke i valjka, dobro se širi na površini i ima mogućnost dotjerivanja.",
        "Bez mirisa: Želimo da naši majstori rade u zdravijem okruženju umesto da primenjuju pod intenzivnim mirisom boje. U tu svrhu, dizajnirali smo naše proizvode kao 'bez mirisa'.",
        "Savršena tekstura: Svaka primena boje je referenca za naše majstore. Nudimo odličnu teksturu sa našim proizvodima koji nude glatku završnicu."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10172,
      "name": "Fenomastic Kit",
      "images": [
        "/uploads/macun.jpg"
      ],
      "details": [
        "Fenomastic Kit je lako primenljiva, akrilna unutrašnja masa na bazi vode koja ne zahteva primenu temeljnog premaza, nudi savršenu završnicu sa svojom svojstvom punjenja.",
        "Fenomastic Kit je lako primenljiva, akrilna unutrašnja masa na bazi vode koja ne zahteva primenu temeljnog premaza, nudi savršenu završnicu sa svojom svojstvom punjenja.",
        "Nudeći prednosti u vremenu i troškovima, osigurava da se primena boje završi brzo i da se formira glatka i lepa tekstura.",
        "Fenomastic Kit je lako primenljiva, akrilna unutrašnja masa na bazi vode koja ne zahteva primenu temeljnog premaza, nudi savršenu završnicu sa svojom svojstvom punjenja.",
        "Nudi prednost u vremenu i troškovima jer ne zahteva primenu temeljnog premaza.",
        "Stvara glatku i lepu površinu za primenu boje.",
        "Temeljni premaz i masa u jednom: Fenomastic Kit ima '2 u 1' karakteristiku; deluje kao masa i temeljni premaz. Ne zahteva primenu temeljnog premaza.",
        "Ušteda vremena: Fenomastic Kit, sa svim svojim karakteristikama, štedi i vreme i troškove materijala i rada. Omogućava da se primena završi u kraćem vremenu.",
        "Savršena završnica: Stvara glatku površinu sa svojstvom punjenja, tako da se savršena završnica dobija kada se nanese poslednji sloj boje na masu.",
        "Laka primena: Lako se primenjuje, ne umara majstora i dobro se širi na površini."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10173,
      "name": "Fenomastic Strop",
      "images": [
        "/uploads/taavan.jpg"
      ],
      "details": [
        "Nudeći prednosti u vremenu i troškovima, osigurava da se primena boje završi brzo i da se formira glatka i lepa tekstura.",
        "Fenomastic serija je posebno dizajnirana za opštu primenu boje.",
        "Tokom razvoja naše serije, uključili smo četiri glavne karakteristike, uzimajući u obzir očekivanja i potrebe naših majstora.",
        "Fenomastic Strop je lako primenljiva, bez prskanja i bez mirisa boja za strop sa odličnom belinom.",
        "Otpornost na prskanje: To je među našim prioritetima da naši majstori boje u boljim uslovima. U tu svrhu, dizajnirali smo Fenomastic Strop kao 'bez prskanja'.",
        "Laka primena: Lako se primenjuje i dobro se širi; Ne ostavlja tragove četke ili valjka. Zahvaljujući visokom pokrivanju i mogućnosti dotjerivanja, možete brzo završiti primenu stropa.",
        "Bez mirisa: Bez mirisa omogućava primenu stropa u udobnijim uslovima.",
        "Snežno bela: Zahvaljujući svojoj superiornoj belini, nudi bele stropove i čuva belinu dugi niz godina."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10174,
      "name": "Fenomastic Temeljni premaz",
      "images": [
        "/uploads/fenomastikprimer.jpg"
      ],
      "details": [
        "Fenomastic emulzijski temeljni premaz je temeljni premaz na bazi vode visoke kvalitete, posebno razvijen akrilni resin, koji pruža visoku otpornost na alkali.",
        "Fenomastic temeljni premaz je temeljni premaz na bazi vode visoke kvalitete, posebno razvijen akrilni resin.",
        "Zahvaljujući niskoj vrednosti isparljivih organskih jedinjenja (VOC), ne smanjuje kvalitet vazduha u unutrašnjosti.",
        "Fenomastic temeljni premaz je posebno formulisan da bolje prodre u površine na koje se nanosi.",
        "Unutrašnje oblaganje",
        "Niska VOC stopa",
        "Odlična svojstva penetracije"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10175,
      "name": "Fenomastic Güzel Evim Mineral Transparentni Zaštitnik",
      "images": [
        "/uploads/asa.jpg"
      ],
      "details": [
        "Fenomastic Güzel Evim Mineral Transparentni Zaštitnik je proizvod koji nudi transparentno-mat završnicu.",
        "Fenomastic Güzel Evim Mineral Transparentni konzervans treba primeniti najmanje 1 dan nakon primene Fenomastic Güzel Evim Mineral.",
        "Uz pomoć valjka, možete ravnomerno rasporediti po celoj površini i postići najbolji rezultat, treba ga primeniti u jednom sloju.",
        "Transparentno-mat izgled",
        "Otpornost na vodu",
        "Dugotrajna zaštita"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10176,
      "name": "Fenomastic My Home Mineral",
      "images": [
        "/uploads/jotun-fenomastic-guzel-evim-zengin-mineral--4147-.jpg"
      ],
      "details": [
        "Fenomastic Güzel Evim Mineral stvara površine koje su u savršenoj harmoniji sa svim dodacima uz bezvremensku lepotu jednostavnih tekstura.",
        "Nudi prirodan, mekan i mat izgled sa jednostavnim potezima četke.",
        "Fenomastic Güzel Evim Mineral se nanosi jednostavnim potezima četke; nudi prirodan, mat i mekan izgled.",
        "Stvara jedinstvene igre boja na zidovima sa različitim alternativama boja, kao i sivo tonovima koji pružaju izgled grubog betona.",
        "Prirodno teksturisan sirov i mat izgled",
        "Jedinstvena igra boja",
        "Laka primena"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10177,
      "name": "Fenomastic My Beautiful Home Pure Silk",
      "images": [
        "/uploads/110000003397311.jpg"
      ],
      "details": [
        "Fenomastic Güzel Evim Pure Silk daje vašim zidovima glatki i mekani izgled.",
        "Stvara prirodnu teksturu umesto plastičnog izgleda kao druge svile mat boje.",
        "Pruža dugotrajni estetski izgled.",
        "Fenomastic Güzel Evim Saf İpek nudi savršeno iskustvo boje sa svojom visokom kvalitetom svile mat završnice i prirodnom teksturom.",
        "Stvara prirodnu teksturu umesto plastičnog izgleda kao druge svile mat boje, tako da vaši zidovi izgledaju glatko i mekano.",
        "Ima ekološki prihvatljivu formulu.",
        "Pruža dugotrajni estetski izgled sa svojom mogućnošću brisanja.",
        "Na bazi vode i bez mirisa, tako da možete slobodno obojiti svoj dom u bilo koje doba godine.",
        "Svila mat unutrašnja boja",
        "Odlično iskustvo boje",
        "Prirodna tekstura",
        "Mogućnost brisanja"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10178,
      "name": "Fenomenal Zen",
      "images": [
        "/uploads/jotun-fenomastic-zen.jpg"
      ],
      "details": [
        "Zahvaljujući svojoj jedinstvenoj tehnologiji, Fenomastic Zen nudi jedinstven mat izgled i lako se čisti i otporan je na mrlje.",
        "Sa svojom antibakterijskom formulom, osigurava da uvijek imate lijepe i čiste zidove u svim područjima koja su vam potrebna.",
        "Fenomastic Zen je otporan na mrlje zahvaljujući svojoj posebno razvijenoj površinskoj tehnologiji.",
        "Mrlje koje se mogu pojaviti na površini lako se brišu, tako da vaši zidovi zadržavaju svoj lijep izgled svaki dan.",
        "Zahvaljujući svojoj antibakterijskoj formuli, sprječava stvaranje plijesni i bakterija i nudi vam i vašim voljenima siguran i zdrav životni prostor.",
        "Baza je na vodi; slobodno možete obojiti svoj dom u bilo koje doba godine bez problema s mirisom.",
        "Mat unutarnja boja",
        "Ne ostavlja mrlje",
        "Periva",
        "Antibakterijska",
        "Jedinstven mat i prirodan izgled",
        "Izvrsno iskustvo boje"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10179,
      "name": "Fenomastic Moja Lijepa Kuća Bogat Mat",
      "images": [
        "/uploads/jotun-fenomastic-guzel-evim-zengin-mat--4147-.jpg"
      ],
      "details": [
        "Boje imaju bogatiji i mekši izgled s Fenomastic Güzel Evim Rich Matte.",
        "Zahvaljujući svojoj posebnoj formuli, nudi ljepše boje s glatkom i mat teksturom.",
        "Sa svojom obnovljenom formulom, Fenomastic Güzel Evim Zengin Mat nudi izvrsno iskustvo boje sa svojom posebno dizajniranom bogatom mat završnom obradom i prirodnom teksturom.",
        "Zahvaljujući svojoj mat površini, boje izgledaju savršeno na vašim zidovima kao u prirodi.",
        "Fenomastic Güzel Evim pruža vam glatki izgled sa svojom bogatom mat prirodnom teksturom, a ima i ekološki prihvatljivu formulu.",
        "Pruža dugotrajni estetski izgled sa svojom brisivom strukturom.",
        "Baza je na vodi i bez mirisa, tako da slobodno možete obojiti svoj dom u bilo koje doba godine."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10010,
      "name": "Knauf",
      "images": [
        "/uploads/knauf.jpg"
      ],
      "details": [],
      "children": [
        10182,
        10183
      ],
      "measurements": []
    },
    {
      "id": 10182,
      "name": "Praškasti Malteri",
      "images": [
        "/uploads/1_1.jpg"
      ],
      "details": [],
      "children": [
        10184,
        10185,
        10186,
        10187,
        10188,
        10189,
        10190,
        10191,
        10192,
        10193,
        10194,
        10195
      ],
      "measurements": []
    },
    {
      "id": 10184,
      "name": "Fugagips®",
      "images": [
        "/uploads/12_1.jpg"
      ],
      "details": [
        "To je malter za popunjavanje spojeva koji se koristi za ispunjavanje spojeva u spojevima od gipsanih ploča®.",
        "Karakteristike:",
        "Laka primjena",
        "Visoka prionljivost",
        "Produženo vrijeme obrade",
        "Područja upotrebe:",
        "To je poseban malter za popunjavanje spojeva koji se koristi za ispunjavanje spojeva u spojevima gipsanih ploča i pružanje bešavne pojave na površinama gipsanih ploča.",
        "Može se koristiti i za fiksiranje kutnih profila i popravke.",
        "Dimenzije i opće karakteristike:",
        "Težina: 25kg - 3kg",
        "Pakovanje: 60 vreća / paleta - 180 vreća / paleta",
        "Rok trajanja: 6 mjeseci",
        "Standard proizvoda: TS EN 13963 - 3B: Materijal za spojeve za gipsane ploče"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10185,
      "name": "Satengips®",
      "images": [
        "/uploads/11.jpg"
      ],
      "details": [
        "To je ručno naneseni završni malter za izravnavanje površina koji se primjenjuje unutra.",
        "Karakteristike:",
        "Glatka površina spremna za završne obrade",
        "Laka i brza mogućnost primjene",
        "Produženo vrijeme obrade",
        "A1 klasa nematerijalnog građevinskog materijala",
        "Visoka prionljivost",
        "Područja upotrebe:",
        "Primjenjuje se kao jedan ili dva sloja na zidovima prekrivenim gipsanim pločama, cementom ili gipsanim malterom, glatkim crnim malterom i izloženim betonom, prljavim i obojenim površinama.",
        "Koristi se za postizanje glatke i ravne površine prije završnih obrada kao što su boja i tapete.",
        "Dimenzije i opće karakteristike:",
        "Težina: 25 kg - 3 kg",
        "Pakovanje: 60 vreća / paleta - 180 vreća / paleta",
        "Rok trajanja: 6 mjeseci",
        "Standard proizvoda: TS EN 13279 - 1 Tip: C6, Fino primjenjivi malter"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10186,
      "name": "Kargips®",
      "images": [
        "/uploads/10_1.jpg"
      ],
      "details": [
        "To je malter za suhe zidove koji se koristi u unutarnjim dekoracijama stropova i stubova.",
        "Karakteristike:",
        "Glatki izgled",
        "Visok stepen bjeljine",
        "Laka obradivost",
        "Brzo sušenje",
        "Lijepljenje papirnog mašea",
        "A1 klasa nematerijalnog građevinskog materijala",
        "Područja upotrebe:",
        "Primjene i popravci suhog zida",
        "Unutarnji stropovi i stubovi",
        "Dekoracije zidova i stropova",
        "Dimenzije i opće karakteristike:",
        "Težina: 25kg - 3kg",
        "Pakovanje: 60 vreća / paleta - 180 vreća / paleta",
        "Rok trajanja: 6 mjeseci",
        "Standard proizvoda: TS EN 13279 - 1 Tip: C1/8, Građevinski malter za elemente od vlaknasto ojačanog maltera"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10187,
      "name": "Yapıgips®",
      "images": [
        "/uploads/9_2.jpg"
      ],
      "details": [
        "Unutrašnja gipsana ploča® je lepljivi malter.",
        "Karakteristike:",
        "Visoka čvrstoća prianjanja",
        "Brza mogućnost stvrdnjavanja",
        "Jednostavna i brza primena",
        "A1 klasa nematerijalnog građevinskog materijala",
        "Područja upotrebe:",
        "Omogućava primenu gipsanih ploča ili polistiren ploča na svim vrstama neravnih podova lepljenjem bez korišćenja bilo kakve konstrukcije.",
        "Dimenzije i opšte karakteristike:",
        "Težina: 25 kg",
        "Pakovanje: 60 vreća/paleta",
        "Rok trajanja: 6 meseci",
        "Standard proizvoda: TS EN 14496"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10188,
      "name": "İzogips®",
      "images": [
        "/uploads/8_2.jpg"
      ],
      "details": [
        "To je perlitni malter koji se ručno nanosi unutra.",
        "Karakteristike:",
        "Karakteristika disanja",
        "Jednostavna mogućnost primene",
        "Produženo vreme rada",
        "Visoka prionljivost",
        "A1 klasa nematerijalnog građevinskog materijala",
        "Područja upotrebe:",
        "Nanosi se unutra na površinama od opeke, blokova, gasbetona, izloženog betona, blokova pumice i maltera na bazi cementa.",
        "Dimenzije i opšte karakteristike:",
        "Težina: 35 kg - 25 kg - 3 kg",
        "Pakovanje: 45 vreća/paleta - 60 vreća/paleta - 180 vreća/paleta",
        "Rok trajanja: 6 meseci",
        "Standard proizvoda: TS EN 13279-1 : Lagani gipsani malter za građevinu B4/20/2"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10189,
      "name": "Jetgips® Diamant",
      "images": [
        "/uploads/7_1.jpg"
      ],
      "details": [
        "To je malter koji se mašinski nanosi unutra, sa povećanom površinskom tvrdoćom i otpornošću na pritisak, i glatkim površinama.",
        "Karakteristike:",
        "Karakteristika disanja",
        "Mineralna osnova",
        "Visoka površinska tvrdoća i čvrstoća na pritisak",
        "Doprinos udobnom i zdravom vazduhu u prostoriji",
        "Otpornost na habanje",
        "Glatka površina koja ne zahteva poliranje nakon primene",
        "A1 klasa nematerijalnog građevinskog materijala",
        "Produženo vreme rada",
        "Jednostavna i brza primena",
        "Područja upotrebe:",
        "To je malter koji obezbeđuje površinsku tvrdoću i otpornost na pritisak sa jednom složnom primenom za područja sa velikim prometom unutra (javne zgrade, škole, stepeništa, itd.).",
        "Nanosi se na konvencionalne (opeka, gasbeton, pumice) i izložene betonske zidove kako bi se stvorile glatke, ojačane ili izravnate površine.",
        "Nije pogodna za upotrebu kao malter za plafon.",
        "Dimenzije i opšte karakteristike:",
        "Težina: 35 kg",
        "Pakovanje: 45 vreća/paleta",
        "Rok trajanja: 6 meseci",
        "Standard proizvoda: TS EN 13279-1 B7/50/6: Malter za gipsane elemente sa povećanom površinskom tvrdoćom"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10190,
      "name": "Jetsatengips®",
      "images": [
        "/uploads/6_2.jpg"
      ],
      "details": [
        "To je malter koji se mašinski nanosi unutra, sa povećanom površinskom tvrdoćom i otpornošću na pritisak, i glatkim površinama.",
        "Karakteristike:",
        "Karakteristika disanja",
        "Mineralna osnova",
        "Visoka površinska tvrdoća i čvrstoća na pritisak",
        "Doprinos udobnom i zdravom vazduhu u prostoriji",
        "Otpornost na habanje",
        "Glatka površina koja ne zahteva poliranje nakon primene",
        "A1 klasa nematerijalnog građevinskog materijala",
        "Produženo vreme rada",
        "Jednostavna i brza primena",
        "Područja upotrebe:",
        "To je malter koji obezbeđuje površinsku tvrdoću i otpornost na pritisak sa jednom složnom primenom za područja sa velikim prometom unutra (javne zgrade, škole, stepeništa, itd.).",
        "Nanosi se na konvencionalne (opeka, gasbeton, pumice) i izložene betonske zidove kako bi se stvorile glatke, ojačane ili izravnate površine.",
        "Nije pogodna za upotrebu kao malter za plafon.",
        "Dimenzije i opšte karakteristike:",
        "Težina: 35 kg",
        "Pakovanje: 45 vreća/paleta",
        "Rok trajanja: 6 meseci",
        "Standard proizvoda: TS EN 13279-1 B7/50/6: Malter za gipsane elemente sa povećanom površinskom tvrdoćom"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10191,
      "name": "Jetgips® Base",
      "images": [
        "/uploads/5_2.jpg"
      ],
      "details": [
        "To je perlitni malter koji se mašinski nanosi unutra.",
        "Karakteristike:",
        "Karakteristika disanja",
        "Doprinos toplotnoj i zvučnoj izolaciji",
        "Jednostavna i brza primena",
        "A1 klasa nematerijalnog građevinskog materijala",
        "Područja upotrebe:",
        "Površine od opeke, blokova, gasbetona, izloženog betona, blokova pumice i maltera na bazi cementa unutra.",
        "Dimenzije i opšte karakteristike:",
        "Težina: 35 kg",
        "Pakovanje: 45 vreća/paleta",
        "Rok trajanja: 6 meseci",
        "Standard proizvoda: TS EN 13279 - 1 Tip: B4, Lagani gipsani malter za građevinu"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10192,
      "name": "Jetgips® Ultra",
      "images": [
        "/uploads/4_2.jpg"
      ],
      "details": [
        "To je malter za završnu obradu površine koji se mašinski nanosi unutra.",
        "Karakteristike:",
        "Glatka površina spremna za završne primene",
        "Jednostavna i brza primena",
        "Može se primeniti mašinom za gipsanje bez vazduha",
        "Produženo vreme rada",
        "A1 klasa nematerijalnog materijala",
        "Područja upotrebe:",
        "Površine prekrivene gipsanim pločama®, gipsanim površinama, površinama prekrivenim malterom na bazi cementa, boji, tapetama, itd. Može se koristiti na površinama na kojima će se primeniti završni sloj.",
        "Dimenzije i opšte karakteristike:",
        "Težina: 30 kg",
        "Pakovanje: 50 vreća/paleta",
        "Rok trajanja: 6 meseci",
        "Standard proizvoda: TS EN 13279 - 1 Tip: C6, Fin malter za primenu"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10193,
      "name": "Jetgips® Ultra 40",
      "images": [
        "/uploads/3_2.jpg"
      ],
      "details": [
        "To je perlitni malter koji se mašinski nanosi unutra.",
        "Karakteristike:",
        "Karakteristika disanja",
        "Glatka površina koja ne zahteva poliranje nakon primene",
        "Produženo vreme rada",
        "Doprinos toplotnoj izolaciji",
        "Jednostavna i brza primena",
        "A1 klasa nematerijalnog građevinskog materijala",
        "Do 40% veće pokrivenosti površine nego Knauf Jetgips Ultra®",
        "Područja upotrebe:",
        "Površine od opeke, blokova, gasbetona, izloženog betona, blokova pumice i maltera na bazi cementa unutra.",
        "Dimenzije i opšte karakteristike:",
        "Težina: 25 kg",
        "Pakovanje: 50 vreća/paleta",
        "Rok trajanja: 6 meseci",
        "Standard proizvoda: TS EN 13279 - 1 Tip: C4, Malter za toplotnu izolaciju"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10194,
      "name": "Jetgips® Fire",
      "images": [
        "/uploads/2_2.jpg"
      ],
      "details": [
        "Jetgips® Fire je otporni gipsani malter koji se koristi unutra. Specijalna mešavina lakih komponenti i aditiva, zajedno sa malterom u svom sadržaju, obezbeđuje da se proizvod primeni mašinski bez problema.",
        "Karakteristike:",
        "Otpornost na vatru",
        "REI 240 za betonske plafone i zidove",
        "R 180 za betonske grede i stubove",
        "R 120 za čelične grede i stubove",
        "Za REI 120 kompozitne podne obloge (trapezoidni lim ispod betona)",
        "Jednostavna primena mašinom",
        "Jednostavna obradivost i visoka produktivnost",
        "Na bazi gipsa",
        "Unutrašnja upotreba",
        "Bela boja",
        "Može se koristiti u područjima proizvodnje hrane",
        "Područje primene:",
        "Betonski plafoni i zidovi",
        "Betonske grede i stubovi",
        "Čelične grede i stubovi",
        "Kompozitne podne obloge (trapezoidni lim ispod betona)",
        "Dimenzije i opšte karakteristike:",
        "Težina: 20 kg / Pakovanje: 50 vreća/paleta",
        "Standard proizvoda: Jetgips® Fire je odobren prema Evropskoj tehničkoj proceni ETA - 11/0229."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10195,
      "name": "İzogips® Fine",
      "images": [
        "/uploads/1.jpg"
      ],
      "details": [
        "Izogips® Fine; To je malter koji se ručno nanosi kako bi se dobila glatka površina na grubom malteru unutra.",
        "Karakteristike:",
        "Karakteristika disanja",
        "A1 klasa nematerijalnog građevinskog materijala",
        "Jednostavna primena",
        "Visoka prionljivost",
        "Produženo vreme rada",
        "Područja upotrebe:",
        "Nanosi se na grube površine od grubog maltera unutra.",
        "Opšte karakteristike:",
        "Težina: 35 kg - 25 kg",
        "Pakovanje: 45 vreća/paleta (35 kg) - 60 vreća/paleta (25 kg)",
        "Rok trajanja: 6 meseci",
        "Standard proizvoda: TS EN 13279-1 : Lagani gipsani malter za građevinu B4/20/2"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10183,
      "name": "Gipsane ploče",
      "images": [
        "/uploads/cutout_1470131_tab_picture_4x3_1.jpg"
      ],
      "details": [],
      "children": [
        10196,
        10197,
        10198,
        10199,
        10200,
        10201,
        10202,
        10203,
        10204,
        10205,
        10206,
        10207,
        10208,
        10209,
        10210
      ],
      "measurements": []
    },
    {
      "id": 10196,
      "name": "Drywall® Regular Plus",
      "images": [
        "/uploads/cutout_348077_tab_picture_4x3_1.jpg"
      ],
      "details": [
        "To je glatka i fleksibilna gipsana ploča koja se koristi unutra. Prednja strana je prekrivena slonovačom, a stražnja strana je prekrivena sivim papirom.",
        "Karakteristike:",
        "Čista i laka montaža",
        "Ušteda vremena",
        "Visoka kvaliteta površine",
        "Područja upotrebe:",
        "suspendovani plafon",
        "Pregradni zid",
        "Oblaganje zidova",
        "suhi malter zidna obloga",
        "Dimenzije i opće karakteristike:",
        "Debljina: 12.5/15mm",
        "Težina: 9 - 9.5 / 10 - 11 kg/m2",
        "Širina: 1200mm",
        "Dužina: 2200 / 2300 / 2400 / 2500 / 2600 / 2700 / 2800 / 3000 mm",
        "Pakovanje: 30 / 50 kom / paleta",
        "Standard proizvoda: TS EN 520 + A1 - TIP A"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10197,
      "name": "Podna obloga u Guardex® oblogama i ventiliranim fasadnim sistemima",
      "images": [
        "/uploads/cutout_350754_tab_picture_4x3_1.jpg"
      ],
      "details": [
        "To je lagana, A1 klasa vatrootporna, održiva fasadna obloga koja se koristi u unutrašnjim i vanjskim sistemima, koja sprečava formiranje plijesni, doprinosi izolaciji, otporna je na vodu, vlagu i vanjske vremenske uvjete, zahvaljujući svojoj posebnoj gipsanoj jezgri i plavoj staklenoj vlaknima na obje strane.",
        "Karakteristike:",
        "A1 klasa vatrootpornog materijala",
        "Lagano",
        "Dimenzionalna stabilnost pri promjenama temperature i vlage",
        "otpornost na vlagu",
        "otpornost na plijesan",
        "Prozračnost (propusnost za vodenu paru)",
        "Čista i laka montaža",
        "Ušteda vremena",
        "Visoka kvaliteta površine",
        "Područja upotrebe:",
        "Obloga ispod obloge",
        "Stražnja obloga na ventiliranim fasadama",
        "Dimenzije i opće karakteristike:",
        "Debljina: 12.5/15mm",
        "Težina: 11 - 12 / 13.5 - 14 kg/m2",
        "Širina: 1200mm",
        "Dužina: 2400 / 2500 mm",
        "Pakovanje: 30 / 50 kom / paleta",
        "Standard proizvoda: TS EN 15283 - 1 + A1 - GM-FH1R"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10198,
      "name": "Vidipan® zidna ploča",
      "images": [
        "/uploads/cutout_348089_tab_picture_4x3_1.jpg"
      ],
      "details": [
        "To je posebna vrsta gipsane ploče sa dodatkom celuloze, proizvedena tehnologijom vlaknaste gipsa, koja pruža visoku zvučnu izolaciju.",
        "Karakteristike:",
        "otpornost na vlagu",
        "Sposobnost zabijanja eksera",
        "Visoka toplinska i zvučna izolacija",
        "Čista i laka montaža",
        "Ušteda vremena",
        "Visoka kvaliteta površine",
        "Područja upotrebe:",
        "Pregradni zid",
        "Oblaganje zidova",
        "prefabrikovane zgrade",
        "Dimenzije i opće karakteristike:",
        "Debljina: 10 / 12.5 / 15 mm",
        "Težina: 11.8 / 14.75 / 17.7 kg/m2",
        "Širina: 1200mm",
        "Dužina: 2500mm",
        "Pakovanje: 35 / 40 / 50 kom / paleta",
        "Standard proizvoda: EN 15283 - 2 / ETA-07/0086"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10199,
      "name": "Silentboard Drywall® sa superiornom zvučnom izolacijom",
      "images": [
        "/uploads/cutout_348087_tab_picture_4x3_1.jpg"
      ],
      "details": [
        "To je posebna vrsta gipsane ploče sa visokom zvučnom izolacijom koja se može lako i brzo primijeniti. Prednja i stražnja strana su prekrivene smeškim papirom.",
        "Karakteristike:",
        "Superiorna zvučna izolacija",
        "Visoka učinkovitost pri niskim frekvencijama",
        "otpornost na vatru",
        "Fleksibilnost",
        "vatrootporni materijal",
        "Dimenzionalna stabilnost pri promjenama temperature",
        "Čista i laka montaža",
        "Visoka kvaliteta površine",
        "Područja upotrebe:",
        "suspendovani plafon",
        "Pregradni zid",
        "Oblaganje zidova",
        "Dimenzije i opće karakteristike:",
        "Debljina: 12.5mm",
        "Težina: približno 17.5 kg/m2",
        "Širina: 625mm",
        "Dužina: 2000mm",
        "Pakovanje: 42 kom / paleta",
        "Standard proizvoda: EN 520 DFR / DIN18180 GKF"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10200,
      "name": "Safeboard® gipsana ploča sa X-ray zadržavajućom funkcijom",
      "images": [
        "/uploads/cutout_348092_tab_picture_4x3_1.jpg"
      ],
      "details": [
        "To je posebna gipsana ploča bez olova, koja ima sposobnost apsorpcije X-zraka i dodatno ima visoku zvučnu izolaciju.",
        "Karakteristike:",
        "Zaštita od X-zraka",
        "Bez olova",
        "otpornost na vatru",
        "Visoka zvučna izolacija",
        "Fleksibilnost",
        "Čista i laka montaža",
        "Ušteda vremena",
        "Visoka kvaliteta površine",
        "Područja upotrebe:",
        "suspendovani plafon",
        "Pregradni zid",
        "Oblaganje zidova",
        "Dimenzije i opće karakteristike:",
        "Debljina: 12.5mm",
        "Težina: približno 17.8 kg/m2",
        "Širina: 625mm",
        "Dužina: 2500mm",
        "Pakovanje: 42 kom / paleta",
        "Standard proizvoda: EN 520 - TIP DF / DIN18180 - GKF"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10201,
      "name": "Fireboard® klasa A1 posebna gipsana ploča sa visokom otpornosti na vatru",
      "images": [
        "/uploads/cutout_348095_tab_picture_4x3_1.jpg"
      ],
      "details": [
        "To je posebna vrsta gipsane ploče A1 klase, koja nema papir na obje površine, prekrivena je staklenim vlaknima, i pruža otpornost na vatru do 3 sata.",
        "Karakteristike:",
        "Visoka otpornost na vatru",
        "A1 klasa vatrootpornog materijala",
        "Dimenzionalna stabilnost pri promjenama temperature",
        "otpornost na električne lukove",
        "Čista i laka montaža",
        "Ušteda vremena",
        "Visoka kvaliteta površine",
        "Područja upotrebe:",
        "Pregradni zid",
        "suspendovani plafon",
        "zidni okviri",
        "Oblaganje čeličnih konstrukcija",
        "Oblaganje ventilacionih i kablovskih kanala",
        "pomorska oprema",
        "Dimenzije i opće karakteristike:",
        "Debljina: 15 / 20 / 25mm",
        "Težina: približno 12 / približno 15.6 / približno 19.6 kg/m2",
        "Širina: 1200 / 1250 mm",
        "Dužina: 2000mm",
        "Pakovanje: 20 / 30 / 40 kom / paleta",
        "Standard proizvoda: EN 15283 - 1 GM-F"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10202,
      "name": "Vodootporna i vatrootporna Drywall® (WRFR)",
      "images": [
        "/uploads/cutout_348080_tab_picture_4x3_1_2.jpg"
      ],
      "details": [
        "To je glatka, lagana gipsana ploča sa povećanom otpornosti na vatru i vodoodbojnošću. Prednja strana je prekrivena ružičastim, a stražnja strana je prekrivena zeleno-sivim papirom.",
        "Karakteristike:",
        "Otpornost na vlagu i vatru",
        "Čista i laka montaža",
        "Primjenjivost pločica, keramike itd.",
        "Ušteda vremena",
        "Visoka kvaliteta površine",
        "Područja upotrebe:",
        "suspendovani plafon",
        "Pregradni zid",
        "zidni okviri",
        "Vlažna područja",
        "Dimenzije i opće karakteristike:",
        "Debljina: 12.5 / 15 / 18 mm",
        "Težina: 9.5 - 10.5 / 11.5 - 12.5 / 14 - 15 kg/m2",
        "Širina: 1200mm",
        "Dužina: 2500 (standard) / 2600 / 2700 / 2800 / 2900 / 3000 mm",
        "Pakovanje: 30 / 50 kom / paleta",
        "Standard proizvoda: TS EN 520 + A1 - TIP HF"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10203,
      "name": "Vatrootporna Drywall® (FRDF)",
      "images": [
        "/uploads/cutout_348080_tab_picture_4x3_1_1.jpg"
      ],
      "details": [
        "To je vrsta gipsane ploče sa povećanom otpornosti na vatru, dodatkom staklenih vlakana, glatkom površinom i povećanom gustoćom. Prednja strana je prekrivena ružičastim papirom, a stražnja strana je prekrivena sivim papirom.",
        "Karakteristike:",
        "otpornost na vatru",
        "Čista i laka montaža",
        "Ušteda vremena",
        "Visoka kvaliteta površine",
        "Područja upotrebe:",
        "suspendovani plafon",
        "Pregradni zid",
        "Oblaganje zidova",
        "zidni okviri",
        "Dimenzije i opće karakteristike:",
        "Debljina: 12.5mm",
        "Težina: 11 - 12 kg/m2",
        "Širina: 1200mm",
        "Dužina: 2500mm",
        "Pakovanje: 50 kom / paleta",
        "Standard proizvoda: TS EN 520 + A1 - TIP DF"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10204,
      "name": "Vatrootporna Drywall® (FR)",
      "images": [
        "/uploads/cutout_348080_tab_picture_4x3_1.jpg"
      ],
      "details": [
        "To je lagana gipsana ploča sa glatkom površinom, dodatkom staklenih vlakana, sa povećanim vremenom otpornosti na vatru. Prednja strana je prekrivena ružičastim papirom, a stražnja strana je prekrivena sivim papirom.",
        "Karakteristike:",
        "otpornost na vatru",
        "Čista i laka montaža",
        "Ušteda vremena",
        "Visoka kvaliteta površine",
        "Područja upotrebe:",
        "suspendovani plafon",
        "Pregradni zid",
        "Oblaganje zidova",
        "zidni okviri",
        "Dimenzije i opće karakteristike:",
        "Debljina: 12.5 - 15 - 18 mm",
        "Težina: 9.5 - 10.5 / 11.5 - 12.5 / 14 - 15 kg/m2",
        "Širina: 1200mm",
        "Dužina: 2500 (standard) / 2600 / 2700 / 2800 / 2900 / 3000 mm",
        "Pakovanje: 30 / 50 kom / paleta",
        "Standard proizvoda: TS EN 520 + A1 - TIP F"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10205,
      "name": "Moldboard® otporan na plijesan, vodu i vatru Drywall® (MWFR)",
      "images": [
        "/uploads/cutout_348084_tab_picture_4x3_1.jpg"
      ],
      "details": [
        "To je lagana gipsana ploča sa glatkom površinom, dodatkom staklenih vlakana, sa povećanim vremenom otpornosti na vatru. Prednja strana je prekrivena ružičastim papirom, a stražnja strana je prekrivena sivim papirom.",
        "Karakteristike:",
        "otpornost na vatru",
        "Čista i laka montaža",
        "Ušteda vremena",
        "Visoka kvaliteta površine",
        "Područja upotrebe:",
        "suspendovani plafon",
        "Pregradni zid",
        "Oblaganje zidova",
        "zidni okviri",
        "Dimenzije i opće karakteristike:",
        "Debljina: 12.5 - 15 - 18 mm",
        "Težina: 9.5 - 10.5 / 11.5 - 12.5 / 14 - 15 kg/m2",
        "Širina: 1200mm",
        "Dužina: 2500 (standard) / 2600 / 2700 / 2800 / 2900 / 3000 mm",
        "Pakovanje: 30 / 50 kom / paleta",
        "Standard proizvoda: TS EN 520 + A1 - TIP F"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10206,
      "name": "Moldboard® Zidna ploča otporna na vlagu i plijesan® (MWR)",
      "images": [
        "/uploads/cutout_348081_tab_picture_4x3_1.jpg"
      ],
      "details": [
        "To je glatka gipsana ploča koja se može koristiti u vlažnim područjima i vlažnim okruženjima, ima otpornost na vodu, sprečava plijesan i pruža otpornost na plijesan. Prednja strana je prekrivena zelenim papirom, a stražnja strana je prekrivena sivim papirom.",
        "Karakteristike:",
        "otpornost na vlagu",
        "otpornost na plijesan",
        "primjenjivost pločica, keramike itd.",
        "čista i jednostavna montaža",
        "ušteda vremena",
        "visoka kvaliteta površine",
        "Područja upotrebe:",
        "suspendirani strop",
        "pregradni zid",
        "oblaganje zidova",
        "suhi gipsani zid",
        "stube",
        "Vlažna područja",
        "Dimenzije i opće karakteristike:",
        "Debljina: 12.5/15mm",
        "Težina: 10 - 11 / 11 - 12 kg/m2",
        "Širina: 1200mm",
        "Dužina: 2500mm",
        "Pakovanje: 30 / 50 kom / paleta",
        "Standard proizvoda: TS EN 520 + A1 - Tip H1"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10207,
      "name": "Flex Zidna ploča® (FX)",
      "images": [
        "/uploads/cutout_348075_tab_picture_4x3_1.jpg"
      ],
      "details": [
        "To je glatka, fleksibilna i lagana gipsana ploča koja se koristi unutra. Prednja strana je prekrivena slonovačom, a stražnja strana je prekrivena sivim papirom.",
        "Karakteristike:",
        "čista i jednostavna montaža",
        "ušteda vremena",
        "visoka kvaliteta površine",
        "Područja upotrebe:",
        "suspendirani strop",
        "pregradni zid",
        "oblaganje zidova",
        "suhi gipsani zid",
        "Dimenzije i opće karakteristike:",
        "Debljina: 9.5 / 12.5 / 15 / 18 mm",
        "Težina: 6.5 - 7.5 / 7.5 - 8 / 10 - 11 / 14 -15 kg/m2",
        "Širina: 1200mm",
        "Dužina: 2000 / 2500 (standard) / 2600 / 2700 / 2800 / 2900 / 3000 mm",
        "Pakovanje: 30 / 50 / 60 kom / paleta",
        "Standard proizvoda: TS EN 520 + A1 - TIP A"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10208,
      "name": "Vodootporna Zidna ploča® (WR)",
      "images": [
        "/uploads/cutout_348079_tab_picture_4x3_1.jpg"
      ],
      "details": [
        "To je lagana gipsana ploča sa glatkom površinom, koja se može koristiti u svim vrstama vlažnih područja i vlažnih okruženja. Prednja strana je prekrivena zelenim papirom, a stražnja strana je prekrivena sivim papirom.",
        "Karakteristike:",
        "otpornost na vlagu",
        "primjenjivost pločica, keramike itd.",
        "čista i jednostavna montaža",
        "ušteda vremena",
        "visoka kvaliteta površine",
        "Područja upotrebe:",
        "suspendirani strop",
        "pregradni zid",
        "oblaganje zidova",
        "suhi gipsani zid",
        "stube",
        "Vlažna područja",
        "Dimenzije i opće karakteristike:",
        "Debljina: 9.5 / 12.5 / 15 / 18 mm",
        "Težina: 7 - 7.5 / 8 - 8.5 / 10.5 - 11.5 / 14 - 15 kg/m2",
        "Širina: 1200mm",
        "Dužina: 2500 (standard) / 2600 / 2700 / 2800 / 2900 / 3000 mm",
        "Pakovanje: 30 / 50 / 60 kom / paleta",
        "Standard proizvoda: TS EN 520 + A1 - TIP H"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10209,
      "name": "Diamant® Zidna ploča otporna na udarce, vodu i vatru®",
      "images": [
        "/uploads/cutout_348094_tab_picture_4x3_1.jpg"
      ],
      "details": [
        "To je posebna vrsta gipsane ploče koja je otporna na udarce, ima povećanu čvrstoću, otpornost na vatru i vodu, kao i svojstva zvučne izolacije. Prednja i stražnja strana su prekrivene plavim papirom.",
        "Karakteristike:",
        "otpornost na udarce",
        "otpornost na vatru",
        "otpornost na vlagu",
        "visoka zvučna izolacija",
        "viši zidovi",
        "visoka nosivost",
        "povećana tvrdoća površine",
        "čista i jednostavna montaža",
        "ušteda vremena",
        "visoka kvaliteta površine",
        "Područja upotrebe:",
        "pregradni zid",
        "oblaganje zidova",
        "suspendirani strop",
        "stube",
        "Dimenzije i opće karakteristike:",
        "Debljina: 12.5/15mm",
        "Težina: 12.5 - 13.5 / 15-16 kg/m2",
        "Širina: 1200mm",
        "Dužina: 2500mm",
        "Pakovanje: 30/40 kom/paleta",
        "Standard proizvoda: TS EN 520 + A1, TIP DFH2IR"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10210,
      "name": "Drywall Flex Ultra (FX Ultra)",
      "images": [
        "/uploads/cutout_1470131_tab_picture_4x3_1.jpg"
      ],
      "details": [
        "To je posebna vrsta gipsane ploče, otporna na udarce, sa povećanom čvrstoćom i svojstvima zvučne izolacije. Prednja strana je prekrivena žutim papirom, a stražnja strana je prekrivena sivim papirom.",
        "Karakteristike:",
        "otpornost na udarce",
        "čista i jednostavna montaža",
        "visoka zvučna izolacija",
        "viši zidovi",
        "visoka nosivost",
        "povećana tvrdoća površine",
        "ušteda vremena",
        "visoka kvaliteta površine",
        "Područja upotrebe:",
        "suspendirani strop",
        "pregradni zid",
        "oblaganje zidova",
        "Dimenzije i opće karakteristike:",
        "Debljina: 12.5 // 15 mm",
        "Težina: 12.5 - 13.5 kg/m2 // 15 - 16 kg/m2",
        "Širina: 1200mm",
        "Dužina: 2500mm",
        "Pakovanje: 40 kom/paleta // 30 kom/paleta",
        "Standard proizvoda: TS EN 520 + A1 - Tip DIR"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10011,
      "name": "Knauf Izolacija",
      "images": [
        "/uploads/knaufinsulation.jpg"
      ],
      "details": [],
      "children": [
        10211,
        10212,
        10213
      ],
      "measurements": []
    },
    {
      "id": 10211,
      "name": "Izolacija za zidne zavjese",
      "images": [
        "/uploads/1_5.jpg"
      ],
      "details": [],
      "children": [
        10214,
        10215,
        10216,
        10217,
        10218,
        10219
      ],
      "measurements": []
    },
    {
      "id": 10214,
      "name": "Mineral Plus FCB037 KD",
      "images": [
        "/uploads/6_4.jpg"
      ],
      "details": [
        "Nizak koeficijent prijenosa topline, otpornost na provođenje topline. λ = 0.037 W/m.K",
        "Apsorbira zvukove iz zraka. Pruža zvučnu izolaciju.",
        "Klasa reakcije na vatru je A1.",
        "Minimalno prašine, nije svrbež.",
        "Fleksibilan, jednostavan za korištenje, štedi vrijeme i rad.",
        "Kompaktno pakovanje; Niski troškovi skladištenja.",
        "Prekriven Ecose® Tulle. Ima dodatnu izdržljivost protiv vanjskih utjecaja.",
        "KARAKTERISTIKE",
        "Debljina (mm)",
        "R Vrijednost (m²k/W)",
        "Dužina (mm)",
        "Širina (mm)",
        "Broj komada u pakovanju (kom)",
        "Površina pakovanja (m²)",
        "Površina palete (m²)",
        "50 1.35 1200 600 24 12.96 311.04",
        "80 2.15 1200 600 24 7.2 172.8",
        "100 2.75 1200 600 24 6.48 155.52"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10215,
      "name": "Mineral Plus FCB037",
      "images": [
        "/uploads/5_4.jpg"
      ],
      "details": [
        "Rolna ili ploča",
        "Prekrivena ili neprekrivena",
        "PODRUČJE UPOTREBE",
        "Obnova ili nova zgrada, obnova, nova zgrada",
        "Vrsta zgrade javni projekti, stanovanje, hotel, industrija",
        "KARAKTERISTIKE",
        "Debljina (mm)",
        "R Vrijednost (m²k/W)",
        "Dužina (mm)",
        "Širina (mm)",
        "Broj komada u pakovanju (kom)",
        "Površina pakovanja (m²)",
        "Površina palete (m²)",
        "50 1.35 1200 600 18 12.96 311.04",
        "80 2.15 1200 600 12 8.64 172.8",
        "100 2.70 1200 100 9 6.48 155.52"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10216,
      "name": "Mineral Plus FCB034 KD",
      "images": [
        "/uploads/4_5.jpg"
      ],
      "details": [
        "Nizak koeficijent prijenosa topline, otpornost na provođenje topline. λ = 0.034 W/m.K",
        "Apsorbira zvukove iz zraka.",
        "Klasa reakcije na vatru je A1.",
        "Minimalno prašine, nije svrbež.",
        "Fleksibilan, jednostavan za korištenje, štedi vrijeme i rad.",
        "Kompaktno pakovanje; Niski troškovi skladištenja.",
        "Prekriven Ecose® Tulle. Ima dodatnu izdržljivost protiv vanjskih utjecaja."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10217,
      "name": "Mineral Plus FCB 034",
      "images": [
        "/uploads/1_5.jpg"
      ],
      "details": [
        "Nizak koeficijent prenosa toplote, otpornost na toplotnu provodljivost. λ = 0.034 W/m.K",
        "Apsorbuje zvukove iz vazduha.",
        "Klasa reakcije na vatru je A1.",
        "Pravi minimalnu prašinu, nije svrbež.",
        "Fleksibilan je, lak za korišćenje, štedi vreme i rad.",
        "Kompaktna pakovanja; Troškovi skladištenja su niski.",
        "OSOBINE",
        "Debljina (mm)",
        "R vrednost (m²k/W)",
        "Dužina (mm)",
        "Širina (mm)",
        "Broj komada u pakovanju (komada)",
        "Površina pakovanja (m²)",
        "Površina palete (m²)",
        "50 1.45 1200 600 16 9.6 230.4",
        "80 2.35 1200 600 10 7.2 144",
        "100 2.90 1200 600 8 5.76 115.2"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10218,
      "name": "Mineral Plus FCB033 Extra",
      "images": [
        "/uploads/2_7.jpg"
      ],
      "details": [
        "Nizak koeficijent prenosa toplote, otpornost na toplotnu provodljivost. λ = 0.033 W/m.K",
        "Apsorbuje zvukove iz vazduha.",
        "Klasa reakcije na vatru je A1.",
        "Pravi minimalnu prašinu, nije svrbež.",
        "Fleksibilan je, lak za korišćenje, štedi vreme i rad.",
        "Kompaktna pakovanja; Troškovi skladištenja su niski.",
        "OSOBINE",
        "Debljina (mm)",
        "R vrednost (m²k/W)",
        "Dužina (mm)",
        "Širina (mm)",
        "Broj komada u pakovanju (komada)",
        "Površina pakovanja (m²)",
        "Površina palete (m²)",
        "50 1.50 1200 600 10 7.2 144"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10219,
      "name": "Mineral Plus FCB032 Pro",
      "images": [
        "/uploads/1_4.jpg"
      ],
      "details": [
        "Nizak koeficijent prenosa toplote, otpornost na toplotnu provodljivost. λ = 0.032 W/m.K",
        "Apsorbuje zvukove iz vazduha.",
        "Klasa reakcije na vatru je A1.",
        "Pravi minimalnu prašinu, nije svrbež.",
        "Fleksibilan je, lak za korišćenje, štedi vreme i rad.",
        "Kompaktna pakovanja; Troškovi skladištenja su niski.",
        "OSOBINE",
        "Debljina (mm)",
        "R vrednost (m²k/W)",
        "Dužina (mm)",
        "Širina (mm)",
        "Broj komada u pakovanju (komada)",
        "Površina pakovanja (m²)",
        "Površina palete (m²)",
        "50 1.55 1200 600 8 5.76 138.24",
        "100 3.10 1200 600 4 2.88 57.6"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10212,
      "name": "Izolacija krovova",
      "images": [
        "/uploads/1_4.jpg"
      ],
      "details": [],
      "children": [
        10220,
        10221,
        10222
      ],
      "measurements": []
    },
    {
      "id": 10220,
      "name": "Earthwool 043 Alu",
      "images": [
        "/uploads/3_5.jpg"
      ],
      "details": [
        "Zahvaljujući svom niskom koeficijentu toplotne provodljivosti, pruža odličnu toplotnu izolaciju i doprinosi zvučnoj izolaciji.",
        "U A1 klasi u klasifikaciji reakcije na vatru.",
        "Zahvaljujući svojoj jedinstvenoj strukturi vlakana, manje praši i manje svrbi od alternativnih materijala.",
        "Omogućava lake i udobne primene.",
        "Zahvaljujući svojoj fleksibilnoj i izdržljivoj strukturi vlakana, materijal se mnogo lakše seče.",
        "Zahvaljujući inovativnoj ECOSE® tehnologiji, ne sadrži fenol i formaldehidne derivate štetnih hemikalija, akrilne i boje u svom vezivu.",
        "Ekološki je prihvatljiv sa svojim recikliranim sadržajem sirovina; doprinosi stvaranju zdravijih i pogodnijih životnih prostora u zgradama.",
        "Sprječava moguće toplotne i akustične gubitke savršeno se postavljajući na detalje."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10221,
      "name": "Earthwool 043",
      "images": [
        "/uploads/2_6.jpg"
      ],
      "details": [
        "Zahvaljujući svom niskom koeficijentu toplotne provodljivosti, pruža odličnu toplotnu izolaciju i doprinosi zvučnoj izolaciji.",
        "U A1 klasi u klasifikaciji reakcije na vatru.",
        "Zahvaljujući svojoj jedinstvenoj strukturi vlakana, manje praši i manje svrbi od alternativnih materijala.",
        "Omogućava lake i udobne primene.",
        "Zahvaljujući svojoj fleksibilnoj i izdržljivoj strukturi vlakana, materijal se mnogo lakše seče.",
        "Zahvaljujući inovativnoj ECOSE® tehnologiji, ne sadrži fenol i formaldehidne derivate štetnih hemikalija, akrilne i boje u svom vezivu.",
        "Ekološki je prihvatljiv sa svojim recikliranim sadržajem sirovina; doprinosi stvaranju zdravijih i pogodnijih životnih prostora u zgradama.",
        "Sprječava moguće toplotne i akustične gubitke savršeno se postavljajući na detalje."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10222,
      "name": "Earthwool 035",
      "images": [
        "/uploads/1_3.jpg"
      ],
      "details": [
        "Zahvaljujući svom niskom koeficijentu toplotne provodljivosti, pruža odličnu toplotnu izolaciju i doprinosi zvučnoj izolaciji.",
        "U A1 klasi u klasifikaciji reakcije na vatru.",
        "Zahvaljujući svojoj jedinstvenoj strukturi vlakana, manje praši i manje svrbi od alternativnih materijala.",
        "Omogućava lake i udobne primene.",
        "Zahvaljujući svojoj fleksibilnoj i izdržljivoj strukturi vlakana, materijal se mnogo lakše seče.",
        "Zahvaljujući inovativnoj ECOSE® tehnologiji, ne sadrži fenol i formaldehidne derivate štetnih hemikalija, akrilne i boje u svom vezivu.",
        "Ekološki je prihvatljiv sa svojim recikliranim sadržajem sirovina; doprinosi stvaranju zdravijih i pogodnijih životnih prostora u zgradama.",
        "Sprječava moguće toplotne i akustične gubitke savršeno se postavljajući na detalje."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10213,
      "name": "Izolacija pregrada",
      "images": [
        "/uploads/bb3692fdaef3a25a5524808fba724fcf.jpg"
      ],
      "details": [],
      "children": [
        10223,
        10224
      ],
      "measurements": []
    },
    {
      "id": 10223,
      "name": "Mineral Plus IPB 039",
      "images": [
        "/uploads/2_5.jpg"
      ],
      "details": [
        "PREDNOSTI - Nizak koeficijent prenosa toplote, otpornost na toplotnu provodljivost. λ = 0.039 W/m.K",
        "Apsorbuje zvukove iz vazduha.",
        "Klasa reakcije na vatru je A1.",
        "Pravi minimalnu prašinu, nije svrbež.",
        "Fleksibilan je, lak za korišćenje, štedi vreme i rad.",
        "Kompaktna pakovanja; Troškovi skladištenja su niski.",
        "OSOBINE Debljina (mm) R vrednost (m²k/W) Dužina (mm) Širina (mm) Broj komada u pakovanju (komada) Površina pakovanja (m²) Površina palete (m²)",
        "50 1.25 1200 600 20 14.4 403.2",
        "80 2.05 1200 600 12 8.64 241.92",
        "100 2.55 1200 600 10 7.2 201.6"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10224,
      "name": "Mineral Plus IPB 037",
      "images": [
        "/uploads/bb3692fdaef3a25a5524808fba724fcf.jpg"
      ],
      "details": [
        "PREDNOSTI - Nizak koeficijent prenosa toplote, otpornost na toplotnu provodljivost. λ = 0.037 W/m.K",
        "Apsorbuje zvukove iz vazduha.",
        "Klasa reakcije na vatru je A1.",
        "Pravi minimalnu prašinu, nije svrbež.",
        "Fleksibilan je, lak za korišćenje, štedi vreme i rad.",
        "Kompaktna pakovanja; Troškovi skladištenja su niski.",
        "OSOBINE Debljina (mm) R vrednost (m²k/W) Dužina (mm) Širina (mm) Broj komada u pakovanju (komada) Površina pakovanja (m²) Površina palete (m²)",
        "50 1.35 1200 600 20 14.4 345.6",
        "80 2.15 1200 600 12 8.64 207.36",
        "100 2.70 1200 600 10 7.2 172.8"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10012,
      "name": "Fullboard",
      "images": [
        "/uploads/fullboard.jpg"
      ],
      "details": [],
      "children": [
        10225,
        10226,
        10227
      ],
      "measurements": []
    },
    {
      "id": 10225,
      "name": "Grandex Eksterijerna Ploča",
      "images": [
        "/uploads/5cff4b6c78ceb868186506.jpg"
      ],
      "details": [],
      "children": [
        10228
      ],
      "measurements": []
    },
    {
      "id": 10228,
      "name": "Nepoznati Podproizvod",
      "images": [
        "/uploads/5d31a949681ce986380169.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10226,
      "name": "Praškasti Malteri",
      "images": [
        "/uploads/5e14653ce3277290839710.jpg"
      ],
      "details": [],
      "children": [
        10229,
        10230,
        10231,
        10232,
        10233,
        10234,
        10235,
        10236,
        10237
      ],
      "measurements": []
    },
    {
      "id": 10229,
      "name": "Fullgips Fix (Ljepljivi Malter)",
      "images": [
        "/uploads/9_1.jpg"
      ],
      "details": [
        "Primjena: Prije početka primjene, površina mora biti očišćena.",
        "Dovoljna količina vode se ulije u čistu posudu. FULLGIPS Ljepljivi Malter se prosipa da pokrije površinu vode. Čeka se nekoliko minuta, zatim se malter miješa dok ne postigne homogenu konzistenciju za primjenu, tako da u njemu nema grudvica.",
        "Kada je miješanje završeno, malter je spreman za upotrebu. Malter u posudi može se koristiti otprilike 80 minuta.",
        "Nakon što je ljepljivi malter pripremljen, FULLBOARD se nanosi na stražnju stranu FULLBOARD Gipsane Ploče u širini šake, počinjući od početka i na udaljenosti od 40 cm, da se zalijepi za stražnju stranu FULLBOARD Gipsane Ploče, u hrpi svaka 20-25 cm (otprilike jedan razmak) duž ploče. Da se nanese.",
        "U aplikacijama mineralne vune, primjena treba biti napravljena svaka 30 cm 60-90 minuta prije lijepljenja mineralne vune na površinu.",
        "FULLBOARD Gipsana Ploča se lijepi na površinu s nanesenim ljepljivim malterom i njena ravnoteža se provjerava uz pomoć mjerača.",
        "Za mjesta gdje su razmaci na površini veći od 3 cm, prethodno treba izvršiti montažu.",
        "Preporuke: Ne miješajte FULLGIPS Ljepljivi Malter s bilo kojim drugim proizvodom.",
        "Ako koristite mehanički miješač, koristite ga na niskoj brzini.",
        "Ne dodajte vodu ili malter u svoj malter nakon miješanja.",
        "Temperatura okoline za primjenu trebala bi biti najmanje +5°C.",
        "Suhe i vruće površine trebaju se navlažiti prije primjene.",
        "Uvjeti skladištenja: FULLGIPS Ljepljivi Malter može se slagati do 20 vreća jednu na drugu.",
        "Preporučuje se potrošnja FULLGIPS Ljepljivog Maltera unutar 1 godine, pod uvjetom da se skladišti na paleti u suhom, bezvlaznom i zatvorenom prostoru.",
        "Loši uvjeti skladištenja ili prekoračenje roka trajanja mogu degradirati svojstva FULLGIPS Ljepljivog Maltera."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10230,
      "name": "Fullgips Spoj (Malter za Ispunjavanje Spojeva)",
      "images": [
        "/uploads/8_1.jpg"
      ],
      "details": [
        "Primjena: Prije početka primjene, površina mora biti očišćena.",
        "Dovoljna količina vode se ulije u čistu posudu. FULLGIPS Malter za Ispunjavanje Spojeva se posipa da pokrije površinu vode. Čeka se nekoliko minuta, zatim se malter miješa dok ne postigne homogenu konzistenciju za primjenu, tako da u njemu nema grudvica.",
        "Kada je miješanje završeno, malter je spreman za upotrebu. Malter u posudi može se koristiti otprilike 1 sat.",
        "Primjena se vrši čeličnom lopaticom i u dva sloja.",
        "Prvi sloj FULLGIPS Maltera za Ispunjavanje Spojeva se obilno nanosi na traku za spojeve, koja se lijepi na spojeve.",
        "Čekajte najmanje 2 sata da se prvi sloj osuši. Prije nego što se nanese drugi sloj, grubosti na površini se uklanjaju lopaticom.",
        "Drugi sloj širine otprilike 20 cm se nanosi za poravnavanje površine.",
        "Preporuke: Ne miješajte FULLGIPS Malter za Ispunjavanje Spojeva s bilo kojim drugim proizvodom ili materijalom.",
        "Ako koristite mehanički miješač, koristite ga na niskoj brzini.",
        "Ne dodajte vodu ili malter u svoj malter nakon miješanja.",
        "Temperatura okoline za primjenu trebala bi biti najmanje +5°C.",
        "Razmaci veći od 3 mm trebaju se prethodno ispuniti.",
        "Uvjeti skladištenja: Maksimalno 20 vreća FULLGIPS Maltera za Ispunjavanje Spojeva treba slagati jednu na drugu.",
        "Preporučuje se potrošnja FULLGIPS Maltera za Ispunjavanje Spojeva unutar 1 godine, pod uvjetom da se skladišti na paleti u suhom, bezvlaznom i zatvorenom prostoru.",
        "Loši uvjeti skladištenja ili prekoračenje roka trajanja mogu pogoršati kvalitete FULLGIPS Maltera za Ispunjavanje Spojeva."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10231,
      "name": "Fullgips Satin (Satin Završni Malter)",
      "images": [
        "/uploads/7.jpg"
      ],
      "details": [
        "Primjena: Prašina i supstance protiv lijepljenja na površini primjene trebaju se očistiti.",
        "Dovoljna količina vode se ulije u čistu posudu. FULLGIPS Satin Završni Malter se prosipa da pokrije površinu vode. Čeka se nekoliko minuta, zatim se malter miješa dok ne postigne homogenu konzistenciju. Nakon miješanja, malter je spreman za upotrebu. Malter u posudi može se koristiti otprilike 120 minuta.",
        "Primjena se vrši čeličnom lopaticom.",
        "Preporuke: Ne miješajte FULLGIPS Satin Završni Malter s bilo kojim drugim materijalom.",
        "Ako se koristi mehanički miješač, treba ga koristiti na niskoj brzini.",
        "Ne dodajte vodu ili malter u svoj malter nakon miješanja.",
        "Temperatura okoline za primjenu trebala bi biti najmanje +5°C.",
        "Uvjeti skladištenja: Maksimalno 20 vreća treba slagati jednu na drugu.",
        "Preporučuje se potrošnja unutar 1 godine, pod uvjetom da se skladišti na paleti u suhom, bezvlaznom i zatvorenom prostoru.",
        "Loši uvjeti skladištenja ili prekoračenje roka trajanja mogu pogoršati kvalitete proizvoda."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10232,
      "name": "Fullgips Effect (Papirni Malter)",
      "images": [
        "/uploads/6_1.jpg"
      ],
      "details": [
        "Primjena: Dovoljna količina vode se ulije u čistu posudu. FULLGIPS Malter za Kape se prosipa da pokrije površinu vode. Čeka se nekoliko minuta, zatim se miješa dok ne postigne homogenu konzistenciju za primjenu maltera. Nakon miješanja, malter je spreman za lijevanje u kalupe. Malter se stvrdnjava za otprilike 8-10 minuta.",
        "Kape napravljene s FULLGIPS Malterom za Kape trebaju se potpuno osušiti prije sastavljanja.",
        "Preporuke: Ne miješajte FULLGIPS Malter za Kape s bilo kojim drugim proizvodom.",
        "Ako koristite mehanički miješač, koristite ga na niskoj brzini.",
        "Ne dodajte vodu ili malter u svoj malter nakon miješanja.",
        "Temperatura okoline za primjenu trebala bi biti najmanje +5°C.",
        "Uvjeti skladištenja: Preporučuje se ne slagati više od 20 vreća na FULLGIPS Malter za Kape.",
        "Preporučuje se potrošnja FULLGIPS Maltera za Suhe Zidove unutar 1 godine, pod uvjetom da se skladišti na paleti u suhom, bezvlaznom i zatvorenom prostoru.",
        "Loši uvjeti skladištenja ili prekoračenje roka trajanja mogu pogoršati kvalitete FULLGIPS Maltera za Zidare."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10233,
      "name": "Fullgips Malter (Perlitni Malter)",
      "images": [
        "/uploads/5_1.jpg"
      ],
      "details": [
        "Primjena: Prije početka primjene, materijali protiv lijepljenja na površini trebaju se očistiti.",
        "Dovoljna količina vode se ulije u čistu posudu. FULLGIPS Perlitni Malter se posipa dok nivo vode ne premaši 1 cm. Čeka se nekoliko minuta, zatim se miješa dok ne nestanu grudvice u malteru dok ne postigne konzistenciju tijesta za primjenu.",
        "Nakon miješanja, malter je spreman za upotrebu. Malter u posudi može se koristiti otprilike 1 sat.",
        "Primjena se vrši mjeračem, lopaticom za malter i čeličnom lopaticom. Ožiljci se obrišu nakon što malter počne da se stvrdnjava.",
        "FULLGIPS Perlitni Malter se direktno nanosi na zidne površine 5-40 mm, na strop i izložene betonske površine 5-20 mm.",
        "Preporuke: Ne primjenjujte manje od 5 mm u jednom sloju.",
        "Ne dodajte vodu ili malter u svoj malter nakon miješanja.",
        "Ne miješajte FULLGIPS Perlitni Malter s bilo kojim drugim materijalom.",
        "Ako se koristi mehanički miješač, koristite ga na niskoj brzini.",
        "Temperatura okoline za primjenu trebala bi biti najmanje +5°C.",
        "Suhe i vruće površine trebaju se svakako navlažiti prije primjene i površina treba biti vlažna.",
        "Uvjeti skladištenja: Maksimalno 20 vreća FULLGIPS Perlitnog Maltera treba slagati jednu na drugu.",
        "FULLGIPS Perlitni Malter se preporučuje potrošnja unutar 1 godine, pod uvjetom da se skladišti na paleti u suhom, bezvlaznom i zatvorenom prostoru.",
        "Loši uvjeti skladištenja ili prekoračenje roka trajanja mogu pogoršati svojstva FULLGIPS PERLITE Perlitnog Maltera."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10234,
      "name": "Fullgips Finish (Malter za Izravnavanje Površine)",
      "images": [
        "/uploads/4_1.jpg"
      ],
      "details": [
        "Primjena: To je gipsani malter za izravnavanje površine s visokom snagom lijepljenja.",
        "Može se primijeniti na obrađene gipsane maltere, perlitne maltere, crne maltere, grube malterisane površine i gipsane ploče.",
        "Voda maltera stvorenog od Fullgips Finish, koji se proizvodi s posebnim aditivima, manje se upija u površinu na kojoj se koristi.",
        "Lako se primjenjuje. Zatvara sve vrste pukotina i nedostataka na površini i pomaže da površina bude spremna za primjenu Fullgips Satin.",
        "Stvaranjem glatke površine, Fullgips Finish također smanjuje potrošnju satin maltera koji će se primijeniti na površinu.",
        "To je prozračan materijal, balansira vlagu i stvara zdravo okruženje.",
        "Ima svojstva protiv požara zbog vode koju sadrži. To je A1 klasa građevinskog materijala i je nepaljiv materijal.",
        "Ima dugo vrijeme obrade, lako se i brzo primjenjuje.",
        "Doprinosi toplinskoj i zvučnoj izolaciji.",
        "Preporuke: Ne miješajte FULLGIPS Malter za Izravnavanje Površine s bilo kojim drugim materijalom.",
        "Ako se koristi mehanički miješač, treba ga koristiti na niskoj brzini.",
        "Ne dodajte vodu ili malter u svoj malter nakon miješanja.",
        "Temperatura okoline za primjenu trebala bi biti najmanje +5°C.",
        "Uvjeti skladištenja: Maksimalno 20 vreća treba slagati jednu na drugu.",
        "Preporučuje se potrošnja unutar 1 godine, pod uvjetom da se skladišti na paleti u suhom, bezvlaznom i zatvorenom prostoru.",
        "Loši uvjeti skladištenja ili prekoračenje roka trajanja mogu pogoršati kvalitete proizvoda."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10235,
      "name": "Fullgips Turbo (Mašinsko Gipsanje)",
      "images": [
        "/uploads/3_1.jpg"
      ],
      "details": [
        "Primjena: To je gipsana unutrašnja mašinska žbuka.",
        "Beton, grubi beton, plinski beton, vulkanski blok, cigla i crna žbuka, cementna gruba žbuka itd. Primjenjuje se direktno na materijale.",
        "Ima visoku tvrdoću površine i sjajni izgled.",
        "To je prozračan materijal, balansira vlagu i stvara zdravo okruženje.",
        "Ima svojstva protiv požara zbog vode koju sadrži. To je građevinski materijal klase A1 i nije zapaljiv.",
        "Ima dugo vreme obrade, lako se i brzo primenjuje.",
        "Doprinosi toplotnoj i zvučnoj izolaciji.",
        "Preporuke: Ne mešajte FULLGIPS Turbo mašinsku žbuku sa bilo kojim drugim materijalom.",
        "Ako se koristi mehanički mikser, treba ga koristiti na niskoj brzini.",
        "Ne dodajte vodu ili žbuku u svoj malter nakon mešanja.",
        "Temperatura okruženja za primenu treba biti najmanje +5°C.",
        "Uslovi skladištenja: Maksimalno 20 vreća treba složiti jednu na drugu.",
        "Preporučuje se potrošnja unutar 1 godine, pod uslovom da se skladišti na paleti u suvom, bezvlažnom i zatvorenom prostoru.",
        "Loši uslovi skladištenja ili prekoračenje roka upotrebe mogu pogoršati kvalitete proizvoda."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10236,
      "name": "Fullgips Power (Mašinsko Gipsanje)",
      "images": [
        "/uploads/2_1.jpg"
      ],
      "details": [
        "Pre nego što započnete primenu, treba očistiti materijale protiv lepljenja na površini.",
        "Žbuka se lepi na površinu koja se gipsa na približno 150-200 cm razmaka.",
        "FULLGIPS mašinska žbuka se meša mašinom za žbukanje i prska između žbuka.",
        "Nakon prskanja, površine se izravnavaju pomoću merne letve.",
        "Druga mera se vrši približno 15-20 minuta nakon prve mere.",
        "Voda se prska na površinu 50-70 minuta nakon početka primene i površina se trifiluje trifil lopaticom.",
        "Nakon otprilike 10 minuta, površina se polira čeličnom lopaticom.",
        "Druga lopatica se primenjuje otprilike 60 minuta nakon prve lopatice i dobija se sjajna i tvrda površina.",
        "Temperatura okruženja za primenu treba biti najmanje +5°C.",
        "Suve i vruće površine treba navlažiti pre primene.",
        "Ne mešajte FULLGIPS mašinsku žbuku sa bilo kojim drugim materijalom.",
        "Debljina primene treba biti između 8-30 mm.",
        "Mašina za žbukanje i alati koji se koriste moraju biti čisti u svakoj fazi.",
        "Preporučuje se složiti do 20 vreća FULLGIPS mašinske žbuke jednu na drugu.",
        "Preporučuje se potrošnja FULLGIPS mašinske žbuke unutar 1 godine, pod uslovom da se skladišti na paleti u suvom, bezvlažnom i zatvorenom prostoru.",
        "Loši uslovi skladištenja ili prekoračenje roka upotrebe mogu pogoršati kvalitete FULLGIPS mašinske žbuke."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10237,
      "name": "Fullgips Extra (Mašinska Ekstra Površinska Žbuka)",
      "images": [
        "/uploads/5e14653ce3277290839710.jpg"
      ],
      "details": [
        "Pre nego što započnete primenu, treba očistiti materijale protiv lepljenja na površini.",
        "Žbuka se lepi na površinu koja se gipsa na približno 150-200 cm razmaka.",
        "FULLGIPS Extra mašinska žbuka se meša mašinom za žbukanje i prska između ploča.",
        "Nakon prskanja, površine se izravnavaju pomoću merne letve.",
        "Druga mera se vrši približno 15-20 minuta nakon prve mere.",
        "Voda se prska na površinu 50-70 minuta nakon početka primene i površina se trifiluje trifil lopaticom.",
        "Nakon otprilike 10 minuta, površina se polira čeličnom lopaticom.",
        "Druga lopatica se primenjuje otprilike 60 minuta nakon prve lopatice i dobija se sjajna i tvrda površina.",
        "Temperatura okruženja za primenu treba biti najmanje +5°C.",
        "Suve i vruće površine treba navlažiti pre primene.",
        "Ne mešajte FULLGIPS Extra mašinsku žbuku sa bilo kojim drugim materijalom.",
        "Debljina primene treba biti između 8-30 mm.",
        "Mašina za žbukanje i alati koji se koriste moraju biti čisti u svakoj fazi.",
        "Preporučuje se složiti maksimalno 20 vreća FULLGIPS Extra mašinske žbuke jednu na drugu.",
        "Preporučuje se potrošnja FULLGIPS Extra mašinske žbuke unutar 1 godine, pod uslovom da se skladišti na paleti u suvom, bezvlažnom i zatvorenom prostoru.",
        "Loši uslovi skladištenja ili prekoračenje roka upotrebe mogu pogoršati kvalitete FULLGIPS Extra mašinske žbuke."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10227,
      "name": "Gipsane Ploče",
      "images": [
        "/uploads/611128415f22d449077076.jpg"
      ],
      "details": [],
      "children": [
        10238,
        10239,
        10240,
        10241,
        10242,
        10243
      ],
      "measurements": []
    },
    {
      "id": 10238,
      "name": "Nepoznati Podproizvod",
      "images": [
        "/uploads/5a884ebaa5cd7675341708.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10239,
      "name": "Nepoznati Podproizvod",
      "images": [
        "/uploads/5a884e7ae2bb8142530240.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10240,
      "name": "Nepoznati Podproizvod",
      "images": [
        "/uploads/3_9.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10241,
      "name": "Nepoznati Podproizvod",
      "images": [
        "/uploads/5c545d7bf0dbf215838010.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10242,
      "name": "Nepoznati Podproizvod",
      "images": [
        "/uploads/5ca667515e1a0794573362.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10243,
      "name": "Nepoznati Podproizvod",
      "images": [
        "/uploads/611128415f22d449077076.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10013,
      "name": "Ravago",
      "images": [
        "/uploads/ravago.jpg"
      ],
      "details": [],
      "children": [
        10244,
        10245,
        10246
      ],
      "measurements": []
    },
    {
      "id": 10244,
      "name": "RAVATHERM SW - Rockwool",
      "images": [
        "/uploads/diilevhasi-k.jpg"
      ],
      "details": [],
      "children": [
        10247,
        10248,
        10249,
        10250,
        10251
      ],
      "measurements": []
    },
    {
      "id": 10247,
      "name": "Terasa krovna ploča",
      "images": [
        "/uploads/terascatilevhasi-k.jpg"
      ],
      "details": [
        "OPIS PROIZVODA",
        "Koristi se za toplotnu, zvučnu i vlagu izolaciju na krovovima terasa.",
        "To je nepremazana rockwool ploča.",
        "Nudi se tržištu u pakovanjima.",
        "Područja upotrebe",
        "Koristi se na svim drvenim i metalnim krovovima.",
        "Koristi se u svim vrstama terasa.",
        "PRIMENA",
        "Postavlja se na parnu barijeru u svrhu toplotne izolacije."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10248,
      "name": "Plutajuća podna ploča",
      "images": [
        "/uploads/4_7.jpg"
      ],
      "details": [
        "OPIS PROIZVODA",
        "To je nepremazana rockwool ploča.",
        "Dostupna u pakovanjima.",
        "Područja upotrebe",
        "Ispod estriha između dva sloja betona.",
        "Na podovima iznad otvorenih prolaza.",
        "Ispod postolja izvora vibracija.",
        "Koristi se u podovima zgrada.",
        "PRIMENA",
        "Nakon što je pod koji se primenjuje izolovan od vlage, ploče se postavljaju slobodno.",
        "Nakon što je estrih izliven, primena se završava željenim podnim premazom."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10249,
      "name": "Pregradna ploča",
      "images": [
        "/uploads/3_8.jpg"
      ],
      "details": [
        "OPIS PROIZVODA",
        "To je nepremazana rockwool ploča.",
        "Prodaje se u pakovanjima.",
        "Područja upotrebe",
        "U liftovima i stepeništima.",
        "U pregradnim zidovima.",
        "Koristi se na susednim zidovima.",
        "PRIMENA",
        "Obezbeđuje toplotnu, zvučnu i vatrootpornu izolaciju postavljanjem između nosivih struktura i pregradnih zidova."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10250,
      "name": "Spoljašnja toplotna izolaciona ploča",
      "images": [
        "/uploads/diilevhasi-k.jpg"
      ],
      "details": [
        "OPIS PROIZVODA",
        "To je rockwool ploča prekrivena aluminijumskom folijom ili staklenim vlaknima s jedne strane.",
        "Nudi se tržištu u pakovanjima.",
        "Područja upotrebe",
        "Obezbeđuje toplotnu, zvučnu i vatrootpornu izolaciju na granitnim, aluminijumskim, mramornim ili staklenim fasadnim oblogama.",
        "Obezbeđuje vatrootpornu izolaciju na ventilacionim površinama.",
        "PRIMENA",
        "Postavlja se između nosivih profila na fasadnim površinama zahvaljujući opremi za učvršćivanje.",
        "Montira se na fasadni zid pomoću klinova."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10251,
      "name": "Spoljašnji toplotni izolacioni sistem Rockwool ploča",
      "images": [
        "/uploads/2_10.jpg"
      ],
      "details": [
        "OPIS PROIZVODA",
        "Prelaskom vlakana u rockwool ploči formira se složena struktura vlakana, koja stvara otpornost na visoki pritisak pri nižim gustinama.",
        "Pletena struktura vlakana čini aplikaciju obloge na površini rockwool-a izdržljivijom.",
        "Na taj način povećava se stopa nošenja fizičkog opterećenja na strukturi.",
        "Područja upotrebe",
        "Koristi se ispod maltera na spoljnim fasadama za toplotnu i zvučnu izolaciju i vatrogasnu sigurnost.",
        "PRIMENA",
        "Na glatkim površinama, ploče se lepe pomoću maltera na bazi cementa."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10245,
      "name": "Vodena izolacija",
      "images": [
        "/uploads/1_3.jpg"
      ],
      "details": [],
      "children": [
        10252,
        10253,
        10254,
        10255,
        10256,
        10257,
        10258,
        10259,
        10260
      ],
      "measurements": []
    },
    {
      "id": 10252,
      "name": "GARDENSTOPER",
      "images": [
        "/uploads/9_3.jpg"
      ],
      "details": [
        "GardenStopper serija, koja se može modifikovati sa APP ili SBS, su posebno dizajnirane bitumenske vodootporne membrane koje obezbeđuju otpornost na biljke u vrtovima i terasama sa svojom posebnom formulacijom aditiva koja sprečava rast korena biljaka.",
        "Područja upotrebe",
        "Vodootporna zaštita vrta i terase.",
        "Prednosti",
        "Obezbeđuje otpornost na biljke sa svojom formulacijom sa posebnim dodacima koji sprečavaju rast korena biljaka za udobne vrtne terase.",
        "Karakteristike",
        "Jedinična: Garden +PP3, Garden +PP4, Garden EP3, Garden EP4.",
        "Nosilac: Poliesterski za sve varijante.",
        "Debljina: 3 mm (Garden +PP3, Garden EP3), 4 mm (Garden +PP4, Garden EP4).",
        "Bitumenska struktura: APP (Garden +PP3, Garden +PP4), SBS (Garden EP3, Garden EP4).",
        "Otpornost na temperaturu: 120°C (Garden +PP3, Garden +PP4), 100°C (Garden EP3, Garden EP4).",
        "Hladno savijanje: -10°C (Garden +PP3, Garden +PP4), -20°C (Garden EP3, Garden EP4).",
        "Otpornost na istezanje (Dužina/Širina): 800/600 N/5cm za sve varijante.",
        "Produžetak pri lomu (Dužina/Širina): 35%/35% za sve varijante.",
        "Premium premaz: PE za sve varijante.",
        "Niski nivo premaza: PE za sve varijante."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10253,
      "name": "PLASTOPER",
      "images": [
        "/uploads/8_3.jpg"
      ],
      "details": [
        "APP (Ataktički polipropilen) dodatni polimerni bitumenski pokrivači.",
        "Područja upotrebe",
        "Krovne i temeljne primene.",
        "Prednosti",
        "Ima visoku izdržljivost i dug vek trajanja.",
        "Bitumenska struktura sa plastičnim svojstvima.",
        "Visok polimerni dodatak.",
        "Visoka toplotna otpornost.",
        "Otpornost na hladno savijanje -5°C, otpornost na temperaturu +120°C.",
        "Specifikacije",
        "Jedinična: PG2, PG3, PP3, PP4, PP40S, PP50S, PP3AL.",
        "Nosilac: Stakloplastika (PG2, PG3), Poliesterski (PP3, PP4, PP40S, PP50S, PP3AL).",
        "Debljina: 2 mm (PG2), 3 mm (PG3, PP3, PP3AL), 4 mm (PP4), 3.5 mm (PP40S), 4.5 mm (PP50S).",
        "Bitumenska struktura: APP za sve varijante.",
        "Otpornost na temperaturu: +120°C za sve varijante.",
        "Hladno savijanje: -5°C za sve varijante.",
        "Otpornost na istezanje (Dužina/Širina): 300/200 N/5cm (PG2, PG3), 600/400 N/5cm (PP3, PP4, PP40S, PP50S, PP3AL).",
        "Produžetak pri lomu (Dužina/Širina): 2%/2% (PG2, PG3), 30%/30% (PP3, PP4, PP40S, PP50S, PP3AL).",
        "Premium premaz: PE (PG2, PG3, PP3, PP4), Škriljac (PP40S, PP50S), AL (PP3AL).",
        "Niski nivo premaza: PE za sve varijante."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10254,
      "name": "+PLASTOPER",
      "images": [
        "/uploads/7_2.jpg"
      ],
      "details": [
        "APP (Ataktički polipropilen) dodatni polimerni bitumenski pokrivači.",
        "Područja upotrebe",
        "Sve primene na krovovima i temeljima",
        "Prednosti",
        "Ima visoku izdržljivost i dug vek trajanja.",
        "Bitumenska struktura sa plastičnim svojstvima",
        "Visokopolimerni aditiv",
        "Izvrsna otpornost na toplotu i hladnoću",
        "Otpornost na savijanje na hladnoći -10°C, otpornost na temperaturu +120°C",
        "Karakteristike",
        "Jedinična +PG2 +PG3 +PP3 +PP4 +PP40 S +P50 S +PP3AL",
        "Nosilac",
        "Stakleni vlakna Stakleni vlakna Poliesterski Poliesterski Poliesterski Poliesterski Poliesterski",
        "Debljina mm",
        "2 3 3 4 3.5 4.5 3",
        "Bitumenska struktura",
        "APP APP APP APP APP APP",
        "Otpornost na temperaturu °C",
        "120 120 120 120 120 120 120",
        "Savijanje na hladnoći °C",
        "-10 -10 -10 -10 -10 -10 -10",
        "Otpornost na istezanje (Dužina/Širina) N/5cm",
        "400/300 400/300 800/600 800/600 800/600 800/600 800/600",
        "Produžetak pri lomu (Dužina/Širina) %",
        "2/2 2/2 35/35 35/35 35/35 35/35 35/35",
        "Premium premaz",
        "PE PE PE PE Slate Slate AL",
        "Niski nivo premaza",
        "PE PE PE PE PE PE"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10255,
      "name": "ELASTOPER",
      "images": [
        "/uploads/6_3.jpg"
      ],
      "details": [
        "SBS (Stiren-butadien-stiren) dodatni polimerni bitumenski pokrivači.",
        "Područja upotrebe",
        "Sve primene na krovovima i temeljima",
        "Prednosti",
        "Ima visoku fleksibilnost i dug vek trajanja.",
        "Podržan je visokotlačnim poliesterskim nosačem.",
        "Visokoelastomerni polimerni aditiv.",
        "Visokopolimerni aditiv.",
        "Izvrsna otpornost na hladnoću i toplotu.",
        "Specifikacije",
        "Jedinična: EG2, EG3, EP3, EP4, EP40S, EP50S, EP3AL.",
        "Nosilac",
        "Stakleni vlakna (EG2, EG3), Poliesterski (EP3, EP4, EP40S, EP50S, EP3AL).",
        "Debljina mm",
        "2 (EG2), 3 (EG3, EP3, EP3AL), 4 (EP4), 3.5 (EP40S), 4.5 (EP50S).",
        "Bitumenska struktura",
        "SBS za sve varijante.",
        "Otpornost na temperaturu °C",
        "100 za sve varijante.",
        "Savijanje na hladnoći °C",
        "-20 za sve varijante.",
        "Otpornost na istezanje (Dužina/Širina) N/5cm",
        "400/300 (EG2, EG3), 800/600 (EP3, EP4, EP40S, EP50S, EP3AL).",
        "Produžetak pri lomu (Dužina/Širina) %",
        "2/2 (EG2, EG3), 35/35 (EP3, EP4, EP40S, EP50S, EP3AL).",
        "Premium premaz",
        "PE (EG2, EG3, EP3, EP4), Slate (EP40S, EP50S), AL (EP3AL).",
        "Niski nivo premaza",
        "PE za sve varijante."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10256,
      "name": "JOINTSTOPER",
      "images": [
        "/uploads/5_3.jpg"
      ],
      "details": [
        "RAVAPROOF jointSTOPER je elastomerna bitumenska podloga, ojačana poliesterskim filcem, sa fleksibilnom aluminijumskom folijom u sredini, prekrivena polietilenskom folijom na donjoj površini, i može se primeniti na svim horizontalnim i vertikalnim dilatacionim linijama.",
        "OSOBINE",
        "Zasnovan je na visokom SBS dodatnom elastomeru.",
        "Prekriven je fleksibilnom aluminijumskom folijom širine 20 cm u centru gornje površine i zaštićen je silikonskom PP folijom.",
        "Širina je 50 cm i posebno je dizajniran za dilatacije.",
        "Donja površina je prekrivena PE folijom.",
        "Podržan je visokotlačnim poliesterskim nosačem.",
        "PREDNOSTI",
        "Koeficijent produženja pri lomu je 40% i dizajniran je za upotrebu u svim oblastima kao što su pod, zid, podzemno itd.",
        "Zahvaljujući svojoj ultra-fleksibilnoj formuli, pruža izvrsnu adheziju.",
        "Sa svojom širinom od 50 cm, može se koristiti čak i u vrlo širokim otvorima.",
        "Dok fleksibilna aluminijska folija u sredini služi kao vodič za centriranje linije u montaži, oštećenje vode koja će se akumulirati u omega na površini je minimizirano zbog načina montaže."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10257,
      "name": "STICKYSTOPER XL",
      "images": [
        "/uploads/4_4.jpg"
      ],
      "details": [
        "Sigurna, samolepljiva vodonepropusna membrana sa specijalnom križno laminiranom folijom, dizajnirana da odgovara tradicionalnim vodonepropusnim primenama.",
        "Lako se skida, sa glatkim dizajnom za lepljenje",
        "Izvrsna otpornost na kidanje i udarce sa jednim slojem",
        "Visoka adhezija",
        "Brza, čista i laka primena u jednom potezu",
        "Ušteda vremena, troškova i rada",
        "Ekstra lagani rolni",
        "Visoka otpornost na hemikalije",
        "Zahvaljujući svojoj ultra-fleksibilnoj, samolepljivoj specijalnoj formuli, pruža savršenu i kontinuiranu adheziju na površini primene.",
        "Zahvaljujući višeslojnoj, visoke gustine križno laminiranoj HDPE foliji na gornjoj površini, pokazuje visoku otpornost na kidanje i udarce.",
        "OSOBINE",
        "Bitumenska struktura: Samolepljiva, dugotrajna elastomerna membrana dizajnirana sa visokim sadržajem SBS i specijalnim hemijskim aditivom za lepljenje.",
        "Nosilac: Netkani poliesterski filc ili stakleni vlakna.",
        "Gornja površina: Križno laminirana HDPE folija.",
        "Donja površina: Skidajuća silikonska PP folija.",
        "PREDNOSTI",
        "Štedi vreme sa brzim i lakim nanošenjem. Dok se očekuje vreme sušenja od 1 dana za svaki sloj rasprostranjenih izolacionih materijala, stickySTOPER XL pruža trenutnu adheziju i štedi mnogo vremena.",
        "Samolepljiv je, folija se skida i lepi na površinu.",
        "Pruža laku i sigurnu primenu sa ekstra laganim valjcima.",
        "Može se proizvoditi sa poliesterskim ili staklenim vlaknima u zavisnosti od potrebe.",
        "Zahvaljujući križno laminiranoj HDPE foliji, pruža izvrsnu otpornost na udarce i kidanje. U slučaju probijanja i kidanja na površini membrane iz bilo kog razloga, budući da membrana pruža potpunu adheziju na površinu, nije moguće da voda putuje ispod membrane.",
        "Zahvaljujući specijalnoj foliji na gornjoj površini, pruža visoku otpornost na hlor, sulfat, alkalije i kiseline. Otpornosti na UV zrake.",
        "Silikonska PP folija na donjoj površini može se lako skinuti i hladno zalepiti.",
        "Štedi vreme, rad i troškove jer ne zahteva primenu plamena."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10258,
      "name": "STOPERPROOF V3MM",
      "images": [
        "/uploads/3_4.jpg"
      ],
      "details": [
        "STOPERproof V 3MM je dizajniran za vodonepropusne primene sa jednim slojem u vertikalnom obliku.",
        "Jedan sloj",
        "Lako se skida i lepi dizajn",
        "Superiorna otpornost na kidanje i udarce",
        "Laka i brza primena u jednom potezu",
        "Ušteda vremena, troškova i rada",
        "Zahvaljujući svojoj ultra-fleksibilnoj, elastomernoj specijalnoj formuli, pruža izvrsnu i kontinuiranu adheziju na sveže izliveni beton.",
        "Sa svojim kompozitnim poliesterskim filcem, pokazuje superiornu otpornost na prodorne elemente i udarce.",
        "STOPERproof V3MM olakšava vodonepropusnost vertikalnog jednog lica."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10259,
      "name": "BONDSTOPER",
      "images": [
        "/uploads/2_4.jpg"
      ],
      "details": [
        "RAVAPROOF bondSTOPER je elastomerna bitumenska podloga, ojačana TPU-om kao i dodatnim SBS aditivima, povećanim koeficijentom fleksibilnosti i produženim vekom trajanja, ojačana netkanim poliesterskim vlaknima, silika peskom / škriljcem sa zaštitnom površinom; To je nova generacija vodonepropusne membrane, čija je donja strana prekrivena polietilenskom folijom.",
        "Dizajniran je za upotrebu kao jednoslojni u novim terasama i renovacijama terasa.",
        "Rešenje jednoslojnog pokrivača u novim terasama i renovacijama terasa",
        "Brza, kontinuirana i potpuna adhezija",
        "Savršeno zaptivanje",
        "Uštede na radu i vremenu primene",
        "Dug vek trajanja materijala",
        "OSOBINE",
        "Jednoslojni se koristi u novim terasama i renovacijama terasa.",
        "Ima elastomerne bitumenske strukture podržane TPU polimerom kao i dodatnim SBS aditivom.",
        "Zahvaljujući svojoj specijalnoj formuli, brzo se topi i pruža trenutnu i savršenu adheziju na preklapanju i površini primene.",
        "Ima visoku fleksibilnost i dug vek trajanja.",
        "Podržan je visokotlačnim poliesterskim nosačem.",
        "Držanjem poliesterskog nosača bliže površini, debljina pulpe na dnu je povećana."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10260,
      "name": "MAXSTOPER",
      "images": [
        "/uploads/1_2.jpg"
      ],
      "details": [
        "RAVAPROOF MaxSTOPER je nova generacija vodonepropusne membrane zasnovane na elastomeru, sa dodatnim specijalnim polimerima i SBS aditivima, povećanom krhkosti na hladnoći (-25°C) i produženim vekom trajanja, ojačana netkanim poliesterskim filcem, silika peskom na gornjoj površini i polietilenskom folijom na donjem delu.",
        "Jedan sloj",
        "Savršeno zaptivanje",
        "Ekstra lagani rolni",
        "1000% produživi SBS modifikovani bitumen",
        "Uštede na radu i vremenu primene",
        "Dug vek trajanja materijala",
        "OSOBINE",
        "Ima elastomerne bitumenske strukture sa visokim SBS i specijalnim polimernim aditivima.",
        "Ultra fleksibilan je i ima dug vek trajanja.",
        "Gornji premaz je silika pesak. Donja površina je prekrivena PE folijom.",
        "Ima otpornost na krhkost i fleksibilnost na vrlo niskim temperaturama (-25 C).",
        "Podržan je visokotlačnim poliesterskim nosačem."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10246,
      "name": "RAVATHERM XPS - Termalna izolacija",
      "images": [
        "/uploads/1_2.jpg"
      ],
      "details": [],
      "children": [
        10261,
        10262,
        10263,
        10264
      ],
      "measurements": []
    },
    {
      "id": 10261,
      "name": "RAVATHERM XPS T 300 SL",
      "images": [
        "/uploads/4_3.jpg"
      ],
      "details": [
        "Sve vrste konstrukcije ili popravke krovova terasa",
        "Krovovi terasa i podovi sa saobraćajem vozila",
        "Temeljni zidovi u kontaktu sa tlom",
        "Hladnjače",
        "Budući da su materijali za termalnu izolaciju korišćeni u krovovima terasa i zidovima temelja u kontaktu sa tlom stalno izloženi štetnim efektima vlage, pritiska tla i podzemnih voda, proizvodi koji se koriste u ovim detaljima moraju imati karakteristike prikladne za ove uslove.",
        "RAVATHERM XPS T 300 SL proizvodi trajno održavaju svoje izolacione osobine čak i u slučaju kontinuiranih mehaničkih opterećenja pored efekata vlage, pritiska tla, podzemnih voda.",
        "Svi RAVATHERM proizvodi proizvedeni u Dilovası postrojenju Dow Bina rešenja proizvedeni su u skladu sa EN 13164 standardom i nose CE oznaku."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10262,
      "name": "RAVATHERM XPS T 200 SL",
      "images": [
        "/uploads/3_3.jpg"
      ],
      "details": [
        "Za termalnu izolaciju podova zgrada",
        "Za izolaciju nezavisnih delova zgrade koji se koriste u različitim vremenima",
        "U radovima renovacije termalne izolacije",
        "Za zaštitu struktura u sektoru stočarstva"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10263,
      "name": "RAVATHERM XPS T 200 PB",
      "images": [
        "/uploads/2_3.jpg"
      ],
      "details": [
        "Unutrašnja izolacija zidova",
        "U nekim slučajevima, spoljašnja izolacija konstrukcije možda neće biti moguća.",
        "Stoga se potrebna korist može dobiti unutrašnjom izolacijom zidova.",
        "Pruža važnu prednost posebno za konstrukcije koje se ne koriste često i koje nisu stalno grejane, a te konstrukcije se mogu grejati uz minimalnu potrošnju energije.",
        "Zahvaljujući svojoj gruboj površini, čini pogodnu površinu za cementne ili gipsane maltere ili za završne obloge (gipsane ploče itd.)."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10264,
      "name": "RAVATHERM XPS T SHAPE L",
      "images": [
        "/uploads/1_1.jpg"
      ],
      "details": [
        "U spoljašnjoj termalnoj izolaciji zgrada",
        "Sa spoljašnjom termalnom izolacijom zidova, spoljašnja ovojnica zgrade će ostati na toploj strani, čime se smanjuju troškovi popravke i održavanja i produžava život zgrade.",
        "Pošto spoljašnja termalna izolacija potpuno okružuje zgradu, sprečava gubitak toplote i obezbeđuje udobne uslove života sa vlažnom i homogenu raspodelu toplote."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10014,
      "name": "Bonus izolacija",
      "images": [
        "/uploads/bonus-yalitim.jpg"
      ],
      "details": [],
      "children": [
        10265,
        10266,
        10267
      ],
      "measurements": []
    },
    {
      "id": 10265,
      "name": "Bonus kamena vuna",
      "images": [
        "/uploads/1_6.jpg"
      ],
      "details": [],
      "children": [
        10268,
        10269,
        10270,
        10271,
        10272,
        10273,
        10274,
        10275,
        10276,
        10277,
        10278,
        10279
      ],
      "measurements": []
    },
    {
      "id": 10268,
      "name": "BONUS kamena vuna Marine",
      "images": [
        "/uploads/14.jpg"
      ],
      "details": [
        "BONUS kamena vuna Marine serija je madrac i ploča od kamene vune koja se posebno proizvodi za izolaciju brodova u gustinama od 45, 60, 110, 125, 140, 150 kg/m³.",
        "Kao A1 klasa, koristi se za otpornost na vatru i termalnu izolaciju u brodskim aplikacijama.",
        "Pored toga, zahvaljujući svojoj paropropusnosti i apsorpciji vibracija, produžava život broda.",
        "Koristi se u brodskim i pomorskim konstrukcijama, zidovima od čamca, protivpožarnim sekcijama, protivpožarnim vratima i unutrašnjim izolacijama brodova, pećima, kotlovima i visokotemperaturnim područjima kao što su dimnjaci."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10269,
      "name": "BONUS kamena vuna Panel",
      "images": [
        "/uploads/122.jpg"
      ],
      "details": [
        "Preferira se u panel sistemima primenjenim u zgradama koje zahtevaju visoku otpornost na vatru klase A1.",
        "Automatski se seče koristeći ekskluzivne sisteme, a otpornost na pritisak obezbeđena je vertikalnim rasporedom vlakana na površini panela.",
        "Obezbeđujući lakoću primene u proizvodnji panela, BONUS kamena vuna Panel je otporna na rast plesni, bakterija i neželjenih organizama.",
        "Koristi se u hangarima za avione, tržnim centrima, obučnim objektima, vojnim strukturama, silosima, teretanama, zatvorenim bazenima, montažnim gradilištima, hladnjačama, krovovima kuća i skladištima."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10270,
      "name": "BONUS kamena vuna Vrata",
      "images": [
        "/uploads/11_1.jpg"
      ],
      "details": [
        "Ploča visoke gustine koja se koristi za proizvodnju protivpožarnih vrata."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10271,
      "name": "BONUS kamena vuna Fleksibilna Alu",
      "images": [
        "/uploads/10.png"
      ],
      "details": [
        "BONUS kamena vuna Fleksibilna Alu ploča cevi se proizvodi u gustini od 100 kg/m3 i debljini od 25 mm sa dimenzijama 950 mm x 8400 mm.",
        "Ova ploča od kamene vune sa aluminijumskom folijom koristi se u termalnoj izolaciji dimnjaka i visokotemperaturnih industrijskih cevi.",
        "Zahvaljujući aluminijumskoj foliji, kanalisanoj strukturi i prilagođenoj veličini (određenoj analizom područja primene), primenjuje se u dimnjacima i visokotemperaturnim industrijskim cevima sa bilo kojim radijusom u opsegu od 100-300 mm."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10272,
      "name": "Bonus kamena vuna Industrijski Batt",
      "images": [
        "/uploads/8_4.jpg"
      ],
      "details": [
        "Bonus kamena vuna Industrijski Batt se proizvodi kao neprekriven u gustinama od 70 i 110 kg/m3.",
        "Ova ploča od kamene vune koristi se u izolaciji industrijskih objekata.",
        "BONUS kamena vuna Industrijski Batt se koristi za termalnu, akustičnu i protivpožarnu izolaciju ravnih površina koje zahtevaju otpornost na visoke temperature u industrijskim objektima, i apsorbuje moguće vibracije u procesima i procesnim elementima zahvaljujući svojoj fleksibilnoj prirodi.",
        "Koristi se u ekstremno visokim temperaturama i protivpožarnim izolacijama u čeličnim konstrukcijama, procesnim uređajima (kao što su kotlovi, elektronski filteri, kanali, dvostruki kontejneri, sušare) i gotovim panel aplikacijama u svim vrstama industrijskih objekata."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10273,
      "name": "BONUS kamena vuna Privatna",
      "images": [
        "/uploads/7_3.jpg"
      ],
      "details": [
        "BONUS kamena vuna Privatna se proizvodi kao aluminijumski prekrivena sa gustinom od 70 kg/m3.",
        "Ova ploča od kamene vune koristi se u klima uređajima i ventilacionim kanalima.",
        "Zahvaljujući svom izuzetno niskom koeficijentu toplotne provodljivosti, sprečava povećanje temperature koje nastaje usled klima uređaja ili opterećenja, i sprečava da ova oprema prenese moguću vatru unutra ili napolje.",
        "Zahvaljujući svojoj paropropusnosti, takođe sprečava oštećenje metalnih klima uređaja i opreme usled kondenzacije.",
        "BONUS kamena vuna Privatna serija se koristi u klima uređajima i ventilacionim kanalima.",
        "Može se proizvoditi u prilagođenim veličinama do 2,5 cm."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10274,
      "name": "BONUS Stiropor Platin",
      "images": [
        "/uploads/6_5.jpg"
      ],
      "details": [
        "BONUS Stiropor Platin je izolacijska ploča za podove koja se koristi između spratova i ispod građevinskih podova i vibrirajućih osnovnih ploča.",
        "BONUS Stiropor Platin pruža savršenu toplotnu izolaciju, a plutajuća karakteristika sprečava širenje udarnih zvukova na celu zgradu kroz nosive grede i stubove.",
        "BONUS Stiropor Platin, potpuno vatrootporan materijal klase A1, ne stvara dim ili čestice čak ni kada je izložen otvorenom plamenu.",
        "Zahvaljujući svojoj alternativi debljine 2 cm, može se koristiti na podovima između spratova, podovima na otvorenim prelazima, građevinskim podovima i ispod vibrirajućih osnovnih ploča."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10275,
      "name": "Bonus Premium R",
      "images": [
        "/uploads/5_5.jpg"
      ],
      "details": [
        "Premium R i Premium R 150 serije se proizvode kao krovne ploče za terase; mogu zadovoljiti sve vrste potreba u vezi sa toplotnom i akustičnom izolacijom i protivpožarnom sigurnošću i mogu se primeniti na sve vrste krovova.",
        "Budući da se mogu proizvoditi i tanki, mogu se koristiti kao dva sloja, ako je potrebno.",
        "Mogu se koristiti na metalnim i drvenim krovovima sa bilo kojim nagibom, a mogu se primeniti i na krovovima terasa koji su prohodni ili neprohodni."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10276,
      "name": "Bonus Stiropor Premium F",
      "images": [
        "/uploads/4_6.jpg"
      ],
      "details": [
        "Premium F, Premium F 120 i Premium F 150 su tri serije proizvedene posebno za spoljašnje sistemske obloge.",
        "Pogodan je za područja podložna udarcima zahvaljujući svojim svojstvima otpornim na vodu, visokoj gustini i vertikalnoj čvrstoći na zatezanje.",
        "Bonus Stiropor Premium F serije nude odličnu otpornost na toplotu i dobru apsorpciju zvuka za obloge.",
        "Mogu se koristiti na metalnim i drvenim krovovima sa bilo kojim nagibom, a mogu se primeniti i na krovovima terasa koji su prohodni ili neprohodni."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10277,
      "name": "Bonus Stiropor Gold i Gold Plus",
      "images": [
        "/uploads/3_7.jpg"
      ],
      "details": [
        "Bonus Stiropor Gold i Gold Plus se proizvode u gustinama od 50, 70 i 90 kg/m3 kao crni stakleni vlakna, bela stakleni vlakna, žuta stakleni vlakna, aluminijumska folija obložena ili neobložena.",
        "Koriste se za spoljašnje zavese zidove sa ili bez ventilacionih otvora.",
        "GOLD i GOLD PLUS su vodoodbojni i sprečavaju širenje vatre, tako da su klasifikovani kao A1.",
        "Ovi proizvodi poboljšavaju udobnost stanovanja eliminisanjem svih mogućih spoljašnjih zvukova zahvaljujući svojoj visokoj sposobnosti apsorpcije zvuka.",
        "Koriste se ispod aluminijumskih, PVC, granitnih, keramičkih i staklenih obloga."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10278,
      "name": "Bonus Stiropor Decibel",
      "images": [
        "/uploads/1tas-yunu_1.jpg"
      ],
      "details": [
        "Bonus Stiropor Decibel se proizvodi kao kompozit sa gustinom od 110kg/m3 sa gipsanom pločom sa jedne strane i aluminijumskom folijom u sredini.",
        "Ova ploča od stirodura pruža savršenu toplotnu izolaciju, akustičke performanse i otpornost na vatru.",
        "Bonus Stiropor Decibel uklanja bilo koji toplotni i zvučni most izolovanjem betonskih površina kao što su zavese zidovi pored površina koje stvaraju zidni elementi.",
        "Može se lako koristiti na svim strukturama bez obzira na to da li su novoizgrađene ili renovirane.",
        "Koristi se za unutrašnje površine spoljašnjih zidova, unutrašnje pregrade i susedne zidove, zidove pored stepeništa i liftova, kao i unutrašnje obloge drvenih konstrukcija."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10279,
      "name": "Bonus Membran Plus",
      "images": [
        "/uploads/1_6.jpg"
      ],
      "details": [
        "Membrana za hidroizolaciju od 3kg/m2 ili 4kg/m2 napravljena od APP-modifikovanog bitumena, sa staklenom tkaninom i poliesterskim filcem."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10266,
      "name": "Bonus Membran",
      "images": [
        "/uploads/1.png"
      ],
      "details": [
        "Membrana za hidroizolaciju od 3kg/m2 ili 4kg/m2 napravljena od APP-modifikovanog bitumena, sa staklenom tkaninom i poliesterskim filcem."
      ],
      "children": [
        10280,
        10281,
        10282,
        10283
      ],
      "measurements": []
    },
    {
      "id": 10280,
      "name": "Bonus Membran Pro",
      "images": [
        "/uploads/3.png"
      ],
      "details": [],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dubina (mm): 3,0",
            "Tip nosača: Glass Tissue",
            "Zatezna čvrstoća visina/širina (N/5 cm): 300/200",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dubina (mm): 3,0",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 500/300",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dubina (mm): 4,0",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 500/300",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dubina (mm): 4,0",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 600/400",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "5",
          "values": [
            "Dubina (mm): 3,0",
            "Tip nosača: Glass Tissue",
            "Zatezna čvrstoća visina/širina (N/5 cm): 300/200",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "6",
          "values": [
            "Dubina (mm): 3,0",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 500/300",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "7",
          "values": [
            "Dubina (mm): 4,0",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 500/300",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "8",
          "values": [
            "Dubina (mm): 3,0",
            "Tip nosača: Glass Tissue",
            "Zatezna čvrstoća visina/širina (N/5 cm): 300/200",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "9",
          "values": [
            "Dubina (mm): 3,0",
            "Tip nosača: Polyester",
            "Zatezna čvrstoća visina/širina (N/5 cm): 500/300",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "10",
          "values": [
            "Dubina (mm): 4,0",
            "Tip nosača: Polyester",
            "Zatezna čvrstoća visina/širina (N/5 cm): 500/300",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        }
      ]
    },
    {
      "id": 10281,
      "name": "Bonus Membran Premium",
      "images": [
        "/uploads/saaa.png"
      ],
      "details": [
        "To je elastična membrana za hidroizolaciju proizvedena od modifikovanog bitumena ojačanog SBS (Stiren-butadien-stiren).",
        "Zahvaljujući posebnoj mešavini, može ostati elastična čak i na vrlo niskim temperaturama i može održati prednosti savijanja tokom svog veka.",
        "Pruža idealnu izolaciju za visoko elastične, vibrirajuće i ekspanzivne krovove svojom strukturom."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dubina (mm): 2,0",
            "Tip nosača: Glass Tissue",
            "Zatezna čvrstoća visina/širina (N/5 cm): 400/300",
            "Dimenzije rolne širina x visina (mm): 1 x 15"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dubina (mm): 3,0",
            "Tip nosača: Glass Tissue",
            "Zatezna čvrstoća visina/širina (N/5 cm): 400/300",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dubina (mm): 3,0",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 800/600",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dubina (mm): 4,0",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 800/600",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "5",
          "values": [
            "Dubina (mm): 3,5",
            "Tip nosača: Glass Tissue",
            "Zatezna čvrstoća visina/širina (N/5 cm): 400/300",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "6",
          "values": [
            "Dubina (mm): 3,5",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 800/600",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        }
      ]
    },
    {
      "id": 10282,
      "name": "Bonus Membran Platin",
      "images": [
        "/uploads/1.png"
      ],
      "details": [],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dubina (mm): 2,0",
            "Tip nosača: Glass Tissue",
            "Zatezna čvrstoća visina/širina (N/5 cm): 400/300",
            "Dimenzije rolne širina x visina (mm): 1 x 15"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dubina (mm): 3,0",
            "Tip nosača: Glass Tissue",
            "Zatezna čvrstoća visina/širina (N/5 cm): 400/300",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dubina (mm): 3,0",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 800/600",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "4",
          "values": [
            "Dubina (mm): 4,0",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 800/600",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "5",
          "values": [
            "Dubina (mm): 3,5",
            "Tip nosača: Glass Tissue",
            "Zatezna čvrstoća visina/širina (N/5 cm): 400/300",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "6",
          "values": [
            "Dubina (mm): 3,5",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 800/600",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "7",
          "values": [
            "Dubina (mm): 3,0",
            "Tip nosača: Glass Tissue",
            "Zatezna čvrstoća visina/širina (N/5 cm): 400/300",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "8",
          "values": [
            "Dubina (mm): 3,0",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 800/600",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "9",
          "values": [
            "Dubina (mm): 3,0",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 800/600",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "10",
          "values": [
            "Dubina (mm): 4,0",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 800/600",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "11",
          "values": [
            "Dubina (mm): 4,0",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 1000/800",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "12",
          "values": [
            "Dubina (mm): 4,0",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 1000/800",
            "Dimenzije rolne širina x visina (mm): 1 x 10"
          ]
        }
      ]
    },
    {
      "id": 10283,
      "name": "Bonus Membran Plus",
      "images": [
        "/uploads/11.png"
      ],
      "details": [
        "To je visokokvalitetni izolacijski materijal od zaštitnog betona na temeljima i dubokim temeljima, armiranog betona sa potpunom hidratacijom."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Dubina (mm): 2",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 800/600",
            "Širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "2",
          "values": [
            "Dubina (mm): 3",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 1000/800",
            "Širina x visina (mm): 1 x 10"
          ]
        },
        {
          "key": "3",
          "values": [
            "Dubina (mm): 3",
            "Tip nosača: Polyester Felt",
            "Zatezna čvrstoća visina/širina (N/5 cm): 800/600",
            "Širina x visina (mm): 1 x 8"
          ]
        }
      ]
    },
    {
      "id": 10267,
      "name": "Bonus XPS",
      "images": [
        "/uploads/xps-gold.jpg"
      ],
      "details": [],
      "children": [
        10284,
        10285,
        10286,
        10287,
        10288,
        10289,
        10290,
        10291,
        10292
      ],
      "measurements": []
    },
    {
      "id": 10284,
      "name": "Bonus XPS Klinker",
      "images": [
        "/uploads/xps-klinker.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10285,
      "name": "Bonus XPS Panel",
      "images": [
        "/uploads/xps-private.jpg"
      ],
      "details": [
        "Bonus XPS Panel se koristi u sendvič krovovima i zidnim panelima, rashladnim kamionima, skladištima hladnog vazduha, hitnim skloništima, delovima profilnih sistema bez stakla i termički izolovanim sendvič pregradnim zidovima.",
        "Bonus XPS Panel se proizvodi debljine 18-25mm sa širinom 600-750mm i maksimalnom dužinom 1050-3050mm.",
        "Tip proizvoda: BONUS XPS PANEL",
        "Kvalitet površine: Grub, fino kanalisan, bez preklapanja",
        "Širina x Visina: (600-50) - (1050 - 3050)",
        "Dubina: 18 - 25mm",
        "Otpornost na pritisak: 100 - 150 kPa",
        "Koeficijent toplotne provodljivosti λD (W/m.K): 0.032 (18, 19mm), 0.033 (20-25mm)",
        "Toplotna otpornost RD (m2.K/W): 0.56 (18mm), 0.59 (19mm), 0.60 (20mm), 0.63 (21mm), 0.65 (22mm), 0.70 (23mm), 0.75 (25mm)",
        "Normalna otpornost na istezanje (kPa): 400"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10286,
      "name": "Bonus XPS Panel",
      "images": [
        "/uploads/xps-panel.jpg"
      ],
      "details": [
        "Bonus XPS Panel se koristi u sendvič krovovima i zidnim panelima, rashladnim kamionima, skladištima hladnog vazduha, hitnim skloništima, delovima profilnih sistema bez stakla i termički izolovanim sendvič pregradnim zidovima.",
        "Bonus XPS Panel se proizvodi debljine 18-25mm sa širinom 600-750mm i maksimalnom dužinom 1050-3050mm.",
        "Tip proizvoda: BONUS XPS PANEL",
        "Kvalitet površine: Grub, fino kanalisan, bez preklapanja",
        "Širina x Visina: (600-50) - (1050 - 3050)",
        "Dubina: 18 - 25mm",
        "Otpornost na pritisak: 100 - 150 kPa",
        "Koeficijent toplotne provodljivosti λD (W/m.K): 0.032 (18, 19mm), 0.033 (20-25mm)",
        "Toplotna otpornost RD (m2.K/W): 0.56 (18mm), 0.59 (19mm), 0.60 (20mm), 0.63 (21mm), 0.65 (22mm), 0.70 (23mm), 0.75 (25mm)",
        "Normalna otpornost na istezanje (kPa): 400"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10287,
      "name": "Bonus XPS Premium",
      "images": [
        "/uploads/xps-premium.jpg"
      ],
      "details": [
        "Bonus XPS Premium, toplotnoizolacione ploče se koriste u unutrašnjim izolacionim aplikacijama, i proizvode se u veličini 600mm x 2650mm sa debljinama od 30mm do 120mm.",
        "Tip proizvoda: BONUS XPS PREMIUM",
        "Kvalitet površine: Grub, kanalisan, preklapanje",
        "Širina x Visina: 600 x 2650",
        "Dubina: 30 - 120mm",
        "Otpornost na pritisak: 200 - 300 kPa",
        "Koeficijent toplotne provodljivosti λD (W/m.K): 0.033 (30-50mm), 0.036 (60-120mm)",
        "Toplotna otpornost RD (m2.K/W): 0.90 (30mm), 1.20 (40mm), 1.50 (50mm), 1.80 (60mm), 1.95 (70mm), 2.20 (80mm), 2.50 (90mm), 2.80 (100mm), 3.30 (120mm)",
        "Normalna otpornost na istezanje (kPa): 400"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10288,
      "name": "Bonus XPS Pro Roof",
      "images": [
        "/uploads/xps-pro-cati.jpg"
      ],
      "details": [
        "Bonus XPS Pro Roof je toplotnoizolaciona ploča koja se koristi na krovovima i osnovnim zidovima za izolacione svrhe.",
        "Proizvodi se sa grubom površinom, kanalima, preklapajućim ivicama debljine između 30mm-50mm i u veličini 585mm x 2650mm.",
        "Tip proizvoda: BONUS XPS PRO-ČATI",
        "Kvalitet površine: Grub, kanalisan, preklapanje",
        "Širina x Visina: 585 x 1265",
        "Dubina: 30 - 50mm",
        "Otpornost na pritisak: 150 kPa",
        "Koeficijent toplotne provodljivosti λD (W/m.K): 0.036",
        "Toplotna otpornost RD (m2.K/W): 0.80 (30mm), 1.10 (40mm), 1.35 (50mm)",
        "Normalna otpornost na istezanje (kPa): 150"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10289,
      "name": "Bonus XPS Plus",
      "images": [
        "/uploads/xps-plus.jpg"
      ],
      "details": [
        "Bonus XPS Plus toplotnoizolacione ploče se fiksiraju kasnije na zidove, stubove i grede zgrade.",
        "Gruba površina i njeni kanali poboljšavaju prianjanje maltera i osiguravaju optimalnu primenu.",
        "Bonus XPS Plus toplotnoizolacione ploče se proizvode u veličini 600mm x 1200mm sa debljinama od 20mm do 150mm.",
        "**Opcija od 20mm se proizvodi u veličini 600mm x 1250mm.",
        "Tip proizvoda: BONUS XPS PLUS",
        "Kvalitet površine: Grub, kanalisan, preklapanje",
        "Širina x Visina: 600 x 1200",
        "Dubina: 20 - 150mm",
        "Otpornost na pritisak: 100 - 300 kPa",
        "Koeficijent toplotne provodljivosti λD (W/m.K): 0.033 (20mm), 0.033 (30mm), 0.034 (40mm), 0.034 (50mm), 0.034 (60mm), 0.036 (70mm), 0.036 (80mm), 0.036 (90mm), 0.036 (100mm), 0.036 (120mm), 0.036 (150mm)",
        "Toplotna otpornost RD (m2.K/W): 0.60 (20mm), 0.90 (30mm), 1.20 (40mm), 1.50 (50mm), 1.80 (60mm), 1.95 (70mm), 2.20 (80mm), 2.50 (90mm), 2.80 (100mm), 3.30 (120mm), 4.20 (150mm)",
        "Otpornost na pritisak (kPa): 400"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10290,
      "name": "Bonus XPS Pro-E",
      "images": [
        "/uploads/xps-pro-e.jpg"
      ],
      "details": [
        "Bonus XPS Pro-E toplotnoizolacione ploče se fiksiraju kasnije na zidove, stubove i grede zgrade.",
        "Površina sa reljefom i njeni žljebovi poboljšavaju prianjanje maltera i obezbeđuju optimalnu primenu.",
        "Bonus XPS Pro-E toplotnoizolacione ploče se proizvode u veličini 585mm x 1185mm sa debljinama od 30mm do 70mm.",
        "Tip proizvoda: BONUS XPS PRO-E",
        "Kvalitet površine: Reljef, kanalisan, preklapanje",
        "Širina x Visina: 585 x 1185",
        "Dubina: 30 - 70mm",
        "Otpornost na pritisak: 150 kPa",
        "Koeficijent toplotne provodljivosti λD (W/m.K): 0.036 (sve debljine)",
        "Toplotna otpornost RD (m2.K/W): 0.80 (30mm), 1.10 (40mm), 1.35 (50mm), 1.65 (60mm), 1.95 (70mm)",
        "Normalna otpornost na istezanje (kPa): 200"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10291,
      "name": "Bonus XPS - Platin",
      "images": [
        "/uploads/xps-platin.jpg"
      ],
      "details": [
        "Bonus XPS Platin toplotnoizolaciona ploča može se primeniti na grede bez korišćenja bilo koje krovne ploče na krovovima tipa vrt i krovovima sa nagibom kao površinama na kojima se može hodati jer se ne drobe.",
        "Bonus XPS Platin toplotnoizolacione ploče sa visokom otpornom snagom se proizvode sa glatkom površinom, bez preklapanja ivica u veličini 600mm x 1200mm sa debljinama od 30mm do 150mm.",
        "Tip proizvoda: BONUS XPS PLATIN",
        "Kvalitet površine: Glatka, preklapanje",
        "Širina x Visina: 600 x 1200",
        "Dubina: 30 - 150mm",
        "Otpornost na pritisak: 200 - 700 kPa",
        "Koeficijent toplotne provodljivosti λD (W/m.K): 0.028 (30mm), 0.032 (40mm, 50mm), 0.033 (60mm), 0.035 (70mm, 80mm, 90mm), 0.036 (100mm, 120mm, 150mm)",
        "Toplotna otpornost RD (m2.K/W): 1.10 (30mm), 1.25 (40mm), 1.60 (50mm), 1.80 (60mm), 2.00 (70mm), 2.30 (80mm), 2.60 (90mm), 2.80 (100mm), 3.30 (120mm), 4.20 (150mm)",
        "Otpornost na pritisak (kPa): 200 (30mm), 300 (40mm), 200 (50mm), 300 (60mm), 200 (70mm), 300 (80mm), 500 (90mm), 700 (100mm), 200 (120mm), 300 (150mm)"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10292,
      "name": "Bonus XPS Gold",
      "images": [
        "/uploads/xps-gold.jpg"
      ],
      "details": [
        "Bonus XPS Gold toplotna izolacijska ploča se koristi u vanjskom premazu i ispod obloge.",
        "Ivici Bonus XPS Gold toplotne izolacijske ploče primijenjene kompresijom između plastičnih, drvenih ili čeličnih okvira se ne preklapaju, su glatke i proizvode se u debljinama od 10mm do 150mm i u veličini od 600mm x 1200mm.",
        "Tip proizvoda: BONUS XPS GOLD",
        "Kvalitet površine: Glatka, bez preklapanja",
        "Širina x Visina: 600 x 1200",
        "Dubina: 10 - 150mm",
        "Kompresivna čvrstoća: 100 - 300 kPa",
        "Koeficijent toplotne provodljivosti λD (W/m.K): 0.028 (10mm, 20mm, 30mm), 0.032 (40mm, 50mm), 0.033 (60mm), 0.035 (70mm, 80mm, 90mm), 0.036 (100mm, 120mm, 150mm)",
        "Toplotna otpornost RD (m2.K/W): 0.35 (10mm), 0.70 (20mm), 1.10 (30mm), 1.25 (40mm), 1.60 (50mm), 1.80 (60mm), 2.00 (70mm), 2.30 (80mm), 2.60 (90mm), 2.80 (100mm), 3.30 (120mm), 4.20 (150mm)",
        "Kompresivna čvrstoća (kPa): 100 (10mm), 250 (20mm), 300 (30mm, 40mm, 50mm, 60mm, 70mm, 80mm, 90mm, 100mm, 120mm, 150mm)"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10015,
      "name": "Baumit",
      "images": [
        "/uploads/baumit.jpg"
      ],
      "details": [],
      "children": [
        10293,
        10294,
        10295,
        10296
      ],
      "measurements": []
    },
    {
      "id": 10293,
      "name": "Unutrašnji malteri",
      "images": [
        "/uploads/11.jpeg"
      ],
      "details": [],
      "children": [
        10297,
        10298,
        10299,
        10300,
        10301
      ],
      "measurements": []
    },
    {
      "id": 10297,
      "name": "Baumit Spritz",
      "images": [
        "/uploads/66.jpg"
      ],
      "details": [
        "To je proizvod na bazi cementa za pripremu površine (prethodno nanošenje) koji se koristi za povećanje prianjanja i ravnotežu upijanja površine prije nanošenja maltera.",
        "Može se koristiti u svim zgradama, unutrašnjim i vanjskim (uključujući vlažne prostore).",
        "Pogodan je za ciglu, aerirane betone, beton, izloženi beton, pumice zidove i plafone.",
        "Može se primijeniti ručno, molimo da se obratite Baumitu za detalje o mašinskoj primjeni.",
        "Visoka snaga zadržavanja",
        "Visoka sposobnost ravnoteže upijanja",
        "TSE sertifikovan"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10298,
      "name": "Baumit MVR Uni",
      "images": [
        "/uploads/55.jpg"
      ],
      "details": [
        "Jednoslojni vapneno/cementni malter za sve vanjske i unutrašnje prostore uključujući nekomercijalne kuhinje i kupatila, za ručnu i mašinsku primjenu.",
        "(Razjasniti mehaničku obradu sa laboratorijom)",
        "Beli vapneno/cementni malter",
        "Za vanjske i unutrašnje prostore",
        "Za ručnu i mašinsku primjenu"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10299,
      "name": "Baumit Manu 4",
      "images": [
        "/uploads/44.jpg"
      ],
      "details": [
        "To je fabrički miješani gotovi grubi malter koji je na bazi cementa, ima visoku propusnost i vodoodbojnost, može se vrlo dobro puniti svojom gustinom, i može se primijeniti ručno.",
        "Može se koristiti u svim strukturama, unutrašnjim i vanjskim.",
        "Pogodan je za ciglu, aerirane betone, beton, izloženi beton, pumice zidove i plafone.",
        "Izvrsna sposobnost punjenja",
        "Prednost skladištenja na paletama",
        "Jednostavnost pripreme i upotrebe u malim količinama"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10300,
      "name": "Baumit GlemaBrillant",
      "images": [
        "/uploads/22.jpeg"
      ],
      "details": [
        "To je beli cementni, fabrički miješani satenski kit sa visokom propusnošću i vodoodbojnošću, vrlo finom teksturom, koji se može primijeniti ručno.",
        "Može se koristiti u svim zgradama i unutrašnjim prostorima.",
        "Posebno se primenjuje na betonu, grubom malteru, finom malteru i mineralnim površinama.",
        "Koristi se za pripremu površine za boju, kako bi se dobila glatka površina.",
        "Glatka površina",
        "Veće prianjanje i trajnost od maltera",
        "Pogodno za vlažne prostore"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10301,
      "name": "Baumit BetonKontakt",
      "images": [
        "/uploads/11_1.jpeg"
      ],
      "details": [
        "To je proizvod na bazi organske materije, spreman za upotrebu, koji se koristi za povećanje prianjanja i ravnotežu upijanja površine prije nanošenja gipsanih i cementnih maltera.",
        "Može se primijeniti valjkom.",
        "Može se koristiti u svim zgradama, unutrašnjim prostorima (osim vlažnih područja).",
        "Koristi se za pripremu sjajnih površina kao što su beton i izloženi beton za primjenu gipsanih maltera.",
        "Brza i jednostavna primjena",
        "visoko prianjanje",
        "Priprema površine prije gipsanog maltera na sjajnim površinama"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10294,
      "name": "Unutrašnji zid Zdrav život",
      "images": [
        "/uploads/1.jpeg"
      ],
      "details": [],
      "children": [
        10302,
        10303,
        10304,
        10305,
        10306
      ],
      "measurements": []
    },
    {
      "id": 10302,
      "name": "Baumit KlimaWhite",
      "images": [
        "/uploads/5_2.jpeg"
      ],
      "details": [
        "To je fabrički miješani gotovi malter sa visokom propusnošću, može se primijeniti ručno ili mašinski, i ima sposobnost ravnoteže vlažnosti.",
        "Reguliše unutrašnji zrak i je propustan za zrak",
        "Prirodna bela",
        "Štetan i protiv plijesni"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10305,
      "name": "Baumit KlimaFinish",
      "images": [
        "/uploads/2_2.jpeg"
      ],
      "details": [
        "Malter na bazi vapna i cementa je pastozni prirodni bijeli malter na bazi vapna za unutrašnjost za postizanje visokokvalitetnih glatkih površina na podovima, betonu, gas betonu i gipsanim pločama.",
        "Spreman, visokokvalitetan i glatki površinski sloj",
        "Ne sadrži štetne tvari i sprečava plijesan",
        "Poboljšava klimatske uslove u prostoriji"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10306,
      "name": "Baumit KlimaDekor",
      "images": [
        "/uploads/1_1.jpeg"
      ],
      "details": [
        "Spreman za primjenu pastozni i vapneni dekorativni malter za unutrašnjost.",
        "Otvoren je za difuziju u visokom stepenu.",
        "Bez emisije i bez rastvarača, poboljšava klimatske uslove u prostoriji.",
        "To je fabrički miješani gotovi dekorativni malter sa visokom propusnošću i sposobnošću uravnoteženja vlage.",
        "Dimenzije zrna: 1 mm/1.5 mm/2 mm",
        "Visoka sposobnost disanja",
        "Sprečava rast plijesni",
        "Reguliše unutrašnji zrak"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10295,
      "name": "Eksterijerni malteri",
      "images": [
        "/uploads/im0034358.jpeg"
      ],
      "details": [],
      "children": [
        10307,
        10308,
        10309,
        10310,
        10311,
        10312,
        10313
      ],
      "measurements": []
    },
    {
      "id": 10307,
      "name": "Baumit Spritz",
      "images": [
        "/uploads/7_1.jpeg"
      ],
      "details": [
        "To je proizvod za pripremu površine na bazi cementa (prethodno nanošenje) koji se koristi za povećanje prianjanja i uravnoteženje upijanja površine prije nanošenja maltera.",
        "Može se koristiti u svim zgradama, unutrašnjim i vanjskim (uključujući vlažne prostore).",
        "Pogodan je za ciglu, aerirane betone, beton, izloženi beton, pumice zidove i plafone.",
        "Može se primijeniti ručno, molimo da se obratite Baumitu za detalje o mašinskoj primjeni.",
        "Visoka snaga držanja",
        "Visoka sposobnost uravnoteženja upijanja",
        "TSE sertifikovan"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10309,
      "name": "Baumit MVR Uni",
      "images": [
        "/uploads/5_1.jpeg"
      ],
      "details": [
        "Jednoslojni vapneno/cementni malter za sve eksterijere i sve unutrašnje prostore uključujući nekomercijalne kuhinje i kupatila, za ručnu i mašinsku primjenu.",
        "(Razjasniti mehaničku obradu sa laboratorijom)",
        "Bijeli vapneno/cementni malter",
        "Za eksterijere i unutrašnjost",
        "Za ručnu i mašinsku primjenu"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10310,
      "name": "Baumit MPA 35 L",
      "images": [
        "/uploads/4_1.jpeg"
      ],
      "details": [
        "Vapneno cementni malter sa laganim agregatom, za mašinsku i ručnu primjenu, eksterijere i unutrašnjost.",
        "Posebno za visoko izolacione zidne forme.",
        "Veličina zrna 2 mm.",
        "Proizvodnja",
        "Mašinska primjena",
        "Za visoko izolacione zidove"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10311,
      "name": "Baumit MPA 35",
      "images": [
        "/uploads/3_1.jpeg"
      ],
      "details": [
        "Tradicionalni vapneno/cementni mašinski malter.",
        "Za spoljašnje i unutrašnje.",
        "Ribani ili grubo strugani kao podmalter.",
        "Veličina zrna 2 mm.",
        "Čvrst",
        "Mašinska primjena",
        "Za normalnu zidanu konstrukciju"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10312,
      "name": "Baumit Manu 4",
      "images": [
        "/uploads/2_1.jpeg"
      ],
      "details": [
        "To je fabrički miješani gotovi grubi malter koji je na bazi cementa, ima visoku propusnost i vodoodbojnost, može se vrlo dobro puniti svojom gustu teksturom, i može se primijeniti ručno.",
        "Može se koristiti u svim strukturama, unutrašnjim i vanjskim.",
        "Pogodan je za ciglu, aerirane betone, beton, izloženi beton, pumice zidove i plafone.",
        "Izvrsna sposobnost punjenja",
        "Prednost skladištenja na paletama",
        "Jednostavnost pripreme i upotrebe u malim količinama"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10313,
      "name": "Baumit Manu 2",
      "images": [
        "/uploads/im0034358.jpeg"
      ],
      "details": [
        "To je malter na bazi cementa, fabrički miješani gotovi malter sa visokom propusnošću i vodoodbojnošću, koji se može puniti svojom grubozrnatom teksturom i može se primijeniti ručno.",
        "Može se koristiti u svim strukturama, unutrašnjim i vanjskim.",
        "Pogodan je za ciglu, aerirane betone, beton, izloženi beton, pumice zidove i plafone.",
        "Viša učinkovitost u poređenju sa mješavinom na gradilištu",
        "Minimalni gubitak proizvoda zahvaljujući pakovanju",
        "Ista kvaliteta u svakoj vreći"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10296,
      "name": "Eksterijerni sistemi toplotne izolacije",
      "images": [
        "/uploads/1_1.jpeg"
      ],
      "details": [],
      "children": [
        10314,
        10316,
        10317,
        10318,
        10319,
        10320,
        10321,
        10322,
        10323,
        10324,
        10325,
        10326,
        10327,
        10328,
        10329,
        10330,
        10331
      ],
      "measurements": []
    },
    {
      "id": 10314,
      "name": "Baumit StarTrack crveni",
      "images": [
        "/uploads/18.jpeg"
      ],
      "details": [
        "Koristi se tokom faze vezivanja termalne izolacije na postojećem starom malteru ili cigli, bez potrebe za dodatnim učvršćivanjem.",
        "Ne stvara toplotni most",
        "Nema tragova od klinova",
        "Obezbeđuje sigurno, kvalitetnije nanošenje"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10316,
      "name": "Baumit StarTrack narandžasti",
      "images": [
        "/uploads/16.jpeg"
      ],
      "details": [
        "Koristi se tokom faze vezivanja termalne izolacije na postojećem starom malteru i gas betonu, bez potrebe za dodatnim učvršćivanjem.",
        "Ne stvara toplotni most",
        "Nema tragova od klinova",
        "Obezbeđuje sigurno, kvalitetnije nanošenje"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10317,
      "name": "Baumit StarTrack plavi",
      "images": [
        "/uploads/15.jpeg"
      ],
      "details": [
        "Koristi se tokom faze vezivanja termalne izolacije na postojećem starom malteru i gas betonu, bez potrebe za dodatnim učvršćivanjem.",
        "Ne stvara toplotni most",
        "Nema tragova od klinova",
        "Obezbeđuje sigurno, kvalitetnije nanošenje"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10318,
      "name": "Baumit StarTex",
      "images": [
        "/uploads/14.jpeg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10319,
      "name": "Baumit StarContact bijeli",
      "images": [
        "/uploads/13.jpeg"
      ],
      "details": [
        "Adheziv i armirajući malter vrhunskog kvaliteta sa belim cementom, posebno za Baumit StarSystems.",
        "U skladu sa svim parametrima koje zahteva ETAG 004, sa visokim stepenom sigurnosti.",
        "Veoma fleksibilan, izuzetno otporan na vremenske uslove, sa odličnom obradivošću, garantuje dugotrajne performanse.",
        "Formulisan za zahtevne primene, vremenske uslove tokom obrade i zahtevne podloge.",
        "Fleksibilan",
        "Beli",
        "Završni sloj bez temeljnog premaza"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10320,
      "name": "Baumit StarTop",
      "images": [
        "/uploads/12.jpeg"
      ],
      "details": [
        "Visoko otporan na vremenske uslove fasadni malter sa silikonskim vezivom i novim funkcionalnim punilom.",
        "Svi novi Drypor efekat garantuje brzo sušenje fasadne površine nakon kiše, magle i kondenzacije.",
        "Brzo sušeće površine",
        "Povećana zaštita od algi",
        "Savršeno nanošenje"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10321,
      "name": "Baumit SilikonTop",
      "images": [
        "/uploads/11.jpeg"
      ],
      "details": [
        "Univerzalni fasadni malter na bazi pouzdane silikonske smole.",
        "Visoko propustan, izuzetno vodoodbojan, otporan na prljavštinu i univerzalno primenljiv.",
        "Otporan na vremenske uslove",
        "Paropropustan",
        "Vodoodbojan"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10322,
      "name": "Baumit PuraTop",
      "images": [
        "/uploads/10.jpeg"
      ],
      "details": [
        "Fasadni malter od visokokvalitetnog čistog akrila sa veoma dobrim svojstvima nanošenja.",
        "Koristi se kada su potrebne intenzivnije boje, za bogatu dugotrajnu završnu obradu.",
        "Neograničena paleta boja",
        "Intenzivne boje",
        "Tehnologija hladnih pigmenata"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10323,
      "name": "Baumit ProContact",
      "images": [
        "/uploads/9.jpeg"
      ],
      "details": [
        "Mineralni adheziv i armirajući malter za Baumit ProSystem u skladu sa ETAG 004.",
        "Fleksibilan, otporan na vremenske uslove, pogodan za upotrebu pod standardnim uslovima tokom primene.",
        "Posebno za ekonomski efikasna rešenja.",
        "Univerzalan",
        "Dugoročno odobren",
        "Ekonomski"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10324,
      "name": "Baumit PremiumPrimer",
      "images": [
        "/uploads/8.jpeg"
      ],
      "details": [
        "Pripremljen za upotrebu, univerzalno upotrebljiv temeljni premaz za izjednačavanje upijanja i deluje kao vezivno sredstvo za sve Baumit ETIC- i malterske sisteme.",
        "Dodati zrno olakšava primenu i strukturu svih završnih slojeva.",
        "Može se obojiti - pogledajte tehnički list proizvoda za detaljne informacije.",
        "Odličan izjednačavač upijanja",
        "Idealno vezivno sredstvo",
        "Za savršenu strukturu"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10325,
      "name": "Baumit PowerFlex",
      "images": [
        "/uploads/7.jpeg"
      ],
      "details": [
        "Spreman za upotrebu, bezcementni, vlaknima ojačani, organski osnovni premaz",
        "Posebno razvijen za Baumit PowerSystem zbog visoke otpornosti na udarce.",
        "U skladu sa svim zahtjevima ETAG 004.",
        "Visoko otporan na udarce",
        "Bez cementa",
        "Vlaknima ojačani"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10326,
      "name": "Baumit openTherm",
      "images": [
        "/uploads/6.jpeg"
      ],
      "details": [
        "Bijele, prozračne fasadne izolacijske ploče napravljene od EPS (prošireni polistiren) posebno dizajnirane za zidove od opeke i slične prozračne zidove sa vrlo dobrim svojstvima toplotne izolacije.",
        "Sistem komponenta Baumit open.",
        "Prozračne",
        "μ vrijednost ≤ 10",
        "Vodeći sistem toplotne izolacije",
        "Za lijepljenje i malterisanje preporučujemo korištenje Baumit OpenContact ljepila."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10327,
      "name": "Baumit NivoFix",
      "images": [
        "/uploads/5.jpeg"
      ],
      "details": [
        "To je ljepilo za ploče toplotne izolacije na bazi cementa sa visokom prozračnošću i visokom snagom prianjanja sa polimernim ojačanjima.",
        "Proizvod, koji je otporan na vodu, vlagu i udarce i pruža pogodnost u primjeni, može se primijeniti i mašinski.",
        "Koristi se za lijepljenje svih ploča toplotne izolacije i XPS ploča podrumskih ploča.",
        "ETAG 004 Certificiran",
        "Visoka snaga prianjanja",
        "Primjenjivo mašinski"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10328,
      "name": "Baumit NanoporTop",
      "images": [
        "/uploads/4.jpeg"
      ],
      "details": [
        "Samopročišćavajući, otporan na zagađenje, spreman za upotrebu vanjski premaz.",
        "Baumit fotokat tehnologija stvara maksimalnu zaštitu od zagađenja koristeći snagu svjetlosti.",
        "Na bazi minerala, visoko propusan za paru",
        "Zaštita i poboljšanje fasada i površina fasada preko starih i novih mineralnih žbuka i punila, na betonu, prilikom očuvanja historijskih spomenika, tokom renovacija i kao završni sloj sa Baumit sistemima vanjske toplotne izolacije open, KlimaFacade, EPS, Mineral i MineralSchaum i na renovacijskim žbukama.",
        "Samopročišćavajući",
        "Fotokat efekt",
        "Visoka izdržljivost"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10329,
      "name": "Baumit MineralTherm",
      "images": [
        "/uploads/3.jpeg"
      ],
      "details": [
        "To je mineralna vuna ploča toplotne izolacije.",
        "Njegova svojstva odbijanja vode su razvijena tako da se može sigurno koristiti na eksterijerima.",
        "Zahvaljujući svojoj vlaknatoj teksturi, savršeno diše i pogodna je za akustičke aranžmane.",
        "Pogodna je za vanjsku upotrebu, pod uvjetom da je prekrivena završnim slojem.",
        "Pruža toplotnu-zvučnu-vatrostalnu izolaciju",
        "odlična prozračnost"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10330,
      "name": "Baumit GranoporTop",
      "images": [
        "/uploads/2.jpeg"
      ],
      "details": [
        "Univerzalna, spremna za upotrebu akrilna fasadna boja, pogodna za većinu zahtjeva.",
        "Odbija vodu.",
        "Otporna na vremenske uvjete",
        "Propusna za paru",
        "Odbija vodu"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10331,
      "name": "Baumit Classico Special",
      "images": [
        "/uploads/1.jpeg"
      ],
      "details": [
        "To je bijeli dekorativni (mineralni) premaz na bazi cementa, ojačan polimerima, sa visokom otpornosti na vodu i poboljšanom prozračnošću.",
        "Proizvod, koji sadrži čestice mramora koje povećavaju čvrstoću i trajnost, ima stvarnu veličinu čestica od 2 mm, bijelu boju i savršenu teksturu, koristi se na vanjskim sistemima toplotne izolacije.",
        "neuporediva bijelina",
        "Visoka otpornost na udarce",
        "Visoka otpornost na teške klimatske uvjete"
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10016,
      "name": "Profili i dodaci",
      "images": [
        "/uploads/b36ddd-tavan-u-tr.png"
      ],
      "details": [],
      "children": [
        10332,
        10333,
        10334
      ],
      "measurements": []
    },
    {
      "id": 10332,
      "name": "Vanjski profili",
      "images": [
        "/uploads/DII-Cephe-Profilleri.png"
      ],
      "details": [],
      "children": [
        10335,
        10336,
        10337
      ],
      "measurements": []
    },
    {
      "id": 10335,
      "name": "CT PROFIL",
      "images": [
        "/uploads/CT-PROFIL.png"
      ],
      "details": [
        "To je nosivi element koji se koristi za povezivanje ploča u metalnoj konstrukciji instaliranoj za primjenu na vanjskim zidovima, napravljen od čelika vruće pocinčanog korištenjem metode hladnog oblikovanja."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Debljina (mm): 0,90 mm",
            "Dužina (mm): 3000 mm",
            "Pakovanje: 6 pcs/bundle",
            "Tip čelika: DX51D (complies with EN 10346)",
            "Pocinčana debljina: Z100, Z140, Z275",
            "Oznaka otpornosti na vatru: A1 Class",
            "Granica razvlačenja: ≥140 N/mm2"
          ]
        }
      ]
    },
    {
      "id": 10336,
      "name": "J PROFIL",
      "images": [
        "/uploads/J-PROFIL.png"
      ],
      "details": [
        "To je nosivi element koji se koristi za formiranje horizontalne potpore u metalnoj konstrukciji instaliranoj za primjenu na vanjskim zidovima, napravljen od čelika vruće pocinčanih korištenjem metode hladnog oblikovanja."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Debljina (mm): 1,5 - 2,00 mm",
            "Dužina (mm): 3000 mm",
            "Pakovanje: 10 pcs/bundle",
            "Tip čelika: DX51D (complies with EN 10346)",
            "Pocinčana debljina: Z100, Z140, Z275",
            "Oznaka otpornosti na vatru: A1 Class",
            "Granica razvlačenja: ≥140 N/mm2"
          ]
        }
      ]
    },
    {
      "id": 10337,
      "name": "M PROFIL",
      "images": [
        "/uploads/DII-Cephe-Profilleri.png"
      ],
      "details": [
        "To je nosivi element koji se koristi za povezivanje ploča u metalnoj konstrukciji instaliranoj za primjenu na vanjskim zidovima, napravljen od čelika vruće pocinčanih korištenjem metode hladnog oblikovanja.",
        "Proizvodi se kao pocinčani, pocinčani + plavi TOC premazani, pocinčani + crni TOC premazani."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Debljina (mm): 0,45 - 0,50 mm",
            "Dužina (mm): 3000 mm",
            "Pakovanje: 6 pcs/bundle",
            "Tip čelika: DX51D (complies with EN 10346)",
            "Pocinčana debljina: Z100, Z140, Z275",
            "Oznaka otpornosti na vatru: A1 Class",
            "Granica razvlačenja: ≥140 N/mm2"
          ]
        }
      ]
    },
    {
      "id": 10333,
      "name": "Profil za pregrade i zavjese",
      "images": [
        "/uploads/11.png"
      ],
      "details": [],
      "children": [
        10338,
        10339,
        10340,
        10341
      ],
      "measurements": []
    },
    {
      "id": 10338,
      "name": "UA PROFIL",
      "images": [
        "/uploads/UA-PROFIL.png"
      ],
      "details": [
        "To je nosivi element koji se koristi za nošenje okvira vrata i prozora u metalnoj konstrukciji instaliranoj za primjenu na pregradnim zidovima, napravljen od čelika vruće pocinčanih korištenjem metode hladnog oblikovanja."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Debljina (mm): 1,50 - 2,00 mm",
            "Dužina (mm): 3000 mm",
            "Pakovanje: 6 pcs/bundle",
            "Tip čelika: DX51D (complies with EN 10346)",
            "Pocinčana debljina: Z100, Z140, Z275",
            "Oznaka otpornosti na vatru: A1 Class",
            "Granica razvlačenja: ≥140 N/mm2"
          ]
        }
      ]
    },
    {
      "id": 10339,
      "name": "POSEBNI ZIDNI C PROFIL",
      "images": [
        "/uploads/OZEL-DUVAR-C-PROFILI.png"
      ],
      "details": [
        "To je dopunski element koji se koristi kao vodič za zidne C profile i omogućava njihovo nivelisanje u metalnoj konstrukciji instaliranoj za pregradne zidove, obložene zidove, spuštene stropove i vanjske zidove, napravljen od čelika vruće pocinčanih korištenjem metode hladnog oblikovanja."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Debljina (mm): 1,20 - 2,00 mm",
            "Dužina (mm): 3000 mm",
            "Pakovanje: 6 pcs/bundle",
            "Tip čelika: DX51D (complies with EN 10346)",
            "Pocinčana debljina: Z100, Z140, Z275",
            "Oznaka otpornosti na vatru: A1 Class",
            "Granica razvlačenja: ≥140 N/mm2"
          ]
        }
      ]
    },
    {
      "id": 10340,
      "name": "ZIDNI C PROFIL",
      "images": [
        "/uploads/DUVAR-C-PROFILI.png"
      ],
      "details": [
        "To je nosivi element koji se koristi u metalnoj konstrukciji instaliranoj za pregradne zidove, obložene zidove, spuštene stropove i vanjske zidove, napravljen od čelika vruće pocinčanih korištenjem metode hladnog oblikovanja."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Debljina (mm): 0,45 - 0,80 mm",
            "Dužina (mm): 3000 mm",
            "Pakovanje: 12 pcs/bundle",
            "Tip čelika: DX51D (complies with EN 10346)",
            "Pocinčana debljina: Z100, Z140, Z275",
            "Oznaka otpornosti na vatru: A1 Class",
            "Granica razvlačenja: ≥140 N/mm2"
          ]
        }
      ]
    },
    {
      "id": 10341,
      "name": "ZIDNI U PROFIL",
      "images": [
        "/uploads/11_1.png"
      ],
      "details": [
        "To je dopunski element koji se koristi kao vodič za zidne C profile i omogućava njihovo nivelisanje u metalnoj konstrukciji instaliranoj za pregradne zidove, obložene zidove, spuštene stropove i vanjske zidove, napravljen od čelika vruće pocinčanih korištenjem metode hladnog oblikovanja."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Debljina (mm): 0,45 - 0,80 mm",
            "Dužina (mm): 3000 mm",
            "Pakovanje: 12 pcs/bundle",
            "Tip čelika: DX51D (complies with EN 10346)",
            "Pocinčana debljina: Z100, Z140, Z275",
            "Oznaka otpornosti na vatru: A1 Class",
            "Granica razvlačenja: ≥140 N/mm2"
          ]
        }
      ]
    },
    {
      "id": 10334,
      "name": "Profili za spuštene stropove",
      "images": [
        "/uploads/b36ddd-tavan-u-tr.png"
      ],
      "details": [],
      "children": [
        10342,
        10343,
        10344,
        10345,
        10346,
        10347,
        10348,
        10349
      ],
      "measurements": []
    },
    {
      "id": 10342,
      "name": "POSEBNI U PROFIL",
      "images": [
        "/uploads/9_5.jpg"
      ],
      "details": [
        "To je dopunski element koji se koristi u izgradnji spuštenog stropa od gipsanih ploča kao nosivi profil i za nivelisanje sistema, napravljen od čelika vruće pocinčanih korištenjem metode hladnog oblikovanja."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Debljina (mm): 1,00 - 1,50 mm",
            "Dužina (mm): 3000 mm",
            "Pakovanje: 8 pcs/bundle (U20, U30, U40) 6 pcs/bundle (U50, U60)",
            "Tip čelika: DX51D (complies with EN 10346)",
            "Pocinčana debljina: Z100, Z140, Z275",
            "Oznaka otpornosti na vatru: A1 Class",
            "Granica razvlačenja: ≥140 N/mm2"
          ]
        }
      ]
    },
    {
      "id": 10343,
      "name": "PROFIL OKO STROPA",
      "images": [
        "/uploads/8.png"
      ],
      "details": [
        "To je dopunski element koji se koristi u izgradnji spuštenog stropa od gipsanih ploča kao nosivi profil i za nivelisanje sistema, napravljen od čelika vruće pocinčanih korištenjem metode hladnog oblikovanja."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Debljina (mm): 1,00 - 1,50 mm",
            "Dužina (mm): 3000 mm",
            "Pakovanje: 8 pcs/bundle (U20, U30, U40) 6 pcs/bundle (U50, U60)",
            "Tip čelika: DX51D (complies with EN 10346)",
            "Pocinčana debljina: Z100, Z140, Z275",
            "Oznaka otpornosti na vatru: A1 Class",
            "Granica razvlačenja: ≥140 N/mm2"
          ]
        }
      ]
    },
    {
      "id": 10344,
      "name": "CEILING C 47 PROFIL",
      "images": [
        "/uploads/7_4.jpg"
      ],
      "details": [
        "To je nosivi element koji se koristi u metalnoj konstrukciji, instaliran za izgradnju spuštenog plafona od gipsanih ploča, kao nosilac sistema, i napravljen je od čelika vruće pocinčanog korištenjem metode hladnog oblikovanja."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Debljina (mm): 0,50 - 0,60 mm",
            "Dužina (mm): 3000 mm",
            "Pakovanje: 8 pcs/bundle (U20, U30, U40) 6 pcs/bundle (U50, U60)",
            "Tip čelika: DX51D (complies with EN 10346)",
            "Pocinčana debljina: Z100, Z140, Z275",
            "Oznaka otpornosti na vatru: A1 Class",
            "Granica razvlačenja: ≥140 N/mm2"
          ]
        }
      ]
    },
    {
      "id": 10345,
      "name": "CEILING OMEGA 45 PROFIL",
      "images": [
        "/uploads/6.png"
      ],
      "details": [
        "To je dopunski element koji se koristi u metalnoj konstrukciji, instaliran za izgradnju spuštenog plafona od gipsanih ploča, za pričvršćivanje gipsanih ploča, i napravljen je od čelika vruće pocinčanog korištenjem metode hladnog oblikovanja."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Debljina (mm): 0,40 - 0,50 mm",
            "Dužina (mm): 3000 mm",
            "Pakovanje: 20 pcs/bundle",
            "Tip čelika: DX51D (complies with EN 10346)",
            "Pocinčana debljina: Z100, Z140, Z275",
            "Oznaka otpornosti na vatru: A1 Class",
            "Granica razvlačenja: ≥140 N/mm2"
          ]
        }
      ]
    },
    {
      "id": 10346,
      "name": "CEILING OMEGA PROFIL",
      "images": [
        "/uploads/4.png"
      ],
      "details": [
        "To je dopunski element koji se koristi u metalnoj konstrukciji, instaliran za izgradnju spuštenog plafona od gipsanih ploča, za pričvršćivanje gipsanih ploča, i napravljen je od čelika vruće pocinčanog korištenjem metode hladnog oblikovanja."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Debljina (mm): 0,40 - 0,50 mm",
            "Dužina (mm): 3000 mm",
            "Pakovanje: 10 pcs/bundle",
            "Tip čelika: DX51D (complies with EN 10346)",
            "Pocinčana debljina: Z100, Z140, Z275",
            "Oznaka otpornosti na vatru: A1 Class",
            "Granica razvlačenja: ≥140 N/mm2"
          ]
        }
      ]
    },
    {
      "id": 10347,
      "name": "CEILING OMEGA U PROFIL",
      "images": [
        "/uploads/3_1.png"
      ],
      "details": [
        "To je dopunski element koji se koristi u metalnoj konstrukciji, instaliran za izgradnju spuštenog plafona od gipsanih ploča, kao nosilac sistema, i napravljen je od čelika vruće pocinčanog korištenjem metode hladnog oblikovanja."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Debljina (mm): 0,40 - 0,50 mm",
            "Dužina (mm): 3000 mm",
            "Pakovanje: 24 pcs/bundle",
            "Tip čelika: DX51D (complies with EN 10346)",
            "Pocinčana debljina: Z100, Z140, Z275",
            "Oznaka otpornosti na vatru: A1 Class",
            "Granica razvlačenja: ≥140 N/mm2"
          ]
        }
      ]
    },
    {
      "id": 10348,
      "name": "CEILING C POWER PROFIL",
      "images": [
        "/uploads/2.png"
      ],
      "details": [
        "To je dopunski element koji se koristi u metalnoj konstrukciji, instaliran za izgradnju spuštenog plafona od gipsanih ploča, kao nosilac sistema, i napravljen je od čelika vruće pocinčanog korištenjem metode hladnog oblikovanja."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Debljina (mm): 0,40 - 0,50 mm",
            "Dužina (mm): 3000 mm",
            "Pakovanje: 12 pcs/bundle",
            "Tip čelika: DX51D (complies with EN 10346)",
            "Pocinčana debljina: Z100, Z140, Z275",
            "Oznaka otpornosti na vatru: A1 Class",
            "Granica razvlačenja: ≥140 N/mm2"
          ]
        }
      ]
    },
    {
      "id": 10349,
      "name": "CEILING U POWER PROFIL",
      "images": [
        "/uploads/b36ddd-tavan-u-tr.png"
      ],
      "details": [
        "To je dopunski element koji se koristi u metalnoj konstrukciji, instaliran za izgradnju spuštenog plafona od gipsanih ploča kao nosilac Ceiling C profila i za nivelisanje sistema, i napravljen je od čelika vruće pocinčanog korištenjem metode hladnog oblikovanja."
      ],
      "children": [],
      "measurements": [
        {
          "key": "1",
          "values": [
            "Debljina (mm): 0,35 - 0,60 mm",
            "Dužina (mm): 3000 mm",
            "Pakovanje: 24 pcs/bundle",
            "Tip čelika: DX51D (complies with EN 10346)",
            "Pocinčana debljina: Z100, Z140, Z275",
            "Oznaka otpornosti na vatru: A1 Class",
            "Granica razvlačenja: ≥140 N/mm2"
          ]
        }
      ]
    },
    {
      "id": 10350,
      "name": "Zelena kafa",
      "images": [
        "/img/green_beans.webp"
      ],
      "details": [
        "Zelena kafa se odnosi na nepečene zrna kafe koja su ubrana, obrađena i sušena, ali nisu prošla kroz proces pečenja. Ova zrna zadržavaju inherentne ukuse i karakteristike koje utiču na njihovu regiju rasta."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10351,
      "name": "Pečena kafa",
      "images": [
        "/img/coorg-arabica-roasted-coffee-beans.20231001174407.webp"
      ],
      "details": [
        "Pečena kafa je transformacija zelenih zrna kafe kroz pečenje, što donosi karakteristične ukuse, arome i karakteristike konačne šoljice kafe."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10352,
      "name": "Mlevena kafa",
      "images": [
        "/img/71FvXm+rhHL._AC_UF894,1000_QL80_.jpg"
      ],
      "details": [
        "Mlevena kafa se odnosi na pečena zrna kafe obrađena u grubu ili finu konzistenciju pogodnu za pripremu. Povećava ekstrakciju ukusa tokom pripreme."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10353,
      "name": "Kakao maslac",
      "images": [
        "/img/CO-741_Cocoa_Butter_Natural__01321.jpg"
      ],
      "details": [
        "Kakao maslac je prirodna, jestiva mast dobijena iz semena kakao voća. Koristi se u kulinarskim i kozmetičkim aplikacijama, poznata po svojoj glatkoj teksturi i blagoj aromi."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10354,
      "name": "kakao prah",
      "images": [
        "/img/cocoa_powder.webp"
      ],
      "details": [
        "Kakao prah se pravi mlevenjem pečenih kakao zrna. Poznat je po svojoj kulinarskoj svestranosti i antioksidativnim svojstvima, često se koristi u receptima za poboljšanje ukusa dezerta i napitaka."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10355,
      "name": "Omoa kakao",
      "images": [
        "/img/omoa_cacao.jpeg"
      ],
      "details": [
        "Omoa kakao je jedinstven i aromatičan kakao profil iz Omoa regije Hondurasa, poznat po svom posebnom terou i bogatom ukusu."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10356,
      "name": "Yojoa jezero kakao",
      "images": [
        "/img/yoa_lake_cacao.jpg"
      ],
      "details": [
        "Yojoa jezero kakao dolazi iz tropske vlažne regije oko Yojoa jezera. Donosi delikatnu ravnotežu cvetnih i voćnih nota sa glatkim završetkom."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10357,
      "name": "Copán kakao",
      "images": [
        "/img/honduras_cacao.webp"
      ],
      "details": [
        "Copán kakao dolazi iz regije blizu majanskih hramova u Hondurasu. Ima složen profil ukusa, kombinujući bogatstvo čokolade sa suptilnim voćnim i cvetnim notama."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10359,
      "name": "mješavina začina",
      "images": [
        "/img/allspice.jpeg"
      ],
      "details": [
        "Mješavina začina je svestran začin koji kombinuje ukuse slične karanfiliću, cimetu i muškatnom oraščiću. Koristi se za začinjavanje mesa, čorbi, dezerta i cenjen je zbog svoje topline i dubine."
      ],
      "children": [],
      "measurements": []
    },
    {
      "id": 10360,
      "name": "ograda 1",
      "images": [
        "/img/venecia1.jpg",
        "/img/venecia2.jpg",
        "/img/venecia3.jpg",
        "/img/venecia4.jpg",
        "/img/venecia5.jpg",
        "/img/venecia6.jpg",
        "/img/venecia7.jpg",
        "/img/venecia8.jpg",
        "/img/venecia9.jpg",
        "/img/venecia10.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10361,
      "name": "ograda 2",
      "images": [
        "/img/sicilia1.jpg",
        "/img/sicilia2.jpg",
        "/img/sicilia3.jpg",
        "/img/sicilia4.jpg",
        "/img/sicilia5.jpg",
        "/img/sicilia6.jpg",
        "/img/sicilia7.jpg",
        "/img/sicilia8.jpg",
        "/img/sicilia9.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10362,
      "name": "ograda 3",
      "images": [
        "/img/amalfi1.jpg",
        "/img/amalfi2.jpg",
        "/img/amalfi3.jpg",
        "/img/amalfi4.jpg",
        "/img/amalfi5.jpg",
        "/img/amalfi6.jpg",
        "/img/amalfi7.jpg",
        "/img/amalfi8.jpg",
        "/img/amalfi9.jpg"
      ],
      "details": [],
      "children": [],
      "measurements": []
    },
    {
      "id": 10363,
      "name": "OGRADA",
      "images": [
        "/img/fence.jpeg"
      ],
      "details": [
        "Aluminijske ograde nude savršenu kombinaciju izdržljivosti i elegancije, pružajući sigurnu i stilsku barijeru koja izdržava elemente uz minimalno održavanje.",
        "Sa raznim dizajnima i završnim obradama, dodaju trajnu vrednost i privlačnost svakoj nekretnini."
      ],
      "children": [
        10360,
        10361,
        10362
      ],
      "measurements": []
    }
  ]
};

export default translationsBs;
