import { useEffect, useMemo, useState } from "react";
import { useTheme } from "next-themes";
import { Cloud } from "react-icon-cloud";

export const cloudProps = {
  containerProps: {
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      paddingTop: 0,
    },
  },
  options: {
    reverse: true,
    depth: 1,
    wheelZoom: false,
    imageScale: 2,
    activeCursor: "default",
    tooltip: "native",
    initial: [0.1, -0.1],
    clickToFront: 500,
    tooltipDelay: 0,
    outlineColour: "#0000",
    maxSpeed: 0.04,
    minSpeed: 0.02,
  },
};

export function IconCloud({ images }) {
  const { theme } = useTheme();

  const renderedImages = useMemo(() => {
    if (!images || images.length === 0) return null;

    return images.map((image, index) => (
      <a
        key={index}
        href={image.href || undefined}
        target={image.target || undefined}
        rel={image.rel || undefined}
        onClick={(e) => e.preventDefault()}  // Prevent default behavior, similar to icons
        style={{ cursor: "pointer" }}  // Same styling as icons
      >
        {image.newSizing ? <img
          src={image.url}
          alt={image.title}
          width={image.newSizing.width}
          height={image.newSizing.height}
          style={{
            borderRadius: "50%",
            objectFit: "cover",
            backgroundColor: image.hex || (theme === "light" ? "#f3f2ef" : "#080510"),
          }}
        /> : <img
        src={image.url}
        alt={image.title}
        width={40}
        height={25}
        style={{
          borderRadius: "50%",
          objectFit: "cover",
          backgroundColor: image.hex || (theme === "light" ? "#f3f2ef" : "#080510"),
        }}
      />}
        
      </a>
    ));
  }, [images, theme]);

  return (
    <Cloud {...cloudProps}>
      {renderedImages}
    </Cloud>
  );
}
