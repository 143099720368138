import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Typography } from "@material-tailwind/react";
import ContactForm from "../components/ContactForm";
import { LanguageContext } from "../context/LanguageProvider";
import servicesDataEn from "../data/language/servicesData.en";
import servicesDataBs from "../data/language/servicesData.bs";

const ServiceDetail = () => {
  const { id } = useParams();
  const { language } = useContext(LanguageContext);

  const servicesData = language === "en" ? servicesDataEn : servicesDataBs;
  const service = servicesData.find((s) => s.id === id);

  if (!service) {
    return <Typography variant="h5" color="gray">Service not found</Typography>;
  }

  return (
    <section className="pt-8 px-4 lg:pt-20 max-w-7xl mx-auto">
      <div className="text-center mx-auto max-w-2xl py-6">
        <Typography
          variant="h2"
          color="blue-gray"
          className="my-6 text-4xl font-extrabold leading-snug"
        >
          {service.title}
        </Typography>
        <Typography className="font-normal text-gray-600 leading-relaxed">
          {service.description}
        </Typography>
      </div>
      <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 items-center mt-6 lg:mt-12">
        <div className="relative">
          <img
            src={service.imageUrl}
            alt={service.title}
            className="rounded-xl h-[28rem] w-full object-cover shadow-lg"
          />
        </div>
        <div>
          <ContactForm subject={service.title} className={"h-full"} />
        </div>
      </div>
    </section>
  );
};

export default ServiceDetail;
