import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from "@material-tailwind/react";
import ContactForm from "./ContactForm";
import { LanguageContext } from "../context/LanguageProvider";
import translationsEn from "../data/language/products.en";
import translationsBs from "../data/language/products.bs";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { Typewriter } from "react-simple-typewriter";
import Table from "./Table";


export function ProductDetails() {
  const { id } = useParams(); 
  const { language } = useContext(LanguageContext);
  const translations = language === "en" ? translationsEn : translationsBs;
  const uniqueMessage = { en: "Need information about ", bs: "Potrebne su Vam informacije za "}
  const navigate = useNavigate();

  const product = translations.products.find((item) => item.id === parseInt(id));
  const [selectedImage, setSelectedImage] = useState(product.images[0]);

  if (!product) {
    return (
      <Typography variant="h4" color="red" className="text-center text-2xl font-bold mt-10">
        Product not found
      </Typography>
    );
  }
  const handleGoBack = () => {
      navigate(`/pages/products`);
  };

  console.log("These are my product measurements: " + JSON.stringify(product.measurements));

  return (
    <div className="max-w-7xl mx-auto px-4 pt-16 md:pt-24 mb-10">
      <button
          onClick={() => handleGoBack()}
          className="p-4 rounded-full bg-primary text-white hover:text-primary hover:bg-white transition mb-20"
        >
          <ArrowLeftIcon className="h-6 w-6" />
        </button>
      <div className="flex flex-col lg:flex-row gap-12">
        
        {/* Left Side - Image Gallery */}
        <div className="lg:w-1/2 flex flex-col justify-center">
          <img
            src={selectedImage}
            alt={product.name}
            className="rounded-2xl h-[35rem] w-full object-cover shadow-lg mb-6"
          />
          <div className="grid grid-cols-4 sm:grid-cols-5 lg:grid-cols-6 gap-4">
            {product.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`${product.name} ${index + 1}`}
                className="h-24 w-full object-cover cursor-pointer rounded-lg shadow-sm transition-transform duration-300 ease-in-out transform hover:scale-110 hover:shadow-md"
                onClick={() => setSelectedImage(image)}
              />
            ))}
          </div>
        </div>
        {/* Right Side - Title, Description, and Contact Form */}
        <div className="lg:w-1/2">
          <Typography
            variant="h2"
            color="blue-gray"
            className="text-3xl lg:text-4xl font-extrabold mb-6"
          >
            {product.name}
          </Typography>
          {product.links && product.links.map(link => {return <><a className="text-primary font-bold" href={link}>{product.name}</a><Typewriter
              words={["  <---"]}
              loop
              cursor
              cursorStyle="|"
              typeSpeed={150}
              deleteSpeed={150}
              delaySpeed={200}
              
            /></>})}
          {product.details ? (
            <ul className="list-disc pl-6 mb-8 text-lg text-gray-800 dark:text-gray-300">
              {product.details.map((detail, index) => (
                <li key={index} className="mb-3">
                  {detail}
                </li>
              ))}
            </ul>
          ) : (
            <Typography
              variant="paragraph"
              color="blue-gray"
              className="text-lg mb-8"
            >
              No details available for this product.
            </Typography>
          )}
          <ContactForm message={`${uniqueMessage[language]} ${product.name}?`} subject={product.name}/>
        </div>
      </div>
      <Table measurements={product.measurements} lang={language}/>
    </div>
  );
}

export default ProductDetails;
