import React, { useState, useEffect, useContext } from "react"; 
import { testimonialsEN } from "../data/language/testimonialDataEn";
import { testimonialsBS } from "../data/language/testimonialDataBs";
import Testimonial from "./Testimonial";
import { LanguageContext } from "../context/LanguageProvider";

const TestimonialSection = () => {
  const { language } = useContext(LanguageContext);
  const testimonials = language == "en" ? testimonialsEN : testimonialsBS;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // Start fade out
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        setFade(true); // Start fade in
      }, 500); // Match this timeout with the fade-out transition time
    }, 13000); // Change the testimonial every 13 seconds (6s before + 6s after + 1s for fade out/in)

    return () => clearInterval(interval);
  }, [testimonials.length]);

  return (
    <div className="relative overflow-hidden mx-auto w-full bg-white py-6 sm:py-12">
      <div className="absolute top-0 left-0 right-0 z-0">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#183f99"
            fillOpacity="1"
            d="M0,96L30,106.7C60,117,120,139,180,133.3C240,128,300,96,360,80C420,64,480,64,540,85.3C600,107,660,149,720,181.3C780,213,840,235,900,218.7C960,203,1020,149,1080,154.7C1140,160,1200,224,1260,229.3C1320,235,1380,181,1410,154.7L1440,128L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
          ></path>
        </svg>
      </div>

      <div
        className={`transition-opacity duration-500 ${fade ? "opacity-100" : "opacity-0"} z-10 relative`}
      >
        <Testimonial
          key={currentIndex}
          beforeImage={testimonials[currentIndex].beforeImage}
          afterImage={testimonials[currentIndex].afterImage}
          name={testimonials[currentIndex].name}
          descriptionBefore={testimonials[currentIndex].descriptionBefore}
          descriptionAfter={testimonials[currentIndex].descriptionAfter}
        />
      </div>
    </div>
  );
};

export default TestimonialSection;
