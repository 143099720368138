import React from "react";

const logos = [
  "/img/romaindustries.png",
  "/img/alumturk.png",
  "/img/mulata.svg",
];

const TrustedByLogos = () => {
  return (
    <>
      <div className="text-white text-center uppercase tracking-widest font-semibold">trusted by</div>
      <div className="flex flex-wrap justify-center gap-4 py-2">
        {logos.map((logo, index) => (
          <div key={index} className="p-4">
            <img
              src={logo}
              alt={`Logo ${index + 1}`}
              className="h-[1.8rem] md:h-[2.4rem] lg:h-[3.5rem] w-auto object-contain "
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default TrustedByLogos;
