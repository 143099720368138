const translationsBs = {
  aboutUs: "O nama",
  products: "Proizvodi",
  services: "Usluge",
  contactUs: "Kontakt",
  button: "English",
  importExport: "Uvoz",
  transportation: "Transport",
  medicalTourism: "Medicinski turizam",
  home: "Početna stranica",
};

export default translationsBs;
