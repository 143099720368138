export const translationsEn = {
  productTitle: "Products",
  readMore: "Read more",
  products: [
    {
      id: 10023,
      name: "Patterned Sheet Products",
      images: ["/uploads/hekimboard-desenli-levha-1080x1080.jpg"],
      details: [],
      children: [
        10047, 10048, 10049, 10050, 10051, 10052, 10053, 10054, 10055, 10056,
      ],
    },
    {
      id: 10048,
      name: "Checker Brick Pattern Board",
      images: ["/uploads/sasirtmali-tugla-desenli-fibercement-levha.png"],
      details: ["Incombustibility Class: A1"],
      children: [],
      
    },
    {
      id: 10036,
      name: "45 Angled Casing",
      images: ["/uploads/45-acili-fibercement-sove_3.png"],
      details: ["Incombustibility Class: A1"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 80 x 2500",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,2",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 100 x 2500",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,25",
          ],
        },
        {
          key: "3",
          values: [
            "Dimensions (mm x mm): 150 x 2500",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,375",
          ],
        },
        {
          key: "4",
          values: [
            "Dimensions (mm x mm): 200 x 2500",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,5",
          ],
        },
        {
          key: "5",
          values: [
            "Dimensions (mm x mm): 80 x 3000",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,2",
          ],
        },
        {
          key: "6",
          values: [
            "Dimensions (mm x mm): 100 x 3000",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,25",
          ],
        },
        {
          key: "7",
          values: [
            "Dimensions (mm x mm): 150 x 3000",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,375",
          ],
        },
        {
          key: "8",
          values: [
            "Dimensions (mm x mm): 200 x 3000",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,5",
          ],
        },
      ],
    },
    {
      id: 6,
      name: "BUILDING MATERIALS",
      images: [
        "https://media.istockphoto.com/id/836219748/photo/set-of-construction-materials.jpg?s=612x612&w=0&k=20&c=cwsoqQt0poMxiH17EV_rHjxLYXyn9x-F_Q-iLE14hf4=",
      ],
      details: [
        "Building materials like gypsum boards, construction textiles, glass wool, and rock wool are commonly used in dry construction for insulation and structural support.",
        "These materials offer efficient solutions for creating energy-efficient and durable buildings, each serving specific needs in thermal and sound insulation.",
      ],
      initial: true,
      children: [
        10017, 10018, 10019, 10020, 10021, 10022, 10023, 10057, 10058, 10059, 10107, 10108, 10109, 10110, 10111, 10112, 10113, 10114, 10131, 10132, 10133, 10143, 10153, 10157, 10158, 10009,
        10182, 10183, 10211, 10212, 10213, 10225, 10226, 10227, 10244, 10245, 10246, 10265, 10266, 10267, 10293, 10294, 10295, 10296, 10332, 10333, 10334,
      ],
    },
    {
      id: 1,
      name: "ALUMINUM PROFILES (STANDARD AND SPECIAL)",
      images: [
        "https://ba.chinealuminium.com/uploads/202311890/square-tube-standard-aluminium-sections382bdf5d-88b0-419b-bd99-77daa06950b4.jpg",
        "https://feal.ba/wp-content/uploads/2020/06/Francuski-balkoni.jpg",
        "https://www.murex.ba/wp-content/uploads/2023/11/101k.jpeg",
        "https://feal.ba/wp-content/uploads/2023/01/01-FNE-2000.jpg",
      ],
      details: [
        "Aluminum profiles and solar systems provide lightweight, durable, and corrosion-resistant solutions for construction and energy efficiency.",
        "Door & window systems ensure insulation and durability, while fences offer secure, stylish boundaries.",
      ],
      initial: true,
      children: [14, 15, 16, 19, 10363],
    },
    {
      id: 5,
      name: "GALVANIZED PROFILES FOR CONSTRUCTION",
      images: [
        "https://imgusr.tradekey.com/p-2280688-20081119151716/steel-galvanized-profiles-for-dry-construction-of-houses.jpg",
      ],
      details: [
        "Galvanized profiles, including UD, CD, CW, and UW, are designed for construction, offering corrosion-resistant support for plastering and other structural uses.",
        "Pricing varies based on order quantity and profile type, providing flexible solutions for both small and large-scale construction projects.",
      ],
      initial: true,
      children: [],
    },
    {
      id: 12,
      name: "GLASS OF ALL DIMENSIONS AND SPECIFICATIONS",
      images: [
        "https://www.wonderdays.co.uk/uploads/products/_1200x630_crop_center-center_82_none/glassmaking.png?mtime=1653475397",
      ],
      details: [
        "Glass types, including tempered, laminated, and colored glass, are available in various dimensions and specifications, offering strength, safety, and aesthetic versatility.",
        "These glasses come in different thicknesses and finishes, with pricing based on type and order quantity, serving a wide range of construction, automotive, and design applications.",
      ],
      initial: true,
      children: [],
    },
    {
      id: 8,
      name: "INDUSTRIAL IRON AND STEEL",
      images: [
        "https://www.market-prospects.com/storage/images/251-1200x675.jpg",
      ],
      details: [
        "Industrial iron and steel products, such as rebar rods (6-18 mm), reinforcement meshes, and profiles (HEA, NPU, NPI, box, and L), are essential for construction and structural reinforcement.",
        "These materials provide strength and durability, with pricing based on product type and order volume.",
      ],
      initial: true,
      children: [],
    },
    {
      id: 11,
      name: "CERAMIC TILES (WALL AND FLOOR)",
      images: [
        "https://dropinblog.net/34246798/files/featured/Top_8_Flooring_Tiles_You_Must_Know_For_Your_Home.jpg",
      ],
      details: [
        "Ceramic tiles, designed for both wall and floor applications, offer a durable and versatile option for enhancing the aesthetics and functionality of any space.",
        "Available in a range of sizes, designs, and finishes, pricing depends on tile type and order volume, making them suitable for both residential and commercial projects.",
      ],
      initial: true,
      children: [],
    },
    {
      id: 10,
      name: "COFFEE, PEPPER, CARDAMOM SPICE, COCOA",
      images: [
        "https://www.thespruceeats.com/thmb/NnN4vOmp_BqCEjs4CuD1lgBlQog=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/moroccan-spiced-coffee-2394960-step-01-b898ab4c49bf42dbaba949f2c2bbbbd4.jpg",
      ],
      details: [
        "100% organic, produced in Honduras",
        "SHG Supreme coffee, HG coffee, Santa Teresa coffee",
        "Blended coffee for Bosnian coffee (60% Arabica, 40% Robusta)",
        "Cardamom spice and peppercorns, 100% organic",
        "Cocoa powder and cocoa butter, 100% organic",
      ],
      initial: true,
      children: [
        10350, 10351, 10352, 10353, 10354, 10355, 10356, 10357, 10358, 10359,
      ],
    },
    {
      id: 3,
      name: "PARAFFIN",
      images: [
        "https://d2r3z0h7oyiawr.cloudfront.net/2024/05/21/16/01/13/c7d3fa80-559b-4970-aa96-2b0c48b3ca4b/paraffin-header-with-out-text.jpg",
      ],
      details: [
        "Paraffin is a versatile material commonly used in candle-making, waterproofing, and industrial applications due to its clean-burning and water-resistant properties.",
        "Available in bulk quantities, pricing depends on order volume and inquiry, making it ideal for large-scale manufacturing or specialized use.",
      ],
      initial: true,
      children: [],
    },
    {
      id: 14,
      name: "PROFILES",
      images: ["/img/profiles.jpg"],
      details: [
        "Aluminum profiles are lightweight, durable structural components made from extruded aluminum.",
        "They are commonly used in construction and manufacturing due to their corrosion resistance and versatility in various applications.",
      ],
      children: [
        1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010, 1011,
        1012, 1013, 1015, 1016, 1017, 1018, 1019, 1020, 1021, 1022, 1023, 1024,
        1025, 1026, 1027, 1028,
      ],
    },
    {
      id: 15,
      name: "SOLAR SYSTEMS",
      images: ["https://feal.ba/wp-content/uploads/2023/01/01-FNE-2000.jpg"],
      details: [
        "Aluminum solar systems are lightweight, durable, and corrosion-resistant, offering long-lasting support for solar panels.",
        "Easy to install and maintain, they maximize energy efficiency and ensure reliable performance in any environment.",
      ],
      children: [1029, 1030, 1031, 1032, 1033, 1034, 1035],
    },
    {
      id: 16,
      name: "DOOR AND WINDOW SYSTEMS",
      images: ["/img/doorandwindowsystems.jpg"],
      details: [
        "Door & Window Systems, available with or without thermal break, offer superior insulation, energy efficiency, and durability.",
        "Thermal break systems provide enhanced temperature control, while non-thermal options offer cost-effective solutions.",
      ],
      children: [17, 18],
    },
    {
      id: 17,
      name: "WITHOUT THERMAL BREAK",
      images: ["/img/nothermalbreak.png"],
      details: [
        "Door & Window Systems without thermal break offer a budget-friendly, durable solution for areas where insulation isn’t crucial.",
        "Ideal for warmer climates, they deliver reliable performance and long-lasting value at an affordable price.",
      ],
      children: [
        1041, 1042, 1043, 1044, 1045, 1046, 1047, 1048, 1049, 1050, 1051, 1052,
        1053, 1054, 1055, 1056, 1057, 1058, 1059, 1060, 1061, 1062, 1063, 1064,
        1065, 1066, 1067, 1068, 1069, 1070, 1071, 1072, 1073, 1074, 1075, 1076,
        1077, 1078, 1079, 1080, 1081, 1082, 1083, 1084, 1085, 1086, 1087, 1088,
        1089,
      ],
    },
    {
      id: 18,
      name: "WITH THERMAL BREAK",
      images: ["/img/thermalbreak.jpg"],
      details: [
        "Door & Window Systems with thermal break offer superior insulation, reducing energy costs by preventing heat transfer.",
        "They’re perfect for extreme climates, providing comfort, durability, and long-term savings with high efficiency.",
      ],
      children: [
        1090, 1091, 1092, 1093, 1094, 1095, 1096, 1097, 1098, 1099, 1100, 1101,
        1102, 1103, 1104, 1105, 1106, 1108, 1109, 1110, 1111, 1112, 1113, 1114,
        1115, 1116, 1117, 1118, 1119, 1120, 1121,
      ],
    },
    {
      id: 19,
      name: "PROFILE ACCESSORIES",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 20,
      name: "COCOA BUTTER",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 21,
      name: "COCOA POWDER",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 22,
      name: "CACAO PROFILES",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 23,
      name: "Omoa",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 24,
      name: "Yojoa Lake",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 25,
      name: "Copán",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 26,
      name: "COFFEE",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 27,
      name: "Green coffee",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 28,
      name: "Roasted Coffee",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 29,
      name: "Grounded coffee",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 30,
      name: "Arabica",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 31,
      name: "HG EP",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 32,
      name: "HG EP",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },

    {
      id: 33,
      name: "SHG EP",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },

    {
      id: 34,
      name: "SHG EP Plus",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 35,
      name: "Stock A, 150-180 defects",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 36,
      name: "Stock B, 250-180 defects",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 37,
      name: "Stock C, 350-380 defects",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 38,
      name: "CUP PROFILES",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 39,
      name: "Allspice",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 40,
      name: "Cardamom",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 41,
      name: "HG-15 Defects",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 42,
      name: "SHG-15 Defects",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 43,
      name: "Pacamara Mikrolot",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 44,
      name: "Stok Lot 110 Miramar Profile",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 45,
      name: "Stok Lotu 400",
      images: ["/img/accessories.jpeg"],
      details: [
        "Aluminum profile accessories enhance your projects with durability, precision, and versatility.",
        "Lightweight yet sturdy, they simplify assembly and customization, making them ideal for various applications.",
      ],
      children: [1036, 1037, 1038, 1039, 1040],
    },
    {
      id: 1000,
      name: "glazing bead",
      images: ["/img/glazingbead.png"],
      details: ["weight: 0.280 kg/m", "perimeter: 96.5834 mm"],
    },
    {
      id: 1001,
      name: "halved mullion",
      images: ["/img/halvedmullion.png"],
      details: ["weight: 1.866 kg/m", "perimeter: 400.302 mm"],
    },
    {
      id: 1002,
      name: "transom",
      images: ["/img/transom.png"],
      details: ["weight: 2.723 kg/m", "perimeter: 449.153 mm"],
    },
    {
      id: 1003,
      name: "lower transom",
      images: ["/img/lowertransom.png"],
      details: ["weight: 2.280 kg/m", "perimeter: 535 mm"],
    },
    {
      id: 1004,
      name: "upper transom",
      images: ["/img/uppertransom.png"],
      details: ["weight: 2.541 kg/m", "perimeter: 448.34 mm"],
    },
    {
      id: 1005,
      name: "staff bead for fixed wing",
      images: ["/img/staffbeadforfixedwing.png"],
      details: ["weight: 0.431 kg/m", "perimeter: 128.39 mm"],
    },
    {
      id: 1006,
      name: "staff bead for opening wing",
      images: ["/img/staffbeadforopeningwing.png"],
      details: ["weight: 0.257 kg/m", "perimeter: 104.631 mm"],
    },
    {
      id: 1007,
      name: "profile for pannel",
      images: ["/img/profileforpannel.png"],
      details: ["weight: 0.52 kg/m", "perimeter: - mm"],
    },
    {
      id: 1008,
      name: "skirting",
      images: ["/img/skirting.png"],
      details: ["weight: 0.557 kg/m", "perimeter: 285.51 mm"],
    },
    {
      id: 1009,
      name: "smaller transom",
      images: ["/img/smallertransom.png"],
      details: ["weight: 1.753 kg/m", "perimeter: 342.12 mm"],
    },
    {
      id: 1010,
      name: "horizontal wing profile",
      images: ["/img/horizontalwingprofile.png"],
      details: ["weight: 1.059 kg/m", "perimeter: 302.27 mm"],
    },
    {
      id: 1011,
      name: "vertical wing profile",
      images: ["/img/verticalwingprofile.png"],
      details: ["weight: 0.877 kg/m", "perimeter: 231.18 mm"],
    },
    {
      id: 1012,
      name: "reinforcement for mullion",
      images: ["/img/reinforcementformullion.png"],
      details: ["weight: 2.236 kg/m", "perimeter: 507.24 mm"],
    },
    {
      id: 1013,
      name: "larger reinforcement for mullion",
      images: ["/img/largerreinforcementformullion.png"],
      details: ["weight: 2.562 kg/m", "perimeter: 949.16 mm"],
    },
    {
      id: 1015,
      name: "corner",
      images: ["/img/corner.png"],
      details: ["weight: 1.46 kg/m", "perimeter: 285.72 mm"],
    },
    {
      id: 1016,
      name: "vertical wing profile 261.31mm",
      images: ["/img/verticalwingprofile261.31mm.png"],
      details: ["weight: 0.943 kg/m", "perimeter: 261.31 mm"],
    },
    {
      id: 1017,
      name: "horizontal wing profile 288.5mm",
      images: ["/img/horizontalwingprofile288.5mm.png"],
      details: ["weight: 1.083 kg/m", "perimeter: 288.5 mm"],
    },
    {
      id: 1018,
      name: "larger glazing bead",
      images: ["/img/largerglazingbead.png"],
      details: ["weight: 0.299 kg/m", "perimeter: 103.69 mm"],
    },
    {
      id: 1019,
      name: "mullion",
      images: ["/img/mullion.png"],
      details: ["weight: 3.568 kg/m", "perimeter: 537.73 mm"],
    },
    {
      id: 1020,
      name: "closed mullion",
      images: ["/img/closedmullion.png"],
      details: ["weight: 1.384 kg/m", "perimeter: 296.10 mm"],
    },
    {
      id: 1021,
      name: "upper transom 391mm",
      images: ["/img/uppertransom391mm.png"],
      details: ["weight: 0 kg/m", "perimeter: 0 mm"],
    },
    {
      id: 1022,
      name: "lower transom 424.51mm",
      images: ["/img/lowertransom424.51mm.png"],
      details: ["weight: 2.06 kg/m", "perimeter: 424.51 mm"],
    },
    {
      id: 1023,
      name: "wing 337.07mm",
      images: ["/img/wing337.07mm.png"],
      details: ["weight: 1.482 kg/m", "perimeter: 337.07 mm"],
    },
    {
      id: 1024,
      name: "wing 301.61mm",
      images: ["/img/wing301.61mm.png"],
      details: ["weight: 1.332 kg/m", "perimeter: 301.61 mm"],
    },
    {
      id: 1025,
      name: "vertical wing 365.18mm",
      images: ["/img/verticalwing365.18mm.png"],
      details: ["weight: 1.582 kg/m", "perimeter: 365.18 mm"],
    },
    {
      id: 1026,
      name: "thermal break wing",
      images: ["/img/thermalbreakwing.png"],
      details: ["weight: 1.658 kg/m", "perimeter: 373.66 mm"],
    },
    {
      id: 1027,
      name: "vertical wing 355.18mm",
      images: ["/img/verticalwing355.18mm.png"],
      details: ["weight: 1.557 kg/m", "perimeter: 355.18 mm"],
    },
    {
      id: 1028,
      name: "thermal break wing 363.66mm",
      images: ["/img/thermalbreakwing363.66.png"],
      details: ["weight: 1.633 kg/m", "perimeter: 363.66 mm"],
    },
    {
      id: 1029,
      name: "TRAPEZOIDAL & SANDWICH ROOF SYSTEMS",
      images: [
        "/img/ALUM-TR-1065.png",
        "/img/ALUM-TR-1075.png",
        "/img/ALUM-TR-1055.png",
        "/img/ALUM-TR-2023.png",
      ],
    },
    {
      id: 1030,
      name: "STANDING SEAM ROOF SYSTEMS",
      images: [
        "/img/ALUM-TR-1075.png",
        "/img/ALUM-KT-2707.png",
        "/img/ALUM-TR-2023.png",
      ],
    },
    {
      id: 1031,
      name: "ONDULINE ROOF SYSTEMS",
      images: [
        "/img/ALUM-TR-1075.png",
        "/img/ALUM-KT-2207.png",
        "/img/ALUM-TR-2023.png",
      ],
    },
    {
      id: 1032,
      name: "FLAT ROOF SYSTEMS",
      images: [
        "/img/ALUM-FL-1605.png",
        "/img/ALUM-FL-1610.png",
        "/img/ALUM-FL-1615.png",
        "/img/ALUM-FL-1620.png",
      ],
    },
    {
      id: 1033,
      name: "CERAMIC TILE ROOF SYSTEMS",
      images: ["/img/ALUM-TR-1075.png", "/img/ALUM-TR-2023.png"],
    },
    {
      id: 1034,
      name: "MEMBRANE ROOF SYSTEMS",
      images: [
        "/img/ALUM-TR-1075.png",
        "/img/ALUM-MB-2007.png",
        "/img/ALUM-TR-2023.png",
      ],
    },
    {
      id: 1035,
      name: "SHINGLE ROOF SYSTEMS",
      images: [
        "/img/ALUM-TR-1075.png",
        "/img/ALUM-MB-2007.png",
        "/img/ALUM-TR-2023.png",
      ],
    },
    {
      id: 1036,
      name: "BALLBEARINGS",
      images: [
        "/img/ballbearings1.png",
        "/img/ballbearings2.png",
        "/img/ballbearings3.png",
        "/img/ballbearings4.png",
      ],
    },
    {
      id: 1037,
      name: "CORNER",
      images: ["/img/corner1.png", "/img/corner2.png", "/img/corner3.png"],
    },
    {
      id: 1038,
      name: "JOINT CORNER",
      images: [
        "/img/jointcorner1.PNG",
        "/img/jointcorner2.PNG",
        "/img/jointcorner3.PNG",
      ],
    },
    {
      id: 1039,
      name: "HINGE",
      images: ["/img/hinge1.PNG", "/img/hinge2.PNG", "/img/hinge3.PNG"],
    },
    {
      id: 1040,
      name: "KIT ACCESSORIES",
      images: [
        "/img/kitaccessories1.PNG",
        "/img/kitaccessories2.PNG",
        "/img/kitaccessories3.PNG",
        "/img/kitaccessories4.PNG",
        "/img/kitaccessories5.PNG",
        "/img/kitaccessories6.PNG",
        "/img/kitaccessories7.PNG",
        "/img/kitaccessories8.PNG",
        "/img/kitaccessories9.PNG",
        "/img/kitaccessories10.PNG",
        "/img/kitaccessories11.PNG",
        "/img/kitaccessories12.PNG",
        "/img/kitaccessories13.PNG",
      ],
    },
    {
      id: 10000,
      name: "Hekimboard",
      images: ["/uploads/hekimboard.jpg"],
      details: [],
      children: [10017, 10018, 10019, 10020, 10021, 10022, 10023],
    },
    {
      id: 10017,
      name: "New Generation Joint Plate",
      images: ["/uploads/hekimboard-yeni-nesil-fugali-levha-1080x1080.jpg"],
      details: [],
      children: [10024, 10025, 10026, 10027, 10028],
    },

    {
      id: 1041,
      name: "M.SH-K075",
      images: ["/img/M.SH-K075.PNG"],
    },
    {
      id: 1042,
      name: "M.SH-K076",
      images: ["/img/M.SH-K076.PNG"],
    },
    {
      id: 1043,
      name: "M.SH-K072",
      images: ["/img/M.SH-K072.PNG"],
    },
    {
      id: 1044,
      name: "M.SH-K0121",
      images: ["/img/M.SH-K0121.PNG"],
    },
    {
      id: 1045,
      name: "M.SH-K071",
      images: ["/img/M.SH-K071.PNG"],
    },
    {
      id: 1046,
      name: "M.SH-K073",
      images: ["/img/M.SH-K073.PNG"],
    },
    {
      id: 1047,
      name: "M.SH-K070",
      images: ["/img/M.SH-K070.PNG"],
    },
    {
      id: 1048,
      name: "M.SH-K074",
      images: ["/img/M.SH-K074.PNG"],
    },
    {
      id: 1049,
      name: "M.SH-K079",
      images: ["/img/M.SH-K079.PNG"],
    },
    {
      id: 1050,
      name: "M.SH-K078",
      images: ["/img/M.SH-K078.PNG"],
    },
    {
      id: 1051,
      name: "M.SH-K090",
      images: ["/img/M.SH-K090.PNG"],
    },
    {
      id: 1052,
      name: "M.SH-K089",
      images: ["/img/M.SH-K089.PNG"],
    },
    {
      id: 1053,
      name: "M.SH-K0114",
      images: ["/img/M.SH-K0114.PNG"],
    },
    {
      id: 1054,
      name: "M.SH-K083",
      images: ["/img/M.SH-K083.PNG"],
    },
    {
      id: 1055,
      name: "M.SH-K084",
      images: ["/img/M.SH-K084.PNG"],
    },
    {
      id: 1056,
      name: "M.SH-K082",
      images: ["/img/M.SH-K082.PNG"],
    },
    {
      id: 1057,
      name: "M.SH-K086E",
      images: ["/img/M.SH-K086E.PNG"],
    },
    {
      id: 1058,
      name: "M.SH-K081",
      images: ["/img/M.SH-K081.PNG"],
    },
    {
      id: 1059,
      name: "M.SH-K0113",
      images: ["/img/M.SH-K0113.PNG"],
    },
    {
      id: 1060,
      name: "M.SH-K088",
      images: ["/img/M.SH-K088.PNG"],
    },
    {
      id: 1061,
      name: "M.SH-K0112",
      images: ["/img/M.SH-K0112.PNG"],
    },
    {
      id: 1062,
      name: "M.SH-K0106",
      images: ["/img/M.SH-K0106.PNG"],
    },
    {
      id: 1063,
      name: "M.SH-K0102",
      images: ["/img/M.SH-K0102.PNG"],
    },
    {
      id: 1064,
      name: "M.SH_K0106",
      images: ["/img/M.SH-K0106.PNG"],
    },
    {
      id: 1065,
      name: "M.SH-K0104",
      images: ["/img/M.SH-K0104.PNG"],
    },
    {
      id: 1066,
      name: "M.SH-K0116",
      images: ["/img/M.SH-K0116.PNG"],
    },
    {
      id: 1067,
      name: "M.SH-K0115",
      images: ["/img/M.SH-K0115.PNG"],
    },
    {
      id: 1068,
      name: "M.SH-K099",
      images: ["/img/M.SH-K099.png"],
    },
    {
      id: 1069,
      name: "M.SH-K098",
      images: ["/img/M.SH-K098.PNG"],
    },
    {
      id: 1070,
      name: "M.SH-K0107",
      images: ["/img/M.SH-K0107.PNG"],
    },
    {
      id: 1071,
      name: "M.SH-K0109",
      images: ["/img/M.SH-K0109.PNG"],
    },
    {
      id: 1072,
      name: "M.SH-K0108",
      images: ["/img/M.SH-K0108.PNG"],
    },
    {
      id: 1073,
      name: "M.SH-K0105",
      images: ["/img/M.SH-K0105.PNG"],
    },
    {
      id: 1074,
      name: "M.SH-K0100",
      images: ["/img/M.SH-K0100.PNG"],
    },
    {
      id: 1075,
      name: "M.SH-K0101",
      images: ["/img/M.SH-K0101.PNG"],
    },
    {
      id: 1076,
      name: "M.SH-K0103",
      images: ["/img/M.SH-K0103.PNG"],
    },
    {
      id: 1077,
      name: "M.SH-K095",
      images: ["/img/M.SH-K095.PNG"],
    },
    {
      id: 1078,
      name: "M.SH-K097",
      images: ["/img/M.SH-K097.PNG"],
    },
    {
      id: 1079,
      name: "M.SH-K087",
      images: ["/img/M.SH-K087.PNG"],
    },
    {
      id: 1080,
      name: "M.SH-K096",
      images: ["/img/M.SH-K096.PNG"],
    },
    {
      id: 1081,
      name: "M.SH-K077",
      images: ["/img/M.SH-K077.PNG"],
    },
    {
      id: 1082,
      name: "M.SH-K080",
      images: ["/img/M.SH-K080.PNG"],
    },
    {
      id: 1083,
      name: "M.SH-K085",
      images: ["/img/M.SH-K085.PNG"],
    },
    {
      id: 1084,
      name: "M.SH-K0118",
      images: ["/img/M.SH-K0118.PNG"],
    },
    {
      id: 1085,
      name: "M.SH-K0120",
      images: ["/img/M.SH-K0120.PNG"],
    },
    {
      id: 1086,
      name: "M.SH-K091",
      images: ["/img/M.SH-K091.PNG"],
    },
    {
      id: 1087,
      name: "M.SH-K0119",
      images: ["/img/M.SH-K0119.PNG"],
    },
    {
      id: 1088,
      name: "M.SH-K0110",
      images: ["/img/M.SH-K0110.PNG"],
    },
    {
      id: 1089,
      name: "M.SH-K0117",
      images: ["/img/M.SH-K0117.PNG"],
    },
    {
      id: 1090,
      name: "M.SH 005",
      images: ["/img/M.SH 005.PNG"],
    },
    {
      id: 1091,
      name: "M.SH 004",
      images: ["/img/M.SH 004.PNG"],
    },
    {
      id: 1092,
      name: "M.SH 28",
      images: ["/img/M.SH 28.PNG"],
    },
    {
      id: 1093,
      name: "M.SH 006",
      images: ["/img/M.SH 006.PNG"],
    },
    {
      id: 1094,
      name: "M.SH 024",
      images: ["/img/M.SH 024.PNG"],
    },
    {
      id: 1095,
      name: "M.SH 025",
      images: ["/img/M.SH 025.PNG"],
    },
    {
      id: 1096,
      name: "M.SH 021",
      images: ["/img/M.SH 021.PNG"],
    },
    {
      id: 1097,
      name: "M.SH 018",
      images: ["/img/M.SH 018.PNG"],
    },
    {
      id: 1098,
      name: "M.SH 017",
      images: ["/img/M.SH 017.PNG"],
    },
    {
      id: 1099,
      name: "M.SH 007",
      images: ["/img/M.SH 007.PNG"],
    },
    {
      id: 1100,
      name: "M.SH 011",
      images: ["/img/M.SH 011.PNG"],
    },
    {
      id: 1101,
      name: "M.SH 014",
      images: ["/img/M.SH 014.PNG"],
    },
    {
      id: 1102,
      name: "M.SH 020",
      images: ["/img/M.SH 020.PNG"],
    },
    {
      id: 1103,
      name: "M.SH 012",
      images: ["/img/M.SH 012.PNG"],
    },
    {
      id: 1104,
      name: "M.SH 019",
      images: ["/img/M.SH 019.PNG"],
    },
    {
      id: 1105,
      name: "M.SH 013",
      images: ["/img/M.SH 013.PNG"],
    },
    {
      id: 1106,
      name: "M.SH 008",
      images: ["/img/M.SH 008.PNG"],
    },
    {
      id: 1108,
      name: "M.SH 022",
      images: ["/img/M.SH 022.PNG"],
    },
    {
      id: 1109,
      name: "M.SH 023",
      images: ["/img/M.SH 023.PNG"],
    },
    {
      id: 1110,
      name: "M.SH 026",
      images: ["/img/M.SH 026.PNG"],
    },
    {
      id: 1111,
      name: "T.900",
      images: ["/img/T.900.PNG"],
    },
    {
      id: 1112,
      name: "T.901",
      images: ["/img/T.901.PNG"],
    },
    {
      id: 1113,
      name: "T.902",
      images: ["/img/T.902.PNG"],
    },
    {
      id: 1114,
      name: "T.903",
      images: ["/img/T.903.PNG"],
    },
    {
      id: 1115,
      name: "T.904",
      images: ["/img/T.904.PNG"],
    },
    {
      id: 1116,
      name: "M.SH 015",
      images: ["/img/M.SH 015.PNG"],
    },
    {
      id: 1117,
      name: "M.SH 016",
      images: ["/img/M.SH 016.PNG"],
    },
    {
      id: 1118,
      name: "M.SH 010",
      images: ["/img/M.SH 010.PNG"],
    },
    {
      id: 1119,
      name: "M.SH 009",
      images: ["/img/M.SH 009.PNG"],
    },
    {
      id: 1120,
      name: "T.951",
      images: ["/img/T.951.PNG"],
    },
    {
      id: 1121,
      name: "T.950",
      images: ["/img/T.950.PNG"],
    },
    {
      id: 10024,
      name: "TYPE-05 New Generation Fluted Board",
      images: ["/uploads/tip-05-fibercement-fugali-levha.png"],
      details: [
        "Incombustibility Class: A1",
        "Patterns: Smooth, Cedar, Walnut",
      ],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 100 - 415 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,04",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 150 - 615 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,54",
          ],
        },
      ],
      children: [],
    },
    {
      id: 10025,
      name: "TYPE-04 New Generation Fluted Board",
      images: ["/uploads/tip-04-fibercement-fugali-levha.png"],
      details: ["Incombustibility Class: A1", "Patterns: Smooth"],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 275 - 605 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,51",
          ],
        },
      ],
      children: [],
    },
    {
      id: 10026,
      name: "TYPE-03 New Generation Fluted Board",
      images: ["/uploads/tip-03-fibercement-fugali-levha.png"],
      details: ["Incombustibility Class: A1", "Patterns: Smooth"],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 175 - 405 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,01",
          ],
        },
      ],
      children: [],
    },
    {
      id: 10027,
      name: "TYPE-02 New Generation Fluted Board",
      images: ["/uploads/tip-02-fibercement-fugali-levha.png"],
      details: [
        "Incombustibility Class: A1",
        "Patterns: Smooth, Cedar, Walnut, Natural, Stone, Decorative, HekimStone",
      ],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 375 - 410 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,03",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 580 - 615 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,54",
          ],
        },
      ],
      children: [],
    },
    {
      id: 10028,
      name: "TYPE-01 New Generation Fluted Board",
      images: ["/uploads/tip-01-fibercement-fugali-levha.png"],
      details: [
        "Incombustibility Class: A1",
        "Patterns: Smooth, Cedar, Walnut, Natural, Stone, Decorative, HekimStone",
      ],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 175 - 405 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,01",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 275 - 605 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,51",
          ],
        },
      ],
      children: [],
    },
    {
      id: 10018,
      name: "TurkSiding",
      images: ["/uploads/turksiding-1080x1080.jpg"],
      details: [],
      children: [10029, 10030],
    },
    {
      id: 10029,
      name: "Cedar Pattern Siding",
      images: ["/uploads/sedir-agaci-desenli-fibercement-siding.png"],
      details: ["Incombustibility Class: A1"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 170 x 2500",
            "Thickness (mm): 8",
            "Plate Area (m2): 0,425",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 	170 x 2500",
            "Thickness (mm): 10",
            "Plate Area (m2): 0,425",
          ],
        },
        {
          key: "3",
          values: [
            "Dimensions (mm x mm): 200 x 2500",
            "Thickness (mm): 8",
            "Plate Area (m2): 0,500",
          ],
        },
        {
          key: "4",
          values: [
            "Dimensions (mm x mm): 	200 x 2500",
            "Thickness (mm): 10",
            "Plate Area (m2): 0,500",
          ],
        },
      ],
    },
    {
      id: 10030,
      name: "Walnut Pattern Siding",
      images: ["/uploads/ceviz-agaci-desenli-fibercement-siding.png"],
      details: ["Incombustibility Class: A1"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 170 x 2500",
            "Thickness (mm): 8",
            "Plate Area (m2): 0,425",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 170 x 2500",
            "Thickness (mm): 10",
            "Plate Area (m2): 0,425",
          ],
        },
        {
          key: "3",
          values: [
            "Dimensions (mm x mm): 200 x 2500",
            "Thickness (mm): 8",
            "Plate Area (m2): 0,500",
          ],
        },
        {
          key: "4",
          values: [
            "Dimensions (mm x mm): 200 x 2500",
            "Thickness (mm): 10",
            "Plate Area (m2): 0,500",
          ],
        },
        {
          key: "5",
          values: [
            "Dimensions (mm x mm): 170 x 3000",
            "Thickness (mm): 8",
            "Plate Area (m2): 0,510",
          ],
        },
        {
          key: "6",
          values: [
            "Dimensions (mm x mm): 	170 x 3000",
            "Thickness (mm): 10",
            "Plate Area (m2): 0,510",
          ],
        },
        {
          key: "7",
          values: [
            "Dimensions (mm x mm): 200 x 3000",
            "Thickness (mm): 8",
            "Plate Area (m2): 0,600",
          ],
        },
        {
          key: "8",
          values: [
            "Dimensions (mm x mm): 200 x 3000",
            "Thickness (mm): 10",
            "Plate Area (m2): 0,600",
          ],
        },
      ],
    },
    {
      id: 10019,
      name: "Jamb",
      images: ["/uploads/sove-1080x1080.jpg"],
      details: [],
      children: [10031, 10032, 10033, 10034, 10035, 10036],
    },
    {
      id: 10031,
      name: "PS-04 Window Casing",
      images: ["/uploads/ps-04-fibercement-pencere-sovesi.png"],
      details: ["Incombustibility Class: A1"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 150 x 2500",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,375",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 150 x 3000",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,45",
          ],
        },
      ],
    },
    {
      id: 10032,
      name: "PS-03 Window Casing",
      images: ["/uploads/ps-03-fibercement-pencere-sovesi.png"],
      details: ["Incombustibility Class: A1"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 150 x 2500",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,375",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 150 x 3000",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,45",
          ],
        },
      ],
    },
    {
      id: 10033,
      name: "PS-02 Window Casing",
      images: ["/uploads/ps-02-fibercement-pencere-sovesi.png"],
      details: ["Incombustibility Class: A1"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 120 x 2500",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,3",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 120 x 3000",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,36",
          ],
        },
      ],
    },
    {
      id: 10034,
      name: "PS-01 Window Casing",
      images: ["/uploads/ps-01-fibercement-pencere-sovesi.png"],
      details: ["Incombustibility Class: A1"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 100 x 2500",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,25",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 100 x 3000",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,30",
          ],
        },
      ],
    },
    {
      id: 10035,
      name: "Plain Casing",
      images: ["/uploads/duz-fibercement-sove.png"],
      details: ["Incombustibility Class: A1"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 80 x 2500",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,2",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 100 x 2500",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,25",
          ],
        },
        {
          key: "3",
          values: [
            "Dimensions (mm x mm): 150 x 2500",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,375",
          ],
        },
        {
          key: "4",
          values: [
            "Dimensions (mm x mm): 200 x 2500",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,5",
          ],
        },
        {
          key: "5",
          values: [
            "Dimensions (mm x mm): 80 x 3000",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,2",
          ],
        },
        {
          key: "6",
          values: [
            "Dimensions (mm x mm): 100 x 3000",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,25",
          ],
        },
        {
          key: "7",
          values: [
            "Dimensions (mm x mm): 150 x 3000",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,375",
          ],
        },
        {
          key: "8",
          values: [
            "Dimensions (mm x mm): 200 x 3000",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,5",
          ],
        },
      ],
    },
    //OVDJE
    {
      id: 10020,
      name: "Floor Wiping",
      images: ["/uploads/kat-silmesi-1080x1080.jpg"],
      details: [],
      children: [10037, 10038, 10039],
    },
    {
      id: 10037,
      name: "KS-01 Floor Molding",
      images: ["/uploads/ks-01-fibercement-kat-silmesi_1.png"],
      details: ["Incombustibility Class: A1"],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 200 x 2500",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,5",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 200 x 3000",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,6",
          ],
        },
      ],
      children: [],
    },
    {
      id: 10038,
      name: "KS-02 Floor Molding",
      images: ["/uploads/ks-02-fibercement-kat-silmesi.png"],
      details: ["Incombustibility Class: A1"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 205 x 2500",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,51",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 205 x 3000",
            "Thickness (mm): 20",
            "Plate Area (m2): 0,61",
          ],
        },
      ],
    },
    {
      id: 10021,
      name: "Jointed Sheet",
      images: ["/uploads/hekimboard-fuagli-levha-1080x1080.jpg"],
      details: [],
      children: [10040, 10041, 10042, 10043, 10044],
    },
    {
      id: 10040,
      name: "TYPE-E Fluted Board",
      images: ["/uploads/tip-e-fibercement-fugali-levha.png"],
      details: [
        "Incombustibility Class: A1",
        "Patterns: 	Smooth, Cedar, Walnut, Natural Stone, Decorative Stone, HekimStone",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 607 - 625 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,56",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 607 - 625 x 3000",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,87",
          ],
        },
      ],
    },
    {
      id: 10041,
      name: "TYPE-D Fluted Board",
      images: ["/uploads/tip-d-fibercement-fugali-levha.png"],
      details: [
        "Incombustibility Class: A1",
        "Patterns: 	Smooth, Cedar, Walnut, Natural Stone, Decorative Stone, HekimStone",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 385 - 403 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,01",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 	385 - 403 x 3000",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,20",
          ],
        },
      ],
    },
    {
      id: 10042,
      name: "TYPE-C Fluted Board",
      images: ["/uploads/tip-c-fibercement-fugali-levha.png"],
      details: [
        "Incombustibility Class: A1",
        "Patterns: 	Smooth, Cedar, Walnut, Natural Stone, Decorative Stone, HekimStone",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 190 - 608 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,52",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 190 - 608 x 3000",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,82",
          ],
        },
      ],
    },
    {
      id: 10043,
      name: "TYPE-B Fluted Board",
      images: ["/uploads/tip-b-fibercement-fugali-levha.png"],
      details: [
        "Incombustibility Class: A1",
        "Patterns: 	Smooth, Cedar, Walnut, Natural Stone, Decorative Stone, HekimStone",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 290 - 608 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,52",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 290 - 608 x 3000",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,82",
          ],
        },
      ],
    },
    {
      id: 10044,
      name: "TYPE-A Fluted Board",
      images: ["/uploads/tip-a-fibercement-fugali-levha.png"],
      details: [
        "Incombustibility Class: A1",
        "Patterns: 	Smooth, Cedar, Walnut, Natural Stone, Decorative Stone, HekimStone",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 125 - 413 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,03",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 125 - 413 x 3000",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,24",
          ],
        },
      ],
    },
    {
      id: 10022,
      name: "Flat Sheet",
      images: ["/uploads/hekim-board-duz-levha-1080x1080.jpg"],
      details: [],
      children: [10045, 10046],
    },
    {
      id: 10045,
      name: "Plain Internal Board",
      images: ["/uploads/duz-ic-fibercement-levha.png"],
      details: ["Incombustibility Class: A1"],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 6",
            "Plate Area (m2): 3.125",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 8",
            "Plate Area (m2): 3.125",
          ],
        },
        {
          key: "3",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 10",
            "Plate Area (m2): 3.125",
          ],
        },
        {
          key: "4",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 3.125",
          ],
        },
        {
          key: "5",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 14",
            "Plate Area (m2): 3.125",
          ],
        },
        {
          key: "6",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 16",
            "Plate Area (m2): 3.125",
          ],
        },
        {
          key: "7",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 18",
            "Plate Area (m2): 3.125",
          ],
        },
        {
          key: "8",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 20",
            "Plate Area (m2): 3.125",
          ],
        },
        {
          key: "9",
          values: [
            "Dimensions (mm x mm): 1250 x 2800",
            "Thickness (mm): 8",
            "Plate Area (m2): 3.50",
          ],
        },
        {
          key: "10",
          values: [
            "Dimensions (mm x mm): 1250 x 2800",
            "Thickness (mm): 10",
            "Plate Area (m2): 3.50",
          ],
        },
        {
          key: "11",
          values: [
            "Dimensions (mm x mm): 1250 x 2800",
            "Thickness (mm): 12",
            "Plate Area (m2): 3.50",
          ],
        },
        {
          key: "12",
          values: [
            "Dimensions (mm x mm): 1250 x 2800",
            "Thickness (mm): 14",
            "Plate Area (m2): 3.50",
          ],
        },
        {
          key: "13",
          values: [
            "Dimensions (mm x mm): 1250 x 2800",
            "Thickness (mm): 16",
            "Plate Area (m2): 3.50",
          ],
        },
        {
          key: "14",
          values: [
            "Dimensions (mm x mm): 1250 x 2800",
            "Thickness (mm): 18",
            "Plate Area (m2): 3.50",
          ],
        },
        {
          key: "15",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 6",
            "Plate Area (m2): 3.75",
          ],
        },
        {
          key: "16",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 8",
            "Plate Area (m2): 3.75",
          ],
        },
        {
          key: "17",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 10",
            "Plate Area (m2): 3.75",
          ],
        },
        {
          key: "18",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 12",
            "Plate Area (m2): 3.75",
          ],
        },
        {
          key: "19",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 14",
            "Plate Area (m2): 3.75",
          ],
        },
        {
          key: "20",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 16",
            "Plate Area (m2): 3.75",
          ],
        },
        {
          key: "21",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 18",
            "Plate Area (m2): 3.75",
          ],
        },
        {
          key: "22",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 20",
            "Plate Area (m2): 3.75",
          ],
        },
      ],
      children: [],
    },
    {
      id: 10046,
      name: "Plain External Board",
      images: ["/uploads/duz-dis-fibercement-levha.png"],
      details: ["Incombustibility Class: A1"],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 6",
            "Plate Area (m2): 3.125",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 8",
            "Plate Area (m2): 3.125",
          ],
        },
        {
          key: "3",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 10",
            "Plate Area (m2): 3.125",
          ],
        },
        {
          key: "4",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 3.125",
          ],
        },
        {
          key: "5",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 14",
            "Plate Area (m2): 3.125",
          ],
        },
        {
          key: "6",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 16",
            "Plate Area (m2): 3.125",
          ],
        },
        {
          key: "7",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 18",
            "Plate Area (m2): 3.125",
          ],
        },
        {
          key: "8",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 20",
            "Plate Area (m2): 3.125",
          ],
        },
        {
          key: "9",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 6",
            "Plate Area (m2): 3.75",
          ],
        },
        {
          key: "10",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 8",
            "Plate Area (m2): 3.75",
          ],
        },
        {
          key: "11",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 10",
            "Plate Area (m2): 3.75",
          ],
        },
        {
          key: "12",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 12",
            "Plate Area (m2): 3.75",
          ],
        },
        {
          key: "13",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 14",
            "Plate Area (m2): 3.75",
          ],
        },
        {
          key: "14",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 16",
            "Plate Area (m2): 3.75",
          ],
        },
        {
          key: "15",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 18",
            "Plate Area (m2): 3.75",
          ],
        },
        {
          key: "16",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 20",
            "Plate Area (m2): 3.75",
          ],
        },
      ],
      children: [],
    },
    
    {
      id: 10047,
      name: "Cedar Pattern Board",
      images: ["/uploads/sedir-agaci-desenli-fibercement-levha.png"],
      details: ["Incombustibility Class: A1"],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 8",
            "Plate Area (m2): 3,125",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 10",
            "Plate Area (m2): 3,125",
          ],
        },
        ,
        {
          key: "3",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 3,125",
          ],
        },
      ],
      children: [],
    },
    {
      id: 10049,
      name: "Stone Masonry Pattern Board",
      images: ["/uploads/orme-tas-desenli-fibercement-levha.png"],
      details: ["Incombustibility Class: A1"],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 1250 x 2620",
            "Thickness (mm): 10",
            "Plate Area (m2): 3,275",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 1250 x 2620",
            "Thickness (mm): 12",
            "Plate Area (m2): 3,275",
          ],
        },
        {
          key: "3",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 10",
            "Plate Area (m2): 3,75",
          ],
        },
        {
          key: "4",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 12",
            "Plate Area (m2): 3,75",
          ],
        },
      ],
      children: [],
    },
    {
      id: 10050,
      name: "Small Block Stone Pattern Board",
      images: ["/uploads/kucuk-blok-tas-desenli-fibercement-levha.png"],
      details: ["Incombustibility Class: A1"],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 92,5x195 - 615 x 2460	",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,513",
          ],
        },
      ],
      children: [],
    },
    {
      id: 10051,
      name: "Mixed Stone Pattern Board",
      images: ["/uploads/karisik-tas-desenli-fibercement-levha.png"],
      details: ["Incombustibility Class: A1"],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 1225 x 2500",
            "Thickness (mm): 10",
            "Plate Area (m2): 3,21",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 1225 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 3,21",
          ],
        },
        {
          key: "3",
          values: [
            "Dimensions (mm x mm): 1225 x 3000",
            "Thickness (mm): 10",
            "Plate Area (m2): 3,675",
          ],
        },
        {
          key: "4",
          values: [
            "Dimensions (mm x mm): 1225 x 3000",
            "Thickness (mm): 12",
            "Plate Area (m2): 3,675",
          ],
        },
      ],
      children: [],
    },
    {
      id: 10052,
      name: "HekimStone Stone Pattern Board",
      images: ["/uploads/hekimstone-tas-desenli-fibercement-levha.png"],
      details: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 10",
            "Plate Area (m2): 3,125",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 3,125",
          ],
        },
      ],
      children: [],
    },
    {
      id: 10053,
      name: "Natural Stone Pattern Board",
      images: ["/uploads/dogal-tas-desenli-fibercement-levha.png"],
      details: ["Incombustibility Class: A1"],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 10",
            "Plate Area (m2):3,125",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 3,125",
          ],
        },
        {
          key: "3",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 10",
            "Plate Area (m2): 3,75",
          ],
        },
        {
          key: "4",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 12",
            "Plate Area (m2): 3,75",
          ],
        },
      ],
      children: [],
    },
    {
      id: 10054,
      name: "Decorative Stone Patterned Board",
      images: ["/uploads/dekoratif-tas-desenli-fibercement-levha.png"],
      details: ["Incombustibility Class: A1"],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 10",
            "Plate Area (m2):3,125",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 3,125",
          ],
        },
        {
          key: "3",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 10",
            "Plate Area (m2): 3,75",
          ],
        },
        {
          key: "4",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 12",
            "Plate Area (m2): 3,75",
          ],
        },
      ],
      children: [],
    },
    {
      id: 10055,
      name: "Walnut Pattern Board",
      images: ["/uploads/ceviz-agaci-desenli-fibercement-levha.png"],
      details: ["Incombustibility Class: A1"],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 8",
            "Plate Area (m2): 3,75",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 10",
            "Plate Area (m2):3,125",
          ],
        },
        {
          key: "3",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 12",
            "Plate Area (m2): 3,125",
          ],
        },
        {
          key: "4",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 8",
            "Plate Area (m2): 3,75",
          ],
        },
        {
          key: "5",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 10",
            "Plate Area (m2): 3,75",
          ],
        },
        {
          key: "6",
          values: [
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 12",
            "Plate Area (m2): 3,75",
          ],
        },
      ],
      children: [],
    },
    {
      id: 10056,
      name: "Large Block Stone Pattern Board",
      images: ["/uploads/buyuk-blok-tas-desenli-fibercement-levha.png"],
      details: ["Incombustibility Class: A1"],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 195x400 - 615 x 2460",
            "Thickness (mm): 12",
            "Plate Area (m2): 1,513",
          ],
        },
      ],

      children: [],
    },
    {
      id: 10001,
      name: "HekimPanel",
      images: ["/uploads/hekimpanel_1.jpg"],
      details: [],
      children: [10057, 10058, 10059],
    },
    {
      id: 10057,
      name: "Container Panel",
      images: ["/uploads/soguk-oda-tipi-sac-eps-pet-cephe-paneli.png"],
      details: [],
      children: [10060, 10061, 10062, 10063, 10064, 10065, 10066],
    },
    {
      id: 10060,
      name: "Cold Room Type Metal Sheet Rock Wool Panel",
      images: ["/uploads/soguk-oda-tipi-sac-tasyunu-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: A2.S1.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 600 - 1250",
            "Width (mm): 2150 - 15000",
            "Insulation Thickness (mm): 40 - 150",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,4 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10061,
      name: "Cold Room Type Metal Sheet EPS Panel",
      images: ["/uploads/SoIuk-Oda-Tipi-Sac-EPS-Sac-Panel.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 600 - 1250",
            "Width (mm): 2150 - 15000",
            "Insulation Thickness (mm): 40 - 180",
            "TopMetal Sheet Thickness (mm): 0,3 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,3 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10062,
      name: "Cold Room Type Metal Sheet Polyurethane Panel",
      images: ["/uploads/soguk-oda-tipi-sac-poliuretan-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: C.S3.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 800 - 1250",
            "Width (mm): 2150 - 15000",
            "Insulation Thickness (mm): 40 - 150",
            "TopMetal Sheet Thickness (mm): 0,35 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,3 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10063,
      name: "Cold Room Type Metal Sheet PIR Panel",
      images: ["/uploads/soguk-oda-tipi-sac-pir-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: B.S2.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 800 - 1250",
            "Width (mm): 2150 - 15000",
            "Insulation Thickness (mm): 40 - 150",
            "TopMetal Sheet Thickness (mm): 0,35 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,3 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10064,
      name: "Cold Room Type Metal Sheet EPS-Pet Panel",
      images: ["/uploads/soguk-oda-tipi-sac-eps-pet-cephe-paneli.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 600 - 1250",
            "Width (mm): 2500 - 15000",
            "Insulation Thickness (mm): 40 - 180",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,6 - 0,8",
          ],
        },
      ],
    },
    {
      id: 10065,
      name: "Cold Room Type Metal Sheet Rock Wool-Fibercement Panel",
      images: [
        "/uploads/soguk-oda-tipi-sac-tasyunu-fibercement-cephe-paneli.png",
      ],
      details: ["Incombustibility Class: A2.S1.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 600 - 1250",
            "Width (mm): 2500 - 3000",
            "Insulation Thickness (mm): 40 - 150",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 8 - 10",
          ],
        },
      ],
    },
    {
      id: 10066,
      name: "Cold Room Type Metal Sheet EPS-Fibercement Panel",
      images: ["/uploads/soguk-oda-tipi-sac-eps-fibercement-cephe-paneli.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 600 - 1250",
            "Width (mm): 2500 - 3000",
            "Insulation Thickness (mm): 40 - 180",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 8 - 10",
          ],
        },
      ],
    },
    {
      id: 10058,
      name: "Facade Panels",
      images: ["/uploads/cephe-panelleri-1080x1080.jpg"],
      details: [],
      children: [
        10067, 10068, 10069, 10070, 10071, 10072, 10073, 10074, 10075, 10076,
        10077, 10078, 10079, 10080, 10081, 10082, 10083, 10084, 10085, 10086,
        10087, 10088, 10089, 10090, 10091, 10092, 10093, 10094,
      ],
    },
    {
      id: 10067,
      name: "Pure Sine Metal Sheet PIR Panel",
      images: ["/uploads/tam-sinus-sac-pir-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: B.S2.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 50	",
            "TopMetal Sheet Thickness (mm): 0,5 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,35 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10068,
      name: "Half Sine Metal Sheet PIR Panel",
      images: ["/uploads/yarim-sinus-sac-pir-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: B.S2.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 100",
            "TopMetal Sheet Thickness (mm): 0,5 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,35 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10069,
      name: "Pure Sine Metal Sheet Polyurethane Sheet Panel",
      images: ["/uploads/tam-sinus-sac-poliuretan-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: A2.S1.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 600 - 1250",
            "Width (mm): 2150 - 15000",
            "Insulation Thickness (mm): 40 - 150",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,4 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10070,
      name: "Half Sine Metal Sheet Polyurethane Panel",
      images: ["/uploads/yarim-sinus-sac-poliuretan-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: C.S3.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 100",
            "TopMetal Sheet Thickness (mm): 0,5 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,35 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10071,
      name: "H Type Metal Sheet-Rock Wool Panel",
      images: ["/uploads/h-tipi-sac-tasyunu-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: A2.S1.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 600 - 1250",
            "Width (mm): 2150 - 15000",
            "Insulation Thickness (mm): 40 - 150",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,4 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10072,
      name: "Siding Metal Sheet-Polyurethane Panel",
      images: ["/uploads/siding-sac-poliuretan-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: C.S3.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 100",
            "TopMetal Sheet Thickness (mm): 0,5 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,35 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10073,
      name: "Siding Metal Sheet-PIR Panel",
      images: ["/uploads/siding-sac-pir-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: B.S2.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 100",
            "TopMetal Sheet Thickness (mm): 0,5 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,35 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10074,
      name: "H Type Metal Sheet-Rock Wool-Fibercement Panel",
      images: ["/uploads/H-Tipi-Sac-TaIyunu-Fibercement-Panel.png"],
      details: ["Incombustibility Class: A2.S1.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 60 - 1250",
            "Width (mm): 2500 - 3000",
            "Insulation Thickness (mm): 40 - 150",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 8 - 10",
          ],
        },
      ],
    },
    {
      id: 10075,
      name: "H Type Metal Sheet PIR Panel",
      images: ["/uploads/H-Tipi-Sac-PIR-Sac-Panel.png"],
      details: ["Incombustibility Class: B.S2.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000 - 1250",
            "Width (mm): 2500 - 3000",
            "Insulation Thickness (mm): 50 - 100",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 8",
          ],
        },
      ],
    },
    {
      id: 10076,
      name: "H Tipi Sac-PIR-Fibercement Panel",
      images: ["/uploads/h-tipi-sac-pir-fibercement-cephe-paneli.png"],
      details: ["Incombustibility Class: B.S2.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000 - 1250",
            "Width (mm): 2500 - 3000",
            "Insulation Thickness (mm): 50 - 100",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 8",
          ],
        },
      ],
    },
    {
      id: 10077,
      name: "H Type Metal Sheet Polyurethane Panel",
      images: ["/uploads/h-tipi-sac-poliuretan-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: C.S3.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 800 - 1250",
            "Width (mm): 2500 - 15000",
            "Insulation Thickness (mm): 50 - 100",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,4 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10078,
      name: "H Type Metal Sheet-EPS Panel",
      images: ["/uploads/H-Tipi-Sac-EPS-Sac-Panel.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 600 - 1250",
            "Width (mm): 2150 - 15000",
            "Insulation Thickness (mm): 40 - 150",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,4 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10079,
      name: "H Type Metal Sheet Polyurethane-Fibercement Panel",
      images: ["/uploads/H-Tipi-Sac-Poliuretan-Fibercement-Panel.png"],
      details: ["Incombustibility Class: C.S3.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000 - 1250",
            "Width (mm): 2500 - 3000",
            "Insulation Thickness (mm): 50 - 100",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 8",
          ],
        },
      ],
    },
    {
      id: 10080,
      name: "Blind Screw Metal Sheet Polyurethane Panel",
      images: ["/uploads/gizli-vida-sac-poliuretan-sac-cephe-paneli-1.png"],
      details: ["Incombustibility Class: C.S3.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 800 - 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 80",
            "TopMetal Sheet Thickness (mm): 0,35 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,3 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10081,
      name: "H Type Metal Sheet-EPS-Pet Panel",
      images: ["/uploads/h-tipi-sac-eps-pet-cephe-paneli.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 600 - 1250",
            "Width (mm): 2150 - 15000",
            "Insulation Thickness (mm): 50 - 180",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7	",
            "BottomMetal Sheet Thickness (mm): 0,6 - 0,8",
          ],
        },
      ],
    },
    {
      id: 10082,
      name: "H Type Metal Sheet EPS-Fibercement Panel",
      images: ["/uploads/h-tipi-sac-eps-fibercement-cephe-paneli.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 600 - 1250",
            "Width (mm): 2150 - 15000",
            "Insulation Thickness (mm): 50 - 180",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7	",
            "BottomMetal Sheet Thickness (mm): 8 - 10",
          ],
        },
      ],
    },
    {
      id: 10083,
      name: "Blind Screw Metal Sheet PIR Panel",
      images: ["/uploads/gizli-vida-sac-pir-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: B.S2.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 800 - 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 80",
            "TopMetal Sheet Thickness (mm): 0,35 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,3 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10084,
      name: "Blind Screw Metal Sheet Rockwool Panel",
      images: ["/uploads/Gizli-Vida-Sac-TaIyunu-Sac-Panel.png"],
      details: ["Incombustibility Class: A2.S1.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 150",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,4 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10085,
      name: "Blind Screw Metal Sheet EPS Panel",
      images: ["/uploads/gizli-vida-sac-eps-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 50 - 180",
            "TopMetal Sheet Thickness (mm): 0,35 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,3 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10086,
      name: "Blind Screw Metal Sheet EPS-Pet Panel",
      images: ["/uploads/gizli-vida-sac-eps-pet-cephe-paneli.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 50 - 180",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,6 - 0,8",
          ],
        },
      ],
    },
    {
      id: 10087,
      name: "G Form Metal Sheet Polyurethane Panel",
      images: ["/uploads/g-form-sac-poliuretan-sac-cephe-paneli-1.png"],
      details: ["Incombustibility Class: C.S3.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 100",
            "TopMetal Sheet Thickness (mm): 0,5 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,35 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10088,
      name: "G Form Metal Sheet-PIR Panel",
      images: ["/uploads/g-form-sac-pir-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: B.S2.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 100",
            "TopMetal Sheet Thickness (mm): 0,5 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,35 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10089,
      name: "5 Ribs Fluted Metal Sheet Poliüretan Panel",
      images: ["/uploads/5-hadve-fugali-sac-poliuretan-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: C.S3.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 100",
            "TopMetal Sheet Thickness (mm): 0,5 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,35 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10090,
      name: "5 Ribs Fluted Metal Sheet PIR Panel",
      images: ["/uploads/5-hadve-fugali-sac-pir-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: B.S2.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 100",
            "TopMetal Sheet Thickness (mm): 0,5 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,35 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10091,
      name: "3 Ribs Fluted Metal Sheet Polyurethane Panel",
      images: ["/uploads/3-hadve-fugali-sac-poliuretan-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: C.S3.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 100",
            "TopMetal Sheet Thickness (mm): 0,5 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,35 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10092,
      name: "3 Ribs Fluted Metal Sheet PIR Panel",
      images: ["/uploads/3-hadve-fugali-sac-pir-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: B.S2.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 100",
            "TopMetal Sheet Thickness (mm): 0,5 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,35 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10093,
      name: "1 Rib Fluted Metal Sheet Polyurethane Panel",
      images: ["/uploads/1-hadve-fugali-sac-poliuretan-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: C.S3.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 100",
            "TopMetal Sheet Thickness (mm): 0,5 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,35 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10094,
      name: "1 Rib Fluted Metal Sheet PIR Panel",
      images: ["/uploads/1-hadve-fugali-sac-pir-sac-cephe-paneli.png"],
      details: ["Incombustibility Class: B.S2.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 100",
            "TopMetal Sheet Thickness (mm): 0,5 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,35 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10059,
      name: "Roof Panels",
      images: ["/uploads/cati-panelleri-1080x1080.jpg"],
      details: [],
      children: [
        10095, 10096, 10097, 10098, 10099, 10100, 10101, 10102, 10103, 10104,
        10105, 10106,
      ],
    },
    {
      id: 10095,
      name: "5 Ribs Metal Sheet-Rockwool Panel",
      images: ["/uploads/5-hadve-sac-tasyunu-sac-cati-paneli.png"],
      details: ["Incombustibility Class: A1"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 150",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,4 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10096,
      name: "5 Ribs Metal Sheet-Polyurethane Panel",
      images: ["/uploads/5-hadve-sac-poliuretan-sac-cati-paneli.png"],
      details: ["Incombustibility Class: C.S3.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2500 - 15000",
            "Insulation Thickness (mm): 35 - 100",
            "TopMetal Sheet Thickness (mm): 0,3 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,3 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10097,
      name: "5 Ribs Metal Sheet-PIR Panel",
      images: ["/uploads/5-hadve-sac-pir-sac-cati-paneli.png"],
      details: ["Incombustibility Class: B.S2.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2500 - 15000",
            "Insulation Thickness (mm): 35 - 100",
            "TopMetal Sheet Thickness (mm): 0,3 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,3 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10098,
      name: "5 Ribs Metal Sheet-PIR-Membrane Panel",
      images: ["/uploads/5-hadve-sac-pir-membran-cati-paneli.png"],
      details: ["Incombustibility Class: B.S2.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2500 - 15000",
            "Insulation Thickness (mm): 40 - 80",
            "TopMetal Sheet Thickness (mm): 0,45 - 0,7",
            "BottomMetal Sheet Thickness (mm): 1,2",
          ],
        },
      ],
    },
    {
      id: 10099,
      name: "5 Ribs Metal Sheet EPS Panel",
      images: ["/uploads/5-hadve-sac-eps-sac-cati-paneli-1.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 180",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,3 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10100,
      name: "5 Ribs Metal Sheet-Polyurethane-Membrane Panel",
      images: ["/uploads/5-hadve-sac-poliuretan-membran-cati-paneli.png"],
      details: ["Incombustibility Class: C.S3.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2500 - 15000",
            "Insulation Thickness (mm): 40 - 80",
            "TopMetal Sheet Thickness (mm): 0,45 - 0,7",
            "BottomMetal Sheet Thickness (mm): 1,2",
          ],
        },
      ],
    },
    {
      id: 10101,
      name: "5 Ribs-EPS-Pet Panel",
      images: ["/uploads/5-hadve-sac-eps-pet-cati-paneli.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 180",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,6 - 0,8",
          ],
        },
      ],
    },
    {
      id: 10102,
      name: "3 Ribs Metal Sheet Polyurethane Panel",
      images: ["/uploads/3-hadve-sac-poliuretan-sac-cati-paneli.png"],
      details: ["Incombustibility Class: C.S3.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2500 - 15000",
            "Insulation Thickness (mm): 35 - 100",
            "TopMetal Sheet Thickness (mm): 0,3 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,3 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10103,
      name: "3 Ribs Sac-PIR-Metal Sheet Panel",
      images: ["/uploads/3-hadve-sac-pir-sac-cati-paneli.png"],
      details: ["Incombustibility Class: B.S2.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2500 - 15000",
            "Insulation Thickness (mm): 35 - 100",
            "TopMetal Sheet Thickness (mm): 0,3 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,3 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10104,
      name: "3 Ribs Metal Sheet-EPS Panel",
      images: ["/uploads/3-hadve-sac-eps-sac-cati-paneli.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 180",
            "TopMetal Sheet Thickness (mm): 0,35 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,3 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10105,
      name: "Cap Profile 3 Ribs Metal Sheet-Polyurethane Panel",
      images: ["/uploads/3-hadve-kepli-sac-poliuretan-sac-cati-paneli.png"],
      details: ["Incombustibility Class: C.S3.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 100",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,3 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10106,
      name: "Cap Profile 3 Ribs Metal Sheet-PIR-Panel",
      images: ["/uploads/3-hadve-kepli-sac-pir-sac-cati-paneli.png"],
      details: ["Incombustibility Class: B.S2.d0"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Height (mm): 1000",
            "Width (mm): 2300 - 15000",
            "Insulation Thickness (mm): 40 - 100",
            "TopMetal Sheet Thickness (mm): 0,4 - 0,7",
            "BottomMetal Sheet Thickness (mm): 0,3 - 0,7",
          ],
        },
      ],
    },
    {
      id: 10002,
      name: "HekimColorboard",
      images: ["/uploads/hekimcolorboard.jpg"],
      details: [],
      children: [10107, 10108, 10109],
    },
    {
      id: 10107,
      name: "Wood Colored Fibercement",
      images: ["/uploads/wood-renkli-fibercement-levha.png"],
      details: [
        "Incombustibility Class: A1",
        "Product Colors: Ecru, Ash, Grey, Smoke, Grey, Anthracite, Beige, White, Coffee, Orange, Tile, Red",
        "Top Coat Colors: Ecru, Ash, Grey, Smoke, Grey, Anthracite, Beige, White, Coffee, Orange, Tile, Red",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 10",
            "Plate Area (m2): 3,125",
          ],
        },
      ],
    },
    {
      id: 10108,
      name: "Striped Colored Fibercement",
      images: ["/uploads/striped-renkli-fibercement-levha.png"],
      details: [
        "Incombustibility Class: A1",
        "Product Colors: Ecru, Ash, Grey, Smoke, Grey, Anthracite, Beige, White, Coffee, Orange, Tile, Red",
        "Top Coat Colors: Ecru, Ash, Grey, Smoke, Grey, Anthracite, Beige, White, Coffee, Orange, Tile, Red",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 10",
            "Plate Area (m2): 3,125",
          ],
        },
      ],
    },
    {
      id: 10109,
      name: "Stone Colored Fibercement",
      images: ["/uploads/stone-renkli-fibercement-levha.png"],
      details: [
        "Incombustibility Class: A1",
        "Product Colors: Ecru, Ash, Grey, Smoke, Grey, Anthracite, Beige, White, Coffee, Orange, Tile, Red",
        "Top Coat Colors: Ecru, Ash, Grey, Smoke, Grey, Anthracite, Beige, White, Coffee, Orange, Tile, Red",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 10",
            "Plate Area (m2): 3,125",
          ],
        },
      ],
    },
    {
      id: 10003,
      name: "Hekimpor",
      images: ["/uploads/hekimpor_1.jpg"],
      details: [],
      children: [10110, 10111, 10112, 10113, 10114],
    },
    {
      id: 10110,
      name: "EPS Insulation Boards",
      images: ["/uploads/eps-yalitim-levhalari-1080x1080.jpg"],
      details: [],
      children: [10115, 10116, 10117],
    },
    {
      id: 10115,
      name: "MaxiPor EPS Board",
      images: ["/uploads/maxipor-eps-levha.png"],
      details: [
        "Incombustibility Class: E",
        "Thanks to the reflective feature of the carbon in its contain, it makes 20% better insulation than normal boards.",
        "Its λ value is 0,035.",
        "Since there are stabilized boards, dimensional stability is stable.",
        "It does not contain CFC (Chlorofluorocarbon) gas and its derivatives.",
        "Its water vapour permeability is high.",
        "Thermal resistance is high.",
        "It has high pressure resistance.",
        "The building does not loss its insulation feature throughout the life of the building.",
        "It is E class flame retardant.",
        "As insulation material inside of Hekimboard Hekimpan prefabricated wall panel.",
        "As a heat insulation material between Brick, Gas Concrete, Pumice Concrete Block and plaster-based prefabricated wall panels.",
        "As heat insulation between walls in partitioned wall production.",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 10",
            "Number of Product in the Package: 50",
            "Total Area (m2): 25",
            "Total Volume (m3): 0.25",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 20",
            "Number of Product in the Package: 25",
            "Total Area (m2): 12.5",
            "Total Volume (m3): 0.25",
          ],
        },
        {
          key: "3",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 30",
            "Number of Product in the Package: 16",
            "Total Area (m2): 8",
            "Total Volume (m3): 0.24",
          ],
        },
        {
          key: "4",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 40",
            "Number of Product in the Package: 12",
            "Total Area (m2): 6",
            "Total Volume (m3): 0.24",
          ],
        },
        {
          key: "5",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 50",
            "Number of Product in the Package: 10",
            "Total Area (m2): 5",
            "Total Volume (m3): 0.25",
          ],
        },
        {
          key: "6",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 60",
            "Number of Product in the Package: 8",
            "Total Area (m2): 4",
            "Total Volume (m3): 0.24",
          ],
        },
        {
          key: "7",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 70",
            "Number of Product in the Package: 7",
            "Total Area (m2): 3.5",
            "Total Volume (m3): 0.245",
          ],
        },
        {
          key: "8",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 80",
            "Number of Product in the Package: 6",
            "Total Area (m2): 3",
            "Total Volume (m3): 0.24",
          ],
        },
        {
          key: "9",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 90",
            "Number of Product in the Package: 5",
            "Total Area (m2): 2.5",
            "Total Volume (m3): 0.225",
          ],
        },
        {
          key: "10",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 100",
            "Number of Product in the Package: 5",
            "Total Area (m2): 2.5",
            "Total Volume (m3): 0.25",
          ],
        },
      ],
    },
    {
      id: 10116,
      name: "Carbon Reinforced EPS Board",
      images: ["/uploads/karbon-takviyeli-eps-levha.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 10",
            "Number of Product in the Package: 50",
            "Total Area (m2): 25",
            "Total Volume (m3): 0.25",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 20",
            "Number of Product in the Package: 25",
            "Total Area (m2): 12.5",
            "Total Volume (m3): 0.25",
          ],
        },
        {
          key: "3",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 30",
            "Number of Product in the Package: 16",
            "Total Area (m2): 8",
            "Total Volume (m3): 0.24",
          ],
        },
        {
          key: "4",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 40",
            "Number of Product in the Package: 12",
            "Total Area (m2): 6",
            "Total Volume (m3): 0.24",
          ],
        },
        {
          key: "5",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 50",
            "Number of Product in the Package: 10",
            "Total Area (m2): 5",
            "Total Volume (m3): 0.25",
          ],
        },
        {
          key: "6",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 60",
            "Number of Product in the Package: 8",
            "Total Area (m2): 4",
            "Total Volume (m3): 0.24",
          ],
        },
        {
          key: "7",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 70",
            "Number of Product in the Package: 7",
            "Total Area (m2): 3.5",
            "Total Volume (m3): 0.245",
          ],
        },
        {
          key: "8",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 80",
            "Number of Product in the Package: 6",
            "Total Area (m2): 3",
            "Total Volume (m3): 0.24",
          ],
        },
        {
          key: "9",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 90",
            "Number of Product in the Package: 5",
            "Total Area (m2): 2.5",
            "Total Volume (m3): 0.225",
          ],
        },
        {
          key: "10",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 100",
            "Number of Product in the Package: 5",
            "Total Area (m2): 2.5",
            "Total Volume (m3): 0.25",
          ],
        },
      ],
    },
    {
      id: 10117,
      name: "White EPS Board",
      images: ["/uploads/beyaz-eps-levha.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 10",
            "Number of Product in the Package: 50",
            "Total Area (m2): 25",
            "Total Volume (m3): 0.25",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 20",
            "Number of Product in the Package: 25",
            "Total Area (m2): 12.5",
            "Total Volume (m3): 0.25",
          ],
        },
        {
          key: "3",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 30",
            "Number of Product in the Package: 16",
            "Total Area (m2): 8",
            "Total Volume (m3): 0.24",
          ],
        },
        {
          key: "4",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 40",
            "Number of Product in the Package: 12",
            "Total Area (m2): 6",
            "Total Volume (m3): 0.24",
          ],
        },
        {
          key: "5",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 50",
            "Number of Product in the Package: 10",
            "Total Area (m2): 5",
            "Total Volume (m3): 0.25",
          ],
        },
        {
          key: "6",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 60",
            "Number of Product in the Package: 8",
            "Total Area (m2): 4",
            "Total Volume (m3): 0.24",
          ],
        },
        {
          key: "7",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 70",
            "Number of Product in the Package: 7",
            "Total Area (m2): 3.5",
            "Total Volume (m3): 0.245",
          ],
        },
        {
          key: "8",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 80",
            "Number of Product in the Package: 6",
            "Total Area (m2): 3",
            "Total Volume (m3): 0.24",
          ],
        },
        {
          key: "9",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 90",
            "Number of Product in the Package: 5",
            "Total Area (m2): 2.5",
            "Total Volume (m3): 0.225",
          ],
        },
        {
          key: "10",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 100",
            "Number of Product in the Package: 5",
            "Total Area (m2): 2.5",
            "Total Volume (m3): 0.25",
          ],
        },
      ],
    },
    {
      id: 10111,
      name: "EPS Bloklar",
      images: ["/uploads/eps-bloklar-1080x1080.jpg"],
      details: [],
      children: [10118, 10119, 10120],
    },
    {
      id: 10118,
      name: "Carbon Reinforced EPS Block",
      images: ["/uploads/karbon-takviyeli-eps-blok.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Density (kg/m3): 16-22",
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 1000",
            "Number of Product in the Package: 1",
            "Total Area (m2): 3.125",
            "Total Volume (m3): 3.125",
          ],
        },
        {
          key: "2",
          values: [
            "Density (kg/m3): 16-22",
            "Dimensions (mm x mm): 1250 x 2800",
            "Thickness (mm): 1000",
            "Number of Product in the Package: 1",
            "Total Area (m2): 3.5",
            "Total Volume (m3): 3.5",
          ],
        },
        {
          key: "3",
          values: [
            "Density (kg/m3): 16-22",
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 1000",
            "Number of Product in the Package: 1",
            "Total Area (m2): 3.75",
            "Total Volume (m3): 3.75",
          ],
        },
      ],
    },
    {
      id: 10119,
      name: "Hekim Geofoam EPS Blok",
      images: ["/uploads/hekimgeofam-eps-blok.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Density (kg/m3): 10-20",
            "Dimensions (mm x mm): 1250 x 2000",
            "Thickness (mm): 1000",
            "Number of Product in the Package: 1",
            "Total Area (m2): 2.5",
            "Total Volume (m3): 2.5",
          ],
        },
        {
          key: "2",
          values: [
            "Density (kg/m3): 10-40",
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 1000",
            "Number of Product in the Package: 1",
            "Total Area (m2): 3.125",
            "Total Volume (m3): 3.125",
          ],
        },
        {
          key: "3",
          values: [
            "Density (kg/m3): 10-40",
            "Dimensions (mm x mm): 1250 x 2800",
            "Thickness (mm): 1000",
            "Number of Product in the Package: 1",
            "Total Area (m2): 3.5",
            "Total Volume (m3): 3.5",
          ],
        },
        {
          key: "4",
          values: [
            "Density (kg/m3): 10-40",
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 1000",
            "Number of Product in the Package: 1",
            "Total Area (m2): 3.75",
            "Total Volume (m3): 3.75",
          ],
        },
      ],
    },
    {
      id: 10120,
      name: "White EPS Block",
      images: ["/uploads/beyaz-eps-blok.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Density (kg/m3): 10-20",
            "Dimensions (mm x mm): 1250 x 2000",
            "Thickness (mm): 1000",
            "Number of Product in the Package: 1",
            "Total Area (m2): 2.5",
            "Total Volume (m3): 2.5",
          ],
        },
        {
          key: "2",
          values: [
            "Density (kg/m3): 10-40",
            "Dimensions (mm x mm): 1250 x 2500",
            "Thickness (mm): 1000",
            "Number of Product in the Package: 1",
            "Total Area (m2): 3.125",
            "Total Volume (m3): 3.125",
          ],
        },
        {
          key: "3",
          values: [
            "Density (kg/m3): 10-40",
            "Dimensions (mm x mm): 1250 x 2800",
            "Thickness (mm): 1000",
            "Number of Product in the Package: 1",
            "Total Area (m2): 3.5",
            "Total Volume (m3): 3.5",
          ],
        },
        {
          key: "4",
          values: [
            "Density (kg/m3): 10-40",
            "Dimensions (mm x mm): 1250 x 3000",
            "Thickness (mm): 1000",
            "Number of Product in the Package: 1",
            "Total Area (m2): 3.75",
            "Total Volume (m3): 3.75",
          ],
        },
      ],
    },
    {
      id: 10112,
      name: "Decorative Products",
      images: ["/uploads/dekoratif-urunler-1080x1080.jpg"],
      details: [],
      children: [10121, 10122, 10123, 10124, 10125],
    },
    {
      id: 10121,
      name: "T5 Decorative Board",
      images: ["/uploads/t5-dekoratif-eps-levha.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Density (kg/m3): 24",
            "Dimensions (mm x mm): 625 x 625",
            "Thickness (mm): 15",
            "Number of Product in the Package: 41",
            "Total Area (m2): 16.031",
            "Total Volume (m3): 0.246",
          ],
        },
        {
          key: "2",
          values: [
            "Density (kg/m3): 24",
            "Dimensions (mm x mm): 500 x 500",
            "Thickness (mm): 10",
            "Number of Product in the Package: 31",
            "Total Area (m2): 7.75",
            "Total Volume (m3): 0.093",
          ],
        },
      ],
    },
    {
      id: 10122,
      name: "T4 Decorative Board",
      images: ["/uploads/t4-dekoratif-eps-levha.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Density (kg/m3): 24",
            "Dimensions (mm x mm): 625 x 625",
            "Thickness (mm): 15",
            "Number of Product in the Package: 36",
            "Total Area (m2): 14.076",
            "Total Volume (m3): 0.216",
          ],
        },
        {
          key: "2",
          values: [
            "Density (kg/m3): 24",
            "Dimensions (mm x mm): 500 x 500",
            "Thickness (mm): 10",
            "Number of Product in the Package: 	29",
            "Total Area (m2): 7.75",
            "Total Volume (m3): 0.087",
          ],
        },
      ],
    },
    {
      id: 10123,
      name: "T3 Decorative Board",
      images: ["/uploads/t3-dekoratif-eps-levha.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Density (kg/m3): 24",
            "Dimensions (mm x mm): 625 x 625",
            "Thickness (mm): 15",
            "Number of Product in the Package: 48",
            "Total Area (m2): 18.768",
            "Total Volume (m3): 0.288",
          ],
        },
        {
          key: "2",
          values: [
            "Density (kg/m3): 24",
            "Dimensions (mm x mm): 500 x 500",
            "Thickness (mm): 10",
            "Number of Product in the Package: 45",
            "Total Area (m2): 11.25",
            "Total Volume (m3): 0.135",
          ],
        },
      ],
    },
    {
      id: 10124,
      name: "T2 Decorative Board",
      images: ["/uploads/t2-dekoratif-eps-levha.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Density (kg/m3): 24",
            "Dimensions (mm x mm): 625 x 625",
            "Thickness (mm): 15",
            "Number of Product in the Package: 41",
            "Total Area (m2): 16.031",
            "Total Volume (m3): 0.246",
          ],
        },
        {
          key: "2",
          values: [
            "Density (kg/m3): 24",
            "Dimensions (mm x mm): 500 x 500",
            "Thickness (mm): 10",
            "Number of Product in the Package: 35",
            "Total Area (m2): 8.75",
            "Total Volume (m3): 0.105",
          ],
        },
      ],
    },
    {
      id: 10125,
      name: "T1 Decorative Board",
      images: ["/uploads/t1-dekoratif-eps-levha.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Density (kg/m3): 24",
            "Dimensions (mm x mm): 625 x 625",
            "Thickness (mm): 15",
            "Number of Product in the Package: 41",
            "Total Area (m2): 16.031",
            "Total Volume (m3): 0.246",
          ],
        },
        {
          key: "2",
          values: [
            "Density (kg/m3): 24",
            "Dimensions (mm x mm): 500 x 500",
            "Thickness (mm): 10",
            "Number of Product in the Package: 31",
            "Total Area (m2): 7.75",
            "Total Volume (m3): 0.093",
          ],
        },
      ],
    },
    {
      id: 10113,
      name: "Roof Insulation Products",
      images: ["/uploads/cati-izolasyon-urunleri-1080x1080.jpg"],
      details: [],
      children: [10126, 10127],
    },
    {
      id: 10126,
      name: "Styrofoam Under Trapozoid Board",
      images: ["/uploads/trapez-levha-alti-strafor.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Density (kg/m3): 10-16",
            "Dimensions (mm x mm): 860 x 1900",
            "Thickness (mm): 20",
            "Groove Depth (mm): 27",
            "Number of Groove: 4",
            "Number of Product in the Package: 16",
            "Total Area (m2): 26.144",
            "Total Volume (m3): 0.528",
          ],
        },
        {
          key: "2",
          values: [
            "Density (kg/m3): 10-16",
            "Dimensions (mm x mm): 860 x 2000",
            "Thickness (mm): 20",
            "Groove Depth (mm): 27",
            "Number of Groove: 4",
            "Number of Product in the Package: 16",
            "Total Area (m2): 27.52",
            "Total Volume (m3): 0.544",
          ],
        },
      ],
    },
    {
      id: 10127,
      name: "Styrofoam Under Corrugated Boards",
      images: ["/uploads/onduleli-levha-alti-strafor.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Density (kg/m3): 10-16",
            "Dimensions (mm x mm): 877 x 1900",
            "Thickness (mm): 10",
            "Groove Depth (mm): 35",
            "Number of Groove: 9",
            "Number of Product in the Package: 20",
            "Total Area (m2): 33.32",
            "Total Volume (m3): 0.34",
          ],
        },
      ],
    },
    {
      id: 10114,
      name: "Asmolen",
      images: ["/uploads/asmolen-1080x1080.jpg"],
      details: [],
      children: [10128, 10129, 10130],
    },
    {
      id: 10128,
      name: "Karaelmas EPS Levha",
      images: ["/uploads/karaelmas-eps-levha.png"],
      details: [
        "Incombustibility Class: E",
        "Thanks to the reflective feature of the carbon in its contain, it makes 20% better insulation than normal boards.",
        "Its λ value is 0,033.",
        "Since there are stabilized boards, dimensional stability is stable.",
        "It does not contain CFC (Chlorofluorocarbon) gas and its derivatives.",
        "Its water vapour permeability is high. (μ 20-40)",
        "Thermal resistance is high.",
        "It has high pressure resistance.",
        "The building does not loss its insulation feature throughout the life of the building.",
        "It is E class flame retardant.",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 10",
            "Number of Product in the Package: 50",
            "Total Area (m2): 25",
            "Total Volume (m3): 0.25",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 20",
            "Number of Product in the Package: 25",
            "Total Area (m2): 12.5",
            "Total Volume (m3): 0.25",
          ],
        },
        {
          key: "3",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 30",
            "Number of Product in the Package: 16",
            "Total Area (m2): 8",
            "Total Volume (m3): 0.24",
          ],
        },
        {
          key: "4",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 40",
            "Number of Product in the Package: 12",
            "Total Area (m2): 6",
            "Total Volume (m3): 0.24",
          ],
        },
        {
          key: "5",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 50",
            "Number of Product in the Package: 10",
            "Total Area (m2): 5",
            "Total Volume (m3): 0.25",
          ],
        },
        {
          key: "6",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 60",
            "Number of Product in the Package: 8",
            "Total Area (m2): 4",
            "Total Volume (m3): 0.24",
          ],
        },
        {
          key: "7",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 70",
            "Number of Product in the Package: 7",
            "Total Area (m2): 3.5",
            "Total Volume (m3): 0.245",
          ],
        },
        {
          key: "8",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 80",
            "Number of Product in the Package: 6",
            "Total Area (m2): 3",
            "Total Volume (m3): 0.24",
          ],
        },
        {
          key: "9",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 90",
            "Number of Product in the Package: 6",
            "Total Area (m2): 3",
            "Total Volume (m3): 0.27",
          ],
        },
        {
          key: "10",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 100",
            "Number of Product in the Package: 5",
            "Total Area (m2): 2.5",
            "Total Volume (m3): 0.25",
          ],
        },
      ],
    },
    {
      id: 10129,
      name: "Pamukkale Hollow Block",
      images: ["/uploads/pamukkale-asmolen.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 200",
            "Number of Product in the Package: 2",
            "Total Area (m2): 0.8",
            "Total Volume (m3): 0.16",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 220",
            "Number of Product in the Package: 2",
            "Total Area (m2): 0.8",
            "Total Volume (m3): 0.176",
          ],
        },
        {
          key: "3",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 230",
            "Number of Product in the Package: 2",
            "Total Area (m2): 0.8",
            "Total Volume (m3): 0.184",
          ],
        },
        {
          key: "4",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 250",
            "Number of Product in the Package: 2",
            "Total Area (m2): 0.8",
            "Total Volume (m3): 0.2",
          ],
        },
        {
          key: "5",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 270",
            "Number of Product in the Package: 2",
            "Total Area (m2): 0.8",
            "Total Volume (m3): 0.216",
          ],
        },
        {
          key: "6",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 280",
            "Number of Product in the Package: 2",
            "Total Area (m2): 0.8",
            "Total Volume (m3): 0.224",
          ],
        },
        {
          key: "7",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 300",
            "Number of Product in the Package: 2",
            "Total Area (m2): 0.8",
            "Total Volume (m3): 0.24",
          ],
        },
        {
          key: "8",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 330",
            "Number of Product in the Package: 2",
            "Total Area (m2): 0.8",
            "Total Volume (m3): 0.264",
          ],
        },
        {
          key: "9",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 350",
            "Number of Product in the Package: 2",
            "Total Area (m2): 0.8",
            "Total Volume (m3): 0.28",
          ],
        },
      ],
    },
    {
      id: 10130,
      name: "HekimPor Hollow Block",
      images: ["/uploads/hekimpor-asmolen.png"],
      details: ["Incombustibility Class: E"],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 200",
            "Number of Product in the Package: 2",
            "Total Area (m2): 0.8",
            "Total Volume (m3): 0.16",
          ],
        },
        {
          key: "2",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 220",
            "Number of Product in the Package: 2",
            "Total Area (m2): 0.8",
            "Total Volume (m3): 0.176",
          ],
        },
        {
          key: "3",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 230",
            "Number of Product in the Package: 2",
            "Total Area (m2): 0.8",
            "Total Volume (m3): 0.184",
          ],
        },
        {
          key: "4",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 250",
            "Number of Product in the Package: 2",
            "Total Area (m2): 0.8",
            "Total Volume (m3): 0.2",
          ],
        },
        {
          key: "5",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 270",
            "Number of Product in the Package: 2",
            "Total Area (m2): 0.8",
            "Total Volume (m3): 0.216",
          ],
        },
        {
          key: "6",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 280",
            "Number of Product in the Package: 2",
            "Total Area (m2): 0.8",
            "Total Volume (m3): 0.224",
          ],
        },
        {
          key: "7",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 300",
            "Number of Product in the Package: 2",
            "Total Area (m2): 0.8",
            "Total Volume (m3): 0.24",
          ],
        },
        {
          key: "8",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 330",
            "Number of Product in the Package: 2",
            "Total Area (m2): 0.8",
            "Total Volume (m3): 0.264",
          ],
        },
        {
          key: "9",
          values: [
            "Dimensions (mm x mm): 500 x 1000",
            "Thickness (mm): 350",
            "Number of Product in the Package: 2",
            "Total Area (m2): 0.8",
            "Total Volume (m3): 0.28",
          ],
        },
      ],
    },
    {
      id: 10004,
      name: "Technonicol",
      images: ["/uploads/technonicol.jpg"],
      details: [],
      children: [10131, 10132, 10133],
    },
    {
      id: 10131,
      name: "Mastics",
      images: ["/uploads/6f06cdd5c223acaafd52706e249ffdcc.jpg"],
      details: [],
      children: [10134, 10135],
    },
    {
      id: 10134,
      name: "Bitumen-polymer SBS-modified Waterproofing Material",
      images: ["/uploads/TechnoelastMostB_29_10_2021.png"],
      details: [
        "Bitumen-polymer SBS-modified deposited waterproofing material of increased reliability and increased thickness.",
        "It is produced only on a reinforced base, which prevents material shrinkage during the formation and operation of the waterproofing layer.",
        "It can be used for one-layer and multi-layer waterproofing.",
        "Designed for waterproofing concrete structures, foundations and underground parts of buildings.",
        "The material allows the formation of a waterproofing layer in horizontal sections without gluing to the base (free laying).",
        "This is especially important for deep foundations and when working in the autumn-winter period.",
      ],
      children: [],
    },
    {
      id: 10135,
      name: "Architect Mastic Fixer",
      images: ["/uploads/6f06cdd5c223acaafd52706e249ffdcc.jpg"],
      details: [
        "It is applied for bonding bitumen-based soft tiles and other materials to brick, concrete, metal, wood, ceramics and other surfaces.",
        "Having a paste-like consistency, it ensures that the layers are sealed to each other.",
        "The material is ready to use, easy to apply.",
        "Its consumption is quite economical.",
        "Polymer bitumen cold adhesive TECHNONICOL 23 (FIXER)",
        "It is an excellent product for quality assembly.",
        "It is suitable for all types of tiles.",
      ],
      children: [],
    },
    {
      id: 10132,
      name: "Self Adhesive Tape",
      images: ["/uploads/1b8e3eda6cfb157553b1903fffa38d03.jpg"],
      details: [],
      children: [10136, 10137, 10138],
    },
    {
      id: 10136,
      name: "NICOBAND INSIDE",
      images: ["/uploads/a_1.jpg"],
      details: [
        "NICOBAND sealant tape is a very convenient way to seal various cracks and joints.",
        "Can also be used to insulate junctions, for roofing repairs and for roof repair systems.",
        "The special UV protection layer increases its service life outdoors and the assortment of colors makes it suitable for any roof.",
        "Protected from UV radiation.",
        "The bitumen layer of NICOBAND sealant tape is protected from UV radiation by aluminum coating.",
        "This makes it possible to use the tape outdoor.",
        "Easy to use and durable.",
        "NICOBAND sealant tape is very easy to use and its application requires no special skills.",
        "The sealing function of the tape extends for its full lifetime (10 years) thanks to specially formulated polymer-bitumen binder.",
        "Different colors.",
        "The tape is designed for the most popular roofing colors (including metallic).",
        "It allows performing repairs or maintenance in the tone of the main surface without disturbing its form.",
        "Flawless adhesion to many different surfaces.",
        "The self-adhesive layer provides perfect quality adhesion to many different surfaces: metal, slate, wood, plastic, concrete, glass, etc.",
        "The surface must be flat, dry and clean.",
        "Cut the tape to required length, remove the protective film, stick the tape to desired area and press it firmly.",
        "If the temperature is below +5°C it is necessary to first keep the tape in room temperature for at least 12 hours before application.",
        "The use of the material at sub-zero temperatures requires additional heating of the surface.",
        "The use of the sealing tape is not recommended on hot surfaces with slope ≥ 55⁰ or on surfaces with a temperature above +80°C.",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: ["Width (cm): 5", "Length 3m: +", "Length 10m: -"],
        },
        {
          key: "2",
          values: ["Width (cm): 7.5", "Length 3m: +", "Length 10m: +"],
        },
        {
          key: "3",
          values: ["Width (cm): 10", "Length 3m: +", "Length 10m: +"],
        },
        {
          key: "4",
          values: ["Width (cm): 15", "Length 3m: +", "Length 10m: +"],
        },
        {
          key: "5",
          values: ["Width (cm): 20", "Length 3m: -", "Length 10m: +"],
        },
        {
          key: "6",
          values: ["Width (cm): 30", "Length 3m: -", "Length 10m: +"],
        },
      ],
    },
    {
      id: 10137,
      name: "NICOBAND DUO",
      images: ["/uploads/c2c7139d4f0268563b0f62b7df8511ab.jpg"],
      details: [
        "NICOBAND sealant tape is a very convenient way to seal various cracks and joints.",
        "Can also be used to insulate junctions, for roofing repairs and for roof repair systems.",
        "The special UV protection layer increases its service life outdoors and the assortment of colors makes it suitable for any roof.",
        "Protected from UV radiation.",
        "The bitumen layer of NICOBAND sealant tape is protected from UV radiation by aluminum coating.",
        "This makes it possible to use the tape outdoor.",
        "Easy to use and durable.",
        "NICOBAND sealant tape is very easy to use and its application requires no special skills.",
        "The sealing function of the tape extends for its full lifetime (10 years) thanks to specially formulated polymer-bitumen binder.",
        "Different colors.",
        "The tape is designed for the most popular roofing colors (including metallic).",
        "It allows performing repairs or maintenance in the tone of the main surface without disturbing its form.",
        "Flawless adhesion to many different surfaces.",
        "The self-adhesive layer provides perfect quality adhesion to many different surfaces: metal, slate, wood, plastic, concrete, glass, etc.",
        "The surface must be flat, dry and clean.",
        "Cut the tape to required length, remove the protective film from first side, stick the tape to desired area and press it firmly.",
        "Remove protective film from the second side of the tape, press down the surface of the attached element to the tape.",
        "If the temperature is below +5°C it is necessary to first keep tape in room temperature for at least 12 hours before application.",
        "The use of the material at sub-zero temperatures requires additional heating of the surface.",
        "The use of the sealing tape is not recommended on hot surfaces with slope ≥ 55⁰ or on surfaces with a temperature above +80°C.",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: ["Width (cm): 5", "Length 3m: +", "Length 10m: -"],
        },
        {
          key: "2",
          values: ["Width (cm): 7.5", "Length 3m: +", "Length 10m: +"],
        },
        {
          key: "3",
          values: ["Width (cm): 10", "Length 3m: +", "Length 10m: +"],
        },
        {
          key: "4",
          values: ["Width (cm): 15", "Length 3m: +", "Length 10m: +"],
        },
        {
          key: "5",
          values: ["Width (cm): 20", "Length 3m: -", "Length 10m: +"],
        },
        {
          key: "6",
          values: ["Width (cm): 30", "Length 3m: -", "Length 10m: +"],
        },
      ],
    },
    {
      id: 10138,
      name: "NICOBAND",
      images: ["/uploads/1b8e3eda6cfb157553b1903fffa38d03.jpg"],
      details: [
        "NICOBAND sealant tape is a very convenient way to seal various cracks and joints.",
        "Can also be used to insulate junctions, for roofing repairs and for roof repair systems.",
        "The special UV protection layer increases its service life outdoors and the assortment of colors makes it suitable for any roof.",
        "Protected from UV radiation.",
        "The bitumen layer of NICOBAND sealant tape is protected from UV radiation by aluminum coating.",
        "This makes it possible to use the tape outdoor.",
        "Easy to use and durable.",
        "NICOBAND sealant tape is very easy to use and its application requires no special skills.",
        "The sealing function of the tape extends for its full lifetime (10 years) thanks to specially formulated polymer-bitumen binder.",
        "Different colors.",
        "The tape is designed for the most popular roofing colors (including metallic).",
        "It allows performing repairs or maintenance in the tone of the main surface without disturbing its form.",
        "The surface must be flat, dry and clean.",
        "Cut the tape to required length, remove the protective film, stick the tape to desired area and press it firmly.",
        "If the temperature is below +5°C it is necessary to first keep the tape in room temperature for at least 12 hours before application.",
        "The use of the material at sub-zero temperatures requires additional heating of the surface.",
        "The use of the sealing tape is not recommended on hot surfaces with slope ≥ 55⁰ or on surfaces with a temperature above +80°C.",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: ["Width (cm): 5", "Length 3m: +", "Length 10m: -"],
        },
        {
          key: "2",
          values: ["Width (cm): 7.5", "Length 3m: +", "Length 10m: +"],
        },
        {
          key: "3",
          values: ["Width (cm): 10", "Length 3m: +", "Length 10m: +"],
        },
        {
          key: "4",
          values: ["Width (cm): 15", "Length 3m: +", "Length 10m: +"],
        },
        {
          key: "5",
          values: ["Width (cm): 20", "Length 3m: -", "Length 10m: +"],
        },
        {
          key: "6",
          values: ["Width (cm): 30", "Length 3m: -", "Length 10m: +"],
        },
      ],
    },
    {
      id: 10133,
      name: "Bituminous Waterproofing Cover",
      images: ["/uploads/406acfcfc6732073a27ed0c305b09650.jpg"],
      details: [],
      children: [10139, 10140, 10141, 10142],
    },
    {
      id: 10139,
      name: "MIDA TROPİK P 4000",
      images: ["/uploads/e9fc26b5ca9160190fbedbe32a298baa_1.jpg"],
      details: [
        "MIDA Tropik P 4000 is a bitumen-based material used as the first layer membrane, obtained by using polyethylene film as the upper and lower protective layers on the bituminous material with polyester felt carrier.",
        "The material is used as the first layer in multi-layered new roofing systems during waterproofing works or in the renovation of old roof systems.",
        "The material is melted and applied with a torch.",
        "FEATURE MIDA TROPIC P 4000",
        "Temperature resistance, °C +130",
        "Cold bending, °C -5",
        "Carrier polyester felt",
        "Tensile strength, N/50 mm longitudinal / transverse 600 / 400 ±100",
        "Elongation, 30% / 30 ±10%",
        "Thickness, mm 4.0 ±0.1",
        "Length / Width, m 10 / 1",
      ],
      children: [],
    },
    {
      id: 10140,
      name: "MIDA TROPİK P 3000",
      images: ["/uploads/e9fc26b5ca9160190fbedbe32a298baa.jpg"],
      details: [
        "MIDA Tropik P 3000 is a bitumen-based material used as the first layer membrane, obtained by using polyethylene film as the upper and lower protective layers on the bituminous material with polyester felt carrier.",
        "The material is used as the first layer in multi-layered new roofing systems during waterproofing works or in the renovation of old roof systems.",
        "The material is melted and applied with a torch.",
        "FEATURE MIDA TROPIC P 3000",
        "Temperature resistance, °C +130",
        "Cold bending, °C -5",
        "Carrier polyester felt",
        "Tensile strength, N/50 mm longitudinal / transverse 600 / 400 ±100",
        "Elongation, 30% / 30 ±10%",
        "Thickness, mm 3.0 ±0.1",
        "Length / Width, m 10 / 1",
      ],
      children: [],
    },
    {
      id: 10141,
      name: "MIDA TROPİK P40M",
      images: ["/uploads/82df288bee54dc93a18d355c05b99dd0.jpg"],
      details: [
        "MIDA Tropik P40M is a bitumen-based material used as a top layer membrane, obtained by using mineral stone as the upper protective layer and polyethylene film as the lower protective layer, on the bituminous material with polyester felt carrier.",
        "The material is used as a top coat in multi-layered new roofing systems or in the renovation of old roof systems.",
        "The material is melted and applied with a torch.",
        "FEATURE MIDA TROPIC P40M",
        "Temperature resistance, °C +130",
        "Cold bending, °C -5",
        "Carrier polyester felt",
        "Tensile strength, N/50 mm longitudinal / transverse 600 / 400 ±100",
        "Elongation, 30% / 30 ±10%",
        "Thickness, mm 3.3 ±0.1",
        "Length / Width, m 10 / 1",
      ],
      children: [],
    },
    {
      id: 10142,
      name: "MIDA TROPİK C 2000",
      images: ["/uploads/406acfcfc6732073a27ed0c305b09650.jpg"],
      details: [
        "MIDA Tropik C 2000 is the material used as the first layer membrane, which is obtained by using polyethylene film as the upper and lower protective layers on the bituminous material with fiberglass carrier.",
        "The material is used as the first layer in multi-layered new roofing systems during waterproofing works or in the renovation of old roof systems.",
        "The material is melted with a torch or applied mechanically.",
        "FEATURE MIDA TROPIC C 2000",
        "Temperature resistance, °C +130",
        "Cold bending, °C -5",
        "carrier glass veil",
        "Tensile strength, N/50 mm longitudinal / transverse 400 / 300 ±100",
        "Elongation, % 4 / 4 ±2",
        "Thickness, mm 2.0 ±0.1",
        "Length / Width, m 15 / 1",
      ],
      children: [],
    },
    {
      id: 10005,
      name: "Shinglas",
      images: ["/uploads/shinglassss.jpg"],
      details: [],
      children: [10143],
    },
    {
      id: 10143,
      name: "Shingle",
      images: ["/uploads/Shinglass.jpg"],
      details: [],
      children: [10144, 10145, 10146, 10147, 10148, 10149, 10150, 10151, 10152],
    },
    {
      id: 10144,
      name: "STANDARD ROCK SERIES",
      images: ["/uploads/standart-rock.jpg"],
      details: [],
      children: [],
    },
    {
      id: 10145,
      name: "CLASSIC SERIES",
      images: ["/uploads/classic.jpg"],
      details: [],
      children: [],
    },
    {
      id: 10146,
      name: "ULTRA SERIES",
      images: ["/uploads/ultra.jpg"],
      details: [],
      children: [],
    },
    {
      id: 10147,
      name: "ATLANTICA COLLECTION",
      images: ["/uploads/atlantico.jpg"],
      details: [],
      children: [],
    },
    {
      id: 10148,
      name: "RANCHO COLLECTION",
      images: ["/uploads/rancho.jpg"],
      details: [],
      children: [],
    },
    {
      id: 10149,
      name: "COUNTRY COLLECTION",
      images: ["/uploads/country.jpg"],
      details: [],
      children: [],
    },
    {
      id: 10150,
      name: "JAZZ COLLECTION",
      images: ["/uploads/jazz.jpg"],
      details: [],
      children: [],
    },
    {
      id: 10151,
      name: "WESTERN COLLECTION",
      images: ["/uploads/a827b9d72325cb6d393d780d846ab17e.jpg"],
      details: [],
      children: [],
    },
    {
      id: 10152,
      name: "CONTINENT COLLECTION",
      images: ["/uploads/Shinglass.jpg"],
      details: [],
      children: [],
    },
    {
      id: 10006,
      name: "Kronospan",
      images: ["/uploads/kronospan.jpg"],
      details: [],
      children: [10153],
    },
    {
      id: 10153,
      name: "OSB",
      images: ["/uploads/kronospan-osb3_800x800_crop_478b24840a-1.jpg"],
      details: [],
      children: [10154, 10155, 10156],
    },
    {
      id: 10154,
      name: "OSB 4",
      images: ["/uploads/kronospan-osb3_800x800_crop_478b24840a-1.jpg"],
      details: [
        "OSB 4 boards have a higher load-bearing capacity and stiffness than OSB 3 boards.",
        "OSB 4 is developed and manufactured entirely in compliance with the current demand of ecological living.",
        "Selecting suitable wood and binder, OSB 4 meets high requirements of not only environmental buildings.",
        "Standard EN 300 - type OSB 4; EN 13501-1: class D-s1, d0; EN 13986:2004+A1:2015",
        "Emission Class E1 (EN ISO 12460-5)",
        "Characteristics - Increased resistance to humid conditions",
        "High load-bearing capacity and stiffness for construction purposes",
        "High dimensional accuracy and shape stability",
        "High flexibility and impact resistance",
        "Easy workability with common woodworking tools",
        "Environmentally friendly, recyclable product",
        "Applications - Structural applications: walls, floors, roofs, staircases, hoardings",
        "Interior: furniture elements, decorative elements in indoor spaces, framework in upholstered furniture, door panels, claddings and partition walls",
        "Packaging: shipping boxes and containers, pallets",
        "Retail & exhibitions",
        "Warehouse management (shelving, racking, etc.)",
        "Express Program - Kronobuild",
        "Format 2500x1250",
        "Thickness 12 15 18 22 25",
        "OSB 4",
      ],
      children: [],
    },
    {
      id: 10155,
      name: "OSB 3",
      images: ["/uploads/kronospan-osb3_800x800_crop_478b24840a.jpg"],
      details: [
        "OSB 3 is the most progressive OSB type that is currently available.",
        "OSB 3 is developed and manufactured entirely in compliance with the current demand of ecological living focused on organic materials.",
        "Selecting suitable wood and binder, OSB 3 meets high standards of not only environmental buildings.",
        "Standard EN 300 - type OSB 3; EN 13501-1: class D-s1, d0; EN 13986:2004+A1:2015",
        "Emission Class E1 (EN ISO 12460-5)",
        "Characteristics - Increased resistance to humid conditions",
        "High load-bearing capacity and stiffness for construction purposes",
        "High dimensional accuracy and shape stability",
        "High flexibility and impact resistance",
        "Easy workability with common woodworking tools",
        "Environmentally friendly, recyclable product",
        "Applications - Structural applications: walls, floors, roofs, staircases, hoardings",
        "Interior: furniture elements, decorative elements in indoor spaces, framework in upholstered furniture, door panels, claddings and partition walls",
        "Packaging: shipping boxes and containers, pallets",
        "Retail & exhibitions",
        "Warehouse management (shelving, racking, etc.)",
        "Express Program - Kronobuild",
        "Format 2500x1250 2440x1220",
        "Thickness 9 10 12 15 18 22 25 9 10 11 12 15 18 22 25",
        "OSB 3",
      ],
      children: [],
    },
    {
      id: 10156,
      name: "OSB 2",
      images: ["/uploads/kronospan-osb2_800x800_crop_478b24840a.jpg"],
      details: [
        "OSB 2 is developed and manufactured entirely in compliance with the current demand of ecological living focused on organic materials.",
        "Selecting suitable wood and binder, OSB 2 meets high standards of not only environmental buildings.",
        "Standard EN 300 - type OSB 2; EN 13501-1: class D-s1, d0; EN 13986:2004+A1:2015",
        "Emission Class E1 (EN ISO 12460-5)",
        "Characteristics - High load-bearing capacity and stiffness for construction purposes",
        "High dimensional accuracy and shape stability",
        "High flexibility and impact resistance",
        "Easy workability with common woodworking tools",
        "Environmentally friendly, recyclable product",
        "Applications - Structural applications: walls, floors, roofs, staircases, hoardings",
        "Interior: furniture elements, decorative elements in indoor spaces, framework in upholstered furniture, door panels, claddings and partition walls",
        "Packaging: shipping boxes and containers, pallets",
        "Retail & exhibitions",
        "Warehouse management (shelving, racking, etc.)",
        "Express Program - Kronobuild",
        "Format 2500x1250 2440x1220",
        "Thickness 9 10 11 12 15 18 22 25 9 10 11 12 15 18 22 25",
        "OSB 2",
      ],
      children: [],
    },
    {
      id: 10007,
      name: "Terrawool",
      images: ["/uploads/terrawoll.jpg"],
      details: [],
      children: [],
      links: ["/links/terrawool.pdf"],
    },
    {
      id: 10008,
      name: "Jotun Paint",
      images: ["/uploads/jotun.jpg"],
      details: [],
      children: [10157, 10158],
    },
    {
      id: 10157,
      name: "Exterior Products",
      images: ["/uploads/1.jpg"],
      details: [],
      children: [
        10159, 10160, 10161, 10162, 10163, 10164, 10165, 10166, 10167, 10168,
        10169, 10170,
      ],
    },
    {
      id: 10159,
      name: "Jotashield Tex Ultra",
      images: ["/uploads/13.jpg"],
      details: [
        "Jotashield Tex Ultra is a medium textured, flexible, semi-gloss, water-based exterior paint with 100% pure acrylic resin.",
        "Jotashield Tex Ultra has crack bridging feature against capillary cracks formed by the settlement of the structure over time.",
        "It protects the aesthetic appearance of your building for years thanks to its low dirt holding feature.",
        "It has carbon dioxide and chlorine ion diffusion, water vapor permeability, water permeability, and water impermeability.",
        "Thanks to its resistance to chlorine ion diffusion, it prevents paint problems caused by salt or moisture, especially when used near the sea or in buildings exposed to intense humidity.",
        "Orange peel-patterned exterior paint",
        "100% pure acrylic",
        "Crack bridging feature",
        "carbon dioxide diffusion",
        "Waterproof feature",
        "Excellent concrete protection",
        "water vapor permeability",
      ],
      children: [],
    },
    {
      id: 10160,
      name: "Jotashield Surface Strengthening Primer (Water Based)",
      images: ["/uploads/12.jpg"],
      details: [],
      children: [],
    },
    {
      id: 10161,
      name: "Jotashield Surface Strengthening Primer (Solvent Based)",
      images: ["/uploads/11aa.jpg"],
      details: [],
      children: [],
    },
    {
      id: 10162,
      name: "Jotashield Heritage Glaze",
      images: ["/uploads/10.jpg"],
      details: [
        "Jotashield Heritage Glaze is a 100% pure acrylic water-based exterior paint that offers a decorative appearance.",
        "Jotashield Heritage Glaze is a premium quality, water-based effect paint.",
        "It has 100% pure acrylic binder.",
        "Antique and aging effects can be made with Jotashield Heritage Glaze, offering different pattern alternatives for surfaces where a decorative finish is desired.",
        "Creates wood adoku",
        "Offers an antique look",
        "100% pure acrylic",
        "High UV resistance and low dirt retention",
        "Excellent concrete protection",
        "Excellent protection against harsh climatic conditions",
      ],
      children: [],
    },
    {
      id: 10163,
      name: "Jotashield Real Matt",
      images: ["/uploads/9.jpg"],
      details: [
        "Jotashield Real Matt is a smooth finish matt exterior paint with unique low dirt holding properties that offers 15 years of protection against harsh climatic conditions.",
        "Matte and smooth finish exterior paint",
        "Exceptionally low dirt retention",
        "Excellent color experience",
        "Excellent protection for up to 15 years",
        "Advantage in maintenance cost",
        "High coverage",
        "High UV resistance",
        "Consumption advantage",
      ],
      children: [],
    },
    {
      id: 10164,
      name: "Jotashield SuperDurable",
      images: ["/uploads/8.jpg"],
      details: [
        "Jotashield SuperDurable is a 100% pure silicone modified acrylic, water-based exterior paint that offers up to 20 years of protection against harsh climatic conditions.",
        "Jotashield SuperDurable has been specially developed to withstand the harshest climatic conditions.",
        "It creates a long-lasting and durable silky matte surface with the low dirt holding feature of its special acrylic binder.",
        "By providing environmentally friendly solutions, it provides long-lasting protection compared to both solvent-based and many decorative paint systems.",
        "It provides an aesthetic appearance while protecting the building against external conditions for 20 years.",
        "With this feature, considering the cost of living curve, it provides long-term solutions by providing advantages in maintenance costs.",
      ],
      children: [],
    },
    {
      id: 10165,
      name: "Jotashield Matt",
      images: ["/uploads/6.jpg"],
      details: [
        "Jotashield Matt is a high performance exterior paint based on 100% pure acrylic resin, offering a smooth and matte finish.",
        "Matte and smooth finish exterior paint",
        "100% pure acrylic",
        "High UV resistance and low dirt retention",
        "Excellent concrete protection",
        "Excellent protection against harsh climatic conditions",
      ],
      children: [],
    },
    {
      id: 10166,
      name: "Jotashield Silk",
      images: ["/uploads/5.jpg"],
      details: [
        "Jotashield Silk is a high performance exterior paint based on 100% pure acrylic resin, offering a smooth and silky matte finish.",
        "Exterior paint with a smooth finish",
        "100% pure acrylic",
        "High UV resistance and low dirt retention",
        "Excellent concrete protection",
        "Excellent protection against harsh climatic conditions",
        "576 color alternatives",
      ],
      children: [],
    },
    {
      id: 10167,
      name: "Jotashield Tex Fine",
      images: ["/uploads/4.jpg"],
      details: [
        "Jotashield Tex Fine is a silky matte, water-based, flexible, 100% pure acrylic resin exterior paint that gives a light textured and decorative appearance with the aggregate particles it contains.",
        "Jotashield Tex Fine offers a silky matte appearance with its fine textured texture.",
        "It has a crack bridging feature against the capillary cracks formed by the settlement of the structure over time.",
        "It has high resistance to water and harsh weather conditions.",
        "When coral is applied with a roller, it hides minor faults and creates a smooth texture.",
        "Fine texture exterior paint",
        "100% pure acrylic",
        "Crack bridging feature",
        "Carbon dioxide diffusion",
        "Waterproof feature",
        "Excellent concrete protection",
        "Water vapor permeability",
      ],
      children: [],
    },
    {
      id: 10168,
      name: "Jotashield Tex Ultra",
      images: ["/uploads/3.jpg"],
      details: [
        "Jotashield Tex Ultra is a medium textured, flexible, semi-gloss, water-based exterior paint with 100% pure acrylic resin.",
        "Jotashield Tex Ultra has crack bridging feature against capillary cracks formed by the settlement of the structure over time.",
        "It protects the aesthetic appearance of your building for years thanks to its low dirt holding feature.",
        "It has carbon dioxide and chlorine ion diffusion, water vapor permeability, water permeability, and water impermeability.",
        "Thanks to its resistance to chlorine ion diffusion, it prevents paint problems caused by salt or moisture, especially when used near the sea or in buildings exposed to intense humidity.",
        "Orange peel-patterned exterior paint",
        "100% pure acrylic",
        "Crack bridging feature",
        "Carbon dioxide diffusion",
        "Waterproof feature",
        "Excellent concrete protection",
        "Water vapor permeability",
      ],
      children: [],
    },
    {
      id: 10169,
      name: "Jotashield Surface Strengthening Primer (Water Based)",
      images: ["/uploads/2.jpg"],
      details: [
        "Jotashield Surface Strengthening Primer is a water-based, acrylic copolymer resin exterior primer.",
        "It has excellent penetrating properties.",
        "Jotashield Surface Reinforcing Primer penetrates perfectly into smooth surfaces such as precast concrete, exposed concrete.",
        "Thanks to its water-based nature, it offers environmentally friendly solutions.",
        "Water based exterior primer",
        "Acrylic copolymer resin",
        "Excellent surface penetration",
      ],
      children: [],
    },
    {
      id: 10170,
      name: "Jotashield Surface Strengthening Primer (Solvent Based)",
      images: ["/uploads/a1.jpg"],
      details: [
        "Jotashield Surface Strengthening Primer is a solvent based, acrylic copolymer resin exterior primer.",
        "It has excellent penetrating properties.",
        "Jotashield Solvent Based Surface Reinforcing Primer is applied to reinforce loose and dusty surfaces on the surface.",
        "Reinforces the surface before the top coat paint application.",
        "It penetrates perfectly into smooth surfaces such as precast concrete and exposed concrete.",
        "It is not recommended to be used on sheathed surfaces due to the solvent it contains.",
        "Solvent based exterior primer",
        "Excellent surface penetration",
      ],
      children: [],
    },
    {
      id: 10158,
      name: "Interior Wall Products",
      images: ["/uploads/fenomastic-guzel-evim.jpg"],
      details: [],
      children: [10171, 10172, 10173, 10174, 10175, 10176, 10177, 10178, 10179],
    },
    {
      id: 10171,
      name: "Phenomastic Matte",
      images: ["/uploads/jotun-fenomastic-mat-kirik-beyaz-15-lt-4ba1-9.jpg"],
      details: [
        "Fenomastic Mat is an easy-to-apply, non-splash interior wall paint with excellent texture.",
        "Fenomastic Series is especially designed for use in general paint applications.",
        "While developing our series, we have included four main features in our center, taking into account the expectations and needs of our masters from paint.",
        "Fenomastic Mat is an easy-to-apply, non-splash interior wall paint with excellent texture.",
        "Provides a smooth matte finish.",
        "Easy to apply and spreads well; It has high covering and retouching properties.",
        "Does not splatter; Does not leave brush or roller marks.",
        "Splashproof: It is among our priorities for our masters to paint under better conditions. For this purpose, we have designed our products as 'non-splashing'.",
        "Easy to apply: While our products are easy to apply, they also allow our master to complete the work on time without encountering any problems, thanks to their high coverage. It does not leave brush and roller marks, spreads well on the surface and has touch-up feature.",
        "Odorless: We want our masters to work in a healthier environment instead of applying under the intense smell of paint. For this purpose, we have designed our products as 'odorless'.",
        "Perfect texture: Every paint application is a reference for our masters. We offer excellent texture with our products that offer a smooth finish.",
      ],
      children: [],
    },
    {
      id: 10172,
      name: "Phenomastic Paste",
      images: ["/uploads/macun.jpg"],
      details: [
        "Fenomastic Putty is an easy-to-apply, water-based acrylic interior putty that does not require a primer application, offers a perfect finish with its filling feature.",
        "Fenomastic Putty is an easy-to-apply, water-based acrylic interior putty that does not require primer application, offers a perfect finish with its filling feature.",
        "By offering time and cost advantages, it ensures that the paint application is completed quickly and a smooth and beautiful texture is formed.",
        "Fenomastic Putty is an easy-to-apply, water-based acrylic interior putty that does not require a primer application, offers a perfect finish with its filling feature.",
        "It offers time and cost advantage since it does not require primer application.",
        "It creates a smooth and beautiful surface for paint application.",
        "Primer and putty in one: Fenomastic Putty has the '2 in 1' feature; acts as both putty and primer. Does not require primer application.",
        "Time saving: Fenomastic Putty, with all its features, saves both time and material and labor costs. It allows the application to be completed in a shorter time.",
        "Perfect finish: It creates a smooth surface with its filling feature, so a perfect finish is obtained when the last coat of paint is applied on the putty.",
        "Easy application: It is easy to apply, does not tire the master and spreads well on the surface.",
      ],
      children: [],
    },
    {
      id: 10173,
      name: "Phenomastic Ceiling",
      images: ["/uploads/taavan.jpg"],
      details: [
        "By offering time and cost advantages, it ensures that the paint application is completed quickly and a smooth and beautiful texture is formed.",
        "Fenomastic Series is especially designed for use in general paint applications.",
        "While developing our series, we have included four main features in our center, taking into account the expectations and needs of our masters from paint.",
        "Fenomastic Ceiling is an easy-to-apply, non-splatter and odorless ceiling paint with excellent whiteness.",
        "Splashproof: It is among our priorities for our masters to paint under better conditions. For this purpose, we designed the Fenomastic Ceiling as 'non-splashing'.",
        "Easy to apply: Easy to apply and spreads well; Does not leave brush or roller marks. Thanks to its high coverage and retouching feature, you can complete your ceiling application in a short time.",
        "Odorless: Being odorless allows ceiling application to be carried out in more comfortable conditions.",
        "Snow white: Thanks to its superior whiteness, it offers white ceilings and preserves its whiteness for many years.",
      ],
      children: [],
    },
    {
      id: 10174,
      name: "Phenomastic Primary",
      images: ["/uploads/fenomastikprimer.jpg"],
      details: [
        "Fenomastic Emulsion Primer is a water-based primer with high quality, specially developed acrylic resin, which provides high resistance to alkali.",
        "Fenomastic Primer is a high quality, specially developed acrylic resin water-based primer.",
        "Thanks to its low volatile organic compound (VOC) value, it does not reduce the air quality in interiors.",
        "Fenomastic Primer has been specially formulated to better penetrate the surfaces it is applied to.",
        "Interior lining",
        "Low VOC rate",
        "Excellent penetrating property",
      ],
      children: [],
    },
    {
      id: 10175,
      name: "Fenomastic Güzel Evim Mineral Transparent Protector",
      images: ["/uploads/asa.jpg"],
      details: [
        "Fenomastic Güzel Evim Mineral Transparent Protector is a product that offers a transparent-matte finish.",
        "Fenomastic Güzel Evim Mineral Transparent preservative should be applied at least 1 day after Fenomasic Güzel Evim Mineral application.",
        "With the help of a roller, you can distribute it evenly on the entire surface and achieve the best result, it should be applied in one layer.",
        "Transparent-matt appearance",
        "Water repellency",
        "Long-lasting protection",
      ],
      children: [],
    },
    {
      id: 10176,
      name: "Fenomastic My Home Mineral",
      images: [
        "/uploads/jotun-fenomastic-guzel-evim-zengin-mineral--4147-.jpg",
      ],
      details: [
        "Fenomastic Güzel Evim Mineral creates surfaces that are in perfect harmony with all accessories with the timeless beauty of simple textures.",
        "It offers a natural, soft and matte look with simple brush strokes.",
        "Fenomastic Güzel Evim Mineral is applied with simple brush strokes; It offers a natural, matte and soft appearance.",
        "It creates unique color plays on the walls with different color alternatives as well as gray tones that provide a gross concrete appearance.",
        "Natural textured raw and matte appearance",
        "Unique color game",
        "Easy to apply",
      ],
      children: [],
    },
    {
      id: 10177,
      name: "Fenomastic My Beautiful Home Pure Silk",
      images: ["/uploads/110000003397311.jpg"],
      details: [
        "Fenomastic Güzel Evim Pure Silk gives your walls a smooth and soft look.",
        "It creates a natural texture instead of a plastic look like other silk matte paints.",
        "It provides a long-term aesthetic appearance.",
        "Fenomastic Güzel Evim Saf İpek offers a perfect color experience with its high quality silk matte finish and natural texture.",
        "It creates a natural texture instead of a plastic look like other silk matte paints, so your walls look smooth and soft.",
        "It has an environmentally friendly formula.",
        "It provides a long-term aesthetic appearance with its erasable feature.",
        "It is water-based and odorless, so you can freely color your home at any time of the year.",
        "Silk matt interior paint",
        "Excellent color experience",
        "Natural texture",
        "Erasable",
      ],
      children: [],
    },
    {
      id: 10178,
      name: "Phenomenal Zen",
      images: ["/uploads/jotun-fenomastic-zen.jpg"],
      details: [
        "Thanks to its unique technology, Fenomastic Zen offers a unique matte appearance and is easy to clean and stain-proof.",
        "With its antibacterial formula, it ensures that you always have beautiful and clean walls in all areas you need.",
        "Fenomastic Zen is stain-proof thanks to its specially developed surface technology.",
        "Stains that may occur on the surface are easily wiped off, so your walls maintain their beautiful appearance every day.",
        "Thanks to its antibacterial formula, it prevents the formation of mold and bacteria and offers you and your loved ones a safe and healthy living space.",
        "It is water-based; You can freely color your home at any time of the year without experiencing odor problems.",
        "Matt interior paint",
        "Does not stain",
        "Washable",
        "Antibacterial",
        "Unique matte and natural look",
        "Excellent color experience",
      ],
      children: [],
    },
    {
      id: 10179,
      name: "Fenomastic My Beautiful Home Rich Matt",
      images: ["/uploads/jotun-fenomastic-guzel-evim-zengin-mat--4147-.jpg"],
      details: [
        "Colors have a richer and softer appearance with Fenomastic Güzel Evim Rich Matte.",
        "Thanks to its special formula, it offers more beautiful colors with a smooth and matte texture.",
        "With its renewed formula, Fenomastic Güzel Evim Zengin Mat offers an excellent color experience with its specially designed rich matte finish and natural texture.",
        "Thanks to its matte surface, colors look as perfect on your walls as in nature.",
        "Fenomastic Güzel Evim provides you with a smooth appearance with its rich matte natural texture, and it has an environmentally friendly formula.",
        "It provides a long-term aesthetic appearance with its erasable structure.",
        "It is water-based and odorless, so you can freely color your home at any time of the year.",
      ],
      children: [],
    },
    {
      id: 10010,
      name: "Knauf",
      images: ["/uploads/knauf.jpg"],
      details: [],
      children: [10182, 10183],
    },
    {
      id: 10182,
      name: "Powder Plasters",
      images: ["/uploads/1_1.jpg"],
      details: [],
      children: [
        10184, 10185, 10186, 10187, 10188, 10189, 10190, 10191, 10192, 10193,
        10194, 10195,
      ],
    },
    {
      id: 10184,
      name: "Fugagips®",
      images: ["/uploads/12_1.jpg"],
      details: [
        "It is a joint filler plaster used for filling the joints in the joints of gypsum board® types.",
        "Features:",
        "Easy application",
        "High adherence",
        "Extended run time",
        "Usage areas:",
        "It is a special joint filling plaster used for filling the joints in the gypsum board joints and providing a seamless appearance on gypsum board surfaces.",
        "It can also be used for fixing corner profiles and repairs.",
        "Dimensions and General Features:",
        "Weight: 25kg - 3kg",
        "Packaging: 60 bags / pallet - 180 bags / pallet",
        "Shelf Life: 6 months",
        "Product Standard: TS EN 13963 - 3B: Joint material for plasterboards",
      ],
      children: [],
    },
    {
      id: 10185,
      name: "Satengips®",
      images: ["/uploads/11.jpg"],
      details: [
        "It is a hand-applied topcoat surface smoothing plaster applied indoors.",
        "Features:",
        "Smooth surface ready for top coat applications",
        "Easy and fast application possibility",
        "Extended run time",
        "A1 class non-flammable building material",
        "High adherence",
        "Usage areas:",
        "It is applied as a single or double layer on walls covered with plasterboard, cement or gypsum plaster, smooth black plaster and exposed concrete, dirty and painted surfaces.",
        "It is used to obtain a smooth and smooth surface before surface finishing applications such as paint and wallpaper.",
        "Dimensions and General Features:",
        "Weight: 25 kg - 3 kg",
        "Packaging: 60 bags / pallet - 180 bags / pallet",
        "Shelf Life: 6 months",
        "Product Standard: TS EN 13279 - 1 Type: C6, Finely applicable plaster",
      ],
      children: [],
    },
    {
      id: 10186,
      name: "Kargips®",
      images: ["/uploads/10_1.jpg"],
      details: [
        "It is drywall plaster used in interior ceiling and column decorations.",
        "Features:",
        "Smooth appearance",
        "High degree of whiteness",
        "Easy workability",
        "Quick freezing",
        "Gluing papier-mâché",
        "A1 class non-flammable building material",
        "Usage areas:",
        "Drywall applications and repair",
        "Interior ceiling and column headers",
        "Wall ceiling decorations",
        "Dimensions and General Features:",
        "Weight: 25kg - 3kg",
        "Packaging: 60 bags / pallet - 180 bags / pallet",
        "Shelf Life: 6 months",
        "Product Standard: TS EN 13279 - 1 Type: C1/8, Building plaster for fiber reinforced plaster elements",
      ],
      children: [],
    },
    {
      id: 10187,
      name: "Yapıgips®",
      images: ["/uploads/9_2.jpg"],
      details: [
        "Indoor Gypsum Board® is an adhesive plaster.",
        "Features:",
        "High adhesion strength",
        "Quick freeze feature",
        "Easy and fast application possibility",
        "A1 class non-flammable building material",
        "Usage areas:",
        "It enables the application of plasterboard or polystyrene boards on all kinds of uneven floors by sticking them without using any construction.",
        "Dimensions and General Features:",
        "Weight: 25 kg",
        "Packing: 60 bags/pallet",
        "Shelf Life: 6 months",
        "Product Standard: TS EN 14496",
      ],
      children: [],
    },
    {
      id: 10188,
      name: "İzogips®",
      images: ["/uploads/8_2.jpg"],
      details: [
        "It is a perlite plaster plaster applied by hand indoors.",
        "Features:",
        "Breathing feature",
        "Easy application possibility",
        "Extended run time",
        "High adherence",
        "A1 class non-flammable building material",
        "Usage areas:",
        "It is applied indoors on surfaces made of brick, briquette, gas concrete, exposed concrete, pumice block and cement-based plaster.",
        "Dimensions and General Features:",
        "Weight: 35 kg - 25 kg - 3 kg",
        "Packaging: 45 bags / pallet - 60 bags / pallet - 180 bags / pallet",
        "Shelf Life: 6 months",
        "Product Standard: TS EN 13279-1 : Lightweight gypsum plaster for building B4/20/2",
      ],
      children: [],
    },
    {
      id: 10189,
      name: "Jetgips® Diamant",
      images: ["/uploads/7_1.jpg"],
      details: [
        "It is a plaster plaster applied indoors by machine, with increased surface hardness and pressure resistance, and smooth surfaces.",
        "Features:",
        "Breathing feature",
        "Mineral based",
        "High surface hardness and compressive strength",
        "Contribution to comfortable and healthy room air",
        "Wear resistance",
        "Smooth surface that does not require polishing after application",
        "A1 class non-flammable building material",
        "Extended run time",
        "Easy and fast application",
        "Usage areas:",
        "It is a plaster that provides surface hardness and pressure resistance with a single layer application for areas with heavy traffic indoors (public buildings, schools, stairwells, etc.).",
        "It is applied on conventional (brick, gas concrete, pumice) and exposed concrete walls to create smooth, reinforced or leveled surfaces.",
        "Not suitable for use as ceiling plaster.",
        "Dimensions and General Features:",
        "Weight: 35 kg",
        "Packing: 45 bags/pallet",
        "Shelf Life: 6 months",
        "Product Standard: TS EN 13279-1 B7/50/6: Plaster plaster for gypsum elements with increased surface hardness",
      ],
      children: [],
    },
    {
      id: 10190,
      name: "Jetsatengips®",
      images: ["/uploads/6_2.jpg"],
      details: [
        "It is a plaster plaster applied indoors by machine, with increased surface hardness and pressure resistance, and smooth surfaces.",
        "Features:",
        "Breathing feature",
        "Mineral based",
        "High surface hardness and compressive strength",
        "Contribution to comfortable and healthy room air",
        "Wear resistance",
        "Smooth surface that does not require polishing after application",
        "A1 class non-flammable building material",
        "Extended run time",
        "Easy and fast application",
        "Usage areas:",
        "It is a plaster that provides surface hardness and pressure resistance with a single layer application for areas with heavy traffic indoors (public buildings, schools, stairwells, etc.).",
        "It is applied on conventional (brick, gas concrete, pumice) and exposed concrete walls to create smooth, reinforced or leveled surfaces.",
        "Not suitable for use as ceiling plaster.",
        "Dimensions and General Features:",
        "Weight: 35 kg",
        "Packing: 45 bags/pallet",
        "Shelf Life: 6 months",
        "Product Standard: TS EN 13279-1 B7/50/6: Plaster plaster for gypsum elements with increased surface hardness",
      ],
      children: [],
    },
    {
      id: 10191,
      name: "Jetgips® Base",
      images: ["/uploads/5_2.jpg"],
      details: [
        "It is a perlite plaster plaster applied by machine indoors.",
        "Features:",
        "Breathing feature",
        "Contribution to heat and sound insulation",
        "Easy and fast application",
        "A1 class non-flammable building material",
        "Usage areas:",
        "Surfaces made of brick, briquette, gas concrete, exposed concrete, pumice block and cement-based plaster indoors.",
        "Dimensions and General Features:",
        "Weight: 35 kg",
        "Packing: 45 bags/pallet",
        "Shelf Life: 6 months",
        "Product Standard: TS EN 13279 - 1 Type: B4, Lightweight gypsum plaster for building",
      ],
      children: [],
    },
    {
      id: 10192,
      name: "Jetgips® Ultra",
      images: ["/uploads/4_2.jpg"],
      details: [
        "It is a top coat surface smoothing plaster applied by machine indoors.",
        "Features:",
        "Smooth surface ready for top coat applications",
        "Easy and fast application",
        "Can be applied with airless plastering machine",
        "Extended run time",
        "A1 class non-flammable material",
        "Usage areas:",
        "Plasterboard® coated surfaces, gypsum plastered surfaces, cement-based plastered surfaces, paint, wallpaper, etc. It can be used on the surfaces on which the final coat will be applied.",
        "Dimensions and General Features:",
        "Weight: 30 kg",
        "Packing: 50 bags / Pallet",
        "Shelf Life: 6 months",
        "Product Standard: TS EN 13279 - 1 Type: C6, Finely applicable plaster",
      ],
      children: [],
    },
    {
      id: 10193,
      name: "Jetgips® Ultra 40",
      images: ["/uploads/3_2.jpg"],
      details: [
        "It is a perlite plaster plaster applied by machine indoors.",
        "Features:",
        "Breathing feature",
        "Smooth surface that does not require polishing after application",
        "Extended run time",
        "Contribution to thermal insulation",
        "Easy and fast application",
        "A1 class non-flammable building material",
        "Up to 40% more area coverage than Knauf Jetgips Ultra®",
        "Usage areas:",
        "Surfaces made of brick, briquette, gas concrete, exposed concrete, pumice block and cement-based plaster indoors.",
        "Dimensions and General Features:",
        "Weight: 25 kg",
        "Packing: 50 bags/pallet",
        "Shelf Life: 6 months",
        "Product Standard: TS EN 13279 - 1 Type: C4, Plaster plaster for thermal insulation",
      ],
      children: [],
    },
    {
      id: 10194,
      name: "Jetgips® Fire",
      images: ["/uploads/2_2.jpg"],
      details: [
        "Jetgips® Fire is a fire resistant gypsum plaster used indoors. The special mixture of light components and additives, together with the plaster in its content, ensures that the product is applied with the machine without any problems.",
        "Features:",
        "Fire resistance",
        "REI 240 For concrete ceilings and walls",
        "R 180 For concrete beams and columns",
        "R 120 for steel beams and columns",
        "For REI 120 Composite flooring (Under-concrete trapezoidal sheet)",
        "Easy application with machine",
        "Easy workability and high productivity",
        "Gypsum based",
        "Interior use",
        "White color",
        "Can be used in food production areas",
        "Application area:",
        "Concrete ceiling and walls",
        "Concrete beams and columns",
        "Steel beams and columns",
        "Composite flooring (trapezoidal sheet under concrete)",
        "Dimensions and General Features:",
        "Weight: 20 kg / Packaging: 50 bags / pallet",
        "Product Standard: Jetgips® Fire is approved according to European Technical Assessment ETA - 11/0229.",
      ],
      children: [],
    },
    {
      id: 10195,
      name: "İzogips® Fine",
      images: ["/uploads/1.jpg"],
      details: [
        "Izogips® Fine; It is a plaster plaster applied by hand to obtain a smooth surface on rough plaster indoors.",
        "Features:",
        "breathing feature",
        "A1 class non-flammable building material",
        "Easy application",
        "high adherence",
        "Extended run time",
        "Usage areas:",
        "It is applied on rough surfaces made of rough plaster indoors.",
        "General features:",
        "Weight: 35 kg - 25 kg",
        "Packaging: 45 Bags/Pallet (35 kg) - 60 Bags/Pallet (25 Kg)",
        "Shelf Life: 6 months",
        "Product Standard: TS EN 13279-1 : Lightweight gypsum plaster for building B4/20/2",
      ],
      children: [],
    },
    {
      id: 10183,
      name: "Gypsum Boards",
      images: ["/uploads/cutout_1470131_tab_picture_4x3_1.jpg"],
      details: [],
      children: [
        10196, 10197, 10198, 10199, 10200, 10201, 10202, 10203, 10204, 10205,
        10206, 10207, 10208, 10209, 10210,
      ],
    },
    {
      id: 10196,
      name: "Drywall® Regular Plus",
      images: ["/uploads/cutout_348077_tab_picture_4x3_1.jpg"],
      details: [
        "It is a smooth surfaced and flexible Gypsum Board type used indoors. The front side is covered with ivory, the back side is covered with gray colored paper.",
        "Features:",
        "Clean and easy assembly",
        "Saving time",
        "High surface quality",
        "Usage areas:",
        "suspended ceiling",
        "Partition wall",
        "Wall dressing",
        "dry plaster wall covering",
        "Dimensions and General Features:",
        "Thickness: 12.5/15mm",
        "Weight: 9 - 9.5 / 10 - 11 kg/m2",
        "Width: 1200mm",
        "Length: 2200 / 2300 / 2400 / 2500 / 2600 / 2700 / 2800 / 3000 mm",
        "Packing: 30 / 50 pcs / pallet",
        "Product Standard: TS EN 520 + A1 - TYPE A",
      ],
      children: [],
    },
    {
      id: 10197,
      name: "Under Cladding Plate in Guardex® Sheathing and Ventilated Facade Systems",
      images: ["/uploads/cutout_350754_tab_picture_4x3_1.jpg"],
      details: [
        "It is a light, A1 class fireproof, sustainable facade cladding plate used in interior and exterior systems, which prevents mold formation, contributes to insulation, is resistant to water, humidity and external weather conditions, thanks to its special gypsum core and blue glass fiber mattress coating on both sides.",
        "Features:",
        "A1 class fireproof material",
        "Light",
        "Dimensional stability in temperature and humidity changes",
        "moisture resistance",
        "Mold resistance",
        "Breathing (water vapor permeability)",
        "Clean and easy assembly",
        "Saving time",
        "High surface quality",
        "Usage areas:",
        "Coating plate under sheathing",
        "Back cladding plate on ventilated façades",
        "Dimensions and General Features:",
        "Thickness: 12.5/15mm",
        "Weight: 11 - 12 / 13.5 - 14 kg/m2",
        "Width: 1200mm",
        "Length: 2400 / 2500 mm",
        "Packing: 30 / 50 pcs /pallet",
        "Product Standard: TS EN 15283 - 1 + A1 - GM-FH1R",
      ],
      children: [],
    },
    {
      id: 10198,
      name: "Vidipan® Wall Plate",
      images: ["/uploads/cutout_348089_tab_picture_4x3_1.jpg"],
      details: [
        "It is a special type of cellulose-added gypsum board, produced with fiber gypsum technology, providing high sound insulation.",
        "Features:",
        "moisture resistance",
        "Ability to drive nails",
        "High heat and sound insulation",
        "Clean and easy assembly",
        "Saving time",
        "High surface quality",
        "Usage areas:",
        "Partition wall",
        "Wall dressing",
        "prefabricated buildings",
        "Dimensions and General Features:",
        "Thickness: 10 / 12.5 / 15 mm",
        "Weight: 11.8 / 14.75 / 17.7 kg/m2",
        "Width: 1200mm",
        "Length: 2500mm",
        "Packing: 35 / 40 / 50 pcs /pallet",
        "Product Standard: EN 15283 - 2 / ETA-07/0086",
      ],
      children: [],
    },
    {
      id: 10199,
      name: "Silentboard Drywall® with Superior Sound Insulation",
      images: ["/uploads/cutout_348087_tab_picture_4x3_1.jpg"],
      details: [
        "It is a special type of gypsum board with high sound insulation that can be applied easily and quickly. The front and back are covered with brown paper.",
        "Features:",
        "Superior sound insulation",
        "High performance at low frequencies",
        "fire resistance",
        "Flexibility",
        "fireproof material",
        "Dimensional stability under temperature changes",
        "Clean and easy assembly",
        "High surface quality",
        "Usage areas:",
        "suspended ceiling",
        "Partition wall",
        "Wall dressing",
        "Dimensions and General Features:",
        "Thickness: 12.5mm",
        "Weight: approx.17.5 kg/m2",
        "Width: 625mm",
        "Length: 2000mm",
        "Packing: 42 pcs/pallet",
        "Product Standard: EN 520 DFR / DIN18180 GKF",
      ],
      children: [],
    },
    {
      id: 10200,
      name: "Safeboard® Gypsum Board with X-ray Retention Feature",
      images: ["/uploads/cutout_348092_tab_picture_4x3_1.jpg"],
      details: [
        "It is a special lead-free Gypsum Board® type, which has the ability to absorb X-ray rays and additionally has high sound insulation.",
        "Features:",
        "X-ray protection",
        "Lead free",
        "fire resistance",
        "High sound insulation",
        "Flexibility",
        "Clean and easy assembly",
        "Saving time",
        "High surface quality",
        "Usage areas:",
        "suspended ceiling",
        "Partition wall",
        "Wall dressing",
        "Dimensions and General Features:",
        "Thickness: 12.5mm",
        "Weight: approx. 17.8 kg/m2",
        "Width: 625mm",
        "Length: 2500mm",
        "Packing: 42 pcs/pallet",
        "Product Standard: EN 520 - TYPE DF / DIN18180 - GKF",
      ],
      children: [],
    },
    {
      id: 10201,
      name: "Fireboard® Class A1 Special Gypsum Board with High Fire Resistance",
      images: ["/uploads/cutout_348095_tab_picture_4x3_1.jpg"],
      details: [
        "It is a special type of A1 class gypsum board, which does not have paper on both surfaces, is covered with glass fiber mattress, and provides fire resistance up to 3 hours.",
        "Features:",
        "High fire resistance",
        "A1 class fireproof material",
        "Dimensional stability with temperature change",
        "electric arc resistance",
        "Clean and easy assembly",
        "Saving time",
        "High surface quality",
        "Usage areas:",
        "Partition wall",
        "suspended ceiling",
        "shaft wall",
        "Coating of steel constructions",
        "Coating of ventilation and cable ducts",
        "marine equipment",
        "Dimensions and General Features:",
        "Thickness: 15 / 20 / 25mm",
        "Weight: approx.12 / approx.15.6 / approx.19.6 kg/m2",
        "Width: 1200 / 1250 mm",
        "Length: 2000mm",
        "Packing: 20 / 30 / 40 pcs / pallet",
        "Product Standard: EN 15283 - 1 GM-F",
      ],
      children: [],
    },
    {
      id: 10202,
      name: "Water and Fire Resistant Drywall® (WRFR)",
      images: ["/uploads/cutout_348080_tab_picture_4x3_1_2.jpg"],
      details: [
        "It is a smooth surfaced, light gypsum board type with increased fire resistance and water repellency. The front side is covered with pink, the back side is covered with green-gray colored paper.",
        "Features:",
        "Moisture and fire resistance",
        "Clean and easy assembly",
        "Tiles, ceramics etc. applicability",
        "Saving time",
        "High surface quality",
        "Usage areas:",
        "suspended ceiling",
        "Partition wall",
        "shaft walls",
        "Wet areas",
        "Dimensions and General Features:",
        "Thickness: 12.5 / 15 / 18 mm",
        "Weight: 9.5 - 10.5 / 11.5 - 12.5 / 14 - 15 kg/m2",
        "Width: 1200mm",
        "Length: 2500 (standard) / 2600 / 2700 / 2800 / 2900 / 3000 mm",
        "Packing: 30 / 50 pcs / pallet",
        "Product Standard: TS EN 520 + A1 - TYPE HF",
      ],
      children: [],
    },
    {
      id: 10203,
      name: "Fire Resistant Drywall® (FRDF)",
      images: ["/uploads/cutout_348080_tab_picture_4x3_1_1.jpg"],
      details: [
        "It is a type of Gypsum Board with increased fire resistance, glass fiber additive, smooth surface and increased density. The front side is covered with pink paper and the back side is covered with gray colored paper.",
        "Features:",
        "fire resistance",
        "Clean and easy assembly",
        "Saving time",
        "High surface quality",
        "Usage areas:",
        "suspended ceiling",
        "Partition wall",
        "Wall dressing",
        "shaft walls",
        "Dimensions and General Features:",
        "Thickness: 12.5mm",
        "Weight: 11 - 12 kg/m2",
        "Width: 1200mm",
        "Length: 2500mm",
        "Packing: 50 pcs/pallet",
        "Product Standard: TS EN 520 + A1 - TYPE DF",
      ],
      children: [],
    },
    {
      id: 10204,
      name: "Fire Resistant Drywall® (FR)",
      images: ["/uploads/cutout_348080_tab_picture_4x3_1.jpg"],
      details: [
        "It is a light gypsum board type with smooth surface, glass fiber added, with increased fire resistance time. The front side is covered with pink paper and the back side is covered with gray colored paper.",
        "Features:",
        "fire resistance",
        "Clean and easy assembly",
        "Saving time",
        "High surface quality",
        "Usage areas:",
        "suspended ceiling",
        "Partition wall",
        "Wall dressing",
        "shaft walls",
        "Dimensions and General Features:",
        "Thickness: 12.5 - 15 - 18 mm",
        "Weight: 9.5 - 10.5 / 11.5 - 12.5 / 14 - 15 kg/m2",
        "Width: 1200mm",
        "Length: 2500 (standard) / 2600 / 2700 / 2800 / 2900 / 3000 mm",
        "Packing: 30 / 50 pcs / pallet",
        "Product Standard: TS EN 520 + A1 - TYPE F",
      ],
      children: [],
    },
    {
      id: 10205,
      name: "Moldboard® Mold, Water and Fire Resistant Drywall® (MWFR)",
      images: ["/uploads/cutout_348084_tab_picture_4x3_1.jpg"],
      details: [
        "It is a light gypsum board type with smooth surface, glass fiber added, with increased fire resistance time. The front side is covered with pink paper and the back side is covered with gray colored paper.",
        "Features:",
        "fire resistance",
        "Clean and easy assembly",
        "Saving time",
        "High surface quality",
        "Usage areas:",
        "suspended ceiling",
        "Partition wall",
        "Wall dressing",
        "shaft walls",
        "Dimensions and General Features:",
        "Thickness: 12.5 - 15 - 18 mm",
        "Weight: 9.5 - 10.5 / 11.5 - 12.5 / 14 - 15 kg/m2",
        "Width: 1200mm",
        "Length: 2500 (standard) / 2600 / 2700 / 2800 / 2900 / 3000 mm",
        "Packing: 30 / 50 pcs / pallet",
        "Product Standard: TS EN 520 + A1 - TYPE F",
      ],
      children: [],
    },
    {
      id: 10206,
      name: "Moldboard® Mold and Water Resistant Drywall® (MWR)",
      images: ["/uploads/cutout_348081_tab_picture_4x3_1.jpg"],
      details: [
        "It is a smooth surfaced gypsum board type that can be used in wet areas and humid environments, has water repellency, prevents mold and provides resistance against mold. The front side is covered with green paper and the back side is covered with gray paper.",
        "Features:",
        "moisture resistance",
        "Mold resistance",
        "Tiles, ceramics etc. applicability",
        "Clean and easy assembly",
        "Saving time",
        "High surface quality",
        "Usage areas:",
        "suspended ceiling",
        "Partition wall",
        "Wall dressing",
        "dry plaster wall covering",
        "shaft walls",
        "Wet area",
        "Dimensions and General Features:",
        "Thickness: 12.5/15mm",
        "Weight: 10 - 11 / 11 - 12 kg/m2",
        "Width: 1200mm",
        "Length: 2500mm",
        "Packing: 30 / 50 pcs / pallet",
        "Product Standard: TS EN 520 + A1 - Type H1",
      ],
      children: [],
    },
    {
      id: 10207,
      name: "Flex Drywall® (FX)",
      images: ["/uploads/cutout_348075_tab_picture_4x3_1.jpg"],
      details: [
        "It is a smooth surfaced, flexible and light gypsum board type used indoors. The front side is covered with ivory, the back side is covered with gray colored paper.",
        "Properties:",
        "Clean and easy assembly",
        "Saving time",
        "High surface quality",
        "Usage areas:",
        "suspended ceiling",
        "Partition wall",
        "Wall dressing",
        "dry plaster wall covering",
        "Dimensions and General Features:",
        "Thickness: 9.5 / 12.5 / 15 / 18 mm",
        "Weight: 6.5 - 7.5 / 7.5 - 8 / 10 - 11 / 14 -15 kg/m2",
        "Width: 1200mm",
        "Length: 2000 / 2500 (standard) / 2600 / 2700 / 2800 / 2900 / 3000 mm",
        "Packing: 30 / 50 / 60 pcs / pallet",
        "Product Standard: TS EN 520 + A1 - TYPE A",
      ],
      children: [],
    },
    {
      id: 10208,
      name: "Waterproof Drywall® (WR)",
      images: ["/uploads/cutout_348079_tab_picture_4x3_1.jpg"],
      details: [
        "It is a light gypsum board type with smooth surface, which can be used in all kinds of wet areas and humid environments. The front side is covered with green paper and the back side is covered with gray paper.",
        "Features:",
        "moisture resistance",
        "Tiles, ceramics etc. applicability",
        "Clean and easy assembly",
        "Saving time",
        "High surface quality",
        "Usage areas:",
        "suspended ceiling",
        "Partition wall",
        "Wall dressing",
        "dry plaster wall covering",
        "shaft walls",
        "Wet areas",
        "Dimensions and General Features:",
        "Thickness: 9.5 / 12.5 / 15 / 18 mm",
        "Weight: 7 - 7.5 / 8 - 8.5 / 10.5 - 11.5 / 14 - 15 kg/m2",
        "Width: 1200mm",
        "Length: 2500 (standard) / 2600 / 2700 / 2800 / 2900 / 3000 mm",
        "Packing: 30 / 50 / 60 pcs / pallet",
        "Product Standard: TS EN 520 + A1 - TYPE H",
      ],
      children: [],
    },
    {
      id: 10209,
      name: "Diamant® Impact, Water and Fire Resistant Gypsum Board®",
      images: ["/uploads/cutout_348094_tab_picture_4x3_1.jpg"],
      details: [
        "It is a special type of Gypsum Board® that is resistant to impact, has increased strength, fire and water resistance, and sound insulation properties. The front and back sides are covered with blue colored paper.",
        "Features:",
        "Impact resistance",
        "fire resistance",
        "moisture resistance",
        "High value sound insulation",
        "higher walls",
        "High load carrying capacity",
        "Increased surface hardness",
        "Clean and easy assembly",
        "Saving time",
        "High surface quality",
        "Usage areas:",
        "Partition wall",
        "Wall dressing",
        "suspended ceiling",
        "shaft wall",
        "Dimensions and General Features:",
        "Thickness: 12.5/15mm",
        "Weight: 12.5 - 13.5 / 15-16 kg/m2",
        "Width: 1200mm",
        "Length: 2500mm",
        "Packing: 30/40 pcs/pallet",
        "Product Standard: TS EN 520 + A1, TYPE DFH2IR",
      ],
      children: [],
    },
    {
      id: 10210,
      name: "Drywall Flex Ultra (FX Ultra)",
      images: ["/uploads/cutout_1470131_tab_picture_4x3_1.jpg"],
      details: [
        "It is a special type of gypsum board, resistant to impact, with increased strength and sound insulation properties. The front side is covered with yellow paper and the back side is covered with gray paper.",
        "Properties:",
        "Impact resistance",
        "Clean and easy assembly",
        "High value sound insulation",
        "higher walls",
        "High load carrying capacity",
        "Increased surface hardness",
        "Saving time",
        "High surface quality",
        "Usage areas:",
        "suspended ceiling",
        "Partition wall",
        "Wall dressing",
        "Dimensions and General Features:",
        "Thickness: 12.5 // 15 mm",
        "Weight: 12.5 - 13.5 kg/m2 // 15 - 16 kg/m2",
        "Width: 1200mm",
        "Length: 2500mm",
        "Packing: 40 pcs/pallet // 30 pcs/pallet",
        "Product Standard: TS EN 520 + A1 - Type DIR",
      ],
      children: [],
    },
    {
      id: 10011,
      name: "Knauf Insulation",
      images: ["/uploads/knaufinsulation.jpg"],
      details: [],
      children: [10211, 10212, 10213],
    },
    {
      id: 10211,
      name: "Curtain Wall Insulation",
      images: ["/uploads/1_5.jpg"],
      details: [],
      children: [10214, 10215, 10216, 10217, 10218, 10219],
    },
    {
      id: 10214,
      name: "Mineral Plus FCB037 KD",
      images: ["/uploads/6_4.jpg"],
      details: [
        "Low heat transfer coefficient, heat conduction is resistant to. λ = 0.037 W/m.K",
        "Absorbs airborne sounds. It provides sound insulation.",
        "Reaction to fire class is A1.",
        "It makes minimum dusting, it is not itchy.",
        "It is flexible, easy to use, saves time and labor.",
        "Compressed package; The stocking cost is low.",
        "Covered with Ecose® Tulle. Against external influences It has extra durability.",
        "FEATURES",
        "Thickness (mm)",
        "R Value (m²k/W)",
        "Length (mm)",
        "Width (mm)",
        "Number of Items in the Package (pieces)",
        "Package Area (m²)",
        "Pallet Area (m²)",
        "50 1.35 1200 600 24 12.96 311.04",
        "80 2.15 1200 600 24 7.2 172.8",
        "100 2.75 1200 600 24 6.48 155.52",
      ],
      children: [],
    },
    {
      id: 10215,
      name: "Mineral Plus FCB037",
      images: ["/uploads/5_4.jpg"],
      details: [
        "Roll or Sheet Plate",
        "Coated or Uncoated Uncoated",
        "AREA OF USE",
        "Renovation or New Building Renovation, New Building",
        "Building Type Public Projects, Residence, Hotel, Industry",
        "FEATURES",
        "Thickness (mm)",
        "R Value (m²k/W)",
        "Length (mm)",
        "Width (mm)",
        "Number of Items in the Package (pieces)",
        "Package Area (m²)",
        "Pallet Area (m²)",
        "50 1.35 1200 600 18 12.96 311.04",
        "80 2.15 1200 600 12 8.64 172.8",
        "100 2.70 1200 100 9 6.48 155.52",
      ],
      children: [],
    },
    {
      id: 10216,
      name: "Mineral Plus FCB034 KD",
      images: ["/uploads/4_5.jpg"],
      details: [
        "Low heat transfer coefficient, heat conduction is resistant to. λ = 0.034 W/m.K",
        "Absorbs airborne sounds.",
        "Reaction to fire class is A1.",
        "It makes minimum dusting, it is not itchy.",
        "It is flexible, easy to use, saves time and labor.",
        "Compressed package; The stocking cost is low.",
        "Covered with Ecose® Tulle. Against external influences It has extra durability.",
      ],
      children: [],
    },
    {
      id: 10217,
      name: "Mineral Plus FCB 034",
      images: ["/uploads/1_5.jpg"],
      details: [
        "Low heat transfer coefficient, heat conduction is resistant to. λ = 0.034 W/m.K",
        "Absorbs airborne sounds.",
        "Reaction to fire class is A1.",
        "It makes minimum dusting, it is not itchy.",
        "It is flexible, easy to use, saves time and labor.",
        "Compressed package; The stocking cost is low.",
        "FEATURES",
        "Thickness (mm)",
        "R Value (m²k/W)",
        "Length (mm)",
        "Width (mm)",
        "Number of Items in the Package (pieces)",
        "Package Area (m²)",
        "Pallet Area (m²)",
        "50 1.45 1200 600 16 9.6 230.4",
        "80 2.35 1200 600 10 7.2 144",
        "100 2.90 1200 600 8 5.76 115.2",
      ],
      children: [],
    },
    {
      id: 10218,
      name: "Mineral Plus FCB033 Extra",
      images: ["/uploads/2_7.jpg"],
      details: [
        "Low heat transfer coefficient, heat conduction is resistant to. λ = 0.033 W/m.K",
        "Absorbs airborne sounds.",
        "Reaction to fire class is A1.",
        "It makes minimum dusting, it is not itchy.",
        "It is flexible, easy to use, saves time and labor.",
        "Compressed package; The stocking cost is low.",
        "FEATURES",
        "Thickness (mm)",
        "R Value (m²k/W)",
        "Length (mm)",
        "Width (mm)",
        "Number of Items in the Package (pieces)",
        "Package Area (m²)",
        "Pallet Area (m²)",
        "50 1.50 1200 600 10 7.2 144",
      ],
      children: [],
    },
    {
      id: 10219,
      name: "Mineral Plus FCB032 Pro",
      images: ["/uploads/1_4.jpg"],
      details: [
        "Low heat transfer coefficient, heat conduction is resistant to. λ = 0.032 W/m.K",
        "Absorbs airborne sounds.",
        "Reaction to fire class is A1.",
        "It makes minimum dusting, it is not itchy.",
        "It is flexible, easy to use, saves time and labor.",
        "Compressed package; The stocking cost is low.",
        "FEATURES",
        "Thickness (mm)",
        "R Value (m²k/W)",
        "Length (mm)",
        "Width (mm)",
        "Number of Items in the Package (pieces)",
        "Package Area (m²)",
        "Pallet Area (m²)",
        "50 1.55 1200 600 8 5.76 138.24",
        "100 3.10 1200 600 4 2.88 57.6",
      ],
      children: [],
    },
    {
      id: 10212,
      name: "Pitched Roof Insulation",
      images: ["/uploads/1_4.jpg"],
      details: [],
      children: [10220, 10221, 10222],
    },
    {
      id: 10220,
      name: "Earthwool 043 Alu",
      images: ["/uploads/3_5.jpg"],
      details: [
        "Thanks to its low thermal conductivity coefficient, it provides excellent thermal insulation and contributes to sound insulation.",
        "It is in A1 class in reaction to fire classification.",
        "Thanks to its unique fiber structure, it dusts less and itches less than alternative mattresses.",
        "It allows easy and comfortable applications.",
        "Thanks to its flexible and durable fiber structure, the material is cut much more easily.",
        "Thanks to the innovative ECOSE® Technology, it does not contain phenol and formaldehyde derivative harmful chemicals, acrylic and dyestuffs in its binder.",
        "It is environmentally friendly with its recyclable raw material content; It contributes to the creation of healthier and livable living spaces in buildings.",
        "It prevents possible thermal and acoustic leaks by being perfectly placed on the details.",
      ],
      children: [],
    },
    {
      id: 10221,
      name: "Earthwool 043",
      images: ["/uploads/2_6.jpg"],
      details: [
        "Thanks to its low thermal conductivity coefficient, it provides excellent thermal insulation and contributes to sound insulation.",
        "It is in A1 class in reaction to fire classification.",
        "Thanks to its unique fiber structure, it dusts less and itches less than alternative mattresses.",
        "It allows easy and comfortable applications.",
        "Thanks to its flexible and durable fiber structure, the material is cut much more easily.",
        "Thanks to the innovative ECOSE® Technology, it does not contain phenol and formaldehyde derivative harmful chemicals, acrylic and dyestuffs in its binder.",
        "It is environmentally friendly with its recyclable raw material content; It contributes to the creation of healthier and livable living spaces in buildings.",
        "It prevents possible thermal and acoustic leaks by being perfectly placed on the details.",
      ],
      children: [],
    },
    {
      id: 10222,
      name: "Earthwool 035",
      images: ["/uploads/1_3.jpg"],
      details: [
        "Thanks to its low thermal conductivity coefficient, it provides excellent thermal insulation and contributes to sound insulation.",
        "It is in A1 class in reaction to fire classification.",
        "Thanks to its unique fiber structure, it dusts less and itches less than alternative mattresses.",
        "It allows easy and comfortable applications.",
        "Thanks to its flexible and durable fiber structure, the material is cut much more easily.",
        "Thanks to the innovative ECOSE® Technology, it does not contain phenol and formaldehyde derivative harmful chemicals, acrylic and dyestuffs in its binder.",
        "It is environmentally friendly with its recyclable raw material content; It contributes to the creation of healthier and livable living spaces in buildings.",
        "It prevents possible thermal and acoustic leaks by being perfectly placed on the details.",
      ],
      children: [],
    },
    {
      id: 10213,
      name: "Partition Insulation",
      images: ["/uploads/bb3692fdaef3a25a5524808fba724fcf.jpg"],
      details: [],
      children: [10223, 10224],
    },
    {
      id: 10223,
      name: "Mineral Plus IPB 039",
      images: ["/uploads/2_5.jpg"],
      details: [
        "BENEFITS - Low heat transfer coefficient, heat conduction is resistant to. λ = 0.039 W/m.K",
        "Absorbs airborne sounds.",
        "Reaction to fire class is A1.",
        "It makes minimum dusting, it is not itchy.",
        "It is flexible, easy to use, saves time and labor.",
        "Compressed package; The stocking cost is low.",
        "FEATURES Thickness (mm) R Value (m²k/W) Length (mm) Width (mm) Number of Items in the Package (pieces) Package Area (m²) Pallet Area (m²)",
        "50 1.25 1200 600 20 14.4 403.2",
        "80 2.05 1200 600 12 8.64 241.92",
        "100 2.55 1200 600 10 7.2 201.6",
      ],

      children: [],
    },
    {
      id: 10224,
      name: "Mineral Plus IPB 037",
      images: ["/uploads/bb3692fdaef3a25a5524808fba724fcf.jpg"],
      details: [
        "BENEFITS - Low heat transfer coefficient, heat conduction is resistant to. λ = 0.037 W/m.K",
        "Absorbs airborne sounds.",
        "Reaction to fire class is A1.",
        "It makes minimum dusting, it is not itchy.",
        "It is flexible, easy to use, saves time and labor.",
        "Compressed package; The stocking cost is low.",
        "FEATURES Thickness (mm) R Value (m²k/W) Length (mm) Width (mm) Number of Items in the Package (pieces) Package Area (m²) Pallet Area (m²)",
        "50 1.35 1200 600 20 14.4 345.6",
        "80 2.15 1200 600 12 8.64 207.36",
        "100 2.70 1200 600 10 7.2 172.8",
      ],
      children: [],
    },
    {
      id: 10012,
      name: "Fullboard",
      images: ["/uploads/fullboard.jpg"],
      details: [],
      children: [10225, 10226, 10227],
    },
    {
      id: 10225,
      name: "Grandex Exterior Plate",
      images: ["/uploads/5cff4b6c78ceb868186506.jpg"],
      details: [],
      children: [10228],
    },
    {
      id: 10228,
      name: "Unnamed Subproduct",
      images: ["/uploads/5d31a949681ce986380169.jpg"],
      details: [],
      children: [],
    },
    {
      id: 10226,
      name: "Powder Plasters",
      images: ["/uploads/5e14653ce3277290839710.jpg"],
      details: [],
      children: [10229, 10230, 10231, 10232, 10233, 10234, 10235, 10236, 10237],
    },
    {
      id: 10229,
      name: "Fullgips Fix (Adhesive Plaster)",
      images: ["/uploads/9_1.jpg"],
      details: [
        "Application: Before starting the application, the surface must be cleaned.",
        "A sufficient amount of water is poured into a clean container. FULLGIPS Adhesive Plaster is sieved to cover the water surface. It is waited for a few minutes, then the mortar is mixed until it reaches a homogeneous consistency for application, so that there are no lumps in it.",
        "When the mixing work is completed, the mortar is ready for use. The mortar in the container can be used for approximately 80 minutes.",
        "After the adhesive mortar is prepared, FULLBOARD is applied to the back of the FULLBOARD Gypsum Plate in the width of a fist, starting from the beginning and at 40 cm intervals, to adhere to the back side of the FULLBOARD Gypsum Plate, in a heap every 20-25 cm (approximately one span) along the plate. To be applied.",
        "In mineral wool applications, an application should be made every 30 cm 60-90 minutes before adhering the mineral wool to the surface.",
        "FULLBOARD Plasterboard is adhered to the surface with the backing plaster applied and its balance is checked with the help of a gauge.",
        "For places where the gaps on the surface are more than 3 cm, pre-mounting should be done beforehand.",
        "Suggestions: Do not mix FULLGIPS Adhesive Plaster with any other product.",
        "If you use a mechanical mixer, use it at low speed.",
        "Do not add water or plaster to your mortar after mixing.",
        "The temperature of the application environment should be at least +5°C.",
        "Dry and hot surfaces should be moistened before application.",
        "Storage Conditions: FULLGIPS Adhesive Plaster can be stacked up to 20 bags on top of each other.",
        "It is recommended to consume FULLGIPS Adhesive Plaster within 1 year, provided that it is stored on a pallet in a dry, moisture-free and closed environment.",
        "Poor storage conditions or exceeding the shelf life may degrade the properties of FULLGIPS Adhesive Plaster.",
      ],
      children: [],
    },
    {
      id: 10230,
      name: "Fullgips Joint (Joint Filler Plaster)",
      images: ["/uploads/8_1.jpg"],
      details: [
        "Application: Before starting the application, the surface must be cleaned.",
        "A sufficient amount of water is poured into a clean container. FULLGIPS Grouting Plaster is sprinkled to cover the water surface. It is waited for a few minutes, then the mortar is mixed until it reaches a homogeneous consistency for application, so that there are no lumps in it.",
        "When the mixing work is completed, the mortar is ready for use. The mortar in the container can be used for approximately 1 hour.",
        "The application is made with a steel spatula and in two stages.",
        "The first layer of FULLGIPS Joint Filler Plaster is applied liberally on the joint tape, which is adhered to the joints.",
        "Wait at least 2 hours for the first layer to dry. Before the second coat is applied, the roughnesses on the surface are removed with a spatula.",
        "A second coat of approximately 20 cm width is applied to reset the surface.",
        "Suggestions: Do not mix FULLGIPS Grouting Plaster with any other product or material.",
        "If you use a mechanical mixer, use it at low speed.",
        "Do not add water or plaster to your mortar after mixing.",
        "The temperature of the application environment should be at least +5°C.",
        "Gaps larger than 3 mm should be pre-filled.",
        "Storage Conditions: Maximum 20 bags of FULLGIPS Grouting Plaster should be stacked on top of each other.",
        "It is recommended to consume FULLGIPS Grouting Plaster within 1 year, provided that it is stored on a pallet in a dry, moisture-free and closed environment.",
        "Poor storage conditions or exceeding the usage period may impair the qualities of FULLGIPS Grouting Plaster.",
      ],
      children: [],
    },
    {
      id: 10231,
      name: "Fullgips Satin (Satin Finishing Plaster)",
      images: ["/uploads/7.jpg"],
      details: [
        "Application: Dust and anti-adhesion substances on the application surface should be cleaned.",
        "A sufficient amount of water is poured into a clean container. FULLGIPS Satin Finishing Plaster is sieved to cover the water surface. It is waited for a few minutes, then the mortar is mixed until it reaches a homogeneous consistency. After mixing, the mortar is ready to use. The mortar in the container can be used for approximately 120 minutes.",
        "Application is made with a steel trowel.",
        "Suggestions: Do not mix FULLGIPS Satin Finishing Plaster with any other material.",
        "If a mechanical mixer is to be used, it should be used at low speed.",
        "Do not add water or plaster to your mortar after mixing.",
        "The temperature of the application environment should be at least +5°C.",
        "Storage Conditions: Maximum 20 bags should be stacked on top of each other.",
        "It is recommended to be consumed within 1 year, provided that it is stored on a pallet in a dry, moisture-free and closed environment.",
        "Poor storage conditions or exceeding the period of use may deteriorate the product's qualities.",
      ],
      children: [],
    },
    {
      id: 10232,
      name: "Fullgips Effect (Papier Plaster)",
      images: ["/uploads/6_1.jpg"],
      details: [
        "Application: A sufficient amount of water is poured into a clean container. FULLGIPS Cornice Plaster is sieved to cover the water surface. It is waited for a few minutes, then it is mixed until it reaches a homogeneous consistency to apply the mortar. After mixing, the mortar is ready to be poured into the molds. The mortar hardens in about 8-10 minutes.",
        "Cornices made with FULLGIPS Cornice Plaster should be allowed to dry completely before assembly.",
        "Suggestions: Do not mix FULLGIPS Cornice Plaster with any other product.",
        "If you use a mechanical mixer, use it at low speed.",
        "Do not add water or plaster to your mortar after mixing.",
        "The temperature of the application environment should be at least +5°C.",
        "Storage Conditions: It is recommended not to stack more than 20 bags on top of FULLGIPS Cornice Plaster.",
        "It is recommended to consume FULLGIPS Drywall Plaster within 1 year, provided that it is stored on a pallet in a dry, moisture-free and closed environment.",
        "Poor storage conditions or exceeding the usage period may deteriorate the qualities of FULLGIPS Bricklayer Plaster.",
      ],
      children: [],
    },
    {
      id: 10233,
      name: "Fullgips Plaster (Perlite Plaster)",
      images: ["/uploads/5_1.jpg"],
      details: [
        "Application: Before starting the application, the anti-adhesion materials on the surface should be cleaned.",
        "A sufficient amount of water is poured into a clean container. FULLGIPS Perlite Plaster Plaster is sprinkled until the water level exceeds 1 cm. It is waited for a few minutes, then it is mixed until there are no lumps in the mortar until it reaches the consistency of dough for application.",
        "After mixing, the mortar is ready to use. The mortar in the container can be used for approximately 1 hour.",
        "Application is made with a gauge, plaster trowel and steel spatula. The scars are shaved off after the mortar begins to harden.",
        "FULLGIPS Perlite Plaster Gypsum mortar, brick, gas concrete etc. It is applied directly to the wall surfaces 5-40 mm, to the ceiling and exposed concrete surfaces 5-20 mm.",
        "Suggestions: Do not apply less than 5 mm in one coat.",
        "Do not add water or plaster to your mortar after mixing.",
        "Do not mix FULLGIPS Perlite Plaster Plaster with any other material.",
        "If a mechanical mixer is used, use it at low speed.",
        "The temperature of the application environment should be at least +5°C.",
        "Dry and hot surfaces should definitely be wetted before the application and the surface should be moistened.",
        "Storage Conditions: Maximum 20 bags of FULLGIPS Perlite Plaster should be stacked on top of each other.",
        "FULLGIPS Perlite Plaster Plaster is recommended to be consumed within 1 year, provided that it is stored on a pallet in a dry, moisture-free and closed environment.",
        "Poor storage conditions or exceeding the usage period may deteriorate the properties of FULLGIPS PERLITE Perlite Plaster.",
      ],
      children: [],
    },
    {
      id: 10234,
      name: "Fullgips Finish (Surface Smoothing Plaster)",
      images: ["/uploads/4_1.jpg"],
      details: [
        "Application: It is a gypsum-based surface smoothing plaster with high adhesion strength.",
        "It can be applied on machined plaster plaster, perlite plaster plaster, black plaster, rough plastered surfaces and plaster plate.",
        "The water of the mortar created from Fullgips Finish, which is produced with special additives, is less absorbed by the surface on which it is used.",
        "It is easy to apply. It closes all kinds of cracks and defects on the surface and helps the surface to be ready for Fullgips Satin application.",
        "By creating a smooth surface, FullgipsFinish also reduces the consumption of satin plaster to be applied to the surface.",
        "It is a breathing material, balances the humidity and creates a healthy environment.",
        "It has fire retardant properties due to the water it contains. It is an A1 class building material and is a non-combustible material.",
        "It has a long working time, is applied easily and quickly.",
        "Contributes to heat and sound insulation.",
        "Suggestions: Do not mix FULLGIPS Surface Smoothing Plaster with any other material.",
        "If a mechanical mixer is to be used, it should be used at low speed.",
        "Do not add water or plaster to your mortar after mixing.",
        "The temperature of the application environment should be at least +5°C.",
        "Storage Conditions: Maximum 20 bags should be stacked on top of each other.",
        "It is recommended to be consumed within 1 year, provided that it is stored on a pallet in a dry, moisture-free and closed environment.",
        "Poor storage conditions or exceeding the period of use may deteriorate the product's qualities.",
      ],
      children: [],
    },
    {
      id: 10235,
      name: "Fullgips Turbo (Machine Plastering)",
      images: ["/uploads/3_1.jpg"],
      details: [
        "Application: It is a gypsum based interior machine plaster.",
        "Concrete, gross concrete, gas concrete, pumice block, brick and black plaster, cement-based rough plaster etc. Applied directly on materials.",
        "It has high surface hardness and glossy appearance.",
        "It is a breathing material, balances the humidity and creates a healthy environment.",
        "It has fire retardant properties due to the water it contains. It is an A1 class building material and is a non-combustible material.",
        "It has a long working time, is applied easily and quickly.",
        "Contributes to heat and sound insulation.",
        "Suggestions: Do not mix FULLGIPS Turbo Machine Plaster with any other material.",
        "If a mechanical mixer is to be used, it should be used at low speed.",
        "Do not add water or plaster to your mortar after mixing.",
        "The temperature of the application environment should be at least +5°C.",
        "Storage Conditions: Maximum 20 bags should be stacked on top of each other.",
        "It is recommended to be consumed within 1 year, provided that it is stored on a pallet in a dry, moisture-free and closed environment.",
        "Poor storage conditions or exceeding the period of use may deteriorate the product's qualities.",
      ],
      children: [],
    },
    {
      id: 10236,
      name: "Fullgips Power (Machine Plastering)",
      images: ["/uploads/2_1.jpg"],
      details: [
        "Before starting the application, the anti-adhesion materials on the surface should be cleaned.",
        "Plaster laths are adhered to the surface to be plastered at approximately 150-200 cm intervals.",
        "FULLGIPS Machine Plaster is mixed by the plaster machine and sprayed between the plasters.",
        "After spraying, the surfaces are smoothed using a gauge.",
        "The second gauge is made approximately 15-20 minutes after the first gauge.",
        "Water is sprayed on the surface 50-70 minutes after starting the application and the surface is trifilled with a trifil trowel.",
        "After about 10 minutes, the surface is polished with a steel trowel.",
        "The second trowel is applied approximately 60 minutes after the first trowel and a bright and hard surface is obtained.",
        "The temperature of the application environment should be at least +5°C.",
        "Dry and hot surfaces should be moistened before application.",
        "Do not mix FULLGIPS Machine Plaster with any other material.",
        "Application thickness should be between 8-30 mm.",
        "The plastering machine and the tools used must be clean at every stage.",
        "It is recommended to stack up to 20 bags of FULLGIPS Machine Plaster on top of each other.",
        "It is recommended to consume FULLGIPS Machine Plaster within 1 year, provided that it is stored on a pallet in a dry, moisture-free and closed environment.",
        "Poor storage conditions or exceeding the usage period may impair the qualities of FULLGIPS Machine Plaster.",
      ],
      children: [],
    },
    {
      id: 10237,
      name: "Fullgips Extra (Machine Extra Surface Plaster)",
      images: ["/uploads/5e14653ce3277290839710.jpg"],
      details: [
        "Before starting the application, the anti-adhesion materials on the surface should be cleaned.",
        "Plaster laths at the required height are adhered to the surface to be plastered at approximately 150-200 cm intervals.",
        "FULLGIPS Extra Machined Plaster is mixed with a plastering machine and sprayed between the slabs.",
        "After spraying, the surfaces are smoothed using a gauge.",
        "The second gauge is made approximately 15-20 minutes after the first gauge.",
        "Water is sprayed on the surface 50-70 minutes after starting the application and the surface is trifilled with a trifil trowel.",
        "After about 10 minutes, the surface is polished with a steel trowel.",
        "The second trowel is applied approximately 60 minutes after the first trowel and a bright and hard surface is obtained.",
        "The temperature of the application environment should be at least +5°C.",
        "Dry and hot surfaces should be moistened before application.",
        "Do not mix FULLGIPS Extra Machine Plaster with any other material.",
        "Application thickness should be between 8-30 mm.",
        "The plastering machine and the tools used must be clean at every stage.",
        "It is recommended to stack a maximum of 20 bags of FULLGIPS Extra Machined Plaster on top of each other.",
        "It is recommended to consume FULLGIPS Extra Machine Plaster within 1 year, provided that it is stored on a pallet in a dry, moisture-free and closed environment.",
        "Poor storage conditions or exceeding the usage period may impair the qualities of FULLGIPS Extra Machined Plaster.",
      ],
      children: [],
    },
    {
      id: 10227,
      name: "Gypsum Plates",
      images: ["/uploads/611128415f22d449077076.jpg"],
      details: [],
      children: [10238, 10239, 10240, 10241, 10242, 10243],
    },
    {
      id: 10238,
      name: "Unnamed Subproduct",
      images: ["/uploads/5a884ebaa5cd7675341708.jpg"],
      details: [],
      children: [],
    },
    {
      id: 10239,
      name: "Unnamed Subproduct",
      images: ["/uploads/5a884e7ae2bb8142530240.jpg"],
      details: [],
      children: [],
    },
    {
      id: 10240,
      name: "Unnamed Subproduct",
      images: ["/uploads/3_9.jpg"],
      details: [],
      children: [],
    },
    {
      id: 10241,
      name: "Unnamed Subproduct",
      images: ["/uploads/5c545d7bf0dbf215838010.jpg"],
      details: [],
      children: [],
    },
    {
      id: 10242,
      name: "Unnamed Subproduct",
      images: ["/uploads/5ca667515e1a0794573362.jpg"],
      details: [],
      children: [],
    },
    {
      id: 10243,
      name: "Fullboard FULLPLUS",
      images: ["/uploads/611128415f22d449077076.jpg"],
      details: [
        "Superior Quality Increased Density Gypsum Board",
        "High-quality condensed plate FULLPLUS is a smooth surface plasterboard.",
        "Partition wall, suspended ceiling and wall cladding etc. used in applications.",
        "Both sides are covered with special gray colored cardboard that provides flexibility and high strength to the plate.",
        "Features",
        "It is easy and fast to install, has high surface quality and saves time.",
        "Usage areas",
        "Suspended ceiling, Partition wall, Wall cladding, Dry plaster wall covering areas.",
        "Product Standards",
        "TS EN 520 + A1 - TYPE A",
        "Dimensions and General Features",
        "Thickness: 12.5 mm",
        "Width: 1200 mm",
        "Length: 2000/2100/.../2500/2600/2700 mm",
        "Weight: ~9 kg/m2",
        "Pallet Quantity: 50 Pieces/Pallet",
      ],
      children: [],
    },
    {
      id: 10013,
      name: "Ravago",
      images: ["/uploads/ravago.jpg"],
      details: [],
      children: [10244, 10245, 10246],
    },
    {
      id: 10244,
      name: "RAVATHERM SW - Rockwool",
      images: ["/uploads/diilevhasi-k.jpg"],
      details: [],
      children: [10247, 10248, 10249, 10250, 10251],
    },
    {
      id: 10247,
      name: "Terrace Roof Board",
      images: ["/uploads/terascatilevhasi-k.jpg"],
      details: [
        "PRODUCT DESCRIPTION",
        "It is used for heat, sound, and moisture insulation on terrace roofs.",
        "It is an uncoated rockwool plate.",
        "It is offered to the market in shrink packages.",
        "Usage areas",
        "It is used on all wooden and metal roofs.",
        "It is used in all kinds of terraces.",
        "APPLICATION",
        "It is applied on the vapor barrier for thermal insulation purposes.",
      ],
      children: [],
    },
    {
      id: 10248,
      name: "Floating Floor Board",
      images: ["/uploads/4_7.jpg"],
      details: [
        "PRODUCT DESCRIPTION",
        "It is an uncoated rockwool plate.",
        "Available in shrink packs.",
        "Usage areas",
        "Under screed between two layers of concrete.",
        "On floors over open passages.",
        "Under vibration source pedestals.",
        "It is used in the flooring of buildings.",
        "APPLICATION",
        "After the floor to be applied is insulated against moisture, the boards are laid freely.",
        "After the screed is poured, the application is completed with the desired floor covering.",
      ],
      children: [],
    },
    {
      id: 10249,
      name: "Partition Board",
      images: ["/uploads/3_8.jpg"],
      details: [
        "PRODUCT DESCRIPTION",
        "It is an uncoated rockwool plate.",
        "It is marketed in shrink packages.",
        "Usage areas",
        "In elevators and stairwells.",
        "In partition walls.",
        "Used on adjacent walls.",
        "APPLICATION",
        "It provides heat, sound, and fire insulation by being placed between load-bearing structures and partition walls.",
      ],
      children: [],
    },
    {
      id: 10250,
      name: "Exterior Thermal Insulation Board",
      images: ["/uploads/diilevhasi-k.jpg"],
      details: [
        "PRODUCT DESCRIPTION",
        "It is a rockwool plate covered with aluminum foil or glass fiber on one side.",
        "It is offered to the market in shrink packages.",
        "Usage areas",
        "It provides heat, sound, and fire insulation on granite, aluminum, marble, or glass facade cladding.",
        "It provides fire insulation on ventilation surfaces.",
        "APPLICATION",
        "It is applied between the carrier profiles on the facade surfaces thanks to the anchoring equipment.",
        "It is mounted on the facade wall by means of pins.",
      ],
      children: [],
    },
    {
      id: 10251,
      name: "Exterior Thermal Insulation System Rockwool Board",
      images: ["/uploads/2_10.jpg"],
      details: [
        "PRODUCT DESCRIPTION",
        "By interlacing the fibers in the rock wool board to form a complex fiber structure, resistance to high pressure is created at lower densities.",
        "The braided fiber structure makes the sheathing application on the rock wool surface more durable.",
        "In this way, the rate of carrying the physical load on the structure is increased.",
        "Usage areas",
        "It is used under plaster on exterior facades for heat and sound insulation and fire safety.",
        "APPLICATION",
        "On smooth surfaces, the boards are adhered using cement-based adhesive mortar.",
      ],
      children: [],
    },
    {
      id: 10245,
      name: "Water isolation",
      images: ["/uploads/1_3.jpg"],
      details: [],
      children: [10252, 10253, 10254, 10255, 10256, 10257, 10258, 10259, 10260],
    },
    {
      id: 10252,
      name: "GARDENSTOPER",
      images: ["/uploads/9_3.jpg"],
      details: [
        "GardenStopper Series, which can be modified with APP or SBS, are specially designed bituminous waterproofing membranes that provide plant resistance in gardens and terraces with its special additive formulation that prevents plant root growth.",
        "Usage areas",
        "Garden and Terrace waterproofing.",
        "Advantages",
        "It provides plant resistance with its formulation with special additives preventing plant root growth for comfortable garden terraces.",
        "Features",
        "Unit: Garden +PP3, Garden +PP4, Garden EP3, Garden EP4.",
        "Carrier: Polyester for all variants.",
        "Thickness: 3 mm (Garden +PP3, Garden EP3), 4 mm (Garden +PP4, Garden EP4).",
        "Bitumen Structure: APP (Garden +PP3, Garden +PP4), SBS (Garden EP3, Garden EP4).",
        "Temperature Resistance: 120°C (Garden +PP3, Garden +PP4), 100°C (Garden EP3, Garden EP4).",
        "Cold Bending: -10°C (Garden +PP3, Garden +PP4), -20°C (Garden EP3, Garden EP4).",
        "Tensile Strength (Length/Width): 800/600 N/5cm for all variants.",
        "Elongation at Break (Length/Width): 35%/35% for all variants.",
        "Premium Coating: PE for all variants.",
        "Low Level Coating: PE for all variants.",
      ],
      children: [],
    },
    {
      id: 10253,
      name: "PLASTOPER",
      images: ["/uploads/8_3.jpg"],
      details: [
        "APP (Atactic Polypropylene) added polymer bitumen covers.",
        "Usage areas",
        "Roof and Foundation Applications.",
        "Advantages",
        "It has high durability and long service life.",
        "Bitumen structure with plastic properties.",
        "High polymer additive.",
        "High thermal resistance.",
        "Cold bending strength -5°C, temperature resistance +120°C.",
        "Specifications",
        "Unit: PG2, PG3, PP3, PP4, PP40S, PP50S, PP3AL.",
        "Carrier: Fiberglass (PG2, PG3), Polyester (PP3, PP4, PP40S, PP50S, PP3AL).",
        "Thickness: 2 mm (PG2), 3 mm (PG3, PP3, PP3AL), 4 mm (PP4), 3.5 mm (PP40S), 4.5 mm (PP50S).",
        "Bitumen Structure: APP for all variants.",
        "Temperature Resistance: +120°C for all variants.",
        "Cold Bending: -5°C for all variants.",
        "Tensile Strength (Length/Width): 300/200 N/5cm (PG2, PG3), 600/400 N/5cm (PP3, PP4, PP40S, PP50S, PP3AL).",
        "Elongation at Break (Length/Width): 2%/2% (PG2, PG3), 30%/30% (PP3, PP4, PP40S, PP50S, PP3AL).",
        "Premium Coating: PE (PG2, PG3, PP3, PP4), Slate (PP40S, PP50S), AL (PP3AL).",
        "Low Level Coating: PE for all variants.",
      ],
      children: [],
    },
    {
      id: 10254,
      name: "+PLASTOPER",
      images: ["/uploads/7_2.jpg"],
      details: [
        "APP (Atactic Polypropylene) added polymer bitumen covers.",
        "Usage areas",
        "All Roofing and Foundation Applications",
        "Advantages",
        "It has high durability and long service life.",
        "Bitumen structure with plastic properties",
        "High polymer additive",
        "Excellent hot-cold resistance",
        "Bending strength in cold -10°C, temperature resistance +120°C",
        "Characteristics",
        "Unit +PG2 +PG3 +PP3 +PP4 +PP40 S +P50 S +PP3AL",
        "Carrier",
        "Fiberglass Fiberglass Polyester Polyester Polyester Polyester Polyester",
        "Thickness mm",
        "2 3 3 4 3.5 4.5 3",
        "Bitumen Structure",
        "APP APP APP APP APP APP",
        "Temperature Resistance °C",
        "120 120 120 120 120 120 120",
        "Cold Bending °C",
        "-10 -10 -10 -10 -10 -10 -10",
        "Tensile Strength(Length/Width) N/5cm",
        "400/300 400/300 800/600 800/600 800/600 800/600 800/600",
        "Elongation at Break (Length/Width) %",
        "2/2 2/2 35/35 35/35 35/35 35/35 35/35",
        "Premium Coating",
        "PE PE PE PE Slate Slate AL",
        "Low Level Coating",
        "PE PE PE PE PE PE",
      ],
      children: [],
    },
    {
      id: 10255,
      name: "ELASTOPER",
      images: ["/uploads/6_3.jpg"],
      details: [
        "SBS (Styrene Butadiene Styrene) added polymer bitumen covers.",
        "Usage areas",
        "All Roofing and Foundation Applications",
        "Advantages",
        "It has high flexibility and long service life.",
        "It is supported by a high strength polyester carrier.",
        "Highly elastomeric polymer additive.",
        "High polymer additive.",
        "Excellent cold – hot resistance.",
        "Specifications",
        "Unit: EG2, EG3, EP3, EP4, EP40S, EP50S, EP3AL.",
        "Carrier",
        "Fiberglass (EG2, EG3), Polyester (EP3, EP4, EP40S, EP50S, EP3AL).",
        "Thickness mm",
        "2 (EG2), 3 (EG3, EP3, EP3AL), 4 (EP4), 3.5 (EP40S), 4.5 (EP50S).",
        "Bitumen Structure",
        "SBS for all variants.",
        "Temperature Resistance °C",
        "100 for all variants.",
        "Cold Bending °C",
        "-20 for all variants.",
        "Tensile Strength(Length/Width) N/5cm",
        "400/300 (EG2, EG3), 800/600 (EP3, EP4, EP40S, EP50S, EP3AL).",
        "Elongation at Break (Length/Width) %",
        "2/2 (EG2, EG3), 35/35 (EP3, EP4, EP40S, EP50S, EP3AL).",
        "Premium Coating",
        "PE (EG2, EG3, EP3, EP4), Slate (EP40S, EP50S), AL (EP3AL).",
        "Low Level Coating",
        "PE for all variants.",
      ],
      children: [],
    },
    {
      id: 10256,
      name: "JOINTSTOPER",
      images: ["/uploads/5_3.jpg"],
      details: [
        "RAVAPROOF jointSTOPER is an elastomeric bitumen based, polyester felt reinforced, 20 cm flexible aluminum foil in the middle, polyethylene film coated on the lower surface, and can be applied on all horizontal and vertical dilatation lines.",
        "PROPERTIES",
        "It is based on high SBS added elastomeric bitumen.",
        "It is covered with 20cm wide flexible aluminum foil in the center of the upper surface and is protected by a siliconized PP film.",
        "It is 50 cm wide and specially designed for dilatations.",
        "The bottom surface is covered with PE film.",
        "It is supported by a high strength polyester carrier.",
        "ADVANTAGES",
        "The elongation coefficient at break is 40% and it is designed to be used in all areas such as floor, wall, underground etc.",
        "Thanks to its ultra-flexible formulation, it provides excellent adhesion.",
        "With its 50cm width, it can be used even in very wide openings.",
        "While the flexible aluminum foil in the middle serves as a guide to center the line in the assembly, the damage of the water that will accumulate in the omega to the surface is minimized due to the assembly method.",
      ],
      children: [],
    },
    {
      id: 10257,
      name: "STICKYSTOPER XL",
      images: ["/uploads/4_4.jpg"],
      details: [
        "A safe, self-adhesive waterproofing membrane with Special Cross Laminated Film, designed to match traditional waterproofing applications.",
        "Peel off easily, with smooth paste design",
        "Excellent tear and impact resistance with one coat",
        "High adhesion performance",
        "Fast, clean and easy application in one go",
        "Saving time, cost and labor",
        "Extra light rolls",
        "High resistance to chemicals",
        "Thanks to its ultra-flexible, self-adhesive special formulation, it provides perfect and continuous adhesion to the application surface.",
        "Thanks to the multi-layered, high-density Cross Laminated HDPE Film on the upper surface, it shows high resistance to tearing and impacts.",
        "PROPERTIES",
        "Bitumen structure: A self-adhesive, long-lasting elastomeric membrane designed with a high SBS content and a special adhesive effect chemical additive.",
        "Carrier: Nonwoven polyester felt or fiberglass.",
        "Top Surface: Cross Laminated HDPE film.",
        "Bottom Surface: Peelable siliconized PP film.",
        "ADVANTAGES",
        "It saves time with fast and easy application. While 1 day drying time is expected for each layer of spread insulation materials, stickySTOPER XL provides instant adhesion and saves a lot of time.",
        "It is self-adhesive, the film is peeled off and adhered to the surface.",
        "It provides easy and safe application with extra light rollers.",
        "It can be produced with polyester or fiberglass carrier depending on the need.",
        "Thanks to the Cross Laminated HDPE film, it provides excellent resistance against impacts and tears. In case of puncture and tear on the membrane surface due to any reason, since the membrane provides full adhesion to the surface, it is out of question for water to travel under the membrane.",
        "Thanks to the special film on the upper surface, it provides high resistance against chlorine, sulfate, alkali and acids. It is resistant to UV rays.",
        "Siliconized PP film on the bottom surface can be easily peeled off and cold bonded.",
        "It saves time, labor and cost as it does not require torch application.",
      ],
      children: [],
    },
    {
      id: 10258,
      name: "STOPERPROOF V3MM",
      images: ["/uploads/3_4.jpg"],
      details: [
        "STOPERproof V 3MM is designed for single layer proof waterproofing applications in vertical single face formwork.",
        "One floor",
        "Easily peel and stick design",
        "Superior tear and impact resistance",
        "Easy and fast application in one go",
        "Saving time, cost and labor",
        "Thanks to its ultra-flexible, elastomeric special formulation, it provides excellent and continuous adhesion to freshly poured concrete.",
        "With its composite polyester felt, it shows superior resistance against penetrating elements and impacts.",
        "STOPERproof V3MM facilitates waterproofing of vertical single-face formwork.",
        "PROPERTIES",
        "Bitumen structure: A high performance elastomeric membrane designed with a special formulation of bitumen modified with a high percentage of SBS and specific thermoplastic polymers.",
        "Carrier: Composite polyester carrier",
        "Bottom Surface: Siliconized PP film",
        "Overlay: 5cm self-adhesive + 5cm PE film",
        "ADVANTAGES",
        "Thanks to the hydration heat generated during the curing of the freshly poured concrete, its special formulation provides continuous, homogeneous and perfect adhesion to the curtain concrete.",
        "It prevents air and water passages.",
        "Any possible minor leak is blocked at the exact point so it is easy to fix by injection.",
        "It shows superior resistance against tearing and impacts.",
        "It shows superior resistance against penetrating elements in the construction site.",
        "When the concrete starts to cure, the fine sandy surface softens slightly with the heat of hydration, allowing the membrane to fuse to the concrete perfectly.",
        "The siliconized PP film on its lower surface is easily peeled off, and the OSB, whose self-adhesive surface is prepared according to the construction site details, is easily adhered to a surface such as leveling.",
        "Self-adhesive and torch-applicable overlays",
      ],
      children: [],
    },
    {
      id: 10259,
      name: "BONDSTOPER",
      images: ["/uploads/2_4.jpg"],
      details: [
        "RAVAPROOF bondSTOPER is elastomeric bitumen based, reinforced with TPU as well as extra SBS additives, increased flexibility coefficient and extended service life, nonwoven polyester reinforced, silica sand / slate with protective surface; It is a new generation waterproofing membrane, the bottom of which is covered with polyethylene film.",
        "It is designed to be used as a single floor in new terraces and terrace renovation works.",
        "Single Floor Solution in New Terrace and Terrace Renovations",
        "Fast, Continuous and Full Adhesion",
        "Perfect Sealing",
        "Savings on Labor and Application Time",
        "Long Material Life",
        "PROPERTIES",
        "A single floor is used in new terrace and terrace renovation works.",
        "It has elastomeric bitumen structure supported with TPU polymer as well as extra SBS additive.",
        "Thanks to its special formula, it melts quickly and provides instant and perfect adhesion to the overlap lines and application surface.",
        "It has high flexibility and long service life.",
        "It is supported by a high strength polyester carrier.",
        "By keeping the polyester carrier closer to the surface, the pulp thickness at the bottom has been increased.",
        "ADVANTAGES",
        "It offers a perfect solution for roof or terrace details as a single layer.",
        "It provides perfect sealing by adhering completely and continuously without the formation of air bubbles due to thermal expansion on the applied surfaces.",
        "It provides 100% locking in overlap lines with fast melting and excellent adhesion.",
        "It facilitates roof repairs by being applied directly on the old membrane layer, even with slate.",
        "It saves propane consumption, time and labor.",
      ],
      children: [],
    },
    {
      id: 10260,
      name: "MAXSTOPER",
      images: ["/uploads/1_2.jpg"],
      details: [
        "RAVAPROOF MaxSTOPER is a new generation waterproofing membrane based on elastomeric bitumen, with extra special polymer and SBS additives, increased brittleness at cold (-25°C) and extended service life, reinforced with nonwoven polyester felt, silica sand on the upper surface and polyethylene film on the lower part.",
        "One floor",
        "Perfect Sealing",
        "Extra Light Rolls",
        "1000% extensible SBS modified bitumen",
        "Savings on Labor and Application Time",
        "Long Material Life",
        "PROPERTIES",
        "It has elastomeric bitumen structure with high SBS and special polymer additives.",
        "It is ultra flexible and has a long service life.",
        "Top surface coating is silica sand. The bottom surface is covered with PE film.",
        "It has brittleness and flexibility resistance at very low temperatures (-25 C).",
        "It is supported by a high strength polyester carrier.",
        "ADVANTAGES",
        "It offers a perfect solution with a single layer in roof - terrace, foundation and curtain applications.",
        "Thanks to its special formula, it is very light compared to products of the same thickness; It provides convenience especially in vertical (wall, curtain) applications.",
        "It has a product performance guarantee of at least 20 years.",
        "Thanks to its single layer applicability feature, it provides savings in labor and propane costs with the advantage of fast application in the field.",
      ],
      children: [],
    },
    {
      id: 10246,
      name: "RAVATHERM XPS - Thermal Insulation",
      images: ["/uploads/1_2.jpg"],
      details: [],
      children: [10261, 10262, 10263, 10264],
    },
    {
      id: 10261,
      name: "RAVATHERM XPS T 300 SL",
      images: ["/uploads/4_3.jpg"],
      details: [
        "All kinds of terrace roof construction or repair",
        "Terrace roofs and floors with vehicular traffic",
        "Ground contact foundation curtain walls",
        "Cold Storage",
        "Since the thermal insulation materials used in terrace roofs and soil contact foundation walls are constantly exposed to the harmful effects of moisture, soil pressure and ground water, the products to be used in these details must have the features suitable for these conditions.",
        "RAVATHERM XPS T 300 SL products permanently maintain their insulation properties even in the case of continuous mechanical loads in addition to the effects of humidity, soil pressure, ground water.",
        "All RAVATHERM products produced in Dilovası facilities of Dow Bina solutions are produced in accordance with EN 13164 standard and bear the CE mark.",
      ],
      children: [],
    },
    {
      id: 10262,
      name: "RAVATHERM XPS T 200 SL",
      images: ["/uploads/3_3.jpg"],
      details: [
        "For thermal insulation of floors of buildings",
        "For the insulation of the independent parts of the building used at different times",
        "In thermal insulation renovation works",
        "For the protection of structures in the livestock sector",
      ],
      children: [],
    },
    {
      id: 10263,
      name: "RAVATHERM XPS T 200 PB",
      images: ["/uploads/2_3.jpg"],
      details: [
        "Internal Insulation of Walls",
        "In some cases, external insulation of the structure may not be possible.",
        "Therefore, the necessary benefit can be obtained with the internal insulation of the walls.",
        "It provides an important advantage especially for structures that are not used very often and are not constantly heated, and these structures can be heated with minimum energy consumption.",
        "Thanks to its rough surface, it constitutes a suitable surface for cement or gypsum based plasters or for top coat coatings (gypsum board etc.).",
      ],
      children: [],
    },
    {
      id: 10264,
      name: "RAVATHERM XPS T SHAPE L",
      images: ["/uploads/1_1.jpg"],
      details: [
        "In Exterior Thermal Insulation of Buildings",
        "With external thermal insulation of the walls, the outer shell of the building will remain on the warm side, thus reducing the repair and maintenance costs and extending the life of the building.",
        "Since the external thermal insulation completely surrounds the building, it prevents heat leaks and provides comfortable living conditions with a damp and homogeneous heat distribution.",
      ],
      children: [],
    },
    {
      id: 10014,
      name: "Bonus Insulation",
      images: ["/uploads/bonus-yalitim.jpg"],
      details: [],
      children: [10265, 10266, 10267],
    },
    {
      id: 10265,
      name: "Bonus Stone Wool",
      images: ["/uploads/1_6.jpg"],
      details: [],
      children: [
        10268, 10269, 10270, 10271, 10272, 10273, 10274, 10275, 10276, 10277,
        10278, 10279,
      ],
    },
    {
      id: 10268,
      name: "BONUS Stone Wool Marine",
      images: ["/uploads/14.jpg"],
      details: [
        "BONUS Stone Wool Marine series is a stone wool insulation mattress and plate that is custom produced for ship insulation in the densities of 45, 60, 110, 125, 140, 150 kg/m³.",
        "Being A1 class, it is used for fire resistance and thermal insulation in ship applications.",
        "Moreover, thanks to its vapor permeability and absorbing the vibrations, it prolongs the life of the ship.",
        "Used in ship and marine constructions, cofferdam walls and fire sections, fire doors and interior ship insulations, furnaces, boilers and high-temperature areas such as steam chimneys.",
      ],
      children: [],
    },
    {
      id: 10269,
      name: "BONUS Stone Wool Panel",
      images: ["/uploads/122.jpg"],
      details: [
        "Preferred in panel systems applied in buildings requiring Class A1 high fire resistance.",
        "Sliced automatically using exclusive systems and pressure-resistance is ensured by vertical arrangement of fibers on the panel surface.",
        "Ensuring ease-of-application in panel production, Bonus Stone Wool Panel is resistant to the growth of mold, bacteria and unwanted organisms.",
        "Used in airplane hangars, shopping centers, training facilities, military structures, silos, gyms, indoor swimming pools, prefabricated construction sites, cold storages, house roofs and warehouses.",
      ],
      children: [],
    },
    {
      id: 10270,
      name: "BONUS Stone Wool Door",
      images: ["/uploads/11_1.jpg"],
      details: [
        "High-density insulation plate used for manufacturing fire doors.",
      ],
      children: [],
    },
    {
      id: 10271,
      name: "BONUS Stone Wool Flexible Alu",
      images: ["/uploads/10.png"],
      details: [
        "BONUS Stone Wool Flexible Alu pipe board is produced in the density of 100 kg/m3 and thickness of 25 mm with the size of 950 mm x 8400 mm.",
        "This aluminum foil-coated stone wool plate is used in thermal insulation of chimneys and high-temperature industrial pipes.",
        "Thanks to its aluminum foil coating, channeled structure and custom size (determined by analyzing the area of use), it is applicable in chimneys and high-temperature industrial pipes with any radius in 100-300 mm range.",
      ],
      children: [],
    },
    {
      id: 10272,
      name: "Bonus Stone Wool Industrial Batt",
      images: ["/uploads/8_4.jpg"],
      details: [
        "Bonus Stone Wool Industrial Batt is produced as uncoated in densities of 70 and 110 kg/m3.",
        "This stone wool plate is used in the insulation of industrial facilities.",
        "BONUS Stone Wool Industrial Batt is used for the thermal, acoustic and fire insulation of planar surfaces requiring resistance to high temperatures at industrial facilities, and absorbs the possible vibrations in processes and process elements thanks to its flexible nature.",
        "Used in extremely high temperatures and fire insulations in steel constructions, process equipments (such as boilers, electronic filters, channels, double-walled containers, drying ovens) and ready-to-use panel applications at all types of industrial facilities.",
      ],
      children: [],
    },
    {
      id: 10273,
      name: "BONUS Stone Wool Private",
      images: ["/uploads/7_3.jpg"],
      details: [
        "BONUS Stone Wool Private is produced as aluminum coated with a density of 70 kg/m3.",
        "This stone wool plate is used in air-conditioning and ventilating ducts.",
        "Thanks to its extremely low thermal conductivity coefficient, it prevents the temperature increase resulting from air-conditioning or load-bearing systems, and prevents these equipments to carry the possible fire inside or outside.",
        "Thanks to its vapor permeability, it also prevents metallic air-conditioners and equipments to be damaged due to condensation.",
        "BONUS Stone Wool Private series is used in air-conditioning and ventilating ducts.",
        "It can be produced in custom sizes as thin as 2.5 cm.",
      ],
      children: [],
    },
    {
      id: 10274,
      name: "BONUS Stone Wool Platin",
      images: ["/uploads/6_5.jpg"],
      details: [
        "BONUS Stone Wool Platin is a floating floor insulation plate used between floors and under building floorings and vibrating baseplates.",
        "BONUS Stone Wool Platin provides perfect thermal insulation and the floating feature prevents the impact sound to be spread to the entire building through load-bearing beams and columns.",
        "BONUS Stone Wool Platin, a fully fireproof material of A1 class, does not create smoke or particle even when it is exposed to open fire.",
        "Thanks to its 2 cm thin alternative, it can be used on floorings between floors, floorings on open crossings, building floorings and under vibrating baseplates.",
      ],
      children: [],
    },
    {
      id: 10275,
      name: "Bonus Premium R",
      images: ["/uploads/5_5.jpg"],
      details: [
        "Premium R and Premium R 150 series are produced as terrace roof boards; they can fulfill all types of needs regarding thermal and acoustic insulation and fire safety and can be applied on all types of roofs.",
        "Since they can also be produced thin, they can be used as two coats, if required.",
        "As they can be used on metal and wood roofs with any slope, they can also be applied on trafficable or non-trafficable terrace roofs.",
      ],
      children: [],
    },
    {
      id: 10276,
      name: "Bonus Stone Wool Premium F",
      images: ["/uploads/4_6.jpg"],
      details: [
        "Premium F, Premium F 120 and Premium F 150 are three series produced specifically for the plastered exterior systems.",
        "It is suitable for impact prone areas thanks to its water-repellent properties, high density and vertical tensile strength.",
        "Bonus Stone Wool Premium F series offers excellent heat resistance and good sound absorption for sheathing systems.",
        "As they can be used on metal and wood roofs with any slope, they can also be applied on trafficable or non-trafficable terrace roofs.",
      ],
      children: [],
    },
    {
      id: 10277,
      name: "Bonus Stone Wool Gold and Gold Plus",
      images: ["/uploads/3_7.jpg"],
      details: [
        "Bonus Stone Wool Gold and Gold Plus are produced in the densities of 50, 70 and 90 kg/m3 as black fiberglass, white fiberglass, yellow fiberglass, aluminum foil coated or uncoated.",
        "They are used for exterior curtain walls with or without air vents.",
        "GOLD and GOLD PLUS are water-repellent and prevent the progression of fire, thus they are classified as A1.",
        "These products improve living comfort by eliminating all the possible noise from outside thanks to its high sound absorption capacity.",
        "Used underneath aluminum, PVC, granite, ceramic and glass coating materials.",
      ],
      children: [],
    },
    {
      id: 10278,
      name: "Bonus Stone Wool Decibel",
      images: ["/uploads/1tas-yunu_1.jpg"],
      details: [
        "Bonus Stone Wool Desibel is produced as a composite with 110kg/m3 density with a plaster board on one side and an aluminum foil in the middle.",
        "This stone wool plate provides perfect thermal insulation, acoustic performance and fire resistance.",
        "Bonus Stone Wool Decibel removes any thermal and sound bridge by insulating concrete surfaces such as the curtain walls in addition to the surfaces created by wall elements.",
        "It can easily be used on all structures regardless of being newly built or renovated.",
        "Used for interior surfaces of exterior walls, interior partitions and adjacent walls, the walls adjacent to the stairwells and elevator shafts as well as interior coatings of wood frame structures.",
      ],
      children: [],
    },
    {
      id: 10279,
      name: "Bonus Membran Plus",
      images: ["/uploads/1_6.jpg"],
      details: [
        "3kg/sqm or 4kg/sqm waterproofing membrane made of APP-modified bitumen, with glass tissue and polyester felt carrier.",
      ],
      children: [],
    },
    {
      id: 10266,
      name: "Bonus Membran",
      images: ["/uploads/1.png"],
      details: [ "3kg/sqm or 4kg/sqm waterproofing membrane made of APP-modified bitumen, with glass tissue and polyester felt carrier." ],
      children: [10280, 10281, 10282, 10283],
    },
    {
      id: 10280,
      name: "Bonus Membran Pro",
      images: ["/uploads/3.png"],
      details: [],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Depth (mm): 3,0",
            "Girder Type: Glass Tissue",
            "Tensile Strength Height/Width (N/5 cm): 300/200",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "2",
          values: [
            "Depth (mm): 3,0",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 500/300",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "3",
          values: [
            "Depth (mm): 4,0",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 500/300",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "4",
          values: [
            "Depth (mm): 4,0",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 600/400",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "5",
          values: [
            "Depth (mm): 3,0",
            "Girder Type: Glass Tissue",
            "Tensile Strength Height/Width (N/5 cm): 300/200",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "6",
          values: [
            "Depth (mm): 3,0",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 500/300",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "7",
          values: [
            "Depth (mm): 4,0",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 500/300",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "8",
          values: [
            "Depth (mm): 3,0",
            "Girder Type: Glass Tissue",
            "Tensile Strength Height/Width (N/5 cm): 300/200",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "9",
          values: [
            "Depth (mm): 3,0",
            "Girder Type: Polyester",
            "Tensile Strength Height/Width (N/5 cm): 500/300",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "10",
          values: [
            "Depth (mm): 4,0",
            "Girder Type: Polyester",
            "Tensile Strength Height/Width (N/5 cm): 500/300",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        }
      ]
    },
    {
      id: 10281,
      name: "Bonus Membran Premium",
      images: ["/uploads/saaa.png"],
      details: [
        "It is an elastomeric type waterproofing membrane produced from modified bitumen reinforced with SBS (Styrene butadiene styrene).",
        "Thanks to a special mix, it can remain elastic even at very low temperatures and can maintain the advantages of bending values throughout its life.",
        "It provides the ideal insulation for the highly elastic, vibrating and expanding roofs by its structure."
    ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Depth (mm): 2,0",
            "Girder Type: Glass Tissue",
            "Tensile Strength Height/Width (N/5 cm): 400/300",
            "Roll Dimensions Width x Height mm: 1 x 15",
          ],
        },
        {
          key: "2",
          values: [
            "Depth (mm): 3,0",
            "Girder Type: Glass Tissue",
            "Tensile Strength Height/Width (N/5 cm): 400/300",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "3",
          values: [
            "Depth (mm): 3,0",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 800/600",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "4",
          values: [
            "Depth (mm): 4,0",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 800/600",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "5",
          values: [
            "Depth (mm): 3,5",
            "Girder Type: Glass Tissue",
            "Tensile Strength Height/Width (N/5 cm): 400/300",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "6",
          values: [
            "Depth (mm): 3,5",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 800/600",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        }
      ]
    },
    {
      id: 10282,
      name: "Bonus Membran Platin",
      images: ["/uploads/1.png"],
      details: [],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Depth (mm): 2,0",
            "Girder Type: Glass Tissue",
            "Tensile Strength Height/Width (N/5 cm): 400/300",
            "Roll Dimensions Width x Height mm: 1 x 15",
          ],
        },
        {
          key: "2",
          values: [
            "Depth (mm): 3,0",
            "Girder Type: Glass Tissue",
            "Tensile Strength Height/Width (N/5 cm): 400/300",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "3",
          values: [
            "Depth (mm): 3,0",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 800/600",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "4",
          values: [
            "Depth (mm): 4,0",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 800/600",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "5",
          values: [
            "Depth (mm): 3,5",
            "Girder Type: Glass Tissue",
            "Tensile Strength Height/Width (N/5 cm): 400/300",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "6",
          values: [
            "Depth (mm): 3,5",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 800/600",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "7",
          values: [
            "Depth (mm): 3,0",
            "Girder Type: Glass Tissue",
            "Tensile Strength Height/Width (N/5 cm): 400/300",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "8",
          values: [
            "Depth (mm): 3,0",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 800/600",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "9",
          values: [
            "Depth (mm): 3,0",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 800/600",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "10",
          values: [
            "Depth (mm): 4,0",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 800/600",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "11",
          values: [
            "Depth (mm): 4,0",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 1000/800",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "12",
          values: [
            "Depth (mm): 4,0",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 1000/800",
            "Roll Dimensions Width x Height mm: 1 x 10",
          ],
        },
      ],
    },
    {
      id: 10283,
      name: "Bonus Membran Plus",
      images: ["/uploads/11.png"],
      details: [ "It is a high coating insulation material made of protection concrete on foundation and deep foundation, reinforced concrete with full hydration and full hydration." ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Depth (mm): 2",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 800/600",
            "Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "2",
          values: [
            "Depth (mm): 3",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 1000/800",
            "Width x Height mm: 1 x 10",
          ],
        },
        {
          key: "3",
          values: [
            "Depth (mm): 3",
            "Girder Type: Polyester Felt",
            "Tensile Strength Height/Width (N/5 cm): 800/600",
            "Width x Height mm: 1 x 8",
          ],
        },
      ],
    },
    {
      id: 10267,
      name: "Bonus XPS",
      images: ["/uploads/xps-gold.jpg"],
      details: [],
      children: [10284, 10285, 10286, 10287, 10288, 10289, 10290, 10291, 10292],
    },
    {
      id: 10284,
      name: "Bonus XPS Klinker",
      images: ["/uploads/xps-klinker.jpg"],
      details: [],
      children: [],
    },
    {
      id: 10285,
      name: "Bonus XPS Panel",
      images: ["/uploads/xps-private.jpg"],
      details: [ "Bonus XPS Panel is used in sandwich roof and wall panels, refrigerated trucks, cold air storages, emergency huts, glass-free sections of profile systems and thermally insulated sandwich partition walls.", "Bonus XPS Panel is produced as 18-25mm thick with 600-750mm width and maximum 1050-3050mm length.", "Product Type: BONUS XPS PANEL", "Surface Quality: Rough, fine channeled, no-overlap", "Width x Height: (600-50) - (1050 - 3050)", "Depth: 18 - 25mm", "Compression Strength: 100 - 150 kPa", "Thermal Conductivity Coefficient λD (W/m.K): 0.032 (18, 19mm), 0.033 (20-25mm)", "Thermal Resistance RD (m2.K/W): 0.56 (18mm), 0.59 (19mm), 0.60 (20mm), 0.63 (21mm), 0.65 (22mm), 0.70 (23mm), 0.75 (25mm)", "Normal Tensile Strength (kPa): 400" ],
      children: [],
    },
    {
      id: 10286,
      name: "Bonus XPS Panel",
      images: ["/uploads/xps-panel.jpg"],
      details: [ "Bonus XPS Panel is used in sandwich roof and wall panels, refrigerated trucks, cold air storages, emergency huts, glass-free sections of profile systems and thermally insulated sandwich partition walls.", "Bonus XPS Panel is produced as 18-25mm thick with 600-750mm width and maximum 1050-3050mm length.", "Product Type: BONUS XPS PANEL", "Surface Quality: Rough, fine channeled, no-overlap", "Width x Height: (600-50) - (1050 - 3050)", "Depth: 18 - 25mm", "Compression Strength: 100 - 150 kPa", "Thermal Conductivity Coefficient λD (W/m.K): 0.032 (18, 19mm), 0.033 (20-25mm)", "Thermal Resistance RD (m2.K/W): 0.56 (18mm), 0.59 (19mm), 0.60 (20mm), 0.63 (21mm), 0.65 (22mm), 0.70 (23mm), 0.75 (25mm)", "Normal Tensile Strength (kPa): 400" ],
      children: [],
    },
    {
      id: 10287,
      name: "Bonus XPS Premium",
      images: ["/uploads/xps-premium.jpg"],
      details: [
        "Bonus XPS Premium, thermal insulation boards are used in internal insulation applications, and produced in the size of 600mm x 2650mm with thickness options ranging from 30mm to 120mm.",
        "Product Type: BONUS XPS PREMIUM",
        "Surface Quality: Rough, channeled, overlap",
        "Width x Height: 600 x 2650",
        "Depth: 30 - 120mm",
        "Compression Strength: 200 - 300 kPa",
        "Thermal Conductivity Coefficient λD (W/m.K): 0.033 (30-50mm), 0.036 (60-120mm)",
        "Thermal Resistance RD (m2.K/W): 0.90 (30mm), 1.20 (40mm), 1.50 (50mm), 1.80 (60mm), 1.95 (70mm), 2.20 (80mm), 2.50 (90mm), 2.80 (100mm), 3.30 (120mm)",
        "Normal Tensile Strength (kPa): 400"
    ],
      children: [],
    },
    {
      id: 10288,
      name: "Bonus XPS Pro Roof",
      images: ["/uploads/xps-pro-cati.jpg"],
      details: [
        "Bonus XPS Pro Roof is a thermal insulation board that used on roofs and basic curtain walls for insulation purposes.",
        "It is produced with a rough surface, channels, overlapping edges between 30mm-50mm thickness and in the size of 585mm x 2650mm.",
        "Product Type: BONUS XPS PRO-ÇATI",
        "Surface Quality: Rough, channeled, overlap",
        "Width x Height: 585 x 1265",
        "Depth: 30 - 50mm",
        "Compression Strength: 150 kPa",
        "Thermal Conductivity Coefficient λD (W/m.K): 0.036",
        "Thermal Resistance RD (m2.K/W): 0.80 (30mm), 1.10 (40mm), 1.35 (50mm)",
        "Normal Tensile Strength (kPa): 150"
    ],
      children: [],
    },
    {
      id: 10289,
      name: "Bonus XPS Plus",
      images: ["/uploads/xps-plus.jpg"],
      details: [ "Bonus XPS Plus thermal insulation boards are fixed by being doweled later to the walls, columns and beams of the building.", "The rough surface and its channels improve adhesion of the plaster, and ensure optimum application.", "Bonus XPS Plus thermal insulation boards are manufactured in the size of 600mm x 1200mm with thicknesses ranging from 20mm to 150mm.", "**20mm option is produced in the size of 600mm x 1250mm.", "Product Type: BONUS XPS PLUS", "Surface Quality: Rough, channeled, overlap", "Width x Height: 600 x 1200", "Depth: 20 - 150mm", "Compression Strength: 100 - 300 kPa", "Thermal Conductivity Coefficient λD (W/m.K): 0.033 (20mm), 0.033 (30mm), 0.034 (40mm), 0.034 (50mm), 0.034 (60mm), 0.036 (70mm), 0.036 (80mm), 0.036 (90mm), 0.036 (100mm), 0.036 (120mm), 0.036 (150mm)", "Thermal Resistance RD (m2.K/W): 0.60 (20mm), 0.90 (30mm), 1.20 (40mm), 1.50 (50mm), 1.80 (60mm), 1.95 (70mm), 2.20 (80mm), 2.50 (90mm), 2.80 (100mm), 3.30 (120mm), 4.20 (150mm)", "Compression Strength (kPa): 400" ],
      children: [],
    },
    {
      id: 10290,
      name: "Bonus XPS Pro-E",
      images: ["/uploads/xps-pro-e.jpg"],
      details: [
        "Bonus XPS Pro-E thermal insulation boards are fixed by being doweled later to the walls, columns and beams of the building.",
        "The embossing surface and its grooves improve adhesion of the plaster, and provide optimum application.",
        "Bonus XPS Pro-E thermal insulation boards are manufactured in the size of 585mm x 1185mm with thicknesses ranging from 30mm to 70mm.",
        "Product Type: BONUS XPS PRO-E",
        "Surface Quality: Embossing, channeled, overlap",
        "Width x Height: 585 x 1185",
        "Depth: 30 - 70mm",
        "Compression Strength: 150 kPa",
        "Thermal Conductivity Coefficient λD (W/m.K): 0.036 (all thicknesses)",
        "Thermal Resistance RD (m2.K/W): 0.80 (30mm), 1.10 (40mm), 1.35 (50mm), 1.65 (60mm), 1.95 (70mm)",
        "Normal Tensile Strength (kPa): 200"
    ],
      children: [],
    },
    {
      id: 10291,
      name: "Bonus XPS - Platin",
      images: ["/uploads/xps-platin.jpg"],
      details: [
        "Bonus XPS Platin thermal insulation board can be applied on beams without using any roof board on garden-type smooth roofs and hipped roofs as surfaces that one can walk on since they are not crushed.",
        "Bonus XPS Platin thermal insulation boards with high compression strength are produced with smooth surface, no-overlap edges in the size of 600mm x 1200mm with thickness options ranging from 30mm to 150mm.",
        "Product Type: BONUS XPS PLATIN",
        "Surface Quality: Smooth, overlap",
        "Width x Height: 600 x 1200",
        "Depth: 30 - 150mm",
        "Compression Strength: 200 - 700 kPa",
        "Thermal Conductivity Coefficient λD (W/m.K): 0.028 (30mm), 0.032 (40mm, 50mm), 0.033 (60mm), 0.035 (70mm, 80mm, 90mm), 0.036 (100mm, 120mm, 150mm)",
        "Thermal Resistance RD (m2.K/W): 1.10 (30mm), 1.25 (40mm), 1.60 (50mm), 1.80 (60mm), 2.00 (70mm), 2.30 (80mm), 2.60 (90mm), 2.80 (100mm), 3.30 (120mm), 4.20 (150mm)",
        "Compression Strength (kPa): 200 (30mm), 300 (40mm), 200 (50mm), 300 (60mm), 200 (70mm), 300 (80mm), 500 (90mm), 700 (100mm), 200 (120mm), 300 (150mm)"
    ],
      children: [],
    },
    {
      id: 10292,
      name: "Bonus XPS Gold",
      images: ["/uploads/xps-gold.jpg"],
      details: [
        "Bonus XPS Gold thermal insulation board is used in external surface coating and under siding.",
        "The edges of Bonus XPS Gold thermal insulation board applied by being compressed between plastic, wood, or steel frames don't overlap, are smooth, and are produced in thicknesses ranging from 10mm to 150mm and in the size of 600mm x 1200mm.",
        "Product Type: BONUS XPS GOLD",
        "Surface Quality: Smooth, no-overlap",
        "Width x Height: 600 x 1200",
        "Depth: 10 - 150mm",
        "Compression Strength: 100 - 300 kPa",
        "Thermal Conductivity Coefficient λD (W/m.K): 0.028 (10mm, 20mm, 30mm), 0.032 (40mm, 50mm), 0.033 (60mm), 0.035 (70mm, 80mm, 90mm), 0.036 (100mm, 120mm, 150mm)",
        "Thermal Resistance RD (m2.K/W): 0.35 (10mm), 0.70 (20mm), 1.10 (30mm), 1.25 (40mm), 1.60 (50mm), 1.80 (60mm), 2.00 (70mm), 2.30 (80mm), 2.60 (90mm), 2.80 (100mm), 3.30 (120mm), 4.20 (150mm)",
        "Compression Strength (kPa): 100 (10mm), 250 (20mm), 300 (30mm, 40mm, 50mm, 60mm, 70mm, 80mm, 90mm, 100mm, 120mm, 150mm)"
    ],
      children: [],
    },
    {
      id: 10015,
      name: "Baumit",
      images: ["/uploads/baumit.jpg"],
      details: [],
      children: [10293, 10294, 10295, 10296],
    },
    {
      id: 10293,
      name: "Interior Plasters",
      images: ["/uploads/11.jpeg"],
      details: [],
      children: [10297, 10298, 10299, 10300, 10301],
    },
    {
      id: 10297,
      name: "Baumit Spritz",
      images: ["/uploads/66.jpg"],
      details: [
        "It is a cement-based surface preparation (pre-spreading) product used to increase adherence and balance surface absorbency before plaster applications.",
        "It can be used in all buildings, interior and exterior (including wet areas).",
        "It is suitable for brick, aerated concrete, concrete, exposed concrete, pumice walls and ceilings.",
        "It can be applied by hand, please get information from Baumit for machine application details.",
        "High holding power",
        "High suction balancing ability",
        "TSE Certified"
      ],
      children: [],
    },
    {
      id: 10298,
      name: "Baumit MVR Uni",
      images: ["/uploads/55.jpg"],
      details: [
        "Single-layer lime/cement plaster for all exterior and all interior spaces including non-commercial kitchens and bathrooms, for manual and machine application.",
        "(Clarify mechanical processing with laboratory)",
        "White lime/cement plaster",
        "For exterior and interior",
        "For manual and machine application"
      ],
      children: [],
    },
    {
      id: 10299,
      name: "Baumit Manu 4",
      images: ["/uploads/44.jpg"],
      details: [
        "It is a factory-mixed ready-made rough plaster that is cement-based, has high breathability and water repellency, can be filled very well with its thick texture, and can be applied by hand.",
        "It can be used in all structures, interior and exterior.",
        "It is suitable for brick, aerated concrete, concrete, exposed concrete, pumice walls and ceilings.",
        "Excellent filling ability",
        "Pallet storage advantage",
        "Ease of preparation and use in small quantities"
      ]
      ,
      children: [],
    },
    {
      id: 10300,
      name: "Baumit GlemaBrillant",
      images: ["/uploads/22.jpeg"],
      details: [
        "It is a white cement-based, factory-mixed satin putty with high breathability and water repellency, very fine texture, that can be applied by hand.",
        "It can be used in all buildings and interiors.",
        "It is especially applied on concrete, rough plaster, fine plaster and mineral-based surfaces.",
        "It is used to prepare the surface for paint, to obtain a smooth surface.",
        "Smooth surface",
        "Higher adherence and durability than plaster",
        "Suitable for wet areas"
      ]
      ,
      children: [],
    },
    {
      id: 10301,
      name: "Baumit BetonKontakt",
      images: ["/uploads/11_1.jpeg"],
      details: [
        "It is an organic-based, ready-to-use primer product used to increase adherence and balance surface absorbency before gypsum and cement-based plaster applications.",
        "It can be applied with a roller.",
        "It can be used in all buildings, interiors (except wet areas).",
        "It is used to prepare shiny surfaces such as concrete and exposed concrete for gypsum plaster applications.",
        "Quick and easy application",
        "high adherence",
        "Surface preparation before gypsum plaster on glossy surfaces"
      ]
      ,
      children: [],
    },
    {
      id: 10294,
      name: "Interior Wall Healthy Living",
      images: ["/uploads/1.jpeg"],
      details: [],
      children: [10302, 10303, 10304, 10305, 10306],
    },
    {
      id: 10302,
      name: "Baumit KlimaWhite",
      images: ["/uploads/5_2.jpeg"],
      details: [
        "It is a factory mix ready-made plaster with high breathability, can be applied by hand or machine, and has moisture balancing ability.",
        "Regulates indoor air and is air permeable",
        "Natural White",
        "Harmful and anti-mildew"
      ],
      children: [],
    },
    {
      id: 10305,
      name: "Baumit KlimaFinish",
      images: ["/uploads/2_2.jpeg"],
      details: [
        "Lime and cement based plaster is a pasty natural white lime based plaster for interiors for obtaining high quality smooth surfaces on floors, concrete, gas concrete and gypsum boards.",
        "Ready, high quality and smooth surface",
        "Contains no harmful substances and prevents mold",
        "Improves room climate"
      ]
      ,
      children: [],
    },
    {
      id: 10306,
      name: "Baumit KlimaDekor",
      images: ["/uploads/1_1.jpeg"],
      details: [
        "Ready-to-apply pasty and lime-based decorative plaster for interiors.",
        "It is open to diffusion at a high rate.",
        "It is emission-free and solvent-free, improves the room climate.",
        "It is a factory-mixed ready-made decorative plaster with high breathability and moisture balancing ability.",
        "Grain Dimensions: 1 mm/1.5 mm/2 mm",
        "High breathing ability",
        "Prevents mold growth",
        "Regulates indoor air"
      ],
      children: [],
    },
    {
      id: 10295,
      name: "Exterior Plasters",
      images: ["/uploads/im0034358.jpeg"],
      details: [],
      children: [10307, 10308, 10309, 10310, 10311, 10312, 10313],
    },
    {
      id: 10307,
      name: "Baumit Spritz",
      images: ["/uploads/7_1.jpeg"],
      details: [
        "It is a cement-based surface preparation (pre-spreading) product used to increase adherence and balance surface absorbency before plaster applications.",
        "It can be used in all buildings, interior and exterior (including wet areas).",
        "It is suitable for brick, aerated concrete, concrete, exposed concrete, pumice walls and ceilings.",
        "It can be applied by hand, please get information from Baumit for machine application details.",
        "High holding power",
        "High suction balancing ability",
        "TSE Certified"
      ]
      ,
      children: [],
    },
    {
      id: 10309,
      name: "Baumit MVR Uni",
      images: ["/uploads/5_1.jpeg"],
      details: [
        "Single-layer lime/cement plaster for all exterior and all interior spaces including non-commercial kitchens and bathrooms, for manual and machine application.",
        "(Clarify mechanical processing with laboratory)",
        "White lime/cement plaster",
        "For exterior and interior",
        "For manual and machine application"
      ],
      children: [],
    },
    {
      id: 10310,
      name: "Baumit MPA 35 L",
      images: ["/uploads/4_1.jpeg"],
      details: [
        "Lime cement-based plaster with light aggregate, for mechanical and manual application, exterior and interior.",
        "Especially for highly insulating wall formers.",
        "Grit size 2 mm.",
        "Yielding",
        "Machine application",
        "For highly insulating masonry"
      ]
      ,
      children: [],
    },
    {
      id: 10311,
      name: "Baumit MPA 35",
      images: ["/uploads/3_1.jpeg"],
      details: [
        "The traditional lime/cement machine plaster.",
        "For outside and inside.",
        "Grated or roughly stripped as under plaster.",
        "Grit size 2 mm.",
        "Solid",
        "Machine application",
        "For normal masonry"
      ]
      ,
      children: [],
    },
    {
      id: 10312,
      name: "Baumit Manu 4",
      images: ["/uploads/2_1.jpeg"],
      details: [
        "It is a factory-mixed ready-made rough plaster that is cement-based, has high breathability and water repellency, can be filled very well with its thick texture, and can be applied by hand.",
        "It can be used in all structures, interior and exterior.",
        "It is suitable for brick, aerated concrete, concrete, exposed concrete, pumice walls and ceilings.",
        "Excellent filling ability",
        "Pallet storage advantage",
        "Ease of preparation and use in small quantities"
      ]
      ,
      children: [],
    },
    {
      id: 10313,
      name: "Baumit Manu 2",
      images: ["/uploads/im0034358.jpeg"],
      details: [
        "It is a cement-based, factory-mixed ready-made plaster with high breathability and water repellency, which can be filled with its coarse-grained texture and can be applied by hand.",
        "It can be used in all structures, interior and exterior.",
        "It is suitable for brick, aerated concrete, concrete, exposed concrete, pumice walls and ceilings.",
        "Higher performance compared to construction site mix",
        "Minimum product loss thanks to its packaging",
        "Same quality in every bag"
      ]
      ,
      children: [],
    },
    {
      id: 10296,
      name: "Exterior Thermal Insulation Systems",
      images: ["/uploads/1_1.jpeg"],
      details: [],
      children: [
        10314, 10316, 10317, 10318, 10319, 10320, 10321, 10322, 10323,
        10324, 10325, 10326, 10327, 10328, 10329, 10330, 10331,
      ],
    },
    {
      id: 10314,
      name: "Baumit StarTrack red",
      images: ["/uploads/18.jpeg"],
      details: [
        "It is used during the bonding phase of thermal insulation applications on existing old plaster or brick, without the need for additional anchoring.",
        "Does not create a thermal bridge",
        "No peg traces",
        "Provides a secure, higher quality application"
      ]
      ,
      children: [],
    },
    {
      id: 10316,
      name: "Baumit StarTrack orange",
      images: ["/uploads/16.jpeg"],
      details: [
        "It is used during the bonding phase of thermal insulation applications on existing old plaster and gas concrete, without the need for additional anchoring.",
        "Does not create a thermal bridge",
        "No peg traces",
        "Provides a secure, higher quality application"
      ]
      ,
      children: [],
    },
    {
      id: 10317,
      name: "Baumit StarTrack blue",
      images: ["/uploads/15.jpeg"],
      details: [
        "It is used during the bonding phase of thermal insulation applications on existing old plaster and gas concrete, without the need for additional anchoring.",
        "Does not create a thermal bridge",
        "No peg traces",
        "Provides a secure, higher quality application"
      ],
      children: [],
    },
    {
      id: 10318,
      name: "Baumit StarTex",
      images: ["/uploads/14.jpeg"],
      details: [],
      children: [],
    },
    {
      id: 10319,
      name: "Baumit StarContact White",
      images: ["/uploads/13.jpeg"],
      details: [
        "Top quality adhesive and reinforcing mortar with white cement, especially for Baumit StarSystems.",
        "Complying, with a high degree of safety, to all parameters required by ETAG 004.",
        "Very flexible, extremely weather-resistant, with excellent workability, guaranteeing long lasting performance.",
        "Formulated for demanding applications, weather conditions during processing and demanding substrates.",
        "Flexible",
        "White",
        "Topcoat without primer"
      ]
      ,
      children: [],
    },
    {
      id: 10320,
      name: "Baumit StarTop",
      images: ["/uploads/12.jpeg"],
      details: [
        "Highly weather-resistant facade render with silicone binder and new functional filler.",
        "The all new Drypor effect guarantees a quick-drying facade surface after rain, fog and condensation.",
        "Fast drying surfaces",
        "Increased algae protection",
        "Perfect application"
      ],
      children: [],
    },
    {
      id: 10321,
      name: "Baumit SilikonTop",
      images: ["/uploads/11.jpeg"],
      details: [
        "A universal facade render based on reliable silicone resin.",
        "Highly permeable, extremely water-repellent, dirt resistant and universally applicable.",
        "Weather resistant",
        "Vapour permeable",
        "Water repellant"
      ],
      children: [],
    },
    {
      id: 10322,
      name: "Baumit PuraTop",
      images: ["/uploads/10.jpeg"],
      details: [
        "High quality pure acrylic facade render with very good application properties.",
        "Use when requiring more intense colors, for a rich long lasting color finish.",
        "Unlimited color range",
        "Intense color tones",
        "Cool pigments technology"
      ]
      ,
      children: [],
    },
    {
      id: 10323,
      name: "Baumit ProContact",
      images: ["/uploads/9.jpeg"],
      details: [
        "Mineralic adhesive and reinforcing mortar for Baumit ProSystem complying with ETAG 004.",
        "Flexible, weather-resistant, suitable for use under standard conditions during application.",
        "Particularly for economic efficienct solutions.",
        "Universal",
        "Long-therm approved",
        "Economic"
      ]
      ,
      children: [],
    },
    {
      id: 10324,
      name: "Baumit PremiumPrimer",
      images: ["/uploads/8.jpeg"],
      details: [
        "Ready-to-use, universally usable primer to equalize absorption and acts as bonding agent for all Baumit ETIC- and plaster systems.",
        "The added grain makes it easier to apply and structure all top coats.",
        "Can be colored - see product data sheet for detailed information.",
        "Excellent absorption equalizer",
        "Ideal bonding agent",
        "For a perfect structure"
      ]
      ,
      children: [],
    },
    {
      id: 10325,
      name: "Baumit PowerFlex",
      images: ["/uploads/7.jpeg"],
      details: [
        "Ready-to-use, cement-free, fibre-reinforced, organic base coat",
        "Specially developed for Baumit PowerSystem due to high impact resistance.",
        "Complies with all requirements of ETAG 004.",
        "Highly shockproof",
        "Cement-free",
        "Fibre-reinforced"
      ]
      ,
      children: [],
    },
    {
      id: 10326,
      name: "Baumit openTherm",
      images: ["/uploads/6.jpeg"],
      details: [
        "White, breathable facade insulating panels made from EPS (expanded polystyrene) specially designed for brickwork and similarly breathable masonry with very good thermal insulation properties.",
        "System component of Baumit open.",
        "Breathable",
        "μ value ≤ 10",
        "Leading thermal insulation system",
        "For bonding and mortaring we recommend using Baumit OpenContact adhesive mortar."
      ],
      children: [],
    },
    {
      id: 10327,
      name: "Baumit NivoFix",
      images: ["/uploads/5.jpeg"],
      details: [
        "It is a cement-based, thermal insulation board adhesive with high breathability and high adhesion strength with polymer reinforcements.",
        "The product, which is resistant to water, moisture and impacts and provides convenience in application, can also be applied by machine.",
        "It is used for bonding all heat insulation plates and XPS sub-basement plates.",
        "ETAG 004 Certified",
        "High bonding strength",
        "Applicable by machine"
      ],
      children: [],
    },
    {
      id: 10328,
      name: "Baumit NanoporTop",
      images: ["/uploads/4.jpeg"],
      details: [
        "Self-cleaning, pollution resistant, ready-to-use outdoor render.",
        "Baumit photokat technology creates max protection against contamination using power of light.",
        "Mineral based, highly vapour per",
        "Protection and enhancement of façades and façade surfaces over old and new mineral plasters and fillers, on concrete, when preserving historical monuments, during renovations and as a topcoat with Baumit External Wall Insulation Systems open, KlimaFacade, EPS, Mineral and MineralSchaum and on renovation renders.",
        "Self-cleaning",
        "Photocat effect",
        "High durability"
      ],
      children: [],
    },
    {
      id: 10329,
      name: "Baumit MineralTherm",
      images: ["/uploads/3.jpeg"],
      details: [
        "It is a mineral wool thermal insulation plate.",
        "Its water-repellent properties have been developed so that it can be used safely on exteriors.",
        "Thanks to its fibrous texture, it breathes perfectly and is suitable for acoustic arrangements.",
        "It is suitable for exterior use, provided that it is covered with a top coat.",
        "Provides heat-sound-fire insulation",
        "excellent breathing"
      ],
      children: [],
    },
    {
      id: 10330,
      name: "Baumit GranoporTop",
      images: ["/uploads/2.jpeg"],
      details: [
        "Universal, ready to use acrylic façade paint, suitable for most requirements.",
        "Water repellent.",
        "Weather resistant",
        "Vapour permeable",
        "Water repellant"
      ]
      ,
      children: [],
    },
    {
      id: 10331,
      name: "Baumit Classico Special",
      images: ["/uploads/1.jpeg"],
      details: [
        "It is a white cement based decorative (mineral) coating product, reinforced with polymers, with high water repellency and improved breathability.",
        "The product, which contains marble particles that increase strength and life, has a real 2 mm particle size, white color and perfect texture appearance, is used on exterior thermal insulation systems.",
        "unmatched whiteness",
        "High impact resistance",
        "High resistance to harsh climatic conditions"
      ],
      children: [],
    },
    {
      id: 10016,
      name: "Profiles and Accessories",
      images: ["/uploads/b36ddd-tavan-u-tr.png"],
      details: [],
      children: [10332, 10333, 10334],
    },
    {
      id: 10332,
      name: "Exterior Profiles",
      images: ["/uploads/DII-Cephe-Profilleri.png"],
      details: [],
      children: [10335, 10336, 10337],
    },
    {
      id: 10335,
      name: "CT PROFILE",
      images: ["/uploads/CT-PROFIL.png"],
      details: [
        "It is a carrying element used for connecting plates in the metal construction installed for exterior wall applications, made of hot dip galvanized steels using cold forming method.",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Thickness (mm): 0,90 mm",
            "Length (mm): 3000 mm",
            "Packaging: 6 pcs/bundle",
            "Steel Type: DX51D (complies with EN 10346)",
            "Galvanized Thickness: 	Z100, Z140, Z275",
            "Fire Resistance Rating: A1 Class",
            "Yield Strength: ≥140 N/mm2"
          ],
        },
      ]
    },
    {
      id: 10336,
      name: "J PROFILE",
      images: ["/uploads/J-PROFIL.png"],
      details: [
        "It is a carrying element used to form a horizontal support in the metal construction installed for exterior wall applications, made of hot dip galvanized steels using cold forming method.",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Thickness (mm): 1,5 - 2,00 mm",
            "Length (mm): 3000 mm",
            "Packaging: 10 pcs/bundle",
            "Steel Type: DX51D (complies with EN 10346)",
            "Galvanized Thickness: 	Z100, Z140, Z275",
            "Fire Resistance Rating: A1 Class",
            "Yield Strength: ≥140 N/mm2"
          ],
        },
      ]
    },
    {
      id: 10337,
      name: "M PROFILE",
      images: ["/uploads/DII-Cephe-Profilleri.png"],
      details: [
        "It is a carrying element used for connecting plates in the metal construction installed for exterior wall applications, made of hot dip galvanized steels using cold forming method.",
        "It is manufactured as Galvanized, Galvanization + Blue TOC coated, Galvanization + Black TOC coated.",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Thickness (mm): 0,45 - 0,50 mm",
            "Length (mm): 3000 mm",
            "Packaging: 6 pcs/bundle",
            "Steel Type: DX51D (complies with EN 10346)",
            "Galvanized Thickness: 	Z100, Z140, Z275",
            "Fire Resistance Rating: A1 Class",
            "Yield Strength: ≥140 N/mm2"
          ],
        },
      ]
    },
    {
      id: 10333,
      name: "Partition and Curtain Wall Profiles",
      images: ["/uploads/11.png"],
      details: [],
      children: [10338, 10339, 10340, 10341],
    },
    {
      id: 10338,
      name: "UA PROFILE",
      images: ["/uploads/UA-PROFIL.png"],
      details: [
        "It is a carrying element used to carry door and window frames in the metal construction installed for partition wall applications, and made of hot dip galvanized steels using cold forming method.",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Thickness (mm): 1,50 - 2,00 mm",
            "Length (mm): 3000 mm",
            "Packaging: 6 pcs/bundle",
            "Steel Type: DX51D (complies with EN 10346)",
            "Galvanized Thickness: 	Z100, Z140, Z275",
            "Fire Resistance Rating: A1 Class",
            "Yield Strength: ≥140 N/mm2"
          ],
        },
      ]
    },
    {
      id: 10339,
      name: "SPECIAL WALL C PROFILE",
      images: ["/uploads/OZEL-DUVAR-C-PROFILI.png"],
      details: [
        "It is a supplementary element used to serve as a guide to wall c profiles and allow leveling them in the metal construction installed for partition wall, clad wall, suspended ceiling and exterior wall applications, and made of hot dip galvanized steels using cold forming method.",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Thickness (mm): 1,20 - 2,00 mm",
            "Length (mm): 3000 mm",
            "Packaging: 6 pcs/bundle",
            "Steel Type: DX51D (complies with EN 10346)",
            "Galvanized Thickness: 	Z100, Z140, Z275",
            "Fire Resistance Rating: A1 Class",
            "Yield Strength: ≥140 N/mm2"
          ],
        },
      ]
    },
    {
      id: 10340,
      name: "WALL C PROFILE",
      images: ["/uploads/DUVAR-C-PROFILI.png"],
      details: [
        "It is a carrying element used in the metal construction installed for partition wall, clad wall, suspended ceiling and exterior wall applications, and made of hot dip galvanized steels using cold forming method.",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Thickness (mm): 0,45 - 0,80 mm",
            "Length (mm): 3000 mm",
            "Packaging: 12 pcs/bundle",
            "Steel Type: DX51D (complies with EN 10346)",
            "Galvanized Thickness: 	Z100, Z140, Z275",
            "Fire Resistance Rating: A1 Class",
            "Yield Strength: ≥140 N/mm2"
          ],
        },
      ]
    },
    {
      id: 10341,
      name: "WALL U PROFILE",
      images: ["/uploads/11_1.png"],
      details: [
        "It is a supplementary element used to serve as a guide to wall c profiles and allow leveling them in the metal construction installed for partition wall, clad wall, suspended ceiling and exterior wall applications, and made of hot dip galvanized steels using cold forming method.",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Thickness (mm): 0,45 - 0,80 mm",
            "Length (mm): 3000 mm",
            "Packaging: 12 pcs/bundle",
            "Steel Type: DX51D (complies with EN 10346)",
            "Galvanized Thickness: 	Z100, Z140, Z275",
            "Fire Resistance Rating: A1 Class",
            "Yield Strength: ≥140 N/mm2"
          ],
        },
      ]
    },
    {
      id: 10334,
      name: "Suspended Ceiling Profiles",
      images: ["/uploads/b36ddd-tavan-u-tr.png"],
      details: [],
      children: [10342, 10343, 10344, 10345, 10346, 10347, 10348, 10349],
    },
    {
      id: 10342,
      name: "SPECIAL U PROFILE",
      images: ["/uploads/9_5.jpg"],
      details: [
        "It is a supplementary element used in building plaster board suspended ceiling as carrier box profile and for leveling the system, and made of hot dip galvanized steels using cold forming method."
      ]
      ,
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Thickness (mm): 1,00 - 1,50 mm",
            "Length (mm): 3000 mm",
            "Packaging: 8 pcs/bundle (U20, U30, U40) 6 pcs/bundle (U50, U60)",
            "Steel Type: DX51D (complies with EN 10346)",
            "Galvanized Thickness: 	Z100, Z140, Z275",
            "Fire Resistance Rating: A1 Class",
            "Yield Strength: ≥140 N/mm2"
          ],
        },
      ]
    },
    {
      id: 10343,
      name: "CEILING SURROUNDING PROFILE",
      images: ["/uploads/8.png"],
      details: [
        "It is a supplementary element used in building plaster board suspended ceiling as carrier box profile and for leveling the system, and made of hot dip galvanized steels using cold forming method."
      ]
      ,
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Thickness (mm): 1,00 - 1,50 mm",
            "Length (mm): 3000 mm",
            "Packaging: 8 pcs/bundle (U20, U30, U40) 6 pcs/bundle (U50, U60)",
            "Steel Type: DX51D (complies with EN 10346)",
            "Galvanized Thickness: 	Z100, Z140, Z275",
            "Fire Resistance Rating: A1 Class",
            "Yield Strength: ≥140 N/mm2"
          ],
        },
      ]
    },
    {
      id: 10344,
      name: "CEILING C 47 PROFILE",
      images: ["/uploads/7_4.jpg"],
      details: [
        "It is a carrying element used in metal construction installed for building plaster board suspended ceiling, as a carrier of the system, and made of hot dip galvanized steels using cold forming method."
      ]
      ,
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Thickness (mm): 0,50 - 0,60 mm",
            "Length (mm): 3000 mm",
            "Packaging: 8 pcs/bundle (U20, U30, U40) 6 pcs/bundle (U50, U60)",
            "Steel Type: DX51D (complies with EN 10346)",
            "Galvanized Thickness: 	Z100, Z140, Z275",
            "Fire Resistance Rating: A1 Class",
            "Yield Strength: ≥140 N/mm2"
          ],
        },
      ]
    },
    {
      id: 10345,
      name: "CEILING OMEGA 45 PROFILE",
      images: ["/uploads/6.png"],
      details: [
        "It is a supplementary element used, in metal construction installed for building plaster board suspended ceiling, for fastening plaster boards, and made of hot dip galvanized steels using cold forming method."
      ]
      ,
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Thickness (mm): 0,40 - 0,50 mm",
            "Length (mm): 3000 mm",
            "Packaging: 20 pcs/bundle",
            "Steel Type: DX51D (complies with EN 10346)",
            "Galvanized Thickness: 	Z100, Z140, Z275",
            "Fire Resistance Rating: A1 Class",
            "Yield Strength: ≥140 N/mm2"
          ],
        },
      ]
    },
    {
      id: 10346,
      name: "CEILING OMEGA PROFILE",
      images: ["/uploads/4.png"],
      details: ["It is a supplementary element used, in metal construction installed for building plaster board suspended ceiling, for fastening plaster boards, and made of hot dip galvanized steels using cold forming method."],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Thickness (mm): 0,40 - 0,50 mm",
            "Length (mm): 3000 mm",
            "Packaging: 10 pcs/bundle",
            "Steel Type: DX51D (complies with EN 10346)",
            "Galvanized Thickness: 	Z100, Z140, Z275",
            "Fire Resistance Rating: A1 Class",
            "Yield Strength: ≥140 N/mm2"
          ],
        },
      ]
    },
    {
      id: 10347,
      name: "CEILING OMEGA U PROFILE",
      images: ["/uploads/3_1.png"],
      details: [
        "It is a supplementary element used in metal construction installed for building plaster board suspended ceiling, as a carrier of the system, and made of hot dip galvanized steels using cold forming method."
      ]
      ,
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Thickness (mm): 0,40 - 0,50 mm",
            "Length (mm): 3000 mm",
            "Packaging: 24 pcs/bundle",
            "Steel Type: DX51D (complies with EN 10346)",
            "Galvanized Thickness: 	Z100, Z140, Z275",
            "Fire Resistance Rating: A1 Class",
            "Yield Strength: ≥140 N/mm2"
          ],
        },
      ]
    },
    {
      id: 10348,
      name: "CEILING C POWER PROFILE",
      images: ["/uploads/2.png"],
      details: [
        "It is a supplementary element used in metal construction installed for building plaster board suspended ceiling, as a carrier of the system, and made of hot dip galvanized steels using cold forming method."
      ]      
      ,
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Thickness (mm): 0,40 - 0,50 mm",
            "Length (mm): 3000 mm",
            "Packaging: 12 pcs/bundle",
            "Steel Type: DX51D (complies with EN 10346)",
            "Galvanized Thickness: 	Z100, Z140, Z275",
            "Fire Resistance Rating: A1 Class",
            "Yield Strength: ≥140 N/mm2"
          ],
        },
      ]
    },
    {
      id: 10349,
      name: "CEILING U POWER PROFILE",
      images: ["/uploads/b36ddd-tavan-u-tr.png"],
      details: [
        "It is a supplementary element used in metal construction installed for building plaster board suspended ceiling as carrier Ceiling C profile and for leveling the system, and made of hot dip galvanized steels using cold forming method.",
      ],
      children: [],
      measurements: [
        {
          key: "1",
          values: [
            "Thickness (mm): 0,35 - 0,60 mm",
            "Length (mm): 3000 mm",
            "Packaging: 24 pcs/bundle",
            "Steel Type: DX51D (complies with EN 10346)",
            "Galvanized Thickness: 	Z100, Z140, Z275",
            "Fire Resistance Rating: A1 Class",
            "Yield Strength: ≥140 N/mm2"
          ],
        },
      ]
    },
    {
      id: 10350,
      name: "Green Coffee",
      images: ["/img/green_beans.webp"],
      details: [
        "Green coffee refers to unroasted coffee beans that have been harvested, processed, and dried but have not undergone the roasting process. These beans retain the inherent flavors and characteristics influenced by their growing region.",
      ],
      children: [],
    },
    {
      id: 10351,
      name: "Roasted Coffee",
      images: ["/img/coorg-arabica-roasted-coffee-beans.20231001174407.webp"],
      details: [
        "Roasted coffee is the transformation of green coffee beans through roasting, which brings out the characteristic flavors, aromas, and characteristics of the final cup of coffee.",
      ],
      children: [],
    },
    {
      id: 10352,
      name: "Grounded Coffee",
      images: ["/img/71FvXm+rhHL._AC_UF894,1000_QL80_.jpg"],
      details: [
        "Ground coffee refers to roasted coffee beans processed into a coarse or fine consistency suitable for brewing. It enhances flavor extraction during brewing.",
      ],
      children: [],
    },
    {
      id: 10353,
      name: "Cocoa Butter",
      images: ["/img/CO-741_Cocoa_Butter_Natural__01321.jpg"],
      details: [
        "Cocoa butter is a natural, edible fat derived from the seeds of the cocoa fruit. It is used in both culinary and cosmetic applications, known for its smooth texture and gentle fragrance.",
      ],
      children: [],
    },
    {
      id: 10354,
      name: "Cocoa Powder",
    images: ["/img/cocoa_powder.webp"],
      details: [
        "Cocoa powder is made by grinding roasted cocoa beans. It is known for its culinary versatility and antioxidant properties, often used in recipes to enhance the flavor of desserts and beverages.",
      ],
      children: [],
    },
    {
      id: 10355,
      name: "Omoa Cacao",
      images: ["/img/omoa_cacao.jpeg"],
      details: [
        "Omoa cacao is a unique and aromatic cacao profile from the Omoa region of Honduras, known for its distinct terroir and rich flavor.",
      ],
      children: [],
    },
    {
      id: 10356,
      name: "Yojoa Lake Cacao",
      images: ["/img/yoa_lake_cacao.jpg"],
      details: [
        "Yojoa Lake cacao is sourced from the tropical humid region around Yojoa Lake. It delivers a delicate balance of floral and fruity notes with a smooth finish.",
      ],
      children: [],
    },
    {
      id: 10357,
      name: "Copán Cacao",
      images: ["/img/honduras_cacao.webp"],
      details: [
        "Copán cacao comes from the region near Mayan temples in Honduras. It has a complex flavor profile, combining the richness of chocolate with subtle fruity and floral notes.",
      ],
      children: [],
    },
    {
      id: 10359,
      name: "Allspice",
      images: ["/img/allspice.jpeg"],
      details: [
        "Allspice is a versatile spice that combines flavors reminiscent of clove, cinnamon, and nutmeg. It is used to season meats, stews, desserts, and is cherished for its warmth and depth.",
      ],
      children: [],
    },
    {
      id: 10360,
      name: "Fence 1",
      images: [
        "/img/venecia1.jpg",
        "/img/venecia2.jpg",
        "/img/venecia3.jpg",
        "/img/venecia4.jpg",
        "/img/venecia5.jpg",
        "/img/venecia6.jpg",
        "/img/venecia7.jpg",
        "/img/venecia8.jpg",
        "/img/venecia9.jpg",
        "/img/venecia10.jpg",
      ],
      details: [],
      children: [],
    },
    {
      id: 10361,
      name: "Fence 2",
      images: [
        "/img/sicilia1.jpg",
        "/img/sicilia2.jpg",
        "/img/sicilia3.jpg",
        "/img/sicilia4.jpg",
        "/img/sicilia5.jpg",
        "/img/sicilia6.jpg",
        "/img/sicilia7.jpg",
        "/img/sicilia8.jpg",
        "/img/sicilia9.jpg",
      ],
      details: [],
      children: [],
    },
    {
      id: 10362,
      name: "Fence 3",
      images: [
        "/img/amalfi1.jpg",
        "/img/amalfi2.jpg",
        "/img/amalfi3.jpg",
        "/img/amalfi4.jpg",
        "/img/amalfi5.jpg",
        "/img/amalfi6.jpg",
        "/img/amalfi7.jpg",
        "/img/amalfi8.jpg",
        "/img/amalfi9.jpg",
      ],
      details: [],
      children: [],
    },
    {
      id: 10363,
      name: "FENCES",
      images: ["/img/fence.jpeg"],
      details: [
        "Aluminum fences offer a perfect blend of durability and elegance, providing a secure and stylish barrier that withstands the elements with minimal maintenance.",
        "With a range of designs and finishes, they add lasting value and curb appeal to any property.",
      ],
      children: [10360, 10361, 10362],
    },
  ],
};

export default translationsEn;
