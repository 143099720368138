export const medicalTourism = {
    title: "Medical Tourism",
    description: "Medical tourism is the practice of traveling abroad to obtain medical treatment. In the past, this usually referred to those who traveled from less-developed countries to major medical centers in highly developed countries for treatment unavailable at home.",
    servicesTitle: "Our Services",
    servicesDescription: "We provide top-notch services to our customers with a focus on quality and customer satisfaction. Our team of experts is here to help you with any need you may have. Feel free to reach out through our contact form.",
    cards: [
      {
        title: "Plastic Surgery",
        price: ["$", "4999", ""],
        options: [
          "Consultation",
          "Pre-surgery Examination",
          "Post-surgery Care",
          "Follow-up Visits",
        ],
        path: "/services/plastic-surgery",
      },
      {
        title: "Dentistry",
        price: ["$", "999", ""],
        options: [
          "Consultation",
          "Teeth Whitening",
          "Cavity Treatment",
          "Follow-up Visits",
        ],
        path: "/services/dentistry",
      },
      {
        title: "Weight Loss Surgery",
        price: ["$", "7999", ""],
        options: [
          "Consultation",
          "Pre-surgery Examination",
          "Post-surgery Care",
          "Follow-up Visits",
        ],
        path: "/services/weight-loss-surgery",
      },
    ],
  };
  