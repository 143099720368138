import React, { useContext, useState, useEffect } from "react";
import { BentoCard, BentoGrid } from "./ui/Bento";
import { LanguageContext } from "../context/LanguageProvider";
import ContactForm from "./ContactForm";
import { OrbitingCirclesDemo } from "./OrbitingCirclesDemo";
import { DotPattern } from "./ui/DotPattern";
import { cn } from "../lib/utils";
import { transportationBS } from "../data/language/services.bs";
import { transportationENG } from "../data/language/services.en";
import MapChart from "./MapChart";
import { IconCloud } from "./ui/IconCloud";
import { GridPattern } from "./ui/GridPattern";
import { ServicesDescription } from "./ServicesDescription";


function Transportation({mapBool}) {
  const { language } = useContext(LanguageContext);
  const content = language == "en" ? transportationENG : transportationBS;

  const [geoData, setGeoData] = useState(null);
  const [isMapActive, setIsMapActive] = useState(mapBool);


  useEffect(() => {
    const fetchData = async () => {
      try {
    
        const response = await fetch(
          "https://api.maptiler.com/data/53fa0bcf-b358-4660-8ecb-63cc47799708/features.json?key=tkc8TThBxt6WcapX6R2g"
        );
        const data = await response.json();
        setGeoData(data);
        setTimeout(() => {setIsMapActive(true);}, 4000);
      } catch (error) {
        console.error("Error fetching the GeoJSON data:", error);
      }
    };

    fetchData();
  }, []);
  const features = [
    {
      name: "",
      description: "",
      href: "",
      className: "lg:col-start-1 lg:col-end-3 lg:row-start-1 lg:row-end-1 row-start-2 row-end-2",
      background: <div></div>,
      children: <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 20,
        background: "black",
      }}
    >
     {isMapActive && <MapChart geoData={geoData} center={[36.455175, 40.300258]} />}
    </div>,
    },

    {
      name: content.title,
      description: <ServicesDescription content={content} />,
      href: content.imageUrl,
      cta: content.cta,
      className:
        "lg:col-start-3 lg:col-end-4 col-start-1 col-end-4 row-start-1 lg:row-start-1 lg:row-end-2", // Sets the height to a specific value
      background: <div></div>,
      children: null,
    },
    {
      name: "",
      description: "",
      href: "",
      cta: "",
      className:
        "lg:col-start-1 lg:col-end-2 col-start-1 col-end-4 row-start-3 lg:row-start-2 lg:mt-20", // Sets the column and row spans
      background: <div></div>,
      children: <ContactForm subject={content.title}  message={content.subTitle} className={"h-full"}/>,
    },

    {
      name: "",
      description: "",
      href: "",
      cta: "",
      className:
        "lg:col-start-2 lg:col-end-4 col-start-1 col-end-4 row-start-4 lg:row-start-2",
      background: <div></div>,
      children: (
        <div className="w-full h-full">
          {" "}
          <IconCloud images={[
  {
    slug: "bosnia",
    title: "Bosnia",
    url: "/countryImages/bosnia.webp"
  },
  {
    slug: "germany",
    title: "Germany",
    url: "/countryImages/germany.png"
  },
  {
    slug: "truck",
    title: "Truck",
    url: "/img/deliveryTruck.svg",
    newSizing:{
      width: 70,
      height: 70,
    }
  },
  {
    slug: "slovenia",
    title: "Slovenia",
    url: "/countryImages/slovenia.webp"
  },
  {
    slug: "croatia",
    title: "Croatia",
    url: "/countryImages/croatia.webp"
  },
  {
    slug: "italy",
    title: "Italy",
    url: "/countryImages/italy.png"
  },
  {
    slug: "truck",
    title: "Truck",
    url: "/img/deliveryTruck.svg",
    newSizing:{
      width: 70,
      height: 70,
    }
  },
  {
    slug: "bosnia",
    title: "Bosnia",
    url: "/countryImages/bosnia.webp"
  },
  {
    slug: "germany",
    title: "Germany",
    url: "/countryImages/germany.png"
  },
  {
    slug: "truck",
    title: "Truck",
    url: "/img/deliveryTruck.svg",
    newSizing:{
      width: 70,
      height: 70,
    }
  },
  {
    slug: "slovenia",
    title: "Slovenia",
    url: "/countryImages/slovenia.webp"
  },
  {
    slug: "croatia",
    title: "Croatia",
    url: "/countryImages/croatia.webp"
  },
  {
    slug: "italy",
    title: "Italy",
    url: "/countryImages/italy.png"
  },
  {
    slug: "truck",
    title: "Truck",
    url: "/img/deliveryTruck.svg",
    newSizing:{
      width: 70,
      height: 70,
    }
  },
  {
    slug: "bosnia",
    title: "Bosnia",
    url: "/countryImages/bosnia.webp"
  },
  {
    slug: "germany",
    title: "Germany",
    url: "/countryImages/germany.png"
  },
  {
    slug: "truck",
    title: "Truck",
    url: "/img/deliveryTruck.svg",
    newSizing:{
      width: 70,
      height: 70,
    }
  },
  {
    slug: "slovenia",
    title: "Slovenia",
    url: "/countryImages/slovenia.webp"
  },
  {
    slug: "croatia",
    title: "Croatia",
    url: "/countryImages/croatia.webp"
  },
  {
    slug: "italy",
    title: "Italy",
    url: "/countryImages/italy.png"
  },
  {
    slug: "truck",
    title: "Truck",
    url: "/img/deliveryTruck.svg",
    newSizing:{
      width: 70,
      height: 70,
    }
  },
  {
    slug: "bosnia",
    title: "Bosnia",
    url: "/countryImages/bosnia.webp"
  },
  {
    slug: "germany",
    title: "Germany",
    url: "/countryImages/germany.png"
  },
  {
    slug: "truck",
    title: "Truck",
    url: "/img/deliveryTruck.svg",
    newSizing:{
      width: 70,
      height: 70,
    }
  },
  {
    slug: "slovenia",
    title: "Slovenia",
    url: "/countryImages/slovenia.webp"
  },
  {
    slug: "croatia",
    title: "Croatia",
    url: "/countryImages/croatia.webp"
  },
  {
    slug: "italy",
    title: "Italy",
    url: "/countryImages/italy.png"
  },
  {
    slug: "truck",
    title: "Truck",
    url: "/img/deliveryTruck.svg",
    newSizing:{
      width: 70,
      height: 70,
    }
  },
  
  
  
]} />
        </div>
      ),
      additionalStyling: "overflow-visible mt-4 relative col-span-3 flex flex-col "
    },
  ];
  return (
    <div className="flex justify-center items-center h-full w-full px-4 sm:px-10 md:px-14 lg:px-20 py-40">
      <BentoGrid className="relative">
        {" "}
        {/* Optional: Centering the cards vertically */}
        {features.map((feature, idx) => (
          <BentoCard className="relative" key={idx} {...feature} />
        ))}
        <GridPattern
          numSquares={30}
          maxOpacity={0.1}
          duration={3}
          repeatDelay={1}
          className={cn(
            "[mask-image:radial-gradient(1200px_circle_at_center,white,transparent)]",
            "inset-x-0 inset-y-[-30%] h-[150%] skew-y-12 -z-10"
          )}
        />
      </BentoGrid>
    </div>
  );
}

export default Transportation;
