export const importAndExportBS = {
  id: 1,
  title: "UVOZ PROIZVODA",
  subTitle: `Trebate pomoć sa uvozom proizvoda...?`,
  cta: `Pošaljite nam email!`,
  descriptionP1:
    "Uvoz proizvoda je proces prenošenja robe preko granica u svrhu trgovine, uključujući nabavku, rukovanje i osiguranje sigurne isporuke uz poštivanje zakonskih i regulatornih zahtjeva.",
  descriptionP2:
    "Bomonti uvozi visokokvalitetne proizvode u Bosnu, Tursku i Iran, nudeći raznovrsan asortiman proizvoda uz 24/7 dostupnu tehničku podršku za upotrebu proizvoda.",
  descriptionP3:
    "Cijenimo naš odnos s klijentima i uvijek tražimo nove partnere kako bismo proširili našu mrežu poslovanja i usluga, stavljajući naše kupce na prvo mjesto.",

  imageUrl: "https://i.ibb.co/FV4y050/all-over-the-world.png",

  highLightedWords: {
    P1: ["Uvoz", "proizvoda", "trgovine", "nabavku,", "rukovanje", "sigurne", "isporuke", "granica", "zakonskih", "zahtjeva", "regulatornih"],
    P2: ["visokokvalitetne", "Bosnu,", "Tursku", "Iran,", "proizvoda", "tehnička", "podrška", "24/7", "primjenu"],
    P3: [ "odnos", "klijentima", "kupce", "prvo", "mjesto."],
  },
};

export const transportationBS = {
  id: 2,
  title: "MEĐUNARODNI TRANSPORT",
  subTitle: `Trebate pomoć s transportom...?`,
  cta: `Pošaljite nam email!`,
  descriptionP1:
    "Međunarodni transport robe uključuje međunarodno kretanje punih (FTL) ili parcijalnih (LTL) tereta drumskim putem, uz podršku sveobuhvatnih logističkih usluga.",
  descriptionP2:
    "Osiguravamo brzu i ekonomičnu isporuku koristeći našu široku mrežu prevoznika koja pokriva Njemačku, Italiju, Sloveniju, Hrvatsku i Bosnu.",
  descriptionP3:
    "Bomonti se ističe efikasnošću, brzinom i pouzdanošću, pružajući besprijekorna logistička rješenja prilagođena potrebama klijenata.",
  mapUrl: "https://google.com/maps",
  highLightedWords: {
    P1: ["Međunarodni", "transport", "robe", "tereta", "država", "logistike", "špedicije"],
    P2: ["brzu", "ekonomičnu", "mrežu", "prevoznika", "Njemačku,", "Italiju,", "Sloveniju,", "Hrvatsku", "Bosnu."],
    P3: ["efikasnošću,", "brzinom", "pouzdanošću,", "klijenata"],
  },
};

export const medicalTourismBS = {
  id: 3,
  title: "MEDICINSKI TURIZAM",
  subTitle: `Trebate pomoć s medicinskim turizmom...?`,
  cta: `Pošaljite nam email!`,
  descriptionP1: "",
  descriptionP2:
    "Naš cilj je pružiti vrhunske medicinske tretmane u Istanbulu uz all-inclusive pakete koji obuhvataju: smještaj u hotelu i bolnici, 24/7 zdravstvenu njegu, VIP prevoz, iskusne doktore, preoperativne analize, postoperativnu podršku, lijekove, nutricionističke savjete i podršku na Vašem jeziku.",
  descriptionP3:
    "Fokusiramo se na zadovoljstvo pacijenata, pružajući kompletnu podršku uz certificirane zdravstvene usluge i sveobuhvatno putničko iskustvo prilagođeno individualnim potrebama.",
  videoUrl: "/betterminela.mp4",
  highLightedWords: {
    P1: [],
    P2: [
      "cilj",
      "pružiti",
      "vrhunske",
      "medicinske",
      "tretmane",
      "Istanbul",
      "Turska",
      "povoljnim",
      "cijenama",
      "all-inclusive",
      "pakete",
      "smještaj",
      "hotelu",
      "bolnici",
      "zdravstvenu",
      "njegu",
      "24/7",
      "VIP",
      "prevoz",
      "iskusne",
      "doktore",
      "preoperativne",
      "analize",
      "postoperativnu",
      "podršku",
      "lijekove",
      "nutricionističke",
      "savjete",
      "podrška",
      "Vašem",
      "jeziku",
      "klijentima"
    ],
    P3: [
      "pacijente",
      "podršku",
      "certificirane",
      "zdravstvene",
      "usluge",
      "putničko",
      "iskustvo"
    ],
  },
};
