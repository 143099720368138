import { Typography } from "@material-tailwind/react";
import React from "react";

export const ServicesDescription = ({ content, className }) => {
  return (
    <div className="flex flex-col h-full items-center pt-6 xl:pt-2">
      <div className="max-w-lg text-neutral-400 space-y-4 xl:space-y-2">
      <Typography className={`text-center lg:text-left text-base md:text-lg lg:text-sm xl:text-md 2xl:text-lg  ${className}`}>
          {" "}
          {content.descriptionP1.split(" ").map((word, index) => {
            return (
              <span
                key={index}
                className={
                  content.highLightedWords.P1.includes(word)
                    ? "font-bold text-primary "
                    : ""
                } // Change "highlight" to the word you want to emphasize
              >
                {word}{" "}
              </span>
            );
          })}
        </Typography>
        <Typography className={`text-center lg:text-left text-base md:text-lg lg:text-sm xl:text-md 2xl:text-lg  ${className}`}>
          {" "}
          {content.descriptionP2.split(" ").map((word, index) => {
            return (
              <span
                key={index}
                className={
                  content.highLightedWords.P2.includes(word)
                    ? "font-bold text-primary"
                    : ""
                } // Change "highlight" to the word you want to emphasize
              >
                {word}{" "}
              </span>
            );
          })}
        </Typography>
        <Typography className={`text-center lg:text-left text-base md:text-lg lg:text-sm xl:text-md 2xl:text-lg  ${className}`}>
          {" "}
          {content.descriptionP3.split(" ").map((word, index) => {
            return (
              <span
                key={index}
                className={
                  content.highLightedWords.P3.includes(word)
                    ? "font-bold text-primary"
                    : ""
                } // Change "highlight" to the word you want to emphasize
              >
                {word}{" "}
              </span>
            );
          })}
        </Typography>
      </div>
    </div>
  );
};
