import React from "react";

import MedicalTourismServiceCards from "../components/MedicalTourismServiceCards";

import { VelocityScroll } from "../components/ui/VelocityScroll";
import { ImportExport } from "../components/ImportExport";
import Transportation from "../components/Transportation";
import MedicalTourism from "../components/MedicalTourism";
import { PiTruckTrailerLight } from "react-icons/pi";
import { FaTruckFast } from "react-icons/fa6";
import MapChart from "../components/MapChart";
import DotPattern from "../components/ui/DotPattern";
import { cn } from "../lib/utils";
export function Services({
  importExportRef,
  transportationRef,
  medicalTourismRef,
}) {
  return (
    <section>
      <div ref={importExportRef}>
        <ImportExport />
      </div>
      <div className="h-full w-full py-10">
        <VelocityScroll
          text={
            <FaTruckFast size={100} className="text-primary inline-block" />
          }
          default_velocity={5}
          className="font-display text-center text-4xl font-bold tracking-[-0.02em] text-black drop-shadow-sm dark:text-white md:text-7xl md:leading-[5rem]"
        />
      </div>
      <div ref={transportationRef}>
        <Transportation />
      </div>
      <div ref={medicalTourismRef}>
        <MedicalTourism />
      </div>{" "}
      <div className="bg-primary mx-auto pt-8">
        <MedicalTourismServiceCards />
      </div>
    </section>
  );
}

export default Services;
