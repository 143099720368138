const translationsEn = {
  aboutUs: "About Us",
  products: "Products",
  services: "Services",
  contactUs: "Contact Us",
  button: "Bosanski",
  importExport: "Import",
  transportation: "Transportation",
  medicalTourism: "Medical Tourism",
  home: "Home",
};

export default translationsEn;
